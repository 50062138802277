<template>
  <div class="template-score clickable" @click="showTemplateScore">
    <font-icon
      size="0.9"
      :color="
        nbMissing ? 'var(--ion-color-warning)' : 'var(--ion-color-success)'
      "
      :name="nbMissing ? 'warning' : 'check'"
      material
    />
    <div>
      {{ t("ticketModal.missingFields", { nb: nbMissing }, nbMissing) }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { modalController } from "@ionic/vue";
import TemplateScoreModal from "@/components/modals/TemplateScoreModal.vue";
import { Ticket, TicketTemplate } from "@/types";
import { useI18n } from "@/composables/useI18n";
import { calcModalPercents } from "@/utils/modals";

export default defineComponent({
  name: "TemplateScore",
  props: {
    template: {
      type: Object as PropType<TicketTemplate>,
      required: true,
    },
    requiredFields: {
      type: Object,
      required: true,
    },
    nbFilled: {
      type: Number,
      required: true,
    },
    nbTotal: {
      type: Number,
      required: true,
    },
    ticket: {
      type: Object as PropType<Ticket>,
      required: true,
    },
  },
  emits: ["show"],
  setup(props, { emit }) {
    const nbMissing = computed(() => {
      return props.nbTotal - props.nbFilled;
    });
    const score = computed(() => {
      if (!props.nbTotal) return 1;
      return Math.round((props.nbFilled * 100) / props.nbTotal);
    });
    const scoreColor = computed(() => {
      if (!score.value) return "var(--border-color)";
      if (score.value <= 33) return "var(--ion-color-danger)";
      if (score.value <= 66) return "var(--ion-color-warning)";
      return "var(--ion-color-success)";
    });

    const showTemplateScore = async () => {
      emit("show");
      const breakpoints = calcModalPercents(44, props.nbTotal, 138);
      const modal = await modalController.create({
        component: TemplateScoreModal,
        canDismiss: true,
        mode: "ios",
        breakpoints,
        initialBreakpoint: breakpoints[1],
        componentProps: {
          template: props.template,
          scoreColor: scoreColor.value,
          score: score.value,
          fields: props.requiredFields,
          nbTotal: props.nbTotal,
        },
      });
      await modal.present();
    };

    return {
      ...useI18n(),
      nbMissing,
      score,
      showTemplateScore,
    };
  },
});
</script>

<style scoped>
.template-score {
  border: 1px solid var(--ion-border-color);
  border-radius: var(--f-border-radius);
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  font-size: var(--font-size-s);
}
</style>
