import tracker from "@/utils/tracker";
import axios from "axios";
import { v4 } from "uuid";
import { ApiFactory } from "./ApiFactory";

export class ApiFactoryAxios extends ApiFactory {
  static get #POOR_CONNEXION_MAX_DISPLAY(): number {
    return 1000 * 60;
  }

  #tm: NodeJS.Timeout | null = null;

  async sendRequest(requestOptions: any): Promise<any> {
    const fabriqStore = this.getFabriqStore();
    const uuid = requestOptions.uuid || v4();
    try {
      if (this.#tm) clearTimeout(this.#tm);
      if (requestOptions.uuid) {
        tracker.nextRequest(uuid, "retry");
      } else {
        tracker.beginRequest(uuid, requestOptions.url, "start");
      }
      const startTime = Date.now();
      fabriqStore.startRequest();
      const response: any = await axios(requestOptions);
      const endTime = Date.now();
      const duration = endTime - startTime;
      fabriqStore.setLastRequestDuration(duration);
      this.#tm = setTimeout(() => {
        fabriqStore.resetRequestDuration();
      }, ApiFactoryAxios.#POOR_CONNEXION_MAX_DISPLAY);
      fabriqStore.endRequest();
      if (response.status < 200 && response.status >= 300) {
        tracker.nextRequest(uuid, `error: ${response.status} ${response.data}`);
        tracker.end(uuid);
        return Promise.reject(response.data);
      }
      this.resetAttempts();
      tracker.end(uuid);
      return response.data;
    } catch (e: any) {
      this.#tm = setTimeout(() => {
        fabriqStore.resetRequestDuration();
      }, ApiFactoryAxios.#POOR_CONNEXION_MAX_DISPLAY);
      fabriqStore.endRequest();
      tracker.nextRequest(uuid, "error");
      if (e && e.response && e.response.status === 401) {
        tracker.nextRequest(uuid, "refreshToken");
        return this.refreshToken()
          .then(() => {
            this.resetAttempts();
            return this.sendRequest({
              uuid,
              ...requestOptions,
              headers: this.getHeaders(requestOptions.headers),
            });
          })
          .catch((e) => {
            tracker.nextRequest(uuid, "reconnect");
            console.warn(e);
            return this.reconnectAttempt()
              .then(() => {
                this.resetAttempts();
                return this.sendRequest({
                  uuid,
                  ...requestOptions,
                  headers: this.getHeaders(requestOptions.headers),
                });
              })
              .catch((e) => {
                console.error("Error", e);
                fabriqStore.logout();
              });
          });
      } else {
        tracker.end(uuid);
      }
    }
  }
}
