import { NotSavedYetError } from "@/classes/errors";
import { ListService, Task } from "@/types";
import { ticketUpdatedByMe } from "@/utils/socket";
import { ApiClient } from "./clients";

export const TasksService: ListService<Task> = {
  async update(entity: Task, columns: any) {
    if (entity.ticket) ticketUpdatedByMe(+entity.ticket);
    const task = await ApiClient.put(
      `/api/v1/ticket/${entity.ticket}/task/${entity.id}/`,
      columns
    );
    return { ...task, user: task.user?.id };
  },
  async add(entity: Task) {
    if (typeof entity.ticket !== "number")
      return Promise.reject(new NotSavedYetError("Ticket not saved yet"));
    if (entity.ticket) ticketUpdatedByMe(+entity.ticket);
    const task = await ApiClient.post(`/api/v1/ticket/${entity.ticket}/task/`, {
      description: entity.description,
      user: entity.user,
      due_date: entity.due_date,
      order: entity.order,
      created_from_template: entity.created_from_template,
      config: entity.config,
    });
    return { ...task, user: task.user?.id };
  },
  async remove(entity: Task) {
    if (typeof entity.ticket !== "number") return Promise.resolve();
    if (entity.ticket) ticketUpdatedByMe(+entity.ticket);
    await ApiClient.delete(
      `/api/v1/ticket/${entity.ticket}/task/${entity.id}/`
    );
  },
};
