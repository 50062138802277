import { ApiFactoryAxios } from "@/classes/ApiFactoryAxios";
import { ApiFactoryHttp } from "@/classes/ApiFactoryHttp";
import config from "@/config";

const ApiClient = new ApiFactoryAxios(config.apiUrl);
const SearchClient = new ApiFactoryHttp(config.searchUrl);

const KpiV3Client = new ApiFactoryAxios(config.apiUrl);
const EventsClient = new ApiFactoryAxios(config.apiUrl);
const DependenciesClient = new ApiFactoryAxios(config.apiUrl);
const KnowledgeClient = new ApiFactoryAxios(config.apiUrl);

export {
  ApiClient,
  SearchClient,
  KpiV3Client,
  EventsClient,
  DependenciesClient,
  KnowledgeClient,
};
