import { createListStore } from "./list";
import { InstanceService } from "@/services/instances";
import { Instance, Routine, Schedule } from "@/types";

import { useSchedulesStore } from "./schedules";
import { useRoutinesStore } from "./routines";
import { useFabriqStore } from "./fabriq";

const hooks = {
  all(
    options: any,
    entities: {
      routines: Array<Routine>;
      instances: Array<Instance & { template: number }>;
      schedules: Array<Schedule>;
    }
  ) {
    const routinesStore = useRoutinesStore();
    const schedulesStore = useSchedulesStore();

    const routines = entities.routines.map((r: Routine) => {
      return {
        ...r,
        updated_at: r.created_at,
      };
    });
    const instances: Array<any> = [];
    entities.instances.forEach((i: Instance & { template: number }) => {
      instances.push({
        ...i,
        template_id: i.template,
        template: undefined,
        updated_at: i.created_at,
        sending: false,
      });
    });
    routinesStore.merge(routines);
    schedulesStore.merge(
      entities.schedules.map((s: Schedule) => ({
        ...s,
        updated_at: s.created_at,
      }))
    );

    return Promise.resolve(instances);
  },
};

export const useInstancesStore = createListStore(
  "instances",
  InstanceService,
  hooks,
  null,
  {
    async create(instance: Instance) {
      if (!InstanceService.create) return;
      const fabriqStore = useFabriqStore();
      if (!fabriqStore.online) return Promise.resolve(instance);
      const created = await InstanceService.create(instance);
      await this.update(instance.uuid, { id: created.id });
      return { ...instance, id: created.id };
    },
  },
  true
);
