<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item>
        <div class="hint">{{ t("common.locale") }}</div>
      </ion-item>

      <ion-item
        v-for="locale in locales"
        :key="locale"
        @click="$emit('done', locale.value)"
        class="clickable"
      >
        <ion-label class="list-item">{{ locale.label }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonContent, IonList, IonItem, IonLabel } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { LOCALES_OPTIONS } from "@/i18n/index";

export default defineComponent({
  name: "TicketCreateModal",
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
  },
  emits: ["done", "cancel"],
  setup() {
    const { t } = useI18n();
    const locales = LOCALES_OPTIONS;
    return {
      t,
      locales,
    };
  },
});
</script>

<style scoped>
.list-item {
  font-size: var(--font-size-m);
}
</style>
