<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar edit-modal-toolbar">
      <ion-title>{{ label }}</ion-title>
      <ion-buttons slot="end">
        <ion-button fill="clear" class="cancel-btn" @click="$emit('done')">
          {{ t("common.done") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <div
      class="modal-header"
      style="background-color: var(--ion-color-primary-contrast)"
    >
      <div
        class="ion-padding-horizontal ion-margin-horizontal"
        style="background-color: var(--ion-color-primary-contrast)"
      >
        <ion-input
          class="filter-input"
          :placeholder="placeholder"
          v-model="valueFilter"
        />
        <div class="selected-values ion-margin-top">
          <colored-chip
            clearable
            v-for="(v, idx) of selectedValues"
            :key="idx"
            :label="allowedValues?.find((val) => val.id === v)?.name || ''"
            @remove="removeValue(v)"
          />
        </div>
      </div>
      <div class="list-title ion-margin-top">
        {{ chooseAFieldLabel }}
      </div>
    </div>
  </ion-header>

  <ion-content :fullscreen="true" :scroll-y="false">
    <ion-list ref="list" class="scroller ion-padding-horizontal" lines="none">
      <recycle-scroller
        class="scroller"
        :items="filteredValues"
        :item-size="44"
      >
        <template #default="{ item }">
          <ion-item @click="addValue(item.id)">
            <colored-chip class="clickable" :label="item.name" />
          </ion-item>
        </template>
      </recycle-scroller>
    </ion-list>
  </ion-content>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useAutofocus } from "@/composables/useAutofocus";
import ColoredChip from "../ui/ColoredChip.vue";
import { Field } from "@/types";

interface Props {
  selectedValues: (number | string)[];
  allowedValues: Field[];
  placeholder: string;
  label: string;
  chooseAFieldLabel: string;
}

const emit = defineEmits(["update", "done"]);
const props = defineProps<Props>();

const { t } = useI18n();
const valueFilter = ref("");

onMounted(() => useAutofocus(".filter-input input"));

const filteredValues = computed(() => {
  return props.allowedValues
    .filter((field: Field) => {
      if (props.selectedValues?.includes(parseInt(`${field.id}`))) return false;
      if (valueFilter.value === "") return true;
      return field.name
        .toLocaleLowerCase()
        .includes(valueFilter.value.toLocaleLowerCase());
    })
    .sort((a, b) => a.name?.localeCompare(b.name));
});

const removeValue = (value: number | string) => {
  emit(
    "update",
    props.selectedValues.filter((id) => id != value)
  );
};

const addValue = (value: number | string) => {
  emit("update", [...props.selectedValues, value]);
};
</script>

<style scoped>
.selected-values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--ion-padding) / 2);
  max-height: 100px;
  overflow: auto;
}
.list-title {
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary-shade);
}
</style>
