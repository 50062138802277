import { useConfigStore } from "./config";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { defineStore } from "pinia";
import {
  ApiClient,
  SearchClient,
  KpiV3Client,
  EventsClient,
  DependenciesClient,
  KnowledgeClient,
} from "@/services/clients";
import { AuthClient } from "@/services/auth";
import { SUPPORT_LOCALES } from "@/i18n";
import { StatusBar, Style } from "@capacitor/status-bar";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import {
  AuthType,
  Client,
  FabriqState,
  RoutineExecutionView,
  Token,
  User,
} from "@/types";
import { UserService } from "@/services/user";
import { Capacitor } from "@capacitor/core";
import storage from "@/utils/storage";
import { fromUnixTime } from "date-fns";
import buildSocket from "@/utils/socket";
import { Badge } from "@capawesome/capacitor-badge";
import loader from "@/utils/loader";
import tracker from "@/utils/tracker";
import { version } from "../../package.json";
import { useClientStore } from "./client";
import config from "@/config";
import { urlOpener } from "@/utils/aws-url-opener";
import type { CheckIfEmailExistsData } from "@/classes/FabriqAuth";
import { MessagingSocket } from "@/utils/socket-builder";
import { buildSentryManager, type SentryManager } from "@/utils/sentry";
import { App } from "vue";

interface CheckEmailResult extends CheckIfEmailExistsData {
  futureIdpStatus?: "internal" | "external" | undefined;
  organizationAuthenticationConfig?: {
    ssoMandatory: boolean;
    defaultSSOId?: string | undefined;
    ssoLabel?: string | undefined;
    emailPatterns?: string[] | undefined;
  };
  error?: string;
}

export const FUTURE_SUBDOMAIN_KEY = "future-subdomain";

let socket: MessagingSocket | null = null;
let sentryManager: SentryManager | null = null;

export const useFabriqStore = defineStore("core", {
  state: (): FabriqState => {
    return {
      version,
      today: new Date(),
      locked: false,
      connected: false,
      online: true,
      hideTabs: false,
      token: null,
      loading: false,
      requests: 0,
      locale: "en",
      executionView: RoutineExecutionView.List,
      user: null,
      configChanged: false,
      initializing: true,
      platform: Capacitor.getPlatform(),
      page: "tickets",
      colorScheme: "dark",
      subdomain: null,
      theme: undefined,
      showClosedTasks: [],
      thresholdDuration: 2000,
      lastRequestDuration: 0,
      lastRequestDurations: [],
      pageToOpenAfterLogin: "",
    };
  },
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    setLastRequestDuration(duration: number) {
      this.lastRequestDuration = duration;
      this.lastRequestDurations.unshift(duration);
      this.lastRequestDurations = this.lastRequestDurations.slice(0, 5);
    },
    resetRequestDuration() {
      this.lastRequestDurations = [];
    },
    setVersion(version: string) {
      this.version = version;
    },
    setLocale(locale: string) {
      if (!SUPPORT_LOCALES.includes(locale)) return;
      this.locale = locale;
      storage.set("fabriq-locale", locale);
    },
    setColorScheme(theme: string) {
      this.colorScheme = theme;
    },
    setPageToOpenAfterLogin(page: string) {
      this.pageToOpenAfterLogin = page;
    },
    async setTheme(theme: string | undefined) {
      this.theme = theme;
      if (this.theme) {
        localStorage.setItem("fabriq-theme", this.theme);
      } else {
        localStorage.removeItem("fabriq-theme");
      }
      document.body.classList.remove("dark");
      await StatusBar.setStyle({ style: Style.Light }).catch((e) =>
        console.warn(e)
      );
      if (
        this.theme === "dark" ||
        (!this.theme && this.colorScheme === "dark")
      ) {
        document.body.classList.add("dark");
        await StatusBar.setStyle({ style: Style.Dark }).catch((e) =>
          console.warn(e)
        );
      }
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    setPage(page: string) {
      if (this.page === page) return;
      mixpanelTracker.track(`open | ${page}`, { from: this.page });
      this.page = page;
      tracker.tunnel(
        "page",
        "User change page",
        RecordCategory.General,
        ActionType.Process,
        page
      );
    },
    setOnline(online: boolean) {
      tracker.track(
        "network",
        `Network is ${online ? "active" : "inactive"}`,
        RecordCategory.Network
      );
      this.online = online;
      if (!online) loader.hide();
      ApiClient.setOnline(this.online);
      SearchClient.setOnline(this.online);
      KpiV3Client.setOnline(this.online);
      EventsClient.setOnline(this.online);
      KnowledgeClient.setOnline(this.online);
      DependenciesClient.setOnline(this.online);
    },
    closeTask(uuid: string) {
      this.showClosedTasks.push(uuid);
      setTimeout(() => {
        const idx = this.showClosedTasks.indexOf(uuid);
        if (idx < 0) return;
        this.showClosedTasks = [
          ...this.showClosedTasks.slice(0, idx),
          ...this.showClosedTasks.slice(idx + 1),
        ];
      }, 5000);
    },
    cancelCloseTask(uuid: string) {
      const idx = this.showClosedTasks.indexOf(uuid);
      if (idx < 0) return;
      this.showClosedTasks = [
        ...this.showClosedTasks.slice(0, idx),
        ...this.showClosedTasks.slice(idx + 1),
      ];
    },
    setLocked(locked: boolean) {
      this.locked = locked;
    },
    setInitializing(initializing: boolean) {
      this.initializing = initializing;
    },
    startRequest() {
      this.requests += 1;
    },
    endRequest() {
      if (this.requests > 0) this.requests -= 1;
    },
    setToken(token: Token | null) {
      this.token = token;
      this.connected = !!token;
      ApiClient.setToken(token);
      SearchClient.setToken(token);
      KpiV3Client.setToken(token);
      EventsClient.setToken(token);
      KnowledgeClient.setToken(token);
      DependenciesClient.setToken(token);
      ApiClient.checkToken(this);
      SearchClient.checkToken(this);
      KpiV3Client.checkToken(this);
      EventsClient.checkToken(this);
      KnowledgeClient.checkToken(this);
      DependenciesClient.checkToken(this);
    },
    async setCredentials(email: string, password: string, type: string) {
      AuthClient.setCredentials(email, password, type);
      await this.initializeApiClients();
    },
    async checkCurrent(
      email: string
    ): Promise<{ token: Token; user: any } | null> {
      // TODO To be done with futureAuth

      this.loading = true;
      const { token, user, future } = await AuthClient.checkCurrentSession();

      this.loading = false;
      if (!token || !user) return null;
      if (future) {
        if (
          user?.emailAddress?.toLowerCase() === email.toLowerCase() ||
          user?.username?.toLowerCase() === email.toLowerCase()
        ) {
          return {
            token: {
              type: AuthType.Future,
              // @ts-expect-error futureAuth
              accessToken: token.access.token,
              // @ts-expect-error futureAuth
              refreshToken: token.refresh.token,
              // @ts-expect-error futureAuth
              expiresAt: fromUnixTime(token.access.payload.exp),
            },
            user,
          };
        } else {
          throw new Error(user?.emailAddress?.toLowerCase());
        }
      } else {
        if (user?.attributes?.email?.toLowerCase() === email.toLowerCase()) {
          return {
            token: {
              type: AuthType.External,
              // @ts-expect-error futureAuth
              accessToken: token.getIdToken().getJwtToken(),
              // @ts-expect-error futureAuth
              refreshToken: token.getRefreshToken().getToken(),
              // @ts-expect-error futureAuth
              expiresAt: fromUnixTime(token.getAccessToken().payload?.exp),
            },
            user,
          };
        }
      }
      return null;
    },
    async checkEmail(email: string): Promise<CheckEmailResult> {
      const authHandlerResult = await AuthClient.checkEmail(email);
      if (authHandlerResult.type !== AuthType.Future) return authHandlerResult;
      await storage.set(
        FUTURE_SUBDOMAIN_KEY,
        authHandlerResult.futureSubdomain
      );
      await this.initializeApiClients();
      const config = await AuthClient.organizationAuthenticationConfig();
      try {
        const type = await AuthClient.userIdpProfile(email);
        if (type === "internal")
          return {
            ...authHandlerResult,
            futureIdpStatus: "internal",
            organizationAuthenticationConfig: config,
          };
        const authenticationUrl = await AuthClient.ssoAuthenticationUri();
        if (!authenticationUrl) return authHandlerResult;
        localStorage.setItem(
          "sso-url",
          authenticationUrl.replace(/^(https:\/\/[^\/]+).*$/, "$1")
        );
        urlOpener(authenticationUrl);
        return {
          ...authHandlerResult,
          futureIdpStatus: "external",
          organizationAuthenticationConfig: config,
        };
      } catch (e) {
        console.error(e);
        try {
          await this.checkCurrent(email);
          return {
            ...authHandlerResult,
            futureIdpStatus: "external",
            organizationAuthenticationConfig: config,
            error: "wrongCredentials",
          };
        } catch (e: any) {
          return {
            ...authHandlerResult,
            futureIdpStatus: "external",
            organizationAuthenticationConfig: config,
            error: e?.message,
          };
        }
      }
    },
    async initializeApiClients() {
      const subdomainUrl = await storage.get(FUTURE_SUBDOMAIN_KEY);
      if (!subdomainUrl) return;
      if (!sentryManager) {
        throw new Error("Sentry not setup; cannot report error");
      }
      this.subdomain = subdomainUrl;
      sentryManager.setupSentry(subdomainUrl);
      ApiClient.applySubDomainToBaseUrl(
        subdomainUrl,
        config.futureLegacyApiPath
      );
      SearchClient.applySubDomainToBaseUrl(
        subdomainUrl,
        config.futureLegacySearchPath
      );
      KpiV3Client.applySubDomainToBaseUrl(subdomainUrl, config.futureKpiPrefix);
      EventsClient.applySubDomainToBaseUrl(
        subdomainUrl,
        config.futureEventsPrefix
      );
      KnowledgeClient.applySubDomainToBaseUrl(
        subdomainUrl,
        config.knowledgePrefix
      );
      DependenciesClient.applySubDomainToBaseUrl(
        subdomainUrl,
        config.futureDependenciesPrefix
      );
      AuthClient.applySubDomainUrl(subdomainUrl);
      tracker.applySubDomainUrl(
        subdomainUrl + config.futureMobilePerformancePath
      );
    },
    async login(email: string, password: string, type: string) {
      this.loading = true;
      const token = await AuthClient.login(email, password, type);
      this.setToken(token);
      this.loading = false;
    },
    async loadUserData(
      teamsStore?: any,
      init = false
    ): Promise<{ user: User; client?: Client } | undefined> {
      try {
        const configStore = useConfigStore();
        const clientStore = useClientStore();

        const user = await UserService.me();
        localStorage.setItem("nb-login", "0");
        this.user = user;
        configStore.setConfig(user.config || {});

        mixpanelTracker.setUser(user);
        if (user.config?.hasPushNotifications === undefined && this.user?.id) {
          configStore.setHasNotifications(true);
          configStore.saveUserConfig();
        }

        tracker.setUserId(user.id);
        storage.writeToStorage("user", user);

        if (!init || !clientStore.client) {
          if (teamsStore) {
            tracker.next("connexion", ActionType.Request, "load teams data");
            await teamsStore.all({ id: user.id });
          }
          tracker.next("connexion", ActionType.Request, "load client data");
          const client = await clientStore.load();
          if (client) tracker.setClientId(client.id);
          storage.writeToStorage("client", client);
          return { user, client };
        }
        return { user };
      } catch (e) {
        console.error(e);
      }
    },
    async loadUserOnly(user: User): Promise<User | undefined> {
      try {
        const configStore = useConfigStore();
        this.user = user;
        configStore.setConfig(user.config || {});
        if (SUPPORT_LOCALES.includes(user.language)) {
          this.setLocale(user.language);
        }
        if (this.locale !== user.language) this.setLocale(user.language);
        mixpanelTracker.setUser(user);
        if (user.config?.hasPushNotifications === undefined && this.user?.id) {
          configStore.setHasNotifications(true);
          configStore.saveUserConfig();
        }
        storage.writeToStorage("user", user);
        return Promise.resolve(user);
      } catch (e) {
        console.error(e);
      }
    },
    async connectSocket() {
      if (config.env === "local") return;
      if (!this.subdomain) return;
      socket = await buildSocket(`${this.subdomain}${config.messagingBaseUrl}`);
      socket.open();
    },
    buildSentry(app: App) {
      sentryManager = buildSentryManager(app, this.platform);
    },
    reportSentry(error: Error, data?: Record<string, any>) {
      if (!sentryManager) {
        throw new Error("Sentry not setup; cannot report error");
      }
      sentryManager.reportError(error, data);
    },
    async setTeams(teamIds: Array<number>) {
      if (!socket) return;
      const token = await ApiClient.getFreshToken();
      socket.emit("teamSubscribe", {
        teams: teamIds,
        token: token?.accessToken,
      });
    },
    async logout() {
      const notificationsToken = await storage.get(
        `notificationsToken-${this.version}`
      );
      if (notificationsToken) {
        try {
          await UserService.removePushNotificationToken(notificationsToken);
        } catch (e) {
          console.error(e);
        }
      }
      storage.set(`notificationsToken-${this.version}`, null);
      try {
        await AuthClient.logout(this.token);
      } catch (e) {
        this.reportSentry(e as Error);
        console.error(e);
      }
      await storage.clear();
      Badge.clear();
      this.user = null;
      this.token = null;
      const clientStore = useClientStore();
      clientStore.logout();
      this.connected = false;
      window.location.href = "/login";
    },
  },
});
