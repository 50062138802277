import { DiamondDependency } from "@/types";
import { createListStore } from "./list";
import { DiamondDependenciesService } from "@/services/diamondDependencies";

export const useDiamondDependenciesStore = createListStore(
  "diamondDependencies",
  DiamondDependenciesService,
  undefined,
  {
    removeTicketEventDependencies(ticketId: number) {
      this.collection = this.collection.filter(
        (dependency: DiamondDependency) => {
          if (dependency.dependentType === "ticket")
            return dependency.dependentId !== ticketId;
          if (dependency.dependentType === "event")
            return dependency.relatedId !== ticketId;
          return true;
        }
      );
    },

    removeEventTicketDependencies(eventId: string) {
      this.collection = this.collection.filter(
        (dependency: DiamondDependency) => {
          if (dependency.dependentType === "event")
            return dependency.dependentId !== eventId;
          if (dependency.dependentType === "ticket")
            return dependency.relatedId !== eventId;
          return true;
        }
      );
    },
  },
  undefined,
  true
);
