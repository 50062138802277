<script lang="ts" setup>
import { computed } from "vue";
import CachedImage from "@/components/tools/CachedImage.vue";
import { FabriqFile } from "@/types";
import FileIcon from "@/components/ui/FileIcon.vue";
import { useFile } from "@/composables/useFile";

interface Props {
  file: FabriqFile;
  readonly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  readonly: false,
});

const emit = defineEmits<{
  (event: "remove"): void;
}>();

const { src, isImage, extension, image } = useFile(computed(() => props.file));
</script>

<template>
  <div class="comment-image-div" slot="start">
    <font-icon
      v-if="!file.id"
      class="send-file"
      size="0.3"
      name="spinner"
      color="var(--ion-color-secondary)"
      spin
    />
    <font-icon
      v-if="!file.id"
      class="send-file cloud"
      size="0.5"
      name="cloud-arrow-up"
    />
    <div
      class="comment-file-image-wrapper"
      :class="`image-${file.id}`"
      v-if="file.media_type === 'file' && isImage"
    >
      <cached-image
        v-if="isImage"
        ref="image"
        :viewer="true"
        class="comment-file-image"
        :src="src"
      />
    </div>
    <div v-else class="comment-file-image-wrapper">
      <file-icon
        :class="{ 'file-icon': file.media_type === 'file' }"
        :extension="extension"
      />
    </div>
    <div class="remove-btn" @click="emit('remove')" v-if="!readonly">
      <font-icon name="close" size="0.4" color="white" />
    </div>
  </div>
</template>

<style scoped>
.comment-image-div {
  --img-size: 56px;
}
.comment-image-div,
.comment-file-image-wrapper,
.comment-file-image,
.comment-file-image > span {
  position: relative;
  display: inline-block;
  width: var(--img-size);
  height: var(--img-size);
  min-width: var(--img-size);
  min-height: var(--img-size);
  max-width: var(--img-size);
  max-height: var(--img-size);
}

.remove-btn {
  background-color: black;
  border-radius: 50px;
  position: absolute;
  top: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.comment-file-image > span {
  position: relative;
  display: inline-block;
  width: var(--img-size);
  height: var(--img-size);
  min-width: var(--img-size);
  min-height: var(--img-size);
  max-width: var(--img-size);
  max-height: var(--img-size);
}
.comment-file-image > span img {
  width: var(--img-size);
  height: var(--img-size);
  object-fit: cover;
  border-radius: 8px;
}
.comment-file-image-wrapper .font-icon {
  --fa-font-size: 3.5rem !important;
  --icon-size: 3.5rem !important;
  margin-left: 0;
  margin-top: 0;
}
.comment-file-image-wrapper .font-icon.file-icon {
  margin-left: 8px;
}
</style>
