import { ReconnectingWebSocket } from "./reconnecting_web_socket";

export interface MessagingSocket
  extends Pick<ReconnectingWebSocket, "open" | "close"> {
  emit(type: string, payload: unknown): void;
  onmessage?: (ev: MessageEvent) => void;
  onopen?: () => void;
  onclose?: () => void;
  onerror?: () => void;
}

export function buildReconnectingWebSocket(
  url: string,
  token?: string
): ReconnectingWebSocket {
  const wsUrl = new URL(url);
  wsUrl.protocol = wsUrl.protocol.replace("http", "ws");
  let authenticatedUrl = wsUrl.toString();
  if (token) {
    authenticatedUrl = authenticatedUrl + `?accessToken=${token}`;
  }

  return new ReconnectingWebSocket(authenticatedUrl);
}

export function buildMessagingSocket(
  socket: ReconnectingWebSocket
): MessagingSocket {
  const messagingSocket: MessagingSocket = {
    emit: socket.emit.bind(socket),
    open: socket.open.bind(socket),
    close: socket.close.bind(socket),
  };

  socket.addEventListener("open", () => {
    if (messagingSocket.onopen) {
      messagingSocket.onopen();
    }
  });

  socket.addEventListener("close", () => {
    if (messagingSocket.onclose) {
      messagingSocket.onclose();
    }
  });

  socket.addEventListener("error", () => {
    if (messagingSocket.onerror) {
      messagingSocket.onerror();
    }
  });

  socket.addEventListener("message", (event: any) => {
    if (messagingSocket.onmessage) {
      messagingSocket.onmessage(event);
    }
  });

  return messagingSocket;
}
