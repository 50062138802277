import { log } from "@/utils/notifications";
import {
  CapacitorHttp as Http,
  HttpOptions,
  HttpResponse,
} from "@capacitor/core";

import { ApiFactory } from "./ApiFactory";

export class ApiFactoryHttp extends ApiFactory {
  static get #POOR_CONNEXION_MAX_DISPLAY(): number {
    return 1000 * 60;
  }

  #tm: NodeJS.Timeout | null = null;

  #reworkParams(params: any): any {
    if (!params) return params;
    const reworked: any = [];
    Object.entries(params).forEach(([key, value]) => {
      if (typeof value === "number") {
        reworked.push([key, `${value}`]);
      } else {
        reworked.push([key, value]);
      }
    });
    return Object.fromEntries(reworked);
  }

  async sendRequest(requestOptions: HttpOptions): Promise<any> {
    try {
      if (this.#tm) clearTimeout(this.#tm);
      const fabriqStore = this.getFabriqStore();
      const startTime = Date.now();
      fabriqStore.startRequest();
      const reworkedOptions = {
        ...requestOptions,
        params: this.#reworkParams(requestOptions.params),
      };
      const response: HttpResponse = await Http.request(reworkedOptions);
      fabriqStore.endRequest();
      const endTime = Date.now();
      const duration = endTime - startTime;
      fabriqStore.setLastRequestDuration(duration);
      this.#tm = setTimeout(() => {
        fabriqStore.resetRequestDuration();
      }, ApiFactoryHttp.#POOR_CONNEXION_MAX_DISPLAY);
      if (response.status === 401) {
        return this.refreshToken()
          .then(() => {
            this.resetAttempts();
            return this.sendRequest({
              ...requestOptions,
              headers: this.getHeaders(requestOptions.headers),
            });
          })
          .catch((e) => {
            console.warn(e);
            return this.reconnectAttempt()
              .then(() => {
                this.resetAttempts();
                return this.sendRequest({
                  ...requestOptions,
                  headers: this.getHeaders(requestOptions.headers),
                });
              })
              .catch((e) => {
                console.error("Error", e);
                fabriqStore.logout();
              });
          });
      }
      if (response.status < 200 && response.status >= 300) {
        log(response);
        console.error(response);
        return Promise.reject(response.status);
      }
      this.resetAttempts();

      return response.data;
    } catch (e: any) {
      log(e);
      console.error("sendRequest", e);
    }
  }
}
