<template>
  <div>
    <text-input
      class="step-input"
      :modelValue="answer.value"
      @update:modelValue="$emit('value', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TemplateStep, ExecutionAnswer } from "@/types";
import { useI18n } from "@/composables/useI18n";
import TextInput from "@/components/ui/TextInput.vue";

export default defineComponent({
  name: "StepLongText",
  components: { TextInput },
  emits: ["value"],
  props: {
    step: {
      type: Object as PropType<TemplateStep>,
      default: () => ({}),
    },
    answer: {
      type: Object as PropType<ExecutionAnswer>,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ...useI18n(),
    };
  },
});
</script>

<style scoped></style>
