<template>
  <div>
    <ion-textarea
      class="step-input"
      auto-grow
      rows="1"
      inputmode="text"
      :placeholder="t('routines.placeholders.text')"
      :modelValue="answer.value"
      @update:modelValue="$emit('value', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TemplateStep, ExecutionAnswer } from "@/types";
import { IonTextarea } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";

export default defineComponent({
  name: "StepText",
  components: { IonTextarea },
  emits: ["value"],
  props: {
    step: {
      type: Object as PropType<TemplateStep>,
      default: () => ({}),
    },
    answer: {
      type: Object as PropType<ExecutionAnswer>,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ...useI18n(),
    };
  },
});
</script>

<style scoped></style>
