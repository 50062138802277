<script lang="ts" setup>
import { computed, ComputedRef } from "vue";
import { Datapoint, ExecutionAnswer, TemplateStep } from "@/types";

const props = defineProps<{
  step: TemplateStep;
  indicator: any;
  datapoint: Datapoint;
  answer: ExecutionAnswer;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (event: "value", payload: unknown): void;
}>();

interface Choice {
  key: string;
  value: number;
  selected: boolean;
  class: string;
}

const classPrefix = "step-choice--";
const choices: ComputedRef<Choice[]> = computed(() => {
  return props.indicator.choices.options.map((c: Choice) => {
    const selected = props.answer?.value
      ? parseInt(props.answer?.value) === +c.value
      : false;

    return {
      ...c,
      value: +c.value,
      selected,
      class: props.answer.value
        ? selected
          ? classPrefix + "selected"
          : classPrefix + "not-selected"
        : "",
    };
  });
});
</script>

<template>
  <div class="step-choice-wrapper">
    <div
      v-for="choice of choices"
      @click="emit('value', choice.value)"
      :key="choice.value"
      class="step-choice-item"
      :class="choice.class"
    >
      <p>{{ choice.key }}</p>
    </div>
  </div>
</template>

<style>
.step-choice-wrapper {
  --_step-choice-kpi-status-size: var(--status-size, 2.75);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.step-choice-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((var(--font-size-xl)) * var(--_step-choice-kpi-status-size));
  height: calc((var(--font-size-xl)) * var(--_step-choice-kpi-status-size));
  border-radius: calc(
    (var(--font-size-xl)) * var(--_step-choice-kpi-status-size)
  );
  margin-top: 1rem;
  padding: var(--ion-padding);
  border: solid 1px var(--ion-border-color);
  background-color: var(--ion-background-color);
}

.step-choice-item > p {
  font-size: calc((var(--font-size-xl)) * 1.75);
}

.step-choice--selected {
  animation: blink 0.3s ease 0s 1 normal forwards;
}

.step-choice--not-selected {
  animation: fader 0.5s ease 0s 1 normal forwards;
}

@keyframes blink {
  0% {
    border: 1px solid transparent;
  }
  100% {
    border: 1px solid var(--ion-color-secondary);
  }
}

@keyframes fader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
