<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item>
        <div class="hint">{{ t("tickets.addHeader") }}</div>
      </ion-item>
    </ion-list>
    <ion-list lines="none" class="ticket-templates-list">
      <ion-item
        v-for="template of ticketTemplates"
        :key="template.id"
        @click="done({ type: 'ticket', ticket_template: template.id })"
        class="clickable"
      >
        <font-icon
          v-if="!template.id"
          slot="start"
          name="insert_drive_file"
          material
          outlined
          color="var(--ion-color-primary)"
        />
        <div v-else slot="start" class="emoji">
          {{ template.emoji }}
        </div>
        <ion-label class="list-item">{{ template.label }}</ion-label>
        <font-icon
          v-if="template.id"
          slot="end"
          class="clickable make-favorite"
          @click.stop.prevent="makeTemplateFavorite(template)"
          material
          size="0.813"
          :outlined="!template.favorite"
          :name="template.favorite ? 'star' : 'grade'"
          :color="
            template.favorite
              ? 'var(--ion-color-warning)'
              : 'var(--f-color-discrete)'
          "
        >
        </font-icon>
      </ion-item>
      <br />
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { IonContent, IonItem, IonLabel, IonList } from "@ionic/vue";
import { translateTemplateName, useI18n } from "@/composables/useI18n";
import { useTicketTemplatesStore } from "@/store/ticketTemplates";
import { TicketTemplate } from "@/types";
import { storeToRefs } from "pinia";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { useConfigStore } from "@/store/config";
import { useFabriqStore } from "@/store/fabriq";

export default defineComponent({
  name: "TicketCreateModal",
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
  },
  emits: ["done", "cancel"],
  setup(_, { emit }) {
    const ticketTemplatesStore = useTicketTemplatesStore();
    const configStore = useConfigStore();
    const { config } = storeToRefs(configStore);
    const { locale } = useFabriqStore();
    const { t } = useI18n();

    const done = (ev: { type: string; ticket_template?: unknown }) => {
      emit("done", ev);
    };

    const ticketTemplates = computed(() => {
      const templates: any = [];
      const emptyTemplate = {
        label: t("createTicketModal.blank"),
        config: {
          icon: "mdi-file-outline",
        },
      };
      templates.push(emptyTemplate);
      const favoriteTemplates = [...(config.value?.favoriteTemplates || [])];
      ticketTemplatesStore.collection.forEach((t: TicketTemplate) => {
        if (t.deleted_at) return;
        if (!t.latest) return;
        const searchedId = t.master_ticket_template_id || t.id;
        const favorite = searchedId
          ? favoriteTemplates.includes(+searchedId)
          : false;
        const label = translateTemplateName(t, locale);
        templates.push({
          ...t,
          label,
          emoji: t.config?.emoji || "🏭",
          favorite,
        });
      });
      templates.sort((a: any, b: any) => {
        if (a.favorite && !b.favorite) return -1;
        if (!a.favorite && b.favorite) return 1;
        if (!b.id) return 1;
        return a.label.localeCompare(b.label);
      });
      return templates;
    });

    const makeTemplateFavorite = (t: any) => {
      const favoriteId = t.master_ticket_template_id || t.id;
      mixpanelTracker.track(
        "mark as favorite | ticket template | ticket templates modal"
      );
      if (t.favorite) {
        configStore.removeFavoriteTemplate(favoriteId);
      } else {
        configStore.addFavoriteTemplate(favoriteId);
      }
    };

    return {
      done,
      ticketTemplates,
      ...useI18n(),
      makeTemplateFavorite,
    };
  },
});
</script>

<style scoped>
.hint {
  font-size: var(--font-size-m);
}

.template-list {
  margin-top: var(--ion-padding);
}

.list-item {
  font-size: var(--font-size-m);
}

.make-favorite {
  padding: 10px;
  margin-right: -6px;
}

.emoji {
  font-size: 0.8rem;
}

.ticket-templates-list {
  height: calc(100vh - 210px);
  overflow-y: auto;
}
</style>
