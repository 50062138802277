<script lang="ts" setup>
import { IonButton, IonButtons, IonIcon } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import { useI18n } from "@/composables/useI18n";

const { t } = useI18n();

defineProps<{
  text?: string;
}>();

const emit = defineEmits(["back"]);
</script>

<template>
  <ion-buttons>
    <ion-button @click="emit('back', $event)" class="app-back-button">
      <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
      {{ text || t("common.back") }}
    </ion-button>
  </ion-buttons>
</template>

<style scoped>
.app-back-button {
  margin-left: -8px !important;
  /* padding-right: 1.5rem; */
}

.app-back-button ion-icon[slot="start"] {
  font-size: 14px;
}

ion-button::part(native) {
  --icon-font-size: 14px;
  font-size: 14px;
  color: var(--f-color-discrete);
}

ion-button::part(native) .button-text {
  --icon-font-size: 14px;
  font-size: 14px;
  color: var(--f-color-discrete);
}

ion-button::part(text) {
  /* margin-left: var(--ion-padding); */
}
</style>
