import { useClientStore } from "@/store/client";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import MainPage from "@/views/MainPageTabs.vue";
import LoginPage from "@/views/LoginPage.vue";
import TicketsPage from "@/views/TicketsPage.vue";
import TicketPage from "@/views/TicketPage.vue";
import EventsPage from "@/views/EventsPage.vue";
import InstancePage from "@/views/InstancePage.vue";
import AccountPage from "@/views/AccountPage.vue";
import SearchPage from "@/views/SearchPage.vue";
import RoutinesPage from "@/views/RoutinesPage.vue";
import IndicatorsPage from "@/views/IndicatorsPage.vue";
import KnowledgePage from "@/views/KnowledgePage.vue";
import NotificationsPage from "@/views/NotificationsPage.vue";
import EventPage from "@/views/EventPage.vue";
import { useFabriqStore } from "@/store/fabriq";
import { AuthClient } from "@/services/auth";
import { Browser } from "@capacitor/browser";
import config from "@/config";
import DocumentPage from "@/views/DocumentPage.vue";
import DocumentInfoPage from "@/views/DocumentInfoPage.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    name: "Login",
    path: "/login/:code?",
    component: LoginPage,
    beforeEnter: () => {
      const fabriqStore = useFabriqStore();
      if (fabriqStore.connected) return false;
      return true;
    },
  },
  {
    name: "TicketD",
    path: "/tickets/:id/:instanceId?",
    component: TicketPage,
  },
  {
    name: "DocumentD",
    path: "/documents/:id",
    component: DocumentPage,
    beforeEnter: () => {
      const clientStore = useClientStore();
      return clientStore.config.hasKnowledge;
    },
  },
  {
    name: "DocumentInfoD",
    path: "/documents/:id/info",
    component: DocumentInfoPage,
    beforeEnter: () => {
      const clientStore = useClientStore();
      return clientStore.config.hasKnowledge;
    },
  },

  {
    name: "InstanceD",
    path: "/instances/:instanceId",
    component: InstancePage,
  },
  {
    name: "Account",
    path: "/account",
    component: AccountPage,
  },
  {
    name: "Search",
    path: "/search",
    component: SearchPage,
  },
  {
    name: "Notifications",
    path: "/notifications",
    component: NotificationsPage,
  },
  {
    path: "/main",
    component: MainPage,
    redirect: "/main/tickets",
    children: [
      {
        name: "Tickets",
        path: "tickets",
        component: TicketsPage,
      },
      {
        name: "Events",
        path: "events",
        component: EventsPage,
      },
      {
        name: "Indicators",
        path: "indicators",
        component: IndicatorsPage,
        beforeEnter() {
          const clientStore = useClientStore();
          return clientStore.hasKpi;
        },
      },
      {
        name: "Knowledge",
        path: "knowledge",
        component: KnowledgePage,
        beforeEnter() {
          const clientStore = useClientStore();
          return clientStore.config.hasKnowledge;
        },
      },
      {
        name: "Routines",
        path: "routines",
        component: RoutinesPage,
      },
    ],
  },
  {
    name: "Event",
    path: "/events/:id",
    component: EventPage,
  },
  {
    name: "EventCreation",
    path: "/events/create/:eventTypeId",
    component: EventPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const fabriqStore = useFabriqStore();
  if (to.path === "/saml-auth-code") {
    const url =
      window.location.href.replace(to.fullPath.substring(1), "") +
      to.fullPath.substring(1);
    await finalizeAuthentication(url);
    return next("/login/initialize");
  }
  if (to.fullPath.includes("/documents?document")) {
    fabriqStore.setPageToOpenAfterLogin(
      `/documents/${to.fullPath.split("?document=")[1]}`
    );
  }
  if (to.name !== "Login" && !fabriqStore.connected)
    return next({ name: "Login" });
  if (to.name === "Login" && fabriqStore.connected && !fabriqStore.initializing)
    return next({ name: "Tickets" });
  if (
    to.name === "InstanceD" &&
    from.name !== "Routines" &&
    from.name !== "TicketD"
  ) {
    fabriqStore.$patch((state) => (state.hideTabs = false));
    return next({ name: from.name ?? "Tickets" });
  }
  next();
});

export const finalizeAuthentication = async (url: string) => {
  const eventUrl = new URL(url);
  const code = eventUrl.searchParams.get("code");
  if (code) {
    try {
      window.history.replaceState(
        null,
        "",
        window.location.pathname + window.location.hash
      );
    } catch (e) {}
    await AuthClient.finalizeFutureAuth(code);
  }
};

if (config.env !== "production") {
  window.setSAMLResponse = async (path: string) => {
    await finalizeAuthentication(path);
    router.replace("/login/initialize");
  };
}

const appUrlOpener = async (event: URLOpenListenerEvent) => {
  Browser.close().catch(() => Promise.resolve());
  try {
    if (event.url.includes("/saml-auth-code")) {
      await finalizeAuthentication(event.url);
    } else {
      await AuthClient.continueFederatedSignIn(event.url);
    }
    router.replace("/login/initialize");
  } catch (e) {
    await AuthClient.logout(null);
    console.error("e", e);
  }
};

App.addListener("appUrlOpen", appUrlOpener);

export default router;
