import { createListStore } from "./list";
import { RoutineService } from "@/services/routines";

export const useRoutinesStore = createListStore(
  "routines",
  RoutineService,
  null,
  null,
  {
    async loadAll(teamIds: any) {
      this.loading = true;
      const promises = teamIds.map((id: any) => {
        if (!RoutineService.all) return;
        return RoutineService.all({ id });
      });
      const results = await Promise.allSettled(promises);
      const routines = results.reduce((acc: any[], result: any) => {
        if (result.status === "rejected") return acc;
        const toAdd = result.value.filter(
          (r: any) => acc.findIndex((rr) => rr.id === r.id) < 0
        );
        acc.push(...toAdd);
        return acc;
      }, []);
      this.merge(routines, true);
      this.loading = false;
    },
  },
  true
);
