<script lang="ts" setup>
import { IonList } from "@ionic/vue";
import { computed, onBeforeUnmount, PropType, ref } from "vue";
import InstanceItem from "@/components/business/routines/InstanceItem.vue";
import AccordionItem from "@/components/ui/AccordionItem.vue";
import { FilledInstance, User } from "@/types";
import LoadingBlock from "@/components/ui/LoadingBlock.vue";
import { useFabriqStore } from "@/store/fabriq";
import { useI18n } from "@/composables/useI18n";
import { useFilterInstances } from "@/composables/useFilterInstances";
import { storeToRefs } from "pinia";
import { useNoOffline } from "@/composables/useNoOffline";
import { useInstancesStore } from "@/store/instances";
import { useExecutionsStore } from "@/store/executions";

const { t } = useI18n();
const emit = defineEmits(["goto", "reload"]);
const props = defineProps({
  instances: {
    type: Array as PropType<Array<FilledInstance>>,
    default: () => [],
  },
  users: {
    type: Array as PropType<Array<User>>,
    default: () => [],
  },
  hideLoader: {
    type: Boolean,
    default: false,
  },
});
const fabriqStore = useFabriqStore();

const { requests } = storeToRefs(fabriqStore);

const ordered = computed(() => {
  const instances = props.instances || [];
  instances.sort((a: FilledInstance, b: FilledInstance) => {
    if (a.sending && !b.sending) return -1;
    if (!a.sending && b.sending) return 1;
    return String(a.start_date || "").localeCompare(String(b.start_date || ""));
  });
  return instances;
});

const doneInstances = useFilterInstances(ordered, "done");
const todayInstances = useFilterInstances(ordered, "today");
const laterInstances = useFilterInstances(ordered, "later");

const noRoutine = computed(() => {
  if (todayInstances.value.length) return false;
  if (doneInstances.value.length) return false;
  if (laterInstances.value.length) return false;
  return true;
});

const executionsStore = useExecutionsStore();
const instancesStore = useInstancesStore();
const { noOffline } = useNoOffline();
const loading = ref(false);

let timer: NodeJS.Timeout | null = null;
const resendInstance = () => {
  if (loading.value) return;
  if (timer) clearTimeout(timer);
  if (!fabriqStore.online) return noOffline();
  loading.value = true;
  executionsStore.save();
  instancesStore.save();
  timer = setTimeout(() => {
    emit("reload");
    timer = setTimeout(() => {
      loading.value = false;
    }, 1000);
  }, 2000);
};

onBeforeUnmount(() => {
  if (timer) clearTimeout(timer);
});
</script>
<template>
  <div>
    <div v-if="noRoutine && !requests" class="routines-empty">
      <div class="icon">⏰</div>
      <div>{{ t("routines.empty.scheduled") }}</div>
      <div class="hint">{{ t("routines.empty.scheduledHint") }}</div>
    </div>
    <loading-block v-if="noRoutine && requests && !hideLoader" />
    <accordion-item
      v-if="!noRoutine"
      :label="t('common.now')"
      :disabled="!todayInstances.length"
    >
      <ion-list class="instance-list">
        <instance-item
          v-for="instance of todayInstances"
          :key="instance.id"
          :instance="instance"
          :users="users"
          :loading="loading"
          @goto="emit('goto', $event)"
          @reload="resendInstance"
        />
      </ion-list>
    </accordion-item>
    <accordion-item
      v-if="!noRoutine"
      :label="t('common.donePlural')"
      :disabled="!doneInstances.length"
    >
      <ion-list class="instance-list">
        <instance-item
          v-for="instance of doneInstances"
          :key="instance.id"
          :instance="instance"
          :users="users"
          :loading="loading"
          @goto="emit('goto', $event)"
          @reload="resendInstance"
        />
      </ion-list>
    </accordion-item>
    <accordion-item
      v-if="!noRoutine"
      :label="t('common.later')"
      :disabled="!laterInstances.length"
    >
      <ion-list class="instance-list">
        <instance-item
          v-for="instance of laterInstances"
          :key="instance.id"
          :instance="instance"
          :users="users"
          :loading="loading"
          @reload="resendInstance"
        />
      </ion-list>
    </accordion-item>
  </div>
</template>

<style scoped>
.instance-list {
  padding: 0 calc(var(--ion-padding) / 2) var(--ion-padding);
  background: var(--ion-background-color);
}
</style>
