<script lang="ts" setup>
import { computed } from "vue";
import { modalController } from "@ionic/vue";
import EventMissingFieldsModal from "@/components/modals/EventMissingFieldsModal.vue";
import { EventType, EventTypePropertyWithMandatory } from "@/types";
import { useI18n } from "@/composables/useI18n";
import { calcModalPercents } from "@/utils/modals";

interface Props {
  missingFields: EventTypePropertyWithMandatory[];
  requiredFields: EventTypePropertyWithMandatory[];
  nbTotal: number;
  nbFilled: number;
  eventType: EventType;
}

const props = defineProps<Props>();
const emit = defineEmits(["show"]);
const { t } = useI18n();
const nbMissing = computed(() => {
  return props.nbTotal - props.nbFilled;
});
const score = computed(() => {
  if (!props.nbTotal) return 1;
  return Math.round((props.nbFilled * 100) / props.nbTotal);
});
const scoreColor = computed(() => {
  if (!score.value) return "var(--border-color)";
  if (score.value <= 33) return "var(--ion-color-danger)";
  if (score.value <= 66) return "var(--ion-color-warning)";
  return "var(--ion-color-success)";
});

const showMissingFields = async () => {
  emit("show");
  const breakpoints = calcModalPercents(44, props.nbTotal, 138);
  const modal = await modalController.create({
    component: EventMissingFieldsModal,
    canDismiss: true,
    mode: "ios",
    breakpoints,
    initialBreakpoint: breakpoints[1],
    componentProps: {
      eventType: props.eventType,
      scoreColor: scoreColor.value,
      score: score.value,
      fields: props.requiredFields,
      nbTotal: props.nbTotal,
      missingFields: props.missingFields,
    },
  });
  await modal.present();
};
</script>

<template>
  <div class="missing-fields clickable" @click="showMissingFields">
    <font-icon
      size="0.9"
      :color="
        nbMissing ? 'var(--ion-color-warning)' : 'var(--ion-color-success)'
      "
      :name="nbMissing ? 'warning' : 'check'"
      material
    />
    <div class="missing-field-label">
      {{ t("ticketModal.missingFields", { nb: nbMissing }, nbMissing) }}
    </div>
  </div>
</template>

<style scoped>
.missing-fields {
  border: 1px solid var(--ion-border-color);
  border-radius: var(--f-border-radius);
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  font-size: var(--font-size-m);
  max-height: 35px;
}

.missing-field-label {
  font-size: var(--font-size-s);
}
</style>
