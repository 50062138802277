<script lang="ts" setup>
import { computed } from "vue";
import CachedImage from "@/components/tools/CachedImage.vue";
import { FabriqFile } from "@/types";
import FileIcon from "@/components/ui/FileIcon.vue";
import { useFile } from "@/composables/useFile";

interface Props {
  file: FabriqFile;
}

const props = defineProps<Props>();

const { src, openFile, isImage, extension, image } = useFile(
  computed(() => props.file)
);
</script>

<template>
  <div class="thumbnail-file" @click="openFile">
    <div
      class="ticket-file-image-wrapper"
      :class="`image-${file.id}`"
      v-if="file.media_type === 'file' && isImage"
    >
      <cached-image
        v-if="!file.loading"
        ref="image"
        viewer
        class="ticket-file-image"
        :src="src"
      />
      <font-icon
        class="loading-block-spinner"
        spin
        name="spinner"
        color="var(--ion-color-primary-tint)"
        v-else
        size="0.6"
      />
    </div>
    <div v-else class="ticket-file-image-wrapper">
      <file-icon :extension="extension" />
    </div>
  </div>
</template>

<style scoped>
.file-content {
  --content-padding: calc(var(--ion-padding) / 4);
  padding: var(--content-padding) 0;
  flex: 1;
}
.exit-fs-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.file-actions-button {
  margin-right: calc(var(--ion-padding) / 2 - 4px);
}
.file-url {
  background-color: var(--f-color-discrete);
  border-radius: 5px;
}
</style>
