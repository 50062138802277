import { useClientStore } from "@/store/client";
import { useTicketsStore } from "@/store/tickets";
import { useTasksStore } from "@/store/tasks";
import { useAnswersStore } from "@/store/answers";
import { getShortenedTaskText } from "@/components/business/tickets/services/getShortenedTaskText";

const parseCommentUsers = (unparsed: string): string => {
  const clientStore = useClientStore();
  const users = clientStore.users || [];

  let content: string = unparsed + "";

  const reg = /\{userId: ([0-9]+)}/g;
  const matches = content.matchAll(reg);
  for (const match of matches) {
    const id: number = +match[1];
    const user = users.find((u: any) => u.id === id);
    if (!user) continue;
    const firstName = String(user.first_name || "");
    const lastName = String(user.last_name || "").substring(0, 1);
    const regUser = new RegExp(`{userId: ${id}}`, "g");
    const button = `<span data-user-id="${id}" class="comment-user-link complex-html" contenteditable="false">@${firstName} ${lastName}.</span>`;
    content = content.replaceAll(regUser, button);
  }
  return content;
};

const parseCommentTasks = (unparsed: string): string => {
  const tasksStore = useTasksStore();
  const tasks = tasksStore.collection || [];

  let content: string = unparsed + "";

  const reg = /\{taskId: ([0-9]+)}/g;
  const matches = content.matchAll(reg);
  for (const match of matches) {
    const id: number = +match[1];
    const task = tasks.find((u: any) => u.id === id);
    if (!task) continue;
    const description = getShortenedTaskText(task);
    const regTask = new RegExp(`{taskId: ${id}}`, "g");
    const button = `<span data-task-id="${id}" class="comment-task-link complex-html" contenteditable="false">@${description}.</span>`;
    content = content.replaceAll(regTask, button);
  }
  return content;
};

const parseCommentTickets = (unparsed: string): string => {
  const ticketsStore = useTicketsStore();
  const tickets = ticketsStore.collection || [];

  let content: string = unparsed + "";

  const reg = /\{ticketId: ([0-9]+)}/g;
  const matches = content.matchAll(reg);
  for (const match of matches) {
    const id: number = +match[1];
    const ticket = tickets.find((u: any) => u.id === id);
    if (!ticket) continue;
    const title = String(ticket.title || "").substring(0, 15) + "...";
    const regTicket = new RegExp(`{ticketId: ${id}}`, "g");
    const button = `<span data-ticket-id="${id}" class="comment-ticket-link complex-html" contenteditable="false">@${title}.</span>`;
    content = content.replaceAll(regTicket, button);
  }
  return content;
};

const parseCommentSteps = (unparsed: string): string => {
  const answersStore = useAnswersStore();
  const answers = answersStore.collection || [];

  let content: string = unparsed + "";

  const reg = /\{routineExecutionAnswerId: ([0-9]+)}/g;
  const matches = content.matchAll(reg);
  for (const match of matches) {
    const id: number = +match[1];
    const answer = answers.find((u: any) => u.id === id);
    if (!answer || !answer.step) continue;
    const title = String(answer.step.question || "").substring(0, 15) + "...";
    const regStep = new RegExp(`{routineExecutionAnswerId: ${id}}`, "g");
    const button = `<span data-step-id="${id}" class="comment-step-link complex-html" contenteditable="false">@${title}.</span>`;
    content = content.replaceAll(regStep, button);
  }
  return content;
};

export const commentParser = (unparsed: string) => {
  let content: string = parseCommentUsers(unparsed);
  content = parseCommentTickets(content);
  content = parseCommentTasks(content);
  content = parseCommentSteps(content);
  return parseLinks(content);
};

export const getUserSpan = (id: number) => {
  const clientStore = useClientStore();
  const users = clientStore.users || [];
  const user = users.find((u: any) => u.id === id);
  if (!user) return "";
  const firstName = String(user.first_name || "");
  const lastName = String(user.last_name || "").substring(0, 1);
  return `<span data-user-id="${id}" class="comment-user-link complex-html" contenteditable="false">@${firstName} ${lastName}.</span>`;
};

const unparseCommentUsers = (content: string) => {
  const reg = /data-user-id="([0-9]+)"/g;
  const matches = content.matchAll(reg);
  let unparsed: string = content + "";
  for (const match of matches) {
    const id: string = match[1];
    const regUser = new RegExp(
      `<span data-user-id="${id}" class="comment-user-link complex-html" contenteditable="false">@[^<]*</span>`,
      "g"
    );
    unparsed = unparsed.replaceAll(regUser, `{userId: ${id}}`);
  }
  return unparsed;
};

const unparseCommentTickets = (content: string) => {
  const reg = /data-ticket-id="([0-9]+)"/g;
  const matches = content.matchAll(reg);
  let unparsed: string = content + "";
  for (const match of matches) {
    const id: string = match[1];
    const regTicket = new RegExp(
      `<span data-ticket-id="${id}" class="comment-ticket-link complex-html" contenteditable="false">@[^<]*</span>`,
      "g"
    );
    unparsed = unparsed.replaceAll(regTicket, `{ticketId: ${id}}`);
  }
  unparsed = unparsed.replaceAll(/<text>/g, "").replaceAll(/<\/text>/g, "");
  return unparsed;
};

const unparseCommentTasks = (content: string) => {
  const reg = /data-task-id="([0-9]+)"/g;
  const matches = content.matchAll(reg);
  let unparsed: string = content + "";
  for (const match of matches) {
    const id: string = match[1];
    const regTask = new RegExp(
      `<span data-task-id="${id}" class="comment-task-link complex-html" contenteditable="false">@[^<]*</span>`,
      "g"
    );
    unparsed = unparsed.replaceAll(regTask, `{taskId: ${id}}`);
  }
  unparsed = unparsed.replaceAll(/<text>/g, "").replaceAll(/<\/text>/g, "");
  return unparsed;
};

const unparseCommentSteps = (content: string) => {
  const reg = /data-step-id="([0-9]+)"/g;
  const matches = content.matchAll(reg);
  let unparsed: string = content + "";
  for (const match of matches) {
    const id: string = match[1];
    const regStep = new RegExp(
      `<span data-step-id="${id}" class="comment-step-link complex-html" contenteditable="false">@[^<]*</span>`,
      "g"
    );
    unparsed = unparsed.replaceAll(
      regStep,
      `{routineExecutionAnswerId: ${id}}`
    );
  }
  unparsed = unparsed.replaceAll(/<text>/g, "").replaceAll(/<\/text>/g, "");
  return unparsed;
};
export const commentUnparser = (content: string) => {
  let unparsed = content.replaceAll(/<text>/g, "").replaceAll(/<\/text>/g, "");
  unparsed = unparseCommentUsers(unparsed);
  unparsed = unparseCommentTickets(unparsed);
  unparsed = unparseCommentTasks(unparsed);
  unparsed = unparseCommentSteps(unparsed);
  return parseLinks(unparsed);
};

export const parseLinks = (str: string | null, threshold = 100): string => {
  const before = threshold * 0.85;
  const after = threshold * 0.6;
  const length = before + after + 10;
  if (str && typeof str === "string") {
    const parser = new DOMParser().parseFromString(str, "text/html");
    for (const a of parser.querySelectorAll("a")) {
      a.replaceWith(a.href);
    }
    str = String(parser.body.innerHTML || "").replaceAll("&amp;", "&");
    const expFindUrls =
      /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    str = str.replaceAll(expFindUrls, (_, link: string) => {
      return `<a href="${link}">${
        link.length > length
          ? String(link || "").substring(0, before) +
            "[...]" +
            String(link || "").substring(link.length - after, link.length)
          : link
      }</a>`;
    });
  }
  return String(str || "").trim();
};

export const cleanCommentHtml = (content = "") => {
  if (!content) return "";
  return content
    .replaceAll(/<text>/g, "")
    .replaceAll(/<\/text>/g, "")
    .replaceAll(/&nbsp;/g, " ")
    .replaceAll(/&amp;/g, "&")
    .replaceAll(/\s\s/g, " ")
    .trim();
};

export const getMentionedUserIds = (content: string) => {
  const reg = /\{userId: ([0-9]+)}/g;
  const matches = content.matchAll(reg);
  const ids: Array<number> = [];
  for (const match of matches) {
    const id: string = match[1];
    ids.push(+id);
  }
  return ids;
};

export const getMentionedTicketIds = (content: string) => {
  const reg = /\{ticketId: ([0-9]+)}/g;
  const matches = content.matchAll(reg);
  const ids: Array<number> = [];
  for (const match of matches) {
    const id: string = match[1];
    ids.push(+id);
  }
  return ids;
};

export const getTaggedChildrenIds = (content: string, instance = false) => {
  const reg = instance
    ? /\{routineExecutionAnswerId: ([0-9]+)}/g
    : /\{taskId: ([0-9]+)}/g;
  const matches = content.matchAll(reg);
  const ids: Array<number> = [];
  for (const match of matches) {
    const id: string = match[1];
    ids.push(+id);
  }
  return ids;
};

const MAX_CHARS = 60;
const getReverseIndex = (
  str: string,
  index: number,
  lastSpaceIndex: boolean | number = false
): number => {
  if (index - 1 <= 0) return 0;
  const char = str[index];
  const length = str.slice(index).length;
  if (lastSpaceIndex !== false && length >= MAX_CHARS) return +lastSpaceIndex;
  if (char === " ") lastSpaceIndex = index;
  return getReverseIndex(str, index - 1, lastSpaceIndex);
};

const element = document.createElement("textarea");
export const removeHtml = (str: string | null): string => {
  if (str && typeof str === "string") {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = "";
  }
  return String(str || "").trim();
};

export const removeLinks = (str: string | null): string => {
  if (str && typeof str === "string") {
    const parser = new DOMParser().parseFromString(str, "text/html");
    for (const a of parser.querySelectorAll("a")) {
      a.replaceWith(a.href);
    }
    return parser.body.innerText;
  }
  return String(str || "").trim();
};

export const truncateMarks = (title: string) => {
  let str: string = title.replaceAll(/<br\/?>/g, "&nbsp;");
  str = str
    .replace("</mark> <mark>", " ")
    .replace("<mark>", "##mark##")
    .replace("</mark>", "##/mark##");
  str = removeHtml(str)
    .replace("##mark##", "<mark>")
    .replace("##/mark##", "</mark>");
  const idx = str.indexOf("</mark>") + 7;
  const length = str.length;
  if (idx <= 1 || length <= MAX_CHARS || idx < MAX_CHARS)
    return str.slice(0, MAX_CHARS);
  const startIndex = getReverseIndex(str, idx);
  return str.slice(startIndex, startIndex + MAX_CHARS);
};

const removeChars = (str: string): string => {
  return str
    ?.replaceAll("á", "a")
    .replaceAll("à", "a")
    .replaceAll("â", "a")
    .replaceAll("ä", "a")
    .replaceAll("é", "e")
    .replaceAll("è", "e")
    .replaceAll("ê", "e")
    .replaceAll("ë", "e")
    .replaceAll("ì", "i")
    .replaceAll("í", "i")
    .replaceAll("î", "i")
    .replaceAll("ï", "i")
    .replaceAll("ó", "o")
    .replaceAll("ò", "o")
    .replaceAll("ö", "o")
    .replaceAll("ô", "o")
    .replaceAll("ú", "u")
    .replaceAll("ù", "u")
    .replaceAll("ü", "u")
    .replaceAll("û", "u")
    .replaceAll("ç", "c")
    .replaceAll("ñ", "n");
};

export const normalizeString = (str: string | null | undefined) => {
  return removeChars(
    String(str || "")
      .trim()
      .toLocaleLowerCase()
  );
};
