<script setup lang="ts">
interface Props {
  show?: boolean;
}
withDefaults(defineProps<Props>(), { show: false });

const emojis = [
  "👍",
  "👎",
  "🔥",
  "🚀",
  "✈️",
  "👀",
  "🏭",
  "😄",
  "😊",
  "🙃",
  "😬",
  "❤️",
  "🍾",
  "🎉",
  "😞",
  "😍",
  "✅",
  "🤓",
  "😎",
  "😂",
  "👌",
  "👊",
  "🙌",
  "🙏",
  "👏",
  "😅",
  "🤩",
  "🤗",
  "😱",
  "🥳",
];
</script>
<template>
  <div class="emoji-reactions">
    <div
      v-for="(emoji, index) of emojis"
      :key="index"
      class="emoji clickable"
      @click="$emit('select', emoji)"
    >
      {{ emoji }}
    </div>
  </div>
</template>

<style scoped>
.emoji-reactions {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  padding: var(--ion-padding);
  gap: var(--ion-padding);
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: items-center;
  margin-top: calc(var(--ion-padding) * 2);
}

.emoji {
  width: 48px;
  height: 48px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: solid 1px var(--ion-border-color);
  background-color: var(--ion-background-color-shade);
  border-radius: 150px;
}
</style>
