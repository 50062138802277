<template>
  <ion-page class="modal-page">
    <ion-header
      mode="ios"
      class="ion-no-border"
      collapse="condense"
      :translucent="false"
    >
      <ion-toolbar mode="ios" class="modal-header">
        <slot name="toolbar"></slot>
      </ion-toolbar>
    </ion-header>
    <ion-content
      class="modal-page-content"
      :fullscreen="true"
      :scroll-y="false"
    >
      <slot name="content" />
    </ion-content>
    <ion-footer class="modal-page-footer" v-if="hasFooterSlot">
      <slot name="footer" />
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonFooter,
} from "@ionic/vue";

export default defineComponent({
  name: "ModalPage",
  props: {
    scrollY: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonFooter,
  },
  setup(_, { slots }) {
    const hasFooterSlot = computed(() => {
      return !!slots.footer;
    });

    return {
      hasFooterSlot,
    };
  },
});
</script>

<style scoped>
.modal-page {
  padding-top: calc(var(--ion-statusbar-padding) + (var(--ion-padding) * 2));
  background-color: black;
}
.modal-page ion-header {
  background: black;
  border-radius: 10px 10px 0 0;
}
.modal-page ion-toolbar {
  border-radius: 10px 10px 0 0;
}
</style>
