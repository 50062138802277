import { createListStore } from "./list";
import { ScheduleService } from "@/services/schedules";

export const useSchedulesStore = createListStore(
  "schedules",
  ScheduleService,
  null,
  null,
  null,
  true
);
