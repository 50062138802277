<script lang="ts" setup>
import { PropType, computed } from "vue";
import { IonItem, IonLabel } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { Routine, User } from "@/types";

const props = defineProps({
  routine: {
    type: Object as PropType<Routine>,
    default: () => ({}),
  },
  users: {
    type: Array as PropType<Array<User>>,
    default: () => [],
  },
  favorites: {
    type: Array as PropType<Array<number>>,
    default: () => [],
  },
});
const emit = defineEmits(["favorite", "goto"]);
const { t } = useI18n();

const isFavorite = computed(() => {
  if (!props.routine.id || !props.favorites) return false;
  return props.favorites.includes(+props.routine.id);
});
</script>

<template>
  <ion-item
    lines="none"
    class="f-list-item"
    :class="{ 'look-disabled': routine.disabled }"
  >
    <font-icon
      slot="start"
      class="start"
      @click.stop.prevent="emit('favorite', routine.id)"
      :name="isFavorite ? 'star' : 'star_border'"
      size="1.5"
      material
      :color="
        isFavorite
          ? 'var(--ion-color-warning)'
          : 'var(--ion-color-primary-shade)'
      "
    />
    <ion-label class="routine-title">
      {{ routine.title || t("common.noTitle") }}
    </ion-label>
    <div
      slot="end"
      class="start-instance clickable"
      @click="emit('goto', routine)"
    >
      <font-icon
        name="play_arrow"
        size="0.875"
        material
        color="var(--ion-color-secondary)"
      />
      {{ t("start") }}
    </div>
  </ion-item>

  <!-- <pre style="overflow: auto; width: 100%; height: 100%">{{
    { ...routine, schedule: undefined }
  }}</pre> -->
</template>

<style scoped>
ion-item {
  --f-border-radius: 5px;
}
.routine-title {
  font-size: var(--font-size-m);
}
.routine-title .hint {
  margin-top: calc(var(--ion-padding) / 2);
}
</style>
