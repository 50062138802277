<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item>
        <ion-label>{{ t("routines.placeholders.highlight") }}</ion-label>
      </ion-item>
      <ion-item @click="$emit('done', 'up')">
        <font-icon
          slot="start"
          name="thumb_up"
          material
          color="var(--ion-color-success)"
        />
        <ion-label class="list-item" color="success">{{
          t("common.good")
        }}</ion-label>
      </ion-item>
      <ion-item @click="$emit('done', 'down')">
        <font-icon
          slot="start"
          name="thumb_down"
          material
          color="var(--ion-color-danger)"
        />
        <ion-label class="list-item" color="danger">{{
          t("common.bad")
        }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonContent, IonList, IonItem, IonLabel } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";

export default defineComponent({
  name: "RoutineStepHighlightModal",
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
  },
  emits: ["done", "cancel"],
  setup() {
    return {
      ...useI18n(),
    };
  },
});
</script>

<style scoped>
.list-item {
  font-size: var(--font-size-m);
}
</style>
