import authServerFetch from "./fetchHelper";
import type { SAMLDataInput } from "@fabriq/auth-client/types/auth_playground/libs/_internal_libs/auth_service/interface/_mod";

type Scope = "openid";

type AuthorizationRequest = {
  organizationId: string;
  codeChallenge: string;
  redirectUri?: string;
  scope: Scope[];
  state: string;
  clientId: string;
  codeChallengeMethod: "S256" | "plain";
  responseType: "code";
  subdomainUrl: string;
};

type TokenRequest = {
  organizationId: string;
  refreshToken?: string;
  codeVerifier?: string;
  code?: string;
  redirectUri?: string;
  clientId: string;
  scope: Scope[];
};

type TokenRevokationRequest = {
  token: string;
  tokenType: string;
};

function buildServer(serverBaseUrl: string, subdomainUrl?: string) {
  // FIXME: Workaround as authServerFetch does not support local paths
  serverBaseUrl =
    (subdomainUrl ? subdomainUrl : window.location.origin) + serverBaseUrl;

  return {
    async requestAuthorization(authorizationRequest: AuthorizationRequest) {
      const {
        responseType: response_type,
        clientId: client_id,
        codeChallenge: code_challenge,
        codeChallengeMethod: code_challenge_method,
        redirectUri: redirect_uri,
        scope,
      } = authorizationRequest;
      const params: Record<string, any> = {
        response_type,
        client_id,
        code_challenge,
        code_challenge_method,
        redirect_uri,
        scope,
      };
      return (
        await authServerFetch(
          `${serverBaseUrl}/oauth2/authorize`,
          "GET",
          false,
          undefined,
          params
        )
      ).body;
    },
    async requestAuthCode(organizationId: string, idpToken: string) {
      const params = { token: idpToken };
      return (
        await authServerFetch(
          `${serverBaseUrl}/user/login/auth-code`,
          "GET",
          false,
          undefined,
          params
        )
      ).body;
    },
    async issueTokensFromAuthorizationCode(tokenRequest: TokenRequest) {
      const {
        code,
        codeVerifier: code_verifier,
        redirectUri: redirect_uri,
        clientId: client_id,
        scope,
      } = tokenRequest;
      const data = {
        code_verifier,
        redirect_uri,
        code,
        client_id,
        scope: scope.join(" "),
        grant_type: "authorization_code",
      };
      const result = await authServerFetch(
        `${serverBaseUrl}/oauth2/token`,
        "POST",
        false,
        data
      );
      if (result.status === 200) {
        return {
          outcome: "issued",
          response: {
            scope: result.body.scope,
            accessToken: result.body.access_token,
            refreshToken: result.body.refresh_token,
            idToken: result.body.id_token,
            expiredIn: result.body.expires_in,
            tokenType: result.body.token_type,
          },
        };
      }
      return { ...result.body, outcome: "notIssued" };
    },
    async issueTokensFromRefreshToken(tokenRequest: TokenRequest) {
      const {
        refreshToken: refresh_token,
        clientId: client_id,
        scope,
      } = tokenRequest;
      const data = {
        refresh_token,
        client_id,
        scope: scope.join(" "),
        grant_type: "refresh_token",
      };
      const result = await authServerFetch(
        `${serverBaseUrl}/oauth2/token`,
        "POST",
        false,
        data
      );
      if (result.status === 200) {
        return {
          outcome: "issued",
          response: {
            scope: result.body.scope,
            accessToken: result.body.access_token,
            refreshToken: result.body.refresh_token,
            idToken: result.body.id_token,
            expiredIn: result.body.expires_in,
            tokenType: result.body.token_type,
          },
        };
      }
      return { ...result.body, outcome: "notIssued" };
    },
    async revokeToken(revokationRequest: TokenRevokationRequest) {
      const { token, tokenType: token_type } = revokationRequest;
      const data = {
        token,
        token_type,
      };
      const result = await authServerFetch(
        `${serverBaseUrl}/oauth2/revoke`,
        "POST",
        false,
        data
      );
      if (result.status === 200) {
        return {
          ...result.body,
          outcome: "revoked",
        };
      }
      return {
        ...result.body,
        outcome: "notRevoked",
        reason: result.body.error,
      };
    },
    async getSAMLRequestUri({
      clientId,
      samlInputData,
    }: {
      clientId: string;
      samlInputData: SAMLDataInput;
    }) {
      const params =
        samlInputData.type === "login"
          ? {
              clientId,
              authId: samlInputData.authId,
              redirectUri: samlInputData.redirectUri,
            }
          : {
              clientId,
              authId: samlInputData.authId,
              invitationToken: samlInputData.invitationToken,
              backendSecret: samlInputData.backendSecret,
            };

      return (
        await authServerFetch(
          `${serverBaseUrl}/saml-${samlInputData.type}`,
          "GET",
          false,
          undefined,
          params
        )
      ).body;
    },
    async checkUserIdp(_organizationId: string, username: string) {
      const params = { username };
      return (
        await authServerFetch(
          `${serverBaseUrl}/user/check-idp`,
          "GET",
          false,
          undefined,
          params
        )
      ).body;
    },
    async getOrganizationAuthConfig() {
      return (
        await authServerFetch(`${serverBaseUrl}/auth-config`, "GET", false)
      ).body;
    },
  };
}

export default buildServer;
