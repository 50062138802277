import { TicketTemplateTask } from "@/types";

export function getTextToDisplay(task: TicketTemplateTask, locale: string) {
  const translations = task.config?.i18n;
  if (translations !== undefined && Object.keys(translations).length > 0) {
    if (translations[locale] !== undefined) return translations[locale];
    const keys = Object.keys(translations);
    if (keys.includes("en")) return translations.en;
    const firstAlphabeticalTranslation = Object.keys(translations).sort()[0];
    return translations[firstAlphabeticalTranslation];
  }
  return task.description;
}
