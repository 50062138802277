<template>
  <html-content
    v-if="instance && instance.scheduler && instance.scheduler.instructions"
    class="ion-margin-bottom ion-text-center hint"
    tag="div"
    :content="instance.scheduler.instructions"
  />
  <vertical-scroller class="instance-list-scroller" @scroll="scroll">
    <instance-step
      v-for="step of steps"
      :class="`step step-${step.index}`"
      :key="step.id"
      :step="step"
      :answer="step.answer"
      :instance="instance"
      :template="template"
      :routine="routine"
      :answers="answers"
      :uncompletedSteps="uncompletedSteps"
      :executionDate="executionDate"
      :current-step="step.index + 1"
      :readonly="readonly"
      @pin="pinAnswer(step.answer.uuid, $event)"
      @select="selectAnswer(step.answer.uuid, $event)"
      @checklist="selectChecklist"
      @value="valueAnswer(step.answer.uuid, $event)"
      @detailed_value="detailedValueAnswer(step.answer.uuid, $event)"
      @comment="$emit('comment', step.answer)"
      @file="$emit('file', step.answer)"
    />
    <div class="routine-end-step f-list-item">
      <div v-if="missingSteps.length > 0" class="ion-text-center">
        <ion-row class="thumb-finished ion-justify-content-center">⚠️</ion-row>
        <ion-row class="ion-justify-content-center ion-margin-vertical">
          <div class="routine-end">
            {{ missingSteps.length }}
            {{ t("routines.uncompleted", missingSteps.length) }}
          </div>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <div
            class="missing-step"
            v-for="step of missingSteps"
            :key="step.id"
            @click="$emit('current', step.index - 1)"
          >
            {{ step.index }}
          </div>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-margin-vertical">
          <div class="routine-end">
            {{ t("routines.needToComplete", missingSteps.length) }}
          </div>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-margin-top">
          <div class="routine-end button">
            <ion-button
              expand="block"
              class="ion-margin-top routine-button"
              @click="$emit('current', missingSteps[0].index - 1)"
            >
              {{ t("routines.missing") }}
            </ion-button>
          </div>
        </ion-row>
      </div>
      <div v-else class="ion-text-center">
        <ion-row class="thumb-finished ion-justify-content-center">🎉</ion-row>
        <ion-row
          class="ion-justify-content-center ion-margin-vertical"
          v-if="!readonly || canBeResent"
        >
          <div class="routine-end">{{ t("routines.finished") }}</div>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-margin-vertical" v-else>
          <div class="routine-end">{{ t("routines.complete") }}</div>
        </ion-row>
        <ion-row
          class="ion-justify-content-center ion-margin-top"
          v-if="!readonly || canBeResent"
        >
          <div class="routine-end button">
            <ion-button
              expand="block"
              :disabled="loading"
              class="ion-margin-top routine-button"
              @click="submitRoutine"
            >
              <font-icon
                name="spinner"
                spin
                color="white"
                size="0.4"
                v-if="loading"
              />
              <span v-else>{{ t("routines.submit") }}</span>
            </ion-button>
          </div>
        </ion-row>
      </div>
    </div>
  </vertical-scroller>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, watch } from "vue";
import {
  Instance,
  Execution,
  Template,
  TemplateStep,
  ExecutionAnswer,
  Routine,
} from "@/types";
import InstanceStep from "@/components/business/routines/InstanceStep.vue";
import VerticalScroller from "@/components/ui/VerticalScroller.vue";
import { IonButton, IonRow } from "@ionic/vue";

import { useInstanceExecution } from "@/composables/useInstanceExecution";
import HtmlContent from "@/components/tools/HtmlContent.vue";

export default defineComponent({
  name: "InstanceExecutionList",
  emits: ["comment", "current", "next", "file"],
  props: {
    template: {
      type: Object as PropType<Template>,
      required: true,
    },
    instance: {
      type: Object as PropType<Instance>,
      required: true,
    },
    routine: {
      type: Object as PropType<Routine>,
      required: true,
    },
    execution: {
      type: Object as PropType<Execution>,
      required: true,
    },
    answers: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      required: true,
    },
    steps: {
      type: Array as PropType<Array<TemplateStep>>,
      required: true,
    },
    jumpedSteps: {
      type: Array as PropType<Array<number>>,
      required: true,
    },
    optionalSteps: {
      type: Array as PropType<Array<number>>,
      required: true,
    },
    uncompletedSteps: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      default: () => [],
    },
    missingSteps: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      default: () => [],
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    canBeResent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HtmlContent,
    VerticalScroller,
    InstanceStep,
    IonButton,
    IonRow,
  },
  setup(props, { emit }) {
    const instanceExecutionComposable = useInstanceExecution(props, emit);

    const filteredSteps = computed(() => {
      return props.steps.filter((s: TemplateStep) => {
        if (!s.id) return;
        return !props.jumpedSteps.includes(+s.id);
      });
    });
    const scroll = (ev: any) => {
      const steps: any[] = [];
      document.querySelectorAll(".step").forEach((s: Element, key: number) => {
        const r = s.getBoundingClientRect();
        steps.push({
          top: r.top,
          bottom: r.bottom,
          key,
        });
      });

      let current: any = null;
      const min = 0;
      const max = ev.target.offsetHeight;
      current = steps.find((r) => r.top > min && r.top < max);
      if (current) return emit("current", current.key + 1);
      current = steps.find((r) => r.bottom > min && r.bottom < max);
      if (current) return emit("current", current.key + 1);
    };

    watch(
      () => props.currentStep,
      (value) => {
        const to = document.querySelector(`.step-${value}`);
        if (!to) return;
        to.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    );
    return {
      ...instanceExecutionComposable,
      filteredSteps,
      scroll,
    };
  },
});
</script>

<style scoped>
.thumb-finished {
  font-size: 5rem;
}
.instance-list-scroller {
  max-height: calc(100% - 3rem);
}
</style>
