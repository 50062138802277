<script lang="ts" setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToggle,
  IonToolbar,
  modalController,
  onIonViewDidEnter,
  onIonViewWillLeave,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { LOCALES_OPTIONS } from "@/i18n";
import { useFabriqStore } from "@/store/fabriq";
import BackButton from "@/components/ui/BackButton.vue";
import { storeToRefs } from "pinia";
import UserAvatar from "@/components/business/UserAvatar.vue";
import AccountThemeModal from "@/components/modals/AccountThemeModal.vue";
import AccountLocaleModal from "@/components/modals/AccountLocaleModal.vue";

import addHours from "date-fns/addHours/index";
import { AuthType } from "@/types";
import AppVersion from "@/components/ui/AppVersion.vue";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import loader from "@/utils/loader";
import { useConfigStore } from "@/store/config";

import { useClientStore } from "@/store/client";

const clientStore = useClientStore();

const configStore = useConfigStore();
const fabriqStore = useFabriqStore();

const { config } = storeToRefs(configStore);
const { mobileScreenRotationEnabled } = storeToRefs(clientStore);
const { theme, locale, user, online, version } = storeToRefs(fabriqStore);
const router = useRouter();
const logout = async () => {
  tracker.track("signout", "User sign out", RecordCategory.General);
  await loader.show();
  await fabriqStore.logout();
  router.replace("/login");
  await loader.hide();
};

const tunnel = (action: string) => {
  tracker.tunnel(
    "account",
    "account page",
    RecordCategory.General,
    ActionType.Process,
    action
  );
};

const chooseTheme = async () => {
  const modal = await modalController.create({
    component: AccountThemeModal,
    canDismiss: true,
    mode: "ios",
    breakpoints: [0, 0.4, 0.6],
    initialBreakpoint: 0.4,
    componentProps: {
      onDone: async (action: string | undefined) => {
        await modal.dismiss();
        tunnel("theme");
        fabriqStore.setTheme(action);
      },
      onCancel: () => modal.dismiss(),
    },
  });
  return modal.present();
};
const chooseLanguage = async () => {
  const modal = await modalController.create({
    component: AccountLocaleModal,
    canDismiss: true,
    mode: "ios",
    breakpoints: [0, 0.75, 1],
    initialBreakpoint: 0.75,
    componentProps: {
      onDone: async (action: string) => {
        await modal.dismiss();
        tunnel("locale");
        fabriqStore.setLocale(action);
        clientStore.setTranslations(action);
      },
      onCancel: () => modal.dismiss(),
    },
  });
  return modal.present();
};

const hasPushNotifications = ref(config.value?.hasPushNotifications);

const changeHasPushNotifications = (value: boolean) => {
  if (value === hasPushNotifications.value) return;
  tunnel("push");
  configStore.setHasNotifications(value);
  hasPushNotifications.value = value;
};

const orientation = ref(localStorage.getItem("orientation") || "portrait");

const changeOrientation = () => {
  if (orientation.value === "portrait") {
    localStorage.setItem("orientation", "landscape");
    screen.orientation.lock("landscape");
    orientation.value = "landscape";
  } else {
    localStorage.setItem("orientation", "portrait");
    screen.orientation.lock("portrait");
    orientation.value = "portrait";
  }
};

const fullName = computed(() => {
  if (!user.value) return null;
  if (user.value?.profile?.full_name) return user.value?.profile?.full_name;
  return `${user.value.first_name
    .substring(0, 1)
    .toLocaleUpperCase()}${user.value.first_name
    .substring(1)
    .toLocaleLowerCase()}${user.value.last_name
    .substring(0, 1)
    .toLocaleUpperCase()}${user.value.last_name
    .substring(1)
    .toLocaleLowerCase()}`;
});

const selectedLocale = computed(() => {
  const found = LOCALES_OPTIONS.find((v) => v.value === locale.value);
  if (!found) return "";
  return found.label;
});

onIonViewDidEnter(() => {
  fabriqStore.setPage("profile");
});

onIonViewWillLeave(() => {
  configStore.saveUserConfig();
});

const isDavid = () => {
  if (fabriqStore.user?.email !== "david@usefabriq.com") return;
  const accessToken = prompt("Token ?");
  if (!accessToken) return;
  fabriqStore.setToken({
    type: AuthType.External,
    accessToken,
    refreshToken: "",
    expiresAt: addHours(new Date(), 1),
  });
};

const { t } = useI18n();
</script>

<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar class="ion-padding-start transparent-toolbar">
        <back-button @back="$router.back()" slot="start" />
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ticket-page normal" v-if="user">
      <user-avatar class="account-avatar" :user="user" @click="isDavid" />
      <div class="account-user-name">
        {{ fullName }}
      </div>
      <div class="account-user-email">
        {{ user.email }}
      </div>
      <div class="big-button" @click="chooseTheme">
        <font-icon
          name="lightbulb"
          material
          size="1"
          color="var(--ion-color-secondary)"
        />
        <div class="in-big-button">
          <div>{{ t("common.theme") }}</div>
          <div class="fake-dropdown">
            {{ t(`common.themes.${theme}`) }}
            <font-icon
              name="arrow_drop_down"
              color="var(--f-color-discrete)"
              material
              size="0.875"
            />
          </div>
        </div>
      </div>
      <div class="big-button" @click="chooseLanguage">
        <font-icon
          name="language"
          material
          size="1"
          color="var(--ion-color-secondary)"
        />
        <div class="in-big-button">
          <div>{{ t("common.locale") }}</div>
          <div class="fake-dropdown">
            {{ selectedLocale }}
            <font-icon
              name="arrow_drop_down"
              color="var(--f-color-discrete)"
              material
              size="0.875"
            />
          </div>
        </div>
      </div>
      <div class="big-button">
        <font-icon
          name="notifications"
          material
          outlined
          size="1"
          :color="
            online
              ? 'var(--ion-color-secondary)'
              : 'var(--ion-color-primary-shade)'
          "
        />
        <div class="in-big-button">
          <div>{{ t("common.push") }}</div>
          <ion-toggle
            class="toggle-btn"
            :checked="hasPushNotifications"
            @ionChange="changeHasPushNotifications(!hasPushNotifications)"
            color="secondary"
          />
        </div>
      </div>
      <div class="big-button" v-if="mobileScreenRotationEnabled">
        <font-icon
          name="screen_rotation"
          material
          outlined
          size="1"
          :color="
            online
              ? 'var(--ion-color-secondary)'
              : 'var(--ion-color-primary-shade)'
          "
        />
        <div class="in-big-button">
          <div>{{ t("common.landscapeMode") }}</div>
          <ion-toggle
            class="toggle-btn"
            :checked="orientation === 'landscape'"
            @ionChange="changeOrientation"
            color="secondary"
          />
        </div>
      </div>
      <div class="big-button logout clickable" @click="logout">
        <font-icon
          name="logout"
          material
          size="1"
          color="var(--ion-color-danger)"
        />
        <div>{{ t("common.logout") }}</div>
      </div>
      <app-version :version="version" position="block" />
    </ion-content>
  </ion-page>
</template>

<style scoped>
.in-big-button ion-toggle {
  --background-checked: var(--ion-color-secondary);
  --background: var(--ion-color-primary-shade);
  width: 40px;
  height: 20px;
  --handle-width: 16px;
  --handle-height: 16px;
}

.big-button {
  display: flex;
  flex-direction: row;
  gap: var(--ion-padding);
  align-items: center;
  justify-content: flex-start;
  margin: var(--ion-padding);
  padding: calc(var(--ion-padding) * 2) calc(var(--ion-padding) * 2);
  padding-right: var(--ion-padding);
  background-color: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
  font-size: var(--font-size-m);
  border: 1px solid var(--ion-border-color);
  border-radius: var(--f-border-radius);
  flex-wrap: wrap;
}

.in-big-button {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.big-button.feedback {
  display: block;
}

.feedback .in-big-button {
  width: 100%;
}

.fake-dropdown {
  display: flex;
  flex-direction: row;
  gap: var(--ion-padding);
  align-items: center;
  justify-content: space-end;
  color: var(--ion-color-primary-shade);
}

.logout {
  margin-bottom: calc(var(--ion-padding) * 4);
}

.account-avatar.user-avatar {
  --avatar-size: 5rem;
  justify-content: center;
}

.account-user-name {
  text-align: center;
  margin-top: var(--ion-padding);
  color: var(--ion-color-primary);
  font-size: var(--font-size-xl);
  font-weight: bold;
}

.account-user-email {
  text-align: center;
  color: var(--ion-color-primary-shade);
  font-size: var(--font-size-m);
  margin-bottom: calc(var(--ion-padding) * 4);
}

.send-feedback,
.send-feedback-input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.send-feedback-input {
  margin-top: var(--ion-padding);
}

.send-feedback .ion-margin-end {
  margin-right: var(--ion-padding);
}

.with-background {
  background-color: var(--ion-background-color);
}

.toggle-btn {
  padding: 0;
}
</style>
<style>
.big-button .in-big-button .text-input {
  width: 100%;
  flex: 1;
}

.custom-alert .alert-wrapper {
  background: var(--ion-background-color);
}
</style>
