import { urlOpener } from "@/utils/aws-url-opener";
const FABRIQ_ENV = import.meta.env.VITE_FABRIQ_ENV || "production";

export default {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  //identityPoolId: "XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab",

  // REQUIRED - Amazon Cognito Region
  // OPTIONAL - Amazon Cognito Federated Identity Pool Region
  // Required only if it's different from Amazon Cognito Region
  //identityPoolRegion: "XX-XXXX-X",

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: "eu-west-2_deD33HGFA",

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: "1q2t28ar4t1v8acf5bv3l210oi",

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,

  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  // cookieStorage: {
  //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
  //   domain: ".yourdomain.com",
  //   // OPTIONAL - Cookie path
  //   path: "/",
  //   // OPTIONAL - Cookie expiration in days
  //   expires: 365,
  //   // OPTIONAL - Cookie secure flag
  //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
  //   secure: true,
  // },

  // OPTIONAL - customized storage object
  //storage: MyStorage,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  //authenticationFlowType: "USER_PASSWORD_AUTH",

  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  //clientMetadata: { myCustomKey: "myCustomValue" },

  // OPTIONAL - Hosted UI configuration
  oauth: {
    urlOpener,
    domain: "fabriq.auth.eu-west-2.amazoncognito.com/",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      FABRIQ_ENV === "staging" ||
      window.location.origin === "https://fabriq-mobile-application.pages.dev"
        ? window.location.origin
        : "tech.fabriq.mobile://",
    redirectSignOut: "tech.fabriq.mobile://",
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  federationTarget: "COGNITO_USER_POOLS",
};
