import { getTextToDisplay } from "@/components/business/tickets/services/getTextToDisplay";
import TeamInstancePickerModal from "@/components/modals/TeamInstancePickerModal.vue";
import TicketCreateModal from "@/components/modals/TicketCreateModal.vue";
import { useDiamondDependenciesStore } from "@/store/diamondDependencies";
import { useEventFiltersStore } from "@/store/eventFilters";
import { useEventTypesStore } from "@/store/eventTypes";
import { useFabriqStore } from "@/store/fabriq";
import { useTasksStore } from "@/store/tasks";
import { useTeamsStore } from "@/store/teams";
import { useTicketTemplatesStore } from "@/store/ticketTemplates";
import { useTicketsStore } from "@/store/tickets";
import {
  DiamondDependency,
  EventType,
  EventTypeProperty,
  FabriqEventWithReadableProperties,
  Team,
  Ticket,
  TicketTemplate,
  TicketType,
} from "@/types";
import { addTicketTemplatePresetValues } from "@/utils/addTicketTemplatePresetValues";
import { ticketPageAnimation } from "@/utils/animations";
import { createEmptyTicket } from "@/utils/createEmptyTicket";
import { calcModalPercents } from "@/utils/modals";
import { toastController, modalController, useIonRouter } from "@ionic/vue";
import { addDays, format, formatISO } from "date-fns";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "./useI18n";
import mixpanelTracker from "@/utils/mixpanel-tracker";

export const useAddTicketFromEvent = (
  fEvent: FabriqEventWithReadableProperties,
  inList = false
) => {
  const ticketTemplatesStore = useTicketTemplatesStore();
  const diamondDependenciesStore = useDiamondDependenciesStore();
  const teamsStore = useTeamsStore();
  const fabriqStore = useFabriqStore();
  const ticketsStore = useTicketsStore();
  const tasksStore = useTasksStore();
  const eventTypesStore = useEventTypesStore();
  const eventFiltersStore = useEventFiltersStore();
  const router = useIonRouter();
  const { t } = useI18n();
  const { collection: dependencies } = storeToRefs(diamondDependenciesStore);

  const dependenciesLoading = ref(false);
  const eventDependencies = computed(() => {
    return dependencies.value.filter(
      (d: DiamondDependency) =>
        (d.dependentType === "event" && d.dependentId === fEvent.id) ||
        (d.relatedType === "event" && d.relatedId === fEvent.id)
    );
  });

  const { online } = storeToRefs(fabriqStore);

  const canEdit = computed(() => {
    return eventFiltersStore.canUserEdit(fEvent.id);
  });

  function getTeamFromEventZone() {
    const eventTypeId = fEvent.eventTypeId;
    const eventType = eventTypesStore.collection.find(
      (et: EventType) => et.id === eventTypeId
    );
    if (!eventType) return undefined;
    const zoneProperty = eventType.properties.find(
      (p: EventTypeProperty) => p.type === "zone"
    );
    if (!zoneProperty) return undefined;
    const zoneId = fEvent.properties.find(
      (p: any) => p.eventPropertyTypeId === zoneProperty.id
    )?.value;
    if (!zoneId) return undefined;
    const numberZoneId = Number(zoneId);
    const teams = teamsStore.collection
      .filter((t: Team) => t.extra_fields.includes(numberZoneId))
      .map((t: Team) => t.id);
    if (!teams.length) return teamsStore.collection.map((t: Team) => t.id);
    return teams;
  }

  const openAddMenu = async () => {
    if (!canEdit.value) return;
    if (!online.value) return;
    if (eventDependencies.value.length && !inList) return;
    if (eventDependencies.value.length && inList) {
      const ticketId =
        eventDependencies.value[0].dependentType === "ticket"
          ? eventDependencies.value[0].dependentId
          : eventDependencies.value[0].relatedId;
      const ticket = ticketsStore.collection.find(
        (t: Ticket) => t.id === ticketId
      );
      if (!ticket) {
        const loaded = await ticketsStore.load(ticketId);
        return loaded ? gotoTicket(loaded.uuid) : undefined;
      }
      gotoTicket(ticket.uuid);
      return;
    }
    if (dependenciesLoading.value) return;
    dependenciesLoading.value = true;
    if (!ticketTemplatesStore.collection.length) {
      return addTicket();
    }
    const breakpoints = calcModalPercents(
      44,
      ticketTemplatesStore.collection.length,
      210
    );
    const modal = await modalController.create({
      component: TicketCreateModal,
      canDismiss: true,
      mode: "ios",
      breakpoints,
      initialBreakpoint: breakpoints[1],
      componentProps: {
        onDone: async (response: {
          type: string;
          ticket_template?: number;
        }) => {
          await modal.dismiss();
          switch (response.type) {
            case "ticket":
              addTicket(response.ticket_template);
              break;
            default:
              dependenciesLoading.value = false;
          }
        },
        onCancel: async () => {
          dependenciesLoading.value = false;
          await modal.dismiss();
        },
      },
    });
    modal.onDidDismiss().then(() => {
      dependenciesLoading.value = false;
    });
    return modal.present();
  };
  const addTicket = async (ticket_template?: number) => {
    const teamIds = getTeamFromEventZone();
    if (!teamIds?.length) {
      const toast = await toastController.create({
        message: `🚨 ${t("common.noValues")} (${t("team", 1)})`,
        duration: 2000,
      });
      return toast.present();
    }
    if (teamIds.length === 1) {
      try {
        const ticket = await addTicketToStore(teamIds[0], ticket_template);
        gotoTicket(ticket.uuid);
      } catch (e) {
        console.error(e);
      }
      dependenciesLoading.value = false;
      return;
    }
    const breakpoints = calcModalPercents(44, teamIds.length, 210);
    const modal = await modalController.create({
      component: TeamInstancePickerModal,
      canDismiss: true,
      mode: "ios",
      breakpoints,
      initialBreakpoint: breakpoints[1],
      componentProps: {
        teams: teamIds,
        entityName: "tickets",
        onDone: async (teamId: number | null) => {
          await modal.dismiss();
          if (teamId) {
            try {
              const ticket = await addTicketToStore(teamId, ticket_template);
              gotoTicket(ticket.uuid);
            } catch (e) {
              console.error(e);
            }
          }
          dependenciesLoading.value = false;
        },
        onCancel: async () => {
          dependenciesLoading.value = false;
          await modal.dismiss();
        },
      },
    });
    modal.onDidDismiss().then(() => {
      dependenciesLoading.value = false;
    });
    return modal.present();
  };
  const gotoTicket = (ticket: string) => {
    router.navigate(
      `/tickets/${ticket}`,
      "forward",
      "push",
      ticketPageAnimation
    );
  };
  const addTicketToStore = async (teamId: number, ticket_template?: number) => {
    const date = formatISO(new Date());
    const userId = fabriqStore.user?.id;

    let ticketToAdd: Ticket = createEmptyTicket(date, userId, ticket_template);
    const template: TicketTemplate | undefined =
      ticketTemplatesStore.collection.find(
        (t: any) => t.id === ticket_template
      );

    if (template !== undefined) {
      ticketToAdd = addTicketTemplatePresetValues(
        ticketToAdd,
        template,
        fabriqStore.locale || "en"
      );
    }
    const title = "";
    const description = "";

    const toAdd: Ticket = {
      ...ticketToAdd,
      title,
      description,
      teams: [teamId],
      owner: fabriqStore.user?.id || null,
      due_date: format(new Date(), "yyyy-MM-dd"),
      categories: [],
      updated_at: date,
      created_at: date,
      _type: TicketType.Issue,
    };

    const ticket = await ticketsStore.add(toAdd);
    await ticketsStore.save();

    mixpanelTracker.track("create | ticket | events mobile tab");

    const savedTicket = ticketsStore.collection.find(
      (t: Ticket) => t.uuid === ticket.uuid
    );

    if (!savedTicket.id) {
      throw new Error("Ticket not saved");
    }

    await diamondDependenciesStore.add({
      dependentId: savedTicket.id,
      relatedId: fEvent.id,
      dependentType: "ticket",
      relatedType: "event",
      dependencyType: "relatedTo",
    });
    await diamondDependenciesStore.save();

    if (template?.tasks?.length) {
      const now = new Date();
      let time = 0;
      template.tasks.forEach((t: any) => {
        time += t.resolution_time || 0;
        tasksStore.add({
          ticket: ticket.uuid,
          description: getTextToDisplay(t, fabriqStore.locale),
          user: t.owner_id,
          due_date: t.resolution_time
            ? format(addDays(now, time), "yyyy-MM-dd")
            : null,
          order: t.order,
          created_from_template: true,
        });
      });
    }
    return ticket;
  };

  return {
    openAddMenu,
    gotoTicket,
    eventDependencies,
    dependenciesLoading,
    canEdit,
  };
};
