<script lang="ts" setup>
import { computed, ComputedRef } from "vue";
import type {
  Datapoint,
  ExecutionAnswer,
  Indicator,
  TemplateStep,
} from "@/types";
import { useI18n } from "@/composables/useI18n";
const { t } = useI18n();

const props = defineProps<{
  step: TemplateStep;
  indicator: Indicator;
  datapoint: Datapoint;
  answer: ExecutionAnswer;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (event: "value", payload: unknown): void;
}>();

interface Choice {
  label: string;
  color: string;
  key: string;
  value: number;
  selected: boolean;
}

const choices: ComputedRef<Choice[]> = computed(() => {
  return props.indicator.choices.options.map((c: any) => {
    return {
      ...c,
      value: +c.value,
      label: c.description || t(`indicatorPage.${c.key}`),
      selected: props.answer?.value // <--- if the value is not null
        ? parseInt(props.answer?.value) === +c.value // <-- I can perform a comparison
        : false, // <-- otherwise I set it to false
    };
  });
});
</script>

<template>
  <div>
    <div
      v-for="choice of choices"
      @click="emit('value', choice.value)"
      :key="choice.key"
      class="ion-margin step-choice-counter"
      :class="{
        'step-choice-counter--selected': choice.selected,
      }"
    >
      {{ choice.label }}
      <div class="font-icon check-mark" v-if="choice.selected">
        <i class="material-icons">check</i>
      </div>
    </div>
  </div>
</template>
<style scoped>
.check-mark {
  color: var(--ion-color-secondary);
}

.step-choice-counter {
  position: relative;
  border: 1px solid var(--ion-border-color);
  background-color: var(--ion-background-color);
  text-align: center;
  padding: var(--ion-padding);
  border-radius: var(--f-border-radius);
  margin: var(--ion-padding) 0;
  font-size: var(--font-size-m);
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.step-choice-counter--selected {
  animation: blink 0.3s ease 0s 1 normal forwards;
}

@keyframes blink {
  0% {
    border: 1px solid transparent;
  }

  100% {
    border: 1px solid var(--ion-color-secondary);
  }
}
</style>
