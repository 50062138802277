<script setup lang="ts">
import { computed } from "vue";
import { IonChip, IonLabel } from "@ionic/vue";
import CachedAvatar from "@/components/ui/CachedAvatar.vue";
import { getBackgroundColor, colorBrightness } from "@/utils/colors";

interface Props {
  emoji?: string;
  avatar?: string;
  fallback?: string;
  label: string;
  color?: `#${string}` | "transparent";
  icon?: string;
  faded?: boolean;
  material?: boolean;
  small?: boolean;
  clearable?: boolean;
  coloredIconOnly?: boolean;
  noMaxWidth?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  color: "transparent",
  faded: false,
  material: false,
  small: false,
  clearable: false,
  coloredIconOnly: false,
});

const emit = defineEmits<{ (event: "remove"): void }>();
const noColor = computed(
  () =>
    props.color === "transparent" || !props.color || props.color === "#00000000"
);
const darkenColor = computed(() => {
  if (noColor.value || props.color === "transparent")
    return "var(--ion-color-primary)";
  const col: `#${string}` = props.color;
  return getBackgroundColor(col);
});

const DARK_THRESHOLD = 140;
const textStyle = computed(() => {
  if (props.faded) return "";
  if (props.coloredIconOnly || noColor.value)
    return "background-color: transparent; color: var(--ion-color-primary);";
  const brightness = colorBrightness(props.color);
  const color =
    brightness > DARK_THRESHOLD
      ? "var(--colored-chip-high-color)"
      : "var(--colored-chip-low-color)";
  return `background-color: transparent; color: ${color}`;
});
const chipStyle = computed(() => {
  if (props.faded) return "background-color: transparent;";
  if (props.coloredIconOnly) {
    const borderColor =
      props.color && !noColor.value ? props.color : "var(--ion-border-color)";
    return `background-color: transparent; border: 1px solid ${borderColor}; color: var(--ion-color-primary);`;
  }
  return `background-color: ${noColor.value ? "transparent" : props.color};${
    noColor.value ? ";border: 1px solid var(--ion-color-secondary-shade);" : ""
  }`;
});

const removeChip = (ev: any) => {
  if (!props.clearable) return;
  ev.preventDefault();
  ev.stopPropagation();
  emit("remove");
};
</script>

<template>
  <ion-chip
    class="colored-chip"
    :style="chipStyle"
    :class="{
      'colored-chip-faded': faded,
      'colored-chip-small': small,
      'colored-chip-no-max-width': noMaxWidth,
    }"
    @click="removeChip"
  >
    <cached-avatar
      class="avatar"
      :style="textStyle"
      v-if="avatar"
      :avatar="avatar"
      :fallback="fallback"
    />
    <font-icon
      class="colored-chip-icon"
      v-if="icon"
      :name="icon"
      :size="small ? '0.75' : '1'"
      :color="darkenColor"
      :material="material"
    />
    <span class="chip-emoji" v-if="emoji">{{ emoji }}</span>
    <ion-label :style="textStyle">{{ label }}</ion-label>
    <font-icon
      class="remove-chip-icon"
      v-if="clearable"
      name="close"
      size="0.75"
      :color="darkenColor"
      @click="removeChip"
    />
  </ion-chip>
</template>

<style>
.colored-chip {
  margin-bottom: 0;
  margin-inline: 0;
  --icon-chip-spacing: 0.3rem;
  height: 32px;
  max-height: 32px;
}
.colored-chip.colored-chip-no-max-width {
  height: auto;
  max-height: 100px;
}
.colored-chip-icon {
  margin-left: -2px;
  margin-right: 6px;
}
.chip-emoji {
  font-size: var(--font-size-xs);
  margin-left: calc(var(--icon-chip-spacing) / 3);
  margin-right: calc(var(--icon-chip-spacing) * 2);
}
.remove-chip-icon {
  margin-left: calc(var(--icon-chip-spacing) * 2);
  margin-right: calc(var(--icon-chip-spacing) / 4);
  margin-top: calc(var(--icon-chip-spacing) / 4);
}
.colored-chip-faded {
  border: 1px dashed var(--ion-color-primary-shade);
  border-radius: 50px;
  color: var(--ion-color-primary-shade);
}
.colored-chip .avatar {
  --_size: 1.2rem;
  margin-right: calc(var(--icon-chip-spacing) * 2);
  margin-left: calc(var(--icon-chip-spacing) * -1);
}

.colored-chip-small {
  --icon-chip-spacing: 0.1rem;
  font-size: var(--font-size-xs);
  padding: calc(var(--ion-padding) * 0.75);
  height: 18px;
  max-height: 18px;
}
.colored-chip-small .avatar {
  --_size: 1rem;
  --icon-chip-spacing: 0.1rem;
  font-size: var(--font-size-xs);
}
</style>
