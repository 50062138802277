import { createListStore } from "./list";
import { NotificationService } from "@/services/notifications";
import { Notification } from "@/types";

export const useNotificationsStore = createListStore(
  "notifications",
  NotificationService,
  null,
  {
    count: 0,
  },
  {
    setCount(count: number) {
      this.count = count;
    },
    async getCount() {
      if (!NotificationService.getCount) return;
      const count = await NotificationService.getCount();
      this.count = count;
    },
    async readAll(notifications: Notification[]) {
      if (!NotificationService.readAll) return;
      const ids: number[] = [];
      await Promise.all([
        notifications.map((n: Notification) => {
          if (n.is_read) return Promise.resolve();
          if (n.id) ids.push(+n.id);
          return this.update(n.uuid, { is_read: true }, true);
        }),
      ]);
      await NotificationService.readAll(ids);
      this.getCount();
    },
  },
  true
);
