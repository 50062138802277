<script lang="ts" setup>
import { computed } from "vue";
import ColoredChip from "@/components/ui/ColoredChip.vue";
import type { Indicator } from "@/types";

interface Props {
  indicator: Indicator;
  readonly?: boolean;
  faded?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  readonly: false,
  faded: false,
});

const avatar = computed(() => props.indicator?.avatar?.url);
const color = computed(() => props.indicator?.config?.color || "#aeaeae");
const icon = computed(() => props.indicator?.icon);
</script>

<template>
  <colored-chip
    :avatar="avatar"
    :label="indicator.name"
    :color="color"
    :icon="icon"
    :clearable="!readonly"
    :faded="!indicator.id"
  />
</template>
