import {
  Datapoint,
  DatapointOperation,
  Indicator,
  IndicatorConfig,
  IndicatorFrequency,
  IndicatorRule,
  IndicatorValueType,
  RuleCondition,
} from "@/types";
import { useI18n } from "@/composables/useI18n";
import { format, parse } from "date-fns";
import { secondsToDuration } from "./dates";

export const betaClientId = [
  56, 344, 192, 389, 4, 352, 345, 1, 62, 271, 392, 273, 342, 60, 191, 266, 391,
  347, 10, 177, 264, 171, 174, 51, 33, 267, 269, 272, 395, 341, 354, 166, 262,
  187, 8, 163, 32, 47, 530, 340,
];

export const getDateFormat = (
  frequency?: IndicatorFrequency,
  defaultValue?: string
) => {
  const { t } = useI18n();
  if (!frequency) {
    return defaultValue ?? t("formats.dayOfMonth");
  }
  switch (frequency) {
    case "weekly":
      return `'${t("dates.week")}' I yyyy (dd MMM)`;
    case "monthly":
      return "MMMM yyyy";
    case "yearly":
      return "MMMM";
    default:
      return defaultValue ?? t("formats.dayOfMonth");
  }
};

export const getComplementaryValue = (
  indicator: Indicator,
  datapoints: Array<Datapoint>,
  latestDataPoint: Datapoint
) => {
  const { t } = useI18n();
  const complementaryValue = latestDataPoint.complementary_value ?? 0;

  switch (indicator.value_type) {
    case null:
    case IndicatorValueType.PercentDelta: {
      return (
        (complementaryValue >= 0 ? "+" : "") +
        formatValue(complementaryValue, "percentage", 0, 0) +
        " " +
        t("indicatorPage.vsTarget")
      );
    }
    case IndicatorValueType.ValueDelta: {
      return (
        (complementaryValue >= 0 ? "+" : "") +
        formatValue(
          complementaryValue,
          indicator.unit_type,
          indicator.decimals,
          indicator.power,
          indicator.config?.default_currency
        ) +
        " " +
        t("indicatorPage.vsTarget")
      );
    }
    case IndicatorValueType.PercentChange: {
      return (
        (complementaryValue >= 0 ? "+" : "") +
        formatValue(complementaryValue, "percentage", 0, 0) +
        " vs. " +
        format(
          parse(datapoints[1].end_date, "yyyy-MM-dd", new Date()),
          t("formats.dayOfMonth")
        )
      );
    }
    case IndicatorValueType.ValueChange: {
      return (
        (complementaryValue >= 0 ? "+" : "") +
        formatValue(
          complementaryValue,
          indicator.unit_type,
          indicator.decimals,
          indicator.power,
          indicator.config?.default_currency
        ) +
        " vs. " +
        format(
          parse(datapoints[1].end_date, "yyyy-MM-dd", new Date()),
          t("formats.dayOfMonth")
        )
      );
    }
    case IndicatorValueType.Mtd:
      return (
        formatValue(
          complementaryValue,
          indicator.unit_type,
          indicator.decimals,
          indicator.power,
          indicator.config?.default_currency
        ) +
        " " +
        t("dates.in") +
        " " +
        format(new Date(), "MMM YYYY")
      );
    case IndicatorValueType.Ytd:
      return (
        formatValue(
          complementaryValue,
          indicator.unit_type,
          indicator.decimals,
          indicator.power,
          indicator.config?.default_currency
        ) +
        " " +
        t("dates.in") +
        " " +
        format(new Date(), "YYYY")
      );
    case IndicatorValueType.Target:
      return formatValue(
        latestDataPoint.target ?? indicator.default_target,
        indicator.unit_type,
        indicator.decimals,
        indicator.power,
        indicator.config?.default_currency
      );
  }
  return "";
};

const getDatapointsSum = (datapoints: Array<Datapoint>): number => {
  return datapoints.reduce((acc: number, dp: Datapoint) => {
    if (!dp) return acc;
    return acc + +dp.target;
  }, 0);
};

export const getAggregateTarget = (
  datapoints: Array<Datapoint>,
  operation: DatapointOperation
): number => {
  switch (operation) {
    case DatapointOperation.Sum:
      return getDatapointsSum(datapoints);
    case DatapointOperation.Min:
      return Math.min(...datapoints.map((dp: Datapoint) => +dp.target));
    case DatapointOperation.Max:
      return Math.max(...datapoints.map((dp: Datapoint) => +dp.target));
    case DatapointOperation.Average: {
      if (!datapoints.length) return 0;
      const sum = getDatapointsSum(datapoints);
      return sum / datapoints.length;
    }
    default:
      return 0;
  }
};

export const getPowerSymbol = (power: number): string => {
  if (power === 3) return "k";
  if (power === 6) return "M";
  return "";
};

export const formatValue = (
  value: any,
  type: string | null,
  decimals: number,
  power: number,
  default_currency = "&euro;"
) => {
  const { n } = useI18n();
  let number = "";
  let currency = "";
  switch (type) {
    case "percentage":
      if (decimals == 3) {
        return n(value, "percent_3decimal");
      } else if (decimals == 2) {
        return n(value, "percent_2decimal");
      } else if (decimals == 1) {
        return n(value, "percent_1decimal");
      } else {
        return n(value, "percent_0decimal");
      }

    case "number":
      if (decimals == 3) {
        number = n(value / Math.pow(10, power), "number_3decimal");
      } else if (decimals == 2) {
        number = n(value / Math.pow(10, power), "number_2decimal");
      } else if (decimals == 1) {
        number = n(value / Math.pow(10, power), "number_1decimal");
      } else {
        number = n(value / Math.pow(10, power), "number_0decimal");
      }
      // Return with corresponding power symbol
      if (power == 0) {
        return number;
      } else {
        return number + " " + getPowerSymbol(power);
      }

    case "time":
      return secondsToDuration(+value);

    case "currency":
      if (!default_currency) return "";
      if (decimals == 3) {
        currency = n(
          value / Math.pow(10, power),
          "currency_3decimal",
          default_currency
        );
      } else if (decimals == 2) {
        currency = n(
          value / Math.pow(10, power),
          "currency_2decimal",
          default_currency
        );
      } else if (decimals == 1) {
        currency = n(
          value / Math.pow(10, power),
          "currency_1decimal",
          default_currency
        );
      } else {
        currency = n(
          value / Math.pow(10, power),
          "currency_0decimal",
          default_currency
        );
      }

      // Return with corresponding power symbol
      // remove currency sign and insert it before currency symbol
      // adjust when fist symbol is a -
      if (power == 0) {
        return currency;
      } else {
        // VALUE < 0
        if (value < 0) {
          if (["€", "$", "£"].includes(currency[currency.length - 1])) {
            return (
              currency.slice(0, currency.length - 1) +
              " " +
              getPowerSymbol(power) +
              currency[currency.length - 1]
            );
          } else {
            return currency + " " + getPowerSymbol(power);
          }
          // VALUE > 0
        } else {
          if (["€", "$", "£"].includes(currency[0])) {
            return currency + " " + getPowerSymbol(power);
          } else {
            return (
              currency.slice(0, currency.length - 2) +
              " " +
              getPowerSymbol(power) +
              currency[currency.length - 1]
            );
          }
        }
      }

    default:
      number = n(value / Math.pow(10, power), "number_1decimal");
      // Return with corresponding power symbol
      if (power == 0) {
        return number;
      } else {
        return number + " " + getPowerSymbol(power);
      }
  }
};

const isValueFollowingCondition = (
  condition: RuleCondition,
  value: number,
  previousCondition = true
): boolean => {
  if (condition.conjunction === "and" && previousCondition === false)
    return false;
  let isGood = true;
  const cValue = +condition.value;
  switch (condition.operator) {
    case "!=":
      isGood = value !== cValue;
      break;
    case "=":
      isGood = value === cValue;
      break;
    case ">":
      isGood = value > cValue;
      break;
    case "<":
      isGood = value < cValue;
      break;
    case ">=":
      isGood = value >= cValue;
      break;
    case "<=":
      isGood = value <= cValue;
      break;
  }
  if (condition.conjunction === "and") return previousCondition && isGood;
  if (condition.conjunction === "or") return previousCondition || isGood;
  return isGood;
};

function getValueFromTimeUnit(
  value: string | undefined,
  unit: string | undefined
): number | undefined {
  if (value === undefined || unit === undefined) return undefined;
  const intValue = parseInt(value);
  switch (unit) {
    case "hours":
      return intValue * 60 * 60;
    case "minutes":
      return intValue * 60;
    default:
      return intValue;
  }
}

export const isValueFollowingRules = (
  rules: IndicatorRule[],
  value: string | undefined,
  isTimeUnit = false,
  timeUnit: string | undefined = undefined
) => {
  const valueToApplyRuleTo = isTimeUnit
    ? getValueFromTimeUnit(value, timeUnit)
    : Number(value);

  let isValid: boolean | undefined;
  for (const rule of rules) {
    const conditions = [...rule.conditions];
    conditions.sort((a: RuleCondition, b: RuleCondition) => a.order - b.order);
    for (const condition of rule.conditions) {
      isValid = isValueFollowingCondition(
        condition,
        valueToApplyRuleTo || 0,
        isValid
      );
    }
  }
  return isValid;
};

export const parseValue = (
  value: string | number | null | undefined,
  indicator: Indicator
) => {
  const { unit_type, decimals, config } = indicator;
  // @ts-expect-error
  let numberValue = parseFloat(value);
  if (isNaN(numberValue)) return;
  if (unit_type === "percentage") {
    numberValue *= 100;
  }
  if (unit_type === "time") {
    if (config?.default_time === "minutes") {
      numberValue /= 60;
    }
    if (config?.default_time === "hours") {
      numberValue /= 3600;
    }
    return numberValue;
  }
  if (decimals === undefined) return numberValue;
  const pow = Math.pow(10, decimals);
  return Math.round(numberValue * pow) / pow;
};

export const unParseValue = (
  value: string | number | null | undefined,
  unit_type: string | null,
  config?: IndicatorConfig
) => {
  const stringValue = `${value !== 0 && !value ? "" : value}`.replace(",", ".");
  let numberValue = parseFloat(stringValue);
  if (isNaN(numberValue)) return "";
  if (unit_type === "percentage") {
    numberValue /= 100;
  }
  if (unit_type === "time") {
    if (config?.default_time === "minutes") {
      numberValue *= 60;
    }
    if (config?.default_time === "hours") {
      numberValue *= 3600;
    }
    return numberValue;
  }
  const precision = 100000000;
  const rounded = Math.round(numberValue * precision) / precision;
  return `${rounded}`;
};
