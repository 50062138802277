<script lang="ts" setup>
import { Clipboard } from "@capacitor/clipboard";
import { computed } from "vue";
import CachedImage from "@/components/tools/CachedImage.vue";
import { useFilesStore } from "@/store/files";
import { FabriqFile } from "@/types";
import { IonButton, IonItem, modalController } from "@ionic/vue";
import FileIcon from "@/components/ui/FileIcon.vue";
import FileActionsModalVue from "../modals/FileActionsModal.vue";
import { useFile } from "@/composables/useFile";
import { transformUrl } from "@/utils/files";
import { useFabriqStore } from "@/store/fabriq";

interface Props {
  file: FabriqFile;
  readonly: boolean;
  viewer: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  readonly: false,
  viewer: false,
});

const emit = defineEmits(["delete"]);

const filesStore = useFilesStore();
const fabriqStore = useFabriqStore();
const { src, openFile, isImage, extension, createdAt, name, image } = useFile(
  computed(() => props.file)
);

const openActions = async () => {
  const modal = await modalController.create({
    component: FileActionsModalVue,
    canDismiss: true,
    mode: "ios",
    breakpoints: [0, 0.3, 0.5],
    initialBreakpoint: 0.3,
    componentProps: {
      readonly: props.readonly,
      onDone: async (action: string) => {
        await modal.dismiss();
        switch (action) {
          case "copy": {
            const url = transformUrl(
              props.file.media_type === "file" ? src.value : props.file.url,
              fabriqStore.token,
              fabriqStore.subdomain
            );
            Clipboard.write({
              string: url,
            });
            break;
          }
          case "delete":
            await filesStore.remove(props.file);
            filesStore.save();
            emit("delete", props.file);
            break;
        }
      },
      onCancel: () => modal.dismiss(),
    },
  });

  await modal.present();
};
</script>

<template>
  <ion-item class="ticket-file" @click="openFile">
    <div class="ticket-image-div" slot="start">
      <font-icon
        v-if="!file.id"
        class="send-file"
        size="0.5"
        name="cloud-arrow-up"
      />
      <div
        class="ticket-file-image-wrapper"
        :class="`image-${file.id}`"
        v-if="file.media_type === 'file' && isImage"
      >
        <cached-image
          v-if="!file.loading"
          ref="image"
          :viewer="viewer"
          class="ticket-file-image"
          :src="src"
        />
        <font-icon
          class="loading-block-spinner"
          spin
          name="spinner"
          color="var(--ion-color-primary-tint)"
          v-else
          size="0.6"
        />
      </div>
      <div v-else class="ticket-file-image-wrapper">
        <file-icon :extension="extension" />
      </div>
    </div>
    <div class="file-content">
      <div class="file-title" v-if="name && name.length">{{ name }}</div>
      <div class="file-date">{{ createdAt }}</div>
    </div>
    <ion-button
      class="file-actions-button"
      slot="end"
      fill="clear"
      v-if="file.id"
      @click.stop.prevent="openActions"
    >
      <font-icon slot="icon-only" name="more_horiz" material />
    </ion-button>
    <font-icon
      v-if="!file.id"
      class="ion-margin-end"
      slot="end"
      size="0.3"
      name="spinner"
      color="var(--ion-color-secondary)"
      spin
    />
  </ion-item>
</template>

<style scoped>
.file-content {
  --content-padding: calc(var(--ion-padding) / 4);
  padding: var(--content-padding) 0;
  flex: 1;
}
.exit-fs-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.file-actions-button {
  margin-right: calc(var(--ion-padding) / 2 - 4px);
}
.file-url {
  background-color: var(--f-color-discrete);
  border-radius: 5px;
}
</style>
