export const getFutureAuthError = (
  idpStatus: "external" | "internal" | undefined,
  email: string,
  authConfig: {
    ssoMandatory: boolean;
    defaultSSOId?: string | undefined;
    ssoLabel?: string | undefined;
    emailPatterns?: string[] | undefined;
  }
) => {
  if (!idpStatus) return null;
  if (idpStatus === "external") {
    if (!authConfig.defaultSSOId?.length) return "common.unknownError"; // "future.errors.noDefaultSSOId";
    if (authConfig.ssoMandatory) {
      const matches =
        !authConfig.emailPatterns ||
        authConfig.emailPatterns.some((pattern) => email.includes(pattern));
      if (!matches) return "common.unknownError"; // "future.errors.emailDoesNotMatchPattern";
    }
  }
  if (idpStatus === "internal" && authConfig.ssoMandatory)
    return "common.unknownError"; //"future.errors.ssoMandatory";
  return null;
};
