<template>
  <div class="ticket-hint clickable" @click="$emit('select', ticket.uuid)">
    <div class="ticket-hint-label">
      <font-icon
        v-if="ticket.type === 'loading'"
        name="spinner"
        spin
        :color="color"
        size="0.4"
        class="ticket-hint-loading"
      />
      <font-icon
        v-else
        class="ticket-hint-icon"
        :name="icon"
        material
        outlined
        :color="color"
      />
      <html-content tag="div" class="title" :content="title" />
      <div class="category" v-if="category">
        <category-chip
          small
          style="margin: 0"
          :category="category"
          :readonly="true"
        />
      </div>
    </div>
    <div v-if="subItem" class="ticket-hint-label sub-item">
      <font-icon :name="subItem.icon" material outlined :color="color" />
      <html-content tag="div" class="title" :content="subItem.title" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { Category } from "@/types";
import CategoryChip from "@/components/business/tickets/CategoryChip.vue";
import {
  commentParser,
  removeHtml,
  truncateMarks,
} from "@/utils/comment-parser";
import { useClientStore } from "@/store/client";
import HtmlContent from "@/components/tools/HtmlContent.vue";

export default defineComponent({
  name: "TicketHint",
  components: {
    HtmlContent,
    CategoryChip,
  },
  emits: ["select"],
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const clientStore = useClientStore();
    const title = computed(() =>
      truncateMarks(
        props.ticket._highlightResult?.title?.value || props.ticket.title
      )
    );
    const getMatchScore = (t: any) => {
      const nb = t.matchedWords.length;
      if (t.level === "full") return 2 + nb;
      if (t.level === "partial") return 1 + nb;
      return 0;
    };
    const subItem = computed(() => {
      const items: any = [];
      props.ticket._highlightResult?.task_descriptions?.forEach((t: any) => {
        if (t.matchLevel === "none") return;
        items.push({
          title: truncateMarks(
            t.value
              .replace("__ais-highlight__", "<mark>")
              .replace("__/ais-highlight__", "</mark>")
          ),
          icon: "check_circle_outline",
          score: getMatchScore(t),
        });
      });
      const comments_content =
        props.ticket._highlightResult?.ticket_comments_content ||
        props.ticket._highlightResult?.comments_content;
      comments_content?.forEach((t: any) => {
        if (t.matchLevel === "none") return;
        let title = removeHtml(commentParser(t.value));
        t.matchedWords.forEach((w: any) => {
          const reg = new RegExp(`(${w})`, "gi");
          title = title.replaceAll(reg, "<mark>$1</mark>");
        });
        items.push({
          title: truncateMarks(title),
          icon: "check_circle_outline",
          score: getMatchScore(t),
        });
      });
      props.ticket._highlightResult?.involved_user_names?.forEach((t: any) => {
        if (t.matchLevel === "none") return;
        items.push({
          title: truncateMarks(t.value),
          icon: "person",
          score: getMatchScore(t),
        });
      });
      const description =
        props.ticket._highlightResult?.clean_description ||
        props.ticket._highlightResult?.description;
      if (description?.value?.length && description?.matchLevel !== "none") {
        items.push({
          title: truncateMarks(description.value),
          icon: "reorder",
          score: getMatchScore(description),
        });
      }
      const root_cause =
        props.ticket._highlightResult?.clean_root_cause ||
        props.ticket._highlightResult?.root_cause;
      if (root_cause?.value?.length && root_cause?.matchLevel !== "none") {
        items.push({
          title: truncateMarks(root_cause.value),
          icon: "compare_arrows",
          score: getMatchScore(root_cause),
        });
      }
      if (!items.length) return null;
      items.sort((a: any, b: any) => b.score - a.score);
      return items[0];
    });
    const icon = computed(() => {
      return props.ticket.type === "issue" ? "assignment" : "rss_feed";
    });
    const color = computed(() =>
      props.ticket.is_open
        ? "var(--f-color-discrete)"
        : "var(--f-color-disabled)"
    );
    const category = computed(() => {
      if (!props.ticket || !props.ticket.category_ids) return null;
      return clientStore.categories?.find((c: Category) =>
        props.ticket.category_ids.includes(c.id)
      );
    });
    return {
      title,
      subItem,
      icon,
      color,
      category,
    };
  },
});
</script>

<style scoped>
.ticket-hint {
  background-color: var(--ion-background-color);
  padding: var(--f-padding-m);
  border-bottom: 1px solid var(--ion-border-color);
}
.ticket-hint:first-child {
  border-top: 1px solid var(--ion-border-color);
}
li .ticket-hint:first-child {
  border-top: 0;
}

.ticket-hint-label {
  display: flex;
  flex-direction: row;
  gap: calc(var(--f-padding-m) / 2);
  font-size: var(--font-size-m);
  align-items: center;
}

.ticket-hint-loading {
  margin: -4.5px -4px -4.5px -8px;
}

.ticket-hint-label.sub-item {
  padding-left: calc(var(--f-padding-m) / 2);
  padding-top: var(--f-padding-m);
}
.ticket-hint-label div.title {
  flex: 1;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ticket-hint-icon {
  width: 16px;
  height: 16px;
  display: block;
}
</style>
<style>
.ticket-hint mark {
  color: var(--ion-color-secondary);
  background-color: transparent;
}
p {
  margin: 0 !important;
}

.ticket-hint-label div.title p,
.ticket-hint-label div.title div {
  display: inline;
}
</style>
