export function fabriqDeepClone<T>(input: T, useStructuredClone = true): T {
  if (useStructuredClone) {
    try {
      return structuredClone(input);
    } catch {
      return JSON.parse(JSON.stringify(input));
    }
  }
  return JSON.parse(JSON.stringify(input));
}
