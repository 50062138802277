export const dateTimeFormats = {
  en: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },

    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  de: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  fr: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  tr: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  cs: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  pt: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  ro: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  es: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  zh: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  "zh-hk": {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  sl: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  pl: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  ko: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  it: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  ja: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  sk: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  no: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  hu: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  nl: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  sv: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    shortYearOn2Digits: {
      year: "2-digit",
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
  ar: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    weekdays: {
      weekday: "long",
    },
    shortWeekdays: {
      weekday: "short",
    },
    months: {
      month: "long",
    },
    shortMonths: {
      month: "short",
    },
    monthYear: { month: "short", year: "2-digit" },
    monthYearLong: { month: "long", year: "numeric" },
    shortMonthLongYear: { month: "short", year: "numeric" },
    year: { year: "numeric" },
    shortNoYear: {
      month: "short",
      day: "numeric",
    },
    shortYearOn2Digits: {
      year: "2-digit",
      month: "short",
      day: "numeric",
    },
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortNumeric: {
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric": {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
    "full-numeric-with-time": {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    fullShort: {
      year: "2-digit",
      month: "short",
      day: "numeric",
      weekday: "short",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    longNumeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "weekday-short": {
      weekday: "long",
      month: "short",
      day: "numeric",
    },
    "weekday-shorter": {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  },
};
