<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item>
        <div class="hint">{{ t("common.theme") }}</div>
      </ion-item>
      <ion-item @click="$emit('done', 'light')" class="clickable">
        <ion-label class="list-item">{{ t("common.themes.light") }}</ion-label>
      </ion-item>
      <ion-item @click="$emit('done', 'dark')" class="clickable">
        <ion-label class="list-item">{{ t("common.themes.dark") }}</ion-label>
      </ion-item>
      <ion-item @click="$emit('done', undefined)" class="clickable">
        <ion-label class="list-item">{{
          t("common.themes.undefined")
        }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonContent, IonList, IonItem, IonLabel } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";

export default defineComponent({
  name: "TicketCreateModal",
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
  },
  emits: ["done", "cancel"],
  setup() {
    return {
      ...useI18n(),
    };
  },
});
</script>

<style scoped>
.list-item {
  font-size: var(--font-size-m);
}
</style>
