<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar ion-padding-top">
      <ion-title>{{ t("common.comments") }}</ion-title>
      <ion-buttons slot="end">
        <ion-button fill="clear" class="cancel-btn" @click="$emit('cancel')">
          {{ t("close") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="normal">
    <comment-list
      :comments="comments"
      :users="users"
      :tasks="tasks"
      :teams="teams"
      @add="addComment"
      @edit="editComment"
    />
  </ion-content>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, onMounted } from "vue";
import {
  IonContent,
  IonTitle,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useFabriqStore } from "@/store/fabriq";
import { useCommentsStore } from "@/store/comments";
import { useTasksStore } from "@/store/tasks";
import CommentList from "@/components/business/tickets/CommentList.vue";
import { formatISO } from "date-fns";
import {
  getMentionedUserIds,
  getMentionedTicketIds,
  getTaggedChildrenIds,
} from "@/utils/comment-parser";
import { Task, Team, Ticket, User, Comment } from "@/types";
import { ticketUpdatedByMe } from "@/utils/socket";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import tracker from "@/utils/tracker";
import { ActionType } from "@/classes/PerformanceTracker";

export default defineComponent({
  name: "TicketCommentsModal",
  props: {
    ticket: {
      type: Object as PropType<Ticket>,
      default: null,
    },
    users: {
      type: Array as PropType<Array<User>>,
      default: () => [],
    },
    teams: {
      type: Array as PropType<Array<Team>>,
      default: () => [],
    },
  },
  components: {
    IonContent,
    IonTitle,
    IonButtons,
    IonButton,
    IonHeader,
    IonToolbar,
    CommentList,
  },
  emits: ["done", "cancel"],
  setup(props) {
    const fabriqStore = useFabriqStore();
    const commentStore = useCommentsStore();
    const tasksStore = useTasksStore();

    onMounted(() => {
      tracker.end("openComments");
    });

    const comments = computed(() => {
      if (!props.ticket.id && !props.ticket.uuid) return [];
      const coms = commentStore.collection.filter(
        (c: any) =>
          c.ticket === props.ticket.id || c.ticket === props.ticket.uuid
      );
      return coms;
    });

    const tasks = computed(() =>
      tasksStore.collection.filter((t: Task) => t.ticket === props.ticket.id)
    );

    const addComment = async (payload: any) => {
      if (!payload.comment || !payload.comment.length) return;
      mixpanelTracker.track("add | comment | comment modal");
      const date = formatISO(new Date());
      const author = fabriqStore.user?.id;
      const { uuid, comment, parentId } = payload;
      await commentStore.add({
        uuid: uuid,
        content: comment,
        mentioned_user_ids: getMentionedUserIds(comment),
        mentioned_ticket_ids: getMentionedTicketIds(comment),
        tagged_children: getTaggedChildrenIds(comment),
        ticket: props.ticket.id || props.ticket.uuid,
        parent: parentId,
        author,
        created_at: date,
        updated_at: date,
      });
      if (props.ticket.id) ticketUpdatedByMe(+props.ticket.id);
      commentStore.save();
    };

    const editComment = async (payload: any) => {
      if (!payload.comment || !payload.comment.length) return;
      mixpanelTracker.track("update | comment | comment modal");
      const updated = commentStore.collection.find(
        (c: Comment) => c.id === payload.commentId
      );
      if (!updated) return;
      const { comment } = payload;
      await commentStore.update(updated.uuid, {
        content: comment,
        mentioned_user_ids: getMentionedUserIds(comment),
        mentioned_ticket_ids: getMentionedTicketIds(comment),
        tagged_children: getTaggedChildrenIds(comment),
      });
      if (props.ticket.id) ticketUpdatedByMe(+props.ticket.id);
      commentStore.save();
    };

    tracker.next("openComments", ActionType.Process, "component created");

    return {
      comments,
      tasks,
      addComment,
      editComment,
      ...useI18n(),
    };
  },
});
</script>

<style scoped>
.list-title {
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary-shade);
}
.user-item {
  margin-bottom: var(--ion-padding);
}
.transparent-toolbar {
  --padding-top: calc(var(--ion-padding) * 1.5);
  --padding-bottom: calc(var(--ion-padding) / 2);
}
</style>
