import {
  EventPropertyFilter,
  EventTypeDateTimeProperty,
  FabriqEvent,
} from "@/types";
import { createListStore } from "./list";
import { EventsService } from "@/services/events";
import { format } from "date-fns";
import { isoDateToInputDate } from "@/utils/dates";

export const useEventsStore = createListStore(
  "events",
  EventsService,
  {},
  {
    eventsCount: 0,
  },
  {
    async retrieveEvents(
      eventTypeId: string | null,
      filters: EventPropertyFilter[],
      isComplete: boolean | undefined = undefined,
      page: number,
      asc: boolean,
      sortProperty: string | undefined,
      teamId: number | undefined,
      dateTimeEventPropertyTypesByEventType: Record<
        string,
        EventTypeDateTimeProperty
      >,
      flush = false
    ) {
      this.loading = true;
      const { events, count } = await EventsService.all!({
        eventTypeId,
        filters,
        isComplete,
        page,
        asc,
        sortProperty,
        teamId,
      });

      if (count !== undefined) {
        this.eventsCount = count;
      }

      const mappedEvents = events.map((e: FabriqEvent) => {
        e.properties = e.properties.map((p) => {
          if (
            dateTimeEventPropertyTypesByEventType[e.eventTypeId]?.id ===
            p.eventPropertyTypeId
          ) {
            const { startDate, startTime } = isoDateToInputDate(
              p.value as string
            );
            p.value = format(
              new Date(`${startDate}T${startTime}`),
              "yyyy-MM-dd HH:mm"
            );
          }
          return p;
        });
        return e;
      });
      this.merge(mappedEvents, flush);
      this.loading = false;
    },
  },
  true
);
