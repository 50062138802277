export const numberFormats = {
  "en-US": {
    currency_3decimal: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "fr-FR": {
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "fr-CH": {
    currency_3decimal: {
      style: "currency",
      currency: "CHF",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "CHF",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "CHF",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "CHF",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "en-GB": {
    currency_3decimal: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "ar-TN": {
    currency_3decimal: {
      style: "currency",
      currency: "TND",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "TND",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "TND",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "TND",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "ja-JP": {
    currency_3decimal: {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "en-CA": {
    currency_3decimal: {
      style: "currency",
      currency: "CAD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "CAD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "CAD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "CAD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "tr-TR": {
    currency_3decimal: {
      style: "currency",
      currency: "TRY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "TRY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "TRY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "TRY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "zh-CN": {
    currency_3decimal: {
      style: "currency",
      currency: "CNY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "CNY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "CNY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "CNY",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "cs-CZ": {
    currency_3decimal: {
      style: "currency",
      currency: "CZK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "CZK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "CZK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "CZK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "sv-SE": {
    currency_3decimal: {
      style: "currency",
      currency: "SEK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "SEK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "SEK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "SEK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "pl-PL": {
    currency_3decimal: {
      style: "currency",
      currency: "PLN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "PLN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "PLN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "PLN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "es-MX": {
    currency_3decimal: {
      style: "currency",
      currency: "MXN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "MXN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "MXN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "MXN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "en-AU": {
    currency_3decimal: {
      style: "currency",
      currency: "AUD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "AUD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "AUD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "AUD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "es-CL": {
    currency_3decimal: {
      style: "currency",
      currency: "CLP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "CLP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "CLP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "CLP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "es-CO": {
    currency_3decimal: {
      style: "currency",
      currency: "COP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "COP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "COP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "COP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },

  en: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },

  el: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  de: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  fr: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  tr: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  cs: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  pt: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  ro: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  es: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  zh: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  "zh-hk": {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  sl: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  pl: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  ko: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  it: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  ja: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  sk: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  no: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  hu: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  nl: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  ar: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  sv: {
    number_3decimal: {
      style: "decimal",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    number_2decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number_1decimal: {
      style: "decimal",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    number_0decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent_3decimal: {
      style: "percent",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    percent_2decimal: {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent_1decimal: {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    percent_0decimal: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currency_3decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    currency_2decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency_1decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency_0decimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
};
