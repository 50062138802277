import storage from "@/utils/storage";

export const FUTURE_TOKENS_KEY = "future-tokens";
const FUTURE_CODE_PAIR_KEY = "future-codePair";

interface CodePair {
  codeVerifier: string;
  codeChallenge: string;
}

interface AuthenticationTokens {
  access: DecodedToken;
  refresh: DecodedToken;
  id: DecodedToken;
}
interface DecodedToken {
  token: string;
  payload: any;
}

function buildAuthTokenRepo() {
  return {
    async getAuthenticationTokens(): Promise<AuthenticationTokens | undefined> {
      const tokens = await storage.get(FUTURE_TOKENS_KEY);
      return tokens || undefined;
    },
    setAuthenticationTokens(authTokens: AuthenticationTokens) {
      storage.set(FUTURE_TOKENS_KEY, authTokens);
    },
    clearAuthenticationTokens() {
      storage.remove(FUTURE_TOKENS_KEY);
    },
  };
}

function buildPkceRepo() {
  return {
    async getPkceCodePair(): Promise<CodePair | undefined> {
      const codes = await storage.get(FUTURE_CODE_PAIR_KEY);
      return codes || undefined;
    },
    setPkceCodePair(codePair: CodePair) {
      storage.set(FUTURE_CODE_PAIR_KEY, codePair);
    },
    clearPkceCodePair() {
      storage.remove(FUTURE_CODE_PAIR_KEY);
    },
  };
}

export { buildPkceRepo, buildAuthTokenRepo };
