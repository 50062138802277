import { computed, ComputedRef } from "vue";
import { useClientStore } from "@/store/client";
import { User } from "@/types";

export const useUsers = (): { users: ComputedRef<User[]> } => {
  const clientStore = useClientStore();
  const users = computed(() => {
    if (!clientStore.users || !clientStore.users.length) return [];
    return clientStore.users;
  });

  return {
    users,
  };
};
