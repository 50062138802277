<template>
  <div>
    <ion-input
      class="step-input"
      inputmode="number"
      type="number"
      :placeholder="t('routines.placeholders.number')"
      :modelValue="answer.value"
      @update:modelValue="$emit('value', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TemplateStep, ExecutionAnswer } from "@/types";
import { IonInput } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";

export default defineComponent({
  name: "StepNumber",
  components: { IonInput },
  emits: ["value"],
  props: {
    step: {
      type: Object as PropType<TemplateStep>,
      default: () => ({}),
    },
    answer: {
      type: Object as PropType<ExecutionAnswer>,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ...useI18n(),
    };
  },
});
</script>

<style scoped></style>
