import { ListEntity } from "./states";
import { User, Avatar } from "./user";
import { ViewConfigGroupBy } from "./view";

export interface AdminContentConfig {
  color?: `#${string}` | "transparent";
  icon?: string;
  emoji?: string;
  i18n?: {
    [key: string]: string;
  };
}

export interface Category {
  id: number;
  config?: AdminContentConfig | null;
  created_at?: string;
  deleted_by?: number | null;
  icon?: string;
  client?: number;
  last_modified_by?: number | null;
  name: string;
  originalName?: string;
  rank?: number;
  updated_at?: string;
  avatar?: Avatar;
  color?: `#${string}` | "transparent";
}

export interface ClientConfig {
  hasDKPI: boolean;
  hasDarkMode?: boolean;
  hasNewTasks?: boolean;
  hasStatuses?: boolean;
  hasUpdateLog?: boolean;
  showTicketId?: boolean;
  allowedDomains: Array<{ value: string }>;
  defaultGroupBy: ViewConfigGroupBy;
  hasDependencies?: boolean;
  hasWorkloadView?: boolean;
  showPerformance?: boolean;
  showTeamHomePage?: boolean;
  allowEmailUpdates?: boolean;
  hasTicketWorkflows?: boolean;
  hasNewNotifications?: boolean;
  hasMultipleTaskplanViews?: boolean;
  hasTicketDateRanges?: boolean;
  hasClockAndAnnouncementWidget?: boolean;
  tmpNewTicketCreationFlow?: boolean;
  tmpFlexibleDateKpiStep?: boolean;
}

export interface Group {
  id: number;
  name: string;
  language: string;
  address: {
    emoji: string | undefined;
    subscriptionId: string | undefined;
    flatBilling: boolean | undefined;
  };
  config: null;
  teams: Array<number>;
  admins: Array<number>;
  billing_admins?: Array<number>;
  extra_fields: Field[];
}

export interface Label {
  id: number;
  name: string;
  originalName?: string;
  config?: AdminContentConfig;
  color?: string;
  icon?: string;
}

export interface StatusConfig {
  color?: `#${string}` | "transparent";
  icon?: string;
  emoji?: string;
  i18n?: {
    [key: string]: string;
  };
}

export interface Status {
  id: number;
  name: string;
  originalName?: string;
  config?: AdminContentConfig;
  avatar?: Avatar;
  color?: string;
  icon?: string;
}

export enum FieldType {
  Priority = "priority",
  Zone = "zone",
  Equipment = "equipment",
  Product = "product",
  Custom1 = "custom_1",
  Custom2 = "custom_2",
}

export interface Field extends ListEntity {
  id: number;
  is_global: boolean;
  config: AdminContentConfig;
  name: string;
  type: FieldType;
  parent?: number | null;
  groups: Array<number>;
  teams: Array<number>;
  color?: string;
  icon?: string;
}

export interface FieldVue {
  key: string;
  icon: string;
  value: Array<number | string> | number | string | null;
  values: Array<Field | Status | Label | Category>;
  multiple: boolean;
  readonly: boolean;
  coloredIconOnly: boolean;
}

export interface Client {
  categories: Array<Category>;
  config: ClientConfig;
  domain_whitelist: Array<string>;
  generate_template: boolean;
  groups: Array<Group>;
  id: number;
  is_deleted: boolean;
  labels?: Array<Label>;
  custom_fields: any;
  extra_fields?: Array<Field>;
  last_modified_by: number | null;
  logo: { url: string };
  name: string;
  process: string;
  processes: any;
  provider_names: Array<string>;
  receive_billing_email: boolean;
  status: string;
  statuses?: Array<Status>;
  team_trial_days: number;
  trial_period_about_end_notification: number;
  updated_at: string;
  users: Array<User>;
}
