import { ApiClient } from "./clients";
import { AllOptions, FieldType, ListService, Task, Ticket } from "@/types";
import { HttpParams } from "@capacitor/core";
import { ticketUpdatedByMe } from "@/utils/socket";
import { TeamSharingSettings } from "@/types/teamSharing";

export interface TicketListService extends ListService<Ticket> {
  addField?: (
    ticketId: number,
    field: { type: FieldType; value: number }
  ) => Promise<Ticket>;
  removeField?: (
    ticketId: number,
    field: { type: FieldType; value: number }
  ) => Promise<Ticket>;
  loadTeamSharingSettings: (
    ticketType: "issue" | "communication"
  ) => Promise<TeamSharingSettings>;
}

const transformTicket = (ticket: any) => {
  const transformed = {
    ...ticket,
    owner:
      isNaN(ticket.owner) && ticket.owner?.id ? ticket.owner.id : ticket.owner,
    categories: ticket.categories
      ? ticket.categories.map((c: any) => (isNaN(c) ? c.id : c))
      : undefined,
    teams: ticket.teams
      ? ticket.teams.map((c: any) => (isNaN(c) ? c.id : c))
      : undefined,
    labels: ticket.labels
      ? ticket.labels.map((c: any) => (isNaN(c) ? c.id : c))
      : undefined,
    created_by:
      isNaN(ticket.created_by) && ticket.created_by?.id
        ? ticket.created_by.id
        : ticket.created_by,
  };
  if (transformed.created_by === undefined) delete transformed.created_by;
  if (transformed.owner === undefined) delete transformed.owner;
  if (transformed.categories === undefined) delete transformed.categories;
  if (transformed.teams === undefined) delete transformed.teams;
  if (transformed.labels === undefined) delete transformed.labels;
  return transformed;
};

export const TicketService: TicketListService = {
  async all(options?: AllOptions): Promise<Array<Ticket>> {
    const paramsIsOwner: HttpParams = options?.lastUpdatedAt
      ? {
          is_open: "1",
          is_owner: "1",
          lastUpdatedAt: options?.lastUpdatedAt,
        }
      : { is_open: "1", is_owner: "1" };
    const paramsIsCreatedBy: HttpParams = options?.lastUpdatedAt
      ? {
          is_open: "1",
          is_created_by: "1",
          lastUpdatedAt: options?.lastUpdatedAt,
        }
      : { is_open: "1", is_created_by: "1" };
    const [ownerTickets, createdByTickets, currentTasks] = await Promise.all([
      ApiClient.get("/api/v2/user/current/tickets/", paramsIsOwner),
      ApiClient.get("/api/v2/user/current/tickets/", paramsIsCreatedBy),
      ApiClient.get("/api/v2/user/current/tasks/", { ticket_open: "1" }),
    ]);
    return [
      ...ownerTickets,
      ...createdByTickets,
      ...(currentTasks.tickets || []).map((t: Ticket) => {
        const tasks = (currentTasks.tasks || []).filter(
          (ta: Task) => ta.ticket === t.id
        );
        return {
          ...t,
          tasks,
        };
      }),
    ];
  },
  async load(id: number): Promise<Ticket> {
    const ticket = await ApiClient.get(`/api/v1/ticket/${id}/`);
    return transformTicket(ticket);
  },
  async add(entity: Ticket): Promise<Ticket> {
    const ticket = await ApiClient.post(`/api/v1/ticket/`, entity);
    if (ticket.id) ticketUpdatedByMe(+ticket.id);
    return transformTicket(ticket);
  },
  async update(entity: Ticket, columns: any): Promise<Ticket> {
    if (entity.id) ticketUpdatedByMe(+entity.id);
    const ticket = await ApiClient.put(`/api/v1/ticket/${entity.id}/`, {
      id: entity.id,
      ...columns,
    });
    return transformTicket(ticket);
  },
  async remove(entity: Ticket): Promise<void> {
    if (!entity.id) return;
    if (entity.id) ticketUpdatedByMe(+entity.id);
    await ApiClient.delete(`/api/v1/ticket/${entity.id}/`);
  },
  async addField(
    ticketId: number,
    field: { type: FieldType; value: number }
  ): Promise<Ticket> {
    if (ticketId) ticketUpdatedByMe(+ticketId);
    const ticket = await ApiClient.put(
      `/api/v1/ticket/${ticketId}/extra/`,
      field
    );
    return ticket;
  },
  async removeField(
    ticketId: number,
    field: { type: FieldType; value: number }
  ): Promise<Ticket> {
    if (ticketId) ticketUpdatedByMe(+ticketId);
    const ticket = await ApiClient.delete(
      `/api/v1/ticket/${ticketId}/extra/`,
      field
    );
    return ticket;
  },
  async loadTeamSharingSettings(
    ticketType: "issue" | "communication"
  ): Promise<TeamSharingSettings> {
    const teamSharingSettings = await ApiClient.get(
      `/api/v1/teams/team-sharing-settings/?ticket_type=${ticketType}`
    );
    return teamSharingSettings;
  },
};
