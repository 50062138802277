<script lang="ts" setup>
import { computed, watch, onBeforeUnmount } from "vue";
import { useIndicatorsStore } from "@/store/indicators";
import { useI18n } from "@/composables/useI18n";
import { IonLabel, IonRow } from "@ionic/vue";
import type { Indicator } from "@/types";

const props = defineProps<{
  entityId: number;
}>();

const indicatorsStore = useIndicatorsStore();
const indicator = computed(() =>
  indicatorsStore.collection.find((t: Indicator) => t.id === props.entityId)
);
let tm: NodeJS.Timeout;
watch(
  () => props.entityId,
  (id) => {
    if (tm) clearTimeout(tm);
    if (!id) return;
    tm = setTimeout(() => indicatorsStore.load(props.entityId, true), 750);
  },
  { immediate: true }
);
onBeforeUnmount(() => {
  if (tm) clearTimeout(tm);
});
const { t } = useI18n();
</script>

<template>
  <ion-row
    class="ion-justify-content-between ion-align-items-center indicator-row"
  >
    <ion-row class="ion-align-items-center">
      <font-icon
        class="dependency-icon"
        color="var(--ion-color-secondary)"
        name="bar_chart"
        material
      />
      <ion-label v-if="indicator" class="text-ellipsis indicator-title">
        {{ indicator.name || t("common.noTitle") }}
      </ion-label>
      <ion-label v-else>{{ t("common.loading") }}</ion-label>
    </ion-row>
  </ion-row>
</template>

<style scoped>
.indicator-title {
  max-width: calc(100vw - 150px);
}

.indicator-row {
  width: 100%;
}

.indicator-row .font-icon {
  margin-right: var(--ion-padding);
  margin-left: calc(var(--ion-padding) - 2px);
}
</style>
