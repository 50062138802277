<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { useBuildVersion } from "@/composables/useBuildVersion";
import { needStoreUpdate, reload } from "@/utils/live-updates";
import { toastController } from "@ionic/vue";
import { Browser } from "@capacitor/browser";
import { useI18n } from "@/composables/useI18n";

const props = defineProps<{
  version: string;
  position: string;
}>();

const { t } = useI18n();

const appVersionOrStaging = computed(() => {
  return props.version;
});

const buildVersion = useBuildVersion();

onMounted(async () => {
  const { needUpdate, store } = await needStoreUpdate(props.version);
  if (needUpdate && store) {
    const toast = await toastController.create({
      color: "warning",
      message: t("common.storeUpdate"),
      duration: 10000,
      buttons: [
        {
          text: "Update",
          handler: () => {
            Browser.open({ url: store });
          },
        },
      ],
    });
    toast.present();
  }
});

let clicks = 0;
let resetClicksTimer: any;
function handleOnClick() {
  if (resetClicksTimer) {
    clearTimeout(resetClicksTimer);
  }
  clicks++;
  if (clicks === 5) {
    clicks = 0;
    reload();
  }
  resetClicksTimer = setTimeout(() => {
    clicks = 0;
  }, 1000);
}
</script>
<template>
  <div class="version" @click="handleOnClick">
    <span v-if="version">Version {{ appVersionOrStaging }}</span>
    <span v-if="version && buildVersion">-{{ buildVersion }}</span>
  </div>
</template>
<style>
.version {
  position: v-bind(position);
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--ion-color-medium);
  padding: var(--ion-padding);
}

.keyboard .version {
  display: none;
}
</style>
