import { useFilesStore } from "./files";
import { createListStore } from "./list";
import { CommentsService } from "@/services/comments";
import { useFabriqStore } from "./fabriq";
import { Comment, FabriqFile } from "@/types";
import { toRaw } from "vue";
import { formatISO } from "date-fns";
import { fabriqDeepClone } from "@/services/fabriqDeepClone";

const hooks = {
  async add(entity: Comment) {
    const today = formatISO(new Date());
    const id = entity.id;
    if (!id) return;
    const filesStore = useFilesStore();

    const promises = [];
    const filePromises = filesStore.collection
      .filter((f: any) => f.comment === entity.uuid)
      .map((f: any) => {
        filesStore.update(
          f.uuid,
          { comment: entity.id, updated_at: today },
          true
        );
      });
    promises.push(Promise.all(filePromises));
    setTimeout(() => {
      filesStore.save();
    }, 10);
    await Promise.all(promises);
  },
  async load(entity: any) {
    const today = formatISO(new Date());
    const id = entity.id;
    if (!id) return;
    const filesStore = useFilesStore();
    const files: Array<FabriqFile> = [];

    if (entity.files && entity.files.length) {
      files.push(
        ...entity.files.map((fi: any) => ({
          ...fi,
          comment: entity.id,
          updated_at: today,
        }))
      );
    }
    filesStore.merge(files);
    return Promise.resolve({
      ...entity,
      files: undefined,
    });
  },
  async update(entity: Comment) {
    const today = formatISO(new Date());
    const id = entity.id;
    if (!id) return;
    const filesStore = useFilesStore();

    const promises = filesStore.collection
      .filter((f: any) => f.comment === entity.uuid)
      .map((f: any) =>
        filesStore.update(
          f.uuid,
          { comment: entity.id, updated_at: today },
          true
        )
      );

    setTimeout(() => {
      filesStore.save();
    }, 10);
    await Promise.all(promises);
  },
  async all(options: any, entities: Array<Comment>) {
    const today = formatISO(new Date());
    const filesStore = useFilesStore();
    const files: Array<FabriqFile> = [];
    const comments: Array<Comment> = [];

    entities.forEach((t: any) => {
      comments.push({
        ...t,
        files: undefined,
      });
      if (t.files && t.files.length) {
        files.push(
          ...t.files.map((fi: any) => ({
            ...fi,
            comment: t.id,
            updated_at: today,
          }))
        );
      }
    });
    filesStore.merge(files);
    return Promise.resolve(comments);
  },
};

export const useCommentsStore = createListStore(
  "comments",
  CommentsService,
  hooks,
  {
    draft: "",
    commentId: null,
    parentId: null,
    to: null,
  },
  {
    setDraft(draft: string) {
      this.draft = draft;
    },
    setEditedComment(id: number | null) {
      this.commentId = id;
    },
    setParentComment(id: number | null) {
      this.parentId = id;
    },
    setParentTo(to: string | null) {
      this.to = to;
    },
    clearDraft() {
      this.draft = "";
      this.to = null;
      this.commentId = null;
      this.parentId = null;
    },
    async react(comment: Comment, emoji: string) {
      if (!CommentsService.react) return;
      const fabriqStore = useFabriqStore();
      if (!fabriqStore.online) return Promise.resolve(comment);
      const reactions = [
        ...(comment.reactions || []),
        {
          comment: comment.id,
          reaction: emoji,
          user: fabriqStore.user?.id,
        },
      ].map((r: any) => fabriqDeepClone(toRaw(r)));
      await this.update(comment.uuid, { reactions }, true);
      const created = await CommentsService.react(comment, emoji);
      await this.update(
        comment.uuid,
        {
          reactions: created.reactions.map((r: any) =>
            fabriqDeepClone(toRaw(r))
          ),
        },
        true
      );
      return { ...comment, id: created.id };
    },
    async unreact(reaction: any, comment: Comment) {
      if (!CommentsService.unreact) return;
      const fabriqStore = useFabriqStore();
      if (!fabriqStore.online) return Promise.reject();
      const userId = fabriqStore.user?.id;
      const idx = (comment.reactions || []).findIndex(
        (r) =>
          r.reaction && r.reaction === reaction.reaction && r.user === userId
      );
      if (idx < 0) return;
      const reactions = [
        ...comment.reactions.slice(0, idx),
        ...comment.reactions.slice(idx + 1),
      ].map((r: any) => fabriqDeepClone(toRaw(r)));
      this.update(
        comment.uuid,
        {
          reactions,
        },
        true
      );
      await CommentsService.unreact(comment.reactions[idx]);
    },
    async togglePin(comment: Comment) {
      if (!CommentsService.togglePin) return;
      const fabriqStore = useFabriqStore();
      if (!fabriqStore.online) return Promise.resolve(comment);
      await this.update(
        comment.uuid,
        {
          pinned: !comment.pinned,
          pinned_by: comment.pinned ? null : fabriqStore.user?.id,
        },
        true
      );
      await CommentsService.togglePin(comment);
    },
  },
  true
);
