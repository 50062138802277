import type { FabriqEvent, EventPropertyFilter } from "@/types";

export function filterEvent(
  event: FabriqEvent,
  eventTypeId: string | null,
  filters: EventPropertyFilter[],
  isComplete: boolean | undefined
): boolean {
  if (eventTypeId !== null && event.eventTypeId !== eventTypeId) return false;
  if (isComplete !== undefined && event.isComplete !== isComplete) return false;

  return filters.every((filter) => {
    const eventProperty = event.properties.find(
      (property) => property.eventPropertyTypeId === filter.id
    );
    if (
      !eventProperty ||
      eventProperty.value === null ||
      eventProperty.value === undefined
    )
      return false;
    const value = eventProperty.value;
    switch (filter.operator) {
      case "==":
        return filter.values.includes(value);
      case "!=":
        return !filter.values.includes(value);
      case ">":
        return filter.values.every((filter) => value > filter);
      case ">=":
        return filter.values.every((filter) => value >= filter);
      case "<":
        return filter.values.every((filter) => value < filter);
      case "<=":
        return filter.values.every((filter) => value <= filter);
      default:
        return false;
    }
  });
}
