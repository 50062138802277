import { Ticket, TicketType } from "@/types";

export const createEmptyTicket = (
  date: string,
  userId?: number,
  ticket_template?: number
): Ticket => {
  return {
    title: "",
    id: null,
    due_date: date.slice(0, 10),
    teams: [],
    followers: [],
    processes: [],
    ticket_processes: [],
    processes_active: false,
    comments_count: 0,
    occurence: 0,
    is_open: true,
    ticket_template,
    labels: [],
    owner: userId || null,
    updated_at: date,
    created_at: date,
    created_by: userId,
    _type: TicketType.Issue,
  };
};
