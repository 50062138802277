import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useFabriqStore } from "@/store/fabriq";
import { toastController } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";

export const useConnectivityInfo = () => {
  const fabriqStore = useFabriqStore();
  const { online } = storeToRefs(fabriqStore);

  const poorConnexion = computed(() => {
    const nb = fabriqStore.lastRequestDurations.length;
    if (!nb) return 0;
    const duration = fabriqStore.lastRequestDurations.reduce(
      (acc: number, a: number) => acc + a,
      0
    );
    return duration / nb > fabriqStore.thresholdDuration;
  });

  const { t } = useI18n();
  const showMessage = async () => {
    if (!poorConnexion.value && fabriqStore.online) return;
    const toast = await toastController.create({
      message: t(`connexion.${!fabriqStore.online ? "offline" : "poor"}`),
      duration: 2000,
    });
    toast.present();
  };

  return {
    online,
    showMessage,
    poorConnexion,
  };
};
