<template>
  <!-- <ion-reorder> -->
  <ion-item-sliding :disabled="readonly || right === 'auditor'">
    <ion-item
      class="task"
      :class="{ [`task-${task.uuid}`]: true }"
      lines="none"
      @click="editTask"
    >
      <ion-button
        slot="start"
        fill="clear"
        class="done-icon"
        :class="{ nomargin: readonly }"
        @click.stop.prevent="updateDone"
      >
        <font-icon
          slot="icon-only"
          size="1"
          :color="
            task.done
              ? 'var(--ion-color-secondary)'
              : 'var(--ion-color-primary-shade)'
          "
          :name="task.done ? 'check' : 'radio_button_unchecked'"
          material
        />
      </ion-button>
      <div class="task-content">
        <ion-label
          :class="{
            done: task.done,
            empty: !displayText,
          }"
        >
          <html-content
            tag="div"
            class="task-description"
            :class="{ ellipsis: inTicketItem }"
            :content="displayText"
          />
        </ion-label>
        <div class="task-avatar">
          <!-- FIXME: Explain this -->
          <user-avatar v-if="users" :user="author" @click="editTask" />
          <user-avatar
            v-if="users"
            text-only
            :user="author"
            :name="t('unassigned')"
            @click="editTask"
          />
        </div>
      </div>
      <due-date
        slot="end"
        :date="task.due_date"
        :readonly="true"
        @click="editTask"
        style="margin-top: -4px"
        @date="update('due_date', $event)"
      />
      <!-- <ion-reorder slot="end" /> -->
    </ion-item>
    <ion-item-options side="end">
      <ion-item-option @click="$emit('delete', task)" color="danger">
        <font-icon name="delete" material color="#ffffff" />
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
  <!-- </ion-reorder> -->
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import {
  IonButton,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
} from "@ionic/vue";
import UserAvatar from "@/components/business/UserAvatar.vue";
import DueDate from "@/components/business/tickets/DueDate.vue";
import { useI18n } from "@/composables/useI18n";
import { Task, User } from "@/types";
import { useFabriqStore } from "@/store/fabriq";
import HtmlContent from "@/components/tools/HtmlContent.vue";

export default defineComponent({
  name: "TaskItem",
  components: {
    HtmlContent,
    IonItem,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    // IonReorder,
    IonButton,
    IonLabel,
    UserAvatar,
    DueDate,
  },
  emits: ["update", "edit", "delete"],
  props: {
    task: {
      type: Object as PropType<Task>,
      default: () => ({}),
    },
    users: {
      type: Array as PropType<Array<User>> | null,
      default: () => null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inTicketItem: {
      type: Boolean,
      default: false,
    },
    right: {
      type: String,
      default: "none",
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const hover = ref(false);
    const fabriqStore = useFabriqStore();
    const author = computed(() => {
      if (!props.users) return null;
      return props.users.find((u: User) => u.id === props.task.user);
    });

    const displayText = computed(() => props.task.description || "");

    const update = (field: string, value: any) => {
      emit("update", { uuid: props.task.uuid, field, value });
    };
    const editTask = () => {
      if (props.readonly) return;
      if (props.right === "auditor") return;
      if (props.right === "member" && props.task.user !== fabriqStore.user?.id)
        return;
      emit("edit", props.task);
    };

    const updateDone = () => {
      if (props.readonly && props.task.user !== fabriqStore.user?.id) return;
      if (props.right === "auditor") return;
      if (props.right === "member" && props.task.user !== fabriqStore.user?.id)
        return;
      update("done", !props.task.done);
    };
    return {
      t,
      editTask,
      author,
      hover,
      update,
      updateDone,
      displayText,
    };
  },
});
</script>

<style scoped>
.task {
  align-items: center;
  --border-color: var(--ion-border-color);
}

.task.item::part(native) {
  --_padding-y: var(--task-padding-y, 0);
  padding: var(--_padding-y) 2px var(--_padding-y)
    calc(var(--ion-padding) - 4px);
}

.task-user-link {
  background-color: rgba(123, 209, 209, 0.3);
  border-radius: var(--f-border-radius);
  padding: 0.1em 0.3em;
  font-size: 0.8em;
  white-space: nowrap;
  text-decoration: none;
}

.task-content {
  --content-padding: calc(var(--ion-padding) / 4);
  padding: var(--content-padding) 4px;
  padding-right: var(--content-padding);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.task-content ion-label {
  font-size: var(--font-size-m);
  font-weight: 400;
  margin-bottom: calc(var(--ion-padding) / 4);
  margin-top: calc(var(--ion-padding) / 2);
}

.task-content ion-label.nomargin {
  margin-bottom: 0;
}

.empty {
  opacity: 0.5;
}

.done {
  text-decoration: line-through;
  opacity: 0.2;
}

.done-icon {
  margin-right: calc(var(--ion-padding) / 2);
  background-color: transparent;
}

.done-icon.nomargin {
  margin-top: -2px;
}

.task-description-editor {
  --text-editor-margin: 0;
}

.task-avatar {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-bottom: 5px;
}

.task-content .task-avatar .user-avatar-input {
  transform: scale(0.54);
}

.task-content .task-avatar .user-avatar {
  transform: scale(0.84);
}

.task-description {
  max-width: 100%;
  white-space: normal;
}

.task-description.ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
