import { useFabriqStore } from "@/store/fabriq";
import { defineStore } from "pinia";
import { RecentSearch, OpenedEntitiesTypes, OpenedEntity } from "@/types";
import { UserService } from "@/services/user";
import storage from "@/utils/storage";
import { formatISO, format } from "date-fns";
import { sortOpenedEntities } from "@/utils/opened-entities";
import { UserConfig } from "@/types";

interface ConfigState {
  config: UserConfig | null;
  configChanged: boolean;
}

export const useConfigStore = defineStore("config", {
  state: (): ConfigState => {
    return {
      config: null,
      configChanged: false,
    };
  },
  actions: {
    setConfig(config: UserConfig) {
      this.config = config;
    },
    async addOpenedTicket(id: number, title: string) {
      const config = { ...(this.config || {}) };
      let openedTickets = [...(config.openedTickets || [])];
      const lastOpenedAt = format(new Date(), "yyyy-MM-dd");
      const timestamp = Math.round(Date.now());
      const idx = openedTickets.findIndex(
        (o: OpenedEntity) => o.ticketId === id
      );
      if (idx < 0) {
        openedTickets.push({
          ticketId: id,
          title,
          timestamp,
          lastOpenedAt,
          type: OpenedEntitiesTypes.Ticket,
          count: 1,
        });
      } else {
        const old = openedTickets[idx];
        const count = +old.count + 1;
        openedTickets = [
          ...openedTickets.slice(0, idx),
          {
            ticketId: id,
            title,
            timestamp,
            lastOpenedAt,
            type: OpenedEntitiesTypes.Ticket,
            count: count > 10 ? 10 : count,
          },
          ...openedTickets.slice(idx + 1),
        ];
      }
      this.saveOpenedTickets(openedTickets);
    },

    async addOpenedDocument(id: string, title: string) {
      const config = { ...(this.config || {}) };
      let openedDocuments = [...(config.openedDocuments || [])];
      const lastOpenedAt = format(new Date(), "yyyy-MM-dd");
      const timestamp = Math.round(Date.now());
      const idx = openedDocuments.findIndex(
        (o: OpenedEntity) => o.objectID === id
      );
      if (idx < 0) {
        openedDocuments.push({
          objectID: id,
          title,
          timestamp,
          lastOpenedAt,
          type: OpenedEntitiesTypes.Ticket,
          count: 1,
        });
      } else {
        const old = openedDocuments[idx];
        const count = +old.count + 1;
        openedDocuments = [
          ...openedDocuments.slice(0, idx),
          {
            objectID: id,
            title,
            timestamp,
            lastOpenedAt,
            type: OpenedEntitiesTypes.Ticket,
            count: count > 10 ? 10 : count,
          },
          ...openedDocuments.slice(idx + 1),
        ];
      }
      this.saveOpenedDocuments(openedDocuments);
    },

    async removeOpenedTicket(id: number) {
      const config = { ...(this.config || {}) };
      let openedTickets = [...(config.openedTickets || [])];
      const idx = openedTickets.findIndex(
        (o: OpenedEntity) => o.ticketId === id
      );
      if (idx < 0) return;
      openedTickets = [
        ...openedTickets.slice(0, idx),
        ...openedTickets.slice(idx + 1),
      ];
      this.saveOpenedTickets(openedTickets);
    },

    async saveOpenedDocuments(openedDocuments: any) {
      const config = { ...(this.config || {}) };
      openedDocuments.sort(sortOpenedEntities);
      openedDocuments = openedDocuments.slice(0, 10);
      this.configChanged = true;
      this.config = { ...config, openedDocuments };
    },

    async saveOpenedTickets(openedTickets: any) {
      const config = { ...(this.config || {}) };
      openedTickets.sort(sortOpenedEntities);
      openedTickets = openedTickets.slice(0, 10);
      this.configChanged = true;
      this.config = { ...config, openedTickets };
    },
    async saveRecentSearch(query: string) {
      const str = query.trim().toLocaleLowerCase();
      const config = { ...(this.config || {}) };
      let recentSearches = [...(config.recentSearches || [])];
      const idx = recentSearches.findIndex(
        (o: RecentSearch) => o.query === query
      );
      if (idx < 0) {
        recentSearches.push({
          query: str,
          createdAt: formatISO(new Date()),
          count: 1,
        });
      } else {
        const old = recentSearches[idx];
        const count = +old.count + 1;
        recentSearches = [
          ...recentSearches.slice(0, idx),
          {
            query: str,
            createdAt: old.createdAt,
            count: count > 10 ? 10 : count,
          },
          ...recentSearches.slice(idx + 1),
        ];
      }
      recentSearches.sort((a: any, b: any) => {
        if (a.count === b.count) return b.createdAt.localeCompare(a.createdAt);
        return b.count - a.count;
      });
      recentSearches = recentSearches.slice(0, 10);
      this.configChanged = true;
      this.config = { ...config, recentSearches };
    },
    setHasNotifications(hasPushNotifications: boolean) {
      const config = { ...(this.config || {}) };
      if (config.hasPushNotifications === hasPushNotifications) return;
      this.configChanged = true;
      this.config = { ...config, hasPushNotifications };
    },
    async addFavoriteTemplate(id: number) {
      const config = { ...(this.config || {}) };
      const favoriteTemplates = [...(config.favoriteTemplates || [])];
      if (favoriteTemplates.includes(id)) return;
      favoriteTemplates.push(id);
      this.configChanged = true;
      this.config = { ...config, favoriteTemplates };
    },
    async removeFavoriteTemplate(id: number) {
      const config = { ...(this.config || {}) };
      const oldFavoritesTemplates = [...(config.favoriteTemplates || [])];
      if (!oldFavoritesTemplates.includes(id)) return;
      const favoriteTemplates = oldFavoritesTemplates.filter(
        (tid) => tid !== id
      );
      this.configChanged = true;
      this.config = { ...config, favoriteTemplates };
    },
    async addFavoriteEventType(id: string) {
      const config = { ...(this.config || {}) };
      const favoriteEventTypes = [...(config.favoriteEventTypes || [])];
      if (favoriteEventTypes.includes(id)) return;
      favoriteEventTypes.push(id);
      this.configChanged = true;
      this.config = { ...config, favoriteEventTypes };
    },
    async removeFavoriteEventType(id: string) {
      const config = { ...(this.config || {}) };
      const oldFavoritesEventTypes = [...(config.favoriteEventTypes || [])];
      if (!oldFavoritesEventTypes.includes(id)) return;
      const favoriteEventTypes = oldFavoritesEventTypes.filter(
        (tid) => tid !== id
      );
      this.configChanged = true;
      this.config = { ...config, favoriteEventTypes };
    },
    async addFavoriteRoutine(id: number) {
      const config = { ...(this.config || {}) };
      const favoriteRoutines = [...(config.favoriteRoutines || [])];
      if (favoriteRoutines.includes(id)) return;
      favoriteRoutines.push(id);
      this.configChanged = true;
      this.config = { ...config, favoriteRoutines };
    },
    async removeFavoriteRoutine(id: number) {
      const config = { ...(this.config || {}) };
      const oldFavoritesRoutines = [...(config.favoriteRoutines || [])];
      if (!oldFavoritesRoutines.includes(id)) return;
      const favoriteRoutines = oldFavoritesRoutines.filter((tid) => tid !== id);
      this.configChanged = true;
      this.config = { ...config, favoriteRoutines };
    },
    async addFavoriteKpi(id: number) {
      const config = { ...(this.config || {}) };
      const favoriteKpis = [...(config.favoriteKpis || [])];
      if (favoriteKpis.includes(id)) return;
      favoriteKpis.push(id);
      this.configChanged = true;
      this.config = { ...config, favoriteKpis };
    },
    async removeFavoriteKpi(id: number) {
      const config = { ...(this.config || {}) };
      const oldFavoritesKpis = [...(config.favoriteKpis || [])];
      if (!oldFavoritesKpis.includes(id)) return;
      const favoriteKpis = oldFavoritesKpis.filter((tid) => tid !== id);
      this.configChanged = true;
      this.config = { ...config, favoriteKpis };
    },
    async saveUserConfig() {
      const fabriqStore = useFabriqStore();
      if (!fabriqStore.user) return;
      if (!this.configChanged) return;
      if (!this.config) return;
      try {
        if (fabriqStore.online) {
          const user = await UserService.saveConfig(this.config);
          storage.writeToStorage("user", user);
          this.configChanged = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
});
