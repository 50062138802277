<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar edit-modal-toolbar">
      <ion-title>{{ label }}</ion-title>
      <ion-buttons slot="end">
        <ion-button
          v-if="modified"
          fill="clear"
          class="cancel-btn"
          @click="$emit('cancel')"
        >
          {{ t("common.done") }}
        </ion-button>
        <ion-button
          v-else
          fill="clear"
          class="cancel-btn"
          @click="$emit('cancel')"
        >
          {{ t("common.cancel") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <div
      class="modal-header"
      style="background-color: var(--ion-color-primary-contrast)"
    >
      <div
        class="ion-padding-horizontal ion-margin-horizontal"
        style="background-color: var(--ion-color-primary-contrast)"
      >
        <ion-input
          class="filter-input"
          :placeholder="placeholder"
          v-model="valueFilter"
        />
        <div class="selected-values ion-margin-top">
          <colored-chip
            clearable
            v-for="(v, idx) of valueArray"
            :key="idx"
            :icon="v.icon"
            :label="v.name"
            :emoji="v.config?.emoji"
            :color="v.config?.color"
            :coloredIconOnly="coloredIconOnly"
            :material="true"
            @remove="removeValue(v)"
          />
        </div>
      </div>
      <div class="list-title ion-margin-top">
        {{ choices }}
      </div>
    </div>
  </ion-header>

  <div v-if="!isTeamSelected" class="routines-empty">
    <div class="icon">❕</div>
    <div>{{ t("common.noTeam") }}</div>
  </div>
  <ion-content :fullscreen="true" :scroll-y="false" v-if="isTeamSelected">
    <ion-list ref="list" class="scroller ion-padding-horizontal" lines="none">
      <recycle-scroller
        class="scroller"
        :items="filteredValues"
        :item-size="44"
      >
        <template #default="{ item }">
          <ion-item @click="addValue(item)">
            <colored-chip
              class="clickable"
              v-if="!item.header"
              :label="item.name"
              :emoji="item.config?.emoji"
              :icon="item.icon"
              :color="item.config?.color"
              :coloredIconOnly="coloredIconOnly"
              :material="true"
            />
            <div v-else class="field-header">
              {{ item.header }}
            </div>
          </ion-item>
        </template>
      </recycle-scroller>
    </ion-list>
  </ion-content>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useAutofocus } from "@/composables/useAutofocus";
import ColoredChip from "../ui/ColoredChip.vue";
import { Category, Field, Label, Status, Team } from "@/types";
import { TeamSharingSettings } from "@/types/teamSharing";

interface Props {
  fieldKey: string;
  selectedValues: Team[];
  possibleValues: (Field | Status | Category | Label | Team)[];
  multiple?: boolean;
  coloredIconOnly?: boolean;
  label?: string;
  placeholder?: string;
  choices?: string;
  isAdmin?: boolean;
  teams: Team[];
  allTeams: Team[];
  teamSharingSettings: TeamSharingSettings;
  isTeamSelected: boolean;
}

const emit = defineEmits(["done", "cancel"]);
const props = withDefaults(defineProps<Props>(), {
  multiple: false,
  coloredIconOnly: false,
  label: "",
  placeholder: "",
  choices: "",
  isAdmin: false,
  isTeamSelected: false,
});

const { t } = useI18n();
const valueFilter = ref(null);
const modified = ref(!props.multiple);

onMounted(() => useAutofocus(".filter-input input"));

const getTicketTeamsFieldIds = (key: any): any => {
  if (!props.teams?.length) return [];
  switch (key) {
    case "owner":
      return null;
    case "teams":
      return props.allTeams.map((t) => t.id);
    case "categories": {
      const ids = props.teams.reduce((acc: any, a: any) => {
        acc.push(...a.categories);
        return acc;
      }, []);
      return ids;
    }
    case "status": {
      const ids = props.teams.reduce((acc: any, a: any) => {
        acc.push(...a.ticket_statuses);
        return acc;
      }, []);
      return ids;
    }
    case "labels": {
      const ids = props.teams.reduce((acc: any, a: any) => {
        acc.push(...a.labels);
        return acc;
      }, []);
      return ids;
    }
    default: {
      const ids = props.teams?.length
        ? props.teams.reduce((acc: any, a: any) => {
            acc.push(...a.extra_fields);
            return acc;
          }, [])
        : null;
      return ids;
    }
  }
};

const ticketTeamsFieldIds = computed(() =>
  getTicketTeamsFieldIds(props.fieldKey)
);

const getFilteredValues = () => {
  const filter = String(valueFilter.value || "").toLocaleLowerCase();
  const values: Array<any> = [];
  if (!props.possibleValues) return [];
  props.possibleValues.forEach((v: any) => {
    if (
      props.selectedValues &&
      props.selectedValues.findIndex((va: any) => va.id === v.id) >= 0
    )
      return;
    const isIn =
      props.fieldKey === "teams"
        ? true
        : ticketTeamsFieldIds.value.includes(v.id);
    if (!isIn) return;
    const name = String(v.name).toLocaleLowerCase();
    if (!name.includes(filter)) return;
    const icon =
      props.fieldKey === "priority" ? "flag" : v.icon || v.config?.icon;
    const coloredIconOnly = props.fieldKey === "priority";
    values.push({ ...v, icon, coloredIconOnly });
  });
  values.sort((a, b) => a.name?.localeCompare(b.name));
  return values;
};

const filteredTicketTeamsValues = computed(() => getFilteredValues());

const filteredValues = computed(() => {
  const values = [];
  values.push(...filteredTicketTeamsValues.value);
  if (props.fieldKey === "teams") {
    const siteTeams = props.teamSharingSettings.site || [];
    const possibleValuesIds = props.possibleValues.map((pv) => pv.id);
    const filteredSiteTeams = siteTeams
      .filter((team) => !possibleValuesIds.includes(team.id))
      .filter(
        (team) =>
          !props.selectedValues.find(
            (selectedTeam) => selectedTeam.id === team.id
          )
      );
    if (filteredSiteTeams.length > 0) {
      values.push({
        id: "siteTeams",
        header: t("ticketContent.shareToOtherTeamsOnSite"),
      });
      values.push(...filteredSiteTeams);
    }
    const organizationTeams = props.teamSharingSettings.organization || [];
    const filteredOrganizationTeams = organizationTeams
      .filter((team) => !possibleValuesIds.includes(team.id))
      .filter(
        (team) =>
          !props.selectedValues.find(
            (selectedTeam) => selectedTeam.id === team.id
          )
      );
    if (filteredOrganizationTeams.length > 0) {
      values.push({
        id: "organizationTeams",
        header: t("ticketContent.shareToOtherTeamsOnOrganization"),
      });
      values.push(...filteredOrganizationTeams);
    }
  }
  return values;
});

const valueArray = computed(() => {
  if (!props.selectedValues) return [];
  return props.selectedValues.map((v: any) => {
    const icon =
      props.fieldKey === "priority" ? "flag" : v.icon || v.config?.icon;
    const coloredIconOnly = props.fieldKey === "priority";
    return { ...v, icon, coloredIconOnly };
  });
});

const removeValue = (v: any) => {
  if (!props.multiple) return emit("done", []);
  modified.value = true;
  const values = props.selectedValues.filter((i: any) => i.id !== v.id);
  emit(
    "done",
    values.map((v: any) => v.id)
  );
};

const addValue = (v: any) => {
  if (v.header) return;
  if (!props.multiple) return emit("done", [v.id]);
  modified.value = true;
  const values = props.selectedValues.map((i: any) => i.id);
  emit("done", [...values, v.id]);
};
</script>

<style scoped>
.list-title {
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary-shade);
}

.user-item {
  margin-bottom: var(--ion-padding);
}

.selected-values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--ion-padding) / 2);
  max-height: 100px;
  overflow: auto;
}

.field-header {
  width: 100%;
  padding: calc(var(--ion-padding) / 2);
  margin: calc(var(--ion-padding) / 2 * -1);
  font-size: var(--font-size-s);
  border-bottom: 1px solid var(--ion-border-color);
}
</style>
