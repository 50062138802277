const BRIGHTNESS_THRESHOLD = 140;
const BRIGHTNESS_COEFFICIENT = 35;
const colorIsHexRegExp = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
const isCssVar = /^var\((--[^)]*)\)$/;

export type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBObject = { r: number; g: number; b: number };
export type HEX = `#${string}`;
type CSSVar = `--${string}`;
type VarColor = `var(${CSSVar})`;

const lightColors = [
  // LEGACY COLORS
  { r: 140, g: 185, b: 254 },
  { r: 234, g: 234, b: 234 },
  { r: 200, g: 248, b: 183 },
  { r: 197, g: 214, b: 255 },
  { r: 254, g: 207, b: 243 },
  { r: 198, g: 236, b: 252 },
  { r: 197, g: 214, b: 255 },
  { r: 198, g: 236, b: 252 },
  { r: 183, g: 244, b: 227 },
  { r: 200, g: 248, b: 184 },
  { r: 254, g: 230, b: 168 },
  { r: 253, g: 219, b: 204 },
  { r: 255, g: 210, b: 223 },
  { r: 254, g: 207, b: 243 },
  { r: 232, g: 218, b: 254 },
  { r: 140, g: 185, b: 254 },
  { r: 103, g: 199, b: 239 },
  { r: 99, g: 216, b: 182 },
  { r: 132, g: 221, b: 117 },
  { r: 254, g: 206, b: 92 },
  { r: 253, g: 151, b: 110 },
  { r: 252, g: 137, b: 169 },
  { r: 245, g: 134, b: 218 },
  { r: 193, g: 156, b: 255 },
  { r: 255, g: 172, b: 252 },
  { r: 160, g: 237, b: 255 },
  { r: 235, g: 248, b: 117 },
  { r: 255, g: 139, b: 139 },
];

export const getColorFromId = (id: number, darken = false): RGB => {
  const nb = lightColors.length;
  const color = lightColors[id % nb];
  if (darken) {
    const factor = 4 / 5;
    color.r = Math.round(factor * color.r);
    color.g = Math.round(factor * color.g);
    color.b = Math.round(factor * color.b);
  }
  return `rgb(${color.r}, ${color.g}, ${color.b})`;
};

export const colorHex = (col: VarColor | HEX): string => {
  let color = col.trim();
  if (isCssVar.test(color)) color = color.replace(isCssVar, "$1");
  if (colorIsHexRegExp.test(color)) return color;
  // jsdom
  const varColor = getComputedStyle(document.documentElement)
    .getPropertyValue(color)
    .trim();
  return varColor || color;
};

export const getBackgroundColor = (col: HEX): HEX => {
  const color = col || "#000000";
  const brightness = colorBrightness(color);
  return brightness > BRIGHTNESS_THRESHOLD
    ? lightenColor(color, -BRIGHTNESS_COEFFICIENT)
    : lightenColor(color, BRIGHTNESS_COEFFICIENT);
};

const lightenColor = (col: HEX | RGB, percent: number): HEX => {
  let color = col;
  if (color.slice(0, 3) === "rgb") {
    color = rgbToHex(<RGB>color);
  }
  const num = parseInt(color.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt;

  const res = (
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1);

  return `#${res}`;
};

const getColorObject = (col: string): RGBObject | null => {
  const color = String(col);
  if (color.slice(0, 1) === "#") {
    return hexToRgb(color);
  } else if (color.slice(0, 3) === "rgb") {
    return rgbStringToObject(color);
  }
  return null;
};

export const colorBrightness = (col: string): number => {
  const color = getColorObject(col);
  if (color === null) return 0;
  const brightness = Math.round(
    (color.r * 299 + color.g * 587 + color.b * 114) / 1000
  );
  return brightness;
};

const hexToRgb = (hex: string): RGBObject | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgbToHex = (rgb: RGB): HEX => {
  const arr = rgb.replace("rgb(", "").replace(")", "").split(",");

  const res = (
    componentToHex(parseInt(arr[0])) +
    componentToHex(parseInt(arr[1])) +
    componentToHex(parseInt(arr[2]))
  ).toUpperCase();

  return `#${res}`;
};

const componentToHex = (c: number): string => {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

const rgbStringToObject = (rgb: string): RGBObject => {
  const array = rgb
    .substring(4, rgb.length - 1)
    .replace(/ /g, "")
    .split(",");
  return {
    r: parseInt(array[0]),
    g: parseInt(array[1]),
    b: parseInt(array[2]),
  };
};
