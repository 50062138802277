<script lang="ts" setup>
import { computed, nextTick, ref, watch } from "vue";
import {
  useIonRouter,
  IonButtons,
  IonButton,
  onIonViewDidEnter,
  onIonViewDidLeave,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useFabriqStore } from "@/store/fabriq";
import { storeToRefs } from "pinia";
import { ticketPageAnimation } from "@/utils/animations";
import ModalPage from "@/components/ui/ModalPage.vue";
import { useNotificationsStore } from "@/store/notifications";
import HorizontalScroller from "@/components/ui/HorizontalScroller.vue";
import NotificationItem from "@/components/business/notifications/NotificationItem.vue";
import FiltersBar from "@/components/ui/FiltersBar.vue";
import LoadingBlock from "@/components/ui/LoadingBlock.vue";
import loader from "@/utils/loader";
import {
  Notification,
  ActivityNotifications,
  AdminNotifications,
  MentionsNotifications,
  AssignmentsNotifications,
} from "@/types";
import {
  isTicketCommentNotification,
  isTicketNotification,
} from "@/utils/notifications";
import { useTicketsStore } from "@/store/tickets";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { useNoOffline } from "@/composables/useNoOffline";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { useClientStore } from "@/store/client";

const fabriqStore = useFabriqStore();
const notificationsStore = useNotificationsStore();
const ticketsStore = useTicketsStore();
const clientStore = useClientStore();
const router = useIonRouter();
const { loading } = storeToRefs(notificationsStore);
const { t } = useI18n();
const { noOffline } = useNoOffline();

const ready = ref(!!notificationsStore.collection.length);
const filters = ["unread", "mine", "activity", "admin"];
const filter = ref("unread");

const tunnel = (action: string) => {
  tracker.tunnel(
    "notifications",
    "notifications page",
    RecordCategory.Notifications,
    ActionType.Process,
    action
  );
};

const readNotification = async (notification: any) => {
  if (!notification.is_read) {
    tunnel("read");
    mixpanelTracker.track("read | notification | notification screen");
    await notificationsStore.update(notification.uuid, { is_read: true });
  }
};

const gotoTicketFromPush = async () => {
  if (!ticketsStore.fromPush) return;
  const push = ticketsStore.fromPush;
  tracker.next("push", ActionType.Process, "goto ticket");
  if (isTicketCommentNotification(push.notification_type)) {
    ticketsStore.setOpenComments(true);
  }
  const id = +push.model_id;
  ticketsStore.setFromPush(false);
  let exists = ticketsStore.collection.find((t: any) => t.id === id);
  if (!exists) {
    tracker.next("push", ActionType.Request, "fetch ticket");
    await loader.show();
    try {
      exists = await ticketsStore.load(id, true);
    } catch (e) {}
    if (!exists) {
      loader.hide();
      return tracker.end("push", { error: "ticket_not_found" });
    }
  }
  tunnel("ticket");
  tracker.next("push", ActionType.Process, "go to ticket");
  router.navigate(
    `/tickets/${exists.uuid}`,
    "forward",
    "push",
    ticketPageAnimation
  );
};

const gotoTicket = async (notification: any) => {
  if (!isTicketNotification(notification.type))
    return readNotification(notification);
  if (isTicketCommentNotification(notification.type)) {
    ticketsStore.setOpenComments(true);
  }
  readNotification(notification);
  tracker.begin(
    "notification",
    "Notifications to ticket",
    RecordCategory.General,
    ActionType.Process
  );
  const id = +notification.model_id;
  let exists = ticketsStore.collection.find((t: any) => t.id === id);
  if (!exists) {
    tracker.next("notification", ActionType.Request, "fetch ticket");
    await loader.show();
    exists = await ticketsStore.load(id, true);
    loader.hide();
    if (!exists)
      return tracker.end("notification", { error: "ticket_not_found" });
  }
  loader.hide();
  tunnel("ticket");
  tracker.next("notification", ActionType.Process, "go to ticket");

  router.navigate(
    `/tickets/${exists.uuid}`,
    "forward",
    "push",
    ticketPageAnimation
  );
};

const getNotifications = (nFilter: string) => {
  const filtered = notificationsStore.collection.filter((n: any) => {
    if (nFilter === "unread") return !n.is_read;
    if (nFilter === "activity")
      return Object.values(ActivityNotifications).includes(n.type);
    if (nFilter === "admin")
      return Object.values(AdminNotifications).includes(n.type);
    if (nFilter === "mine")
      return (
        Object.values(MentionsNotifications).includes(n.type) ||
        Object.values(AssignmentsNotifications).includes(n.type)
      );
    return false;
  });
  filtered.sort((a: Notification, b: Notification) =>
    b.event_datetime.localeCompare(a.event_datetime)
  );
  return filtered;
};

let pas = 15;
const max = ref(pas);
const notificationsScroll = (ev: any) => {
  if (max.value > allNotifications.value.length) return;
  if (
    ev.target.scrollTop + ev.target.offsetHeight >
    ev.target.scrollHeight * 0.9
  ) {
    max.value += pas;
  }
};

const allNotifications = computed(() => getNotifications(filter.value));

const notifications = computed(() =>
  allNotifications.value.slice(0, max.value)
);

const badges = computed(() => {
  const badges = filters.map((f: string) => {
    return {
      filter: f,
      nb: getNotifications(f).filter((f: any) => !f.is_read).length,
    };
  });
  return badges;
});

const readAll = async () => {
  if (loading.value) return;
  if (!fabriqStore.online) return noOffline();
  nextTick(async () => {
    const notificationsToRead = allNotifications.value.filter(
      (n: Notification) => !n.is_read
    );
    await notificationsStore.save();
    await notificationsStore.readAll(notificationsToRead);
    tunnel("read all");
    mixpanelTracker.track("read all | notification | notification screen");
  });
};

const toggleIsRead = async (notif: Notification) => {
  await notificationsStore.update(notif.uuid, { is_read: !notif.is_read });
  tunnel("toggle");
  if (notif.is_read)
    mixpanelTracker.track("mark as read | notification | notifcation screen");
};

const closeNotifications = () => {
  tracker.begin(
    "closeNotifications",
    "Notifications closing",
    RecordCategory.Notifications,
    ActionType.Ui,
    "click"
  );
  ticketsStore.setFromPush(false);
  mixpanelTracker.track("close | notification | notification screen");
  router.back();
};

const users = computed(() => clientStore.users);

onIonViewDidLeave(() => {
  if (notificationsStore.collection.some((n: Notification) => !n.is_read)) {
    notificationsStore.setCount(1);
  } else {
    notificationsStore.setCount(0);
  }
  notificationsStore.save().then(() => {
    setTimeout(() => notificationsStore.getCount(), 2000);
  });
  tracker.end("closeNotifications");
});

tracker.next("openNotifications", ActionType.Process, "component created");

onIonViewDidEnter(() => {
  fabriqStore.setPage("notifications");
  tracker.next("notification", ActionType.Request, "async notifications load");
  notificationsStore.all({ all: true });
  tracker.end("notification");
  ready.value = true;
  tracker.next("notification", ActionType.Ui, "notifications page");
  gotoTicketFromPush();
  setTimeout(() => (ready.value = true), 2000);
  tracker.end("openNotifications");
});

const mixevts = (val: string) => {
  switch (val) {
    case "unread":
      return "unread tab";
    case "mine":
      return "form me";
    case "activity":
      return "activity";
    case "admin":
      return "admin";
  }
};
watch(filter, (val) => {
  tunnel(`tab ${val}`);
  mixpanelTracker.track(`open | ${mixevts(val)} | notification screen`);
});
</script>

<template>
  <modal-page>
    <template v-slot:toolbar>
      <div class="modal-title-bar">
        <font-icon
          slot="start"
          name="settings"
          class="notification-settings-button clickable"
          material
          style="opacity: 0"
          size="1"
          color="var(--ion-color-primary-shade)"
        />
        <div class="modal-title">
          {{ t("notification", 2) }}
        </div>
        <ion-buttons slot="end">
          <ion-button
            context="close"
            fill="clear"
            class="modal-button"
            @click="closeNotifications"
          >
            {{ t("close") }}
          </ion-button>
        </ion-buttons>
      </div>
      <horizontal-scroller class="filters-scroller">
        <filters-bar :filters="filters" :badges="badges" v-model="filter" />
      </horizontal-scroller>
    </template>
    <template v-slot:content v-if="ready">
      <loading-block v-if="loading && !notifications.length" />
      <div
        class="ion-content-scroll-host scroll"
        @scroll="notificationsScroll"
        v-if="notifications.length"
      >
        <notification-item
          v-for="notification of notifications"
          :key="notification.id"
          :notification="notification"
          :users="users"
          @toggle="toggleIsRead"
          @select="gotoTicket"
        />
        <div class="margin-for-floating"></div>
      </div>
      <div v-else-if="filter === 'unread' && !loading" class="routines-empty">
        <div class="icon">🤸</div>
        <div>{{ t("notifications.inboxZero") }}</div>
      </div>
      <div v-else-if="!loading" class="routines-empty">
        <div class="icon">😎</div>
        <div>{{ t("notifications.none") }}</div>
      </div>
    </template>
    <template v-slot:footer>
      <div
        class="notifications-read-all-btn clickable"
        :class="{ 'look-disabled': loading }"
        @click="readAll"
      >
        <font-icon
          name="done_all"
          color="var(--ion-color-secondary)"
          material
          size="1.5"
        />
        <div>{{ t("notifications.markAllAsRead") }}</div>
      </div>
    </template>
  </modal-page>
</template>

<style scoped>
.notification-settings-button {
  margin-left: var(--ion-padding);
  margin-right: 35px;
}

.notifications-read-all-btn {
  display: flex;
  flex-direction: row;
  gap: var(--ion-padding);
  align-items: center;
  justify-content: center;
  color: var(--ion-color-secondary);
}

.filters-scroller {
  margin-top: calc(var(--ion-padding) / 2);
  margin-bottom: calc(var(--ion-padding) / 2);
}
</style>
