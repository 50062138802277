<template>
  <ion-content class="ion-padding-top normal">
    <div class="send-task-btn clickable ion-margin-top" @click="updateTask">
      {{ t("common.done") }}
    </div>
    <ion-list lines="none">
      <ion-item>
        <text-input
          class="task-title"
          style="width: 100%"
          :placeholder="t('tickets.placeholders.task')"
          :nohtml="true"
          v-model="textToDisplay"
          autofocus
        >
          {{ textToDisplay }}
        </text-input>
      </ion-item>
      <ion-item>
        <user-avatar-input
          slot="start"
          v-if="users"
          :user="author"
          :name="authorFullName"
          :onlyMe="!teams.length"
          :teams="teams"
          @update="user = $event ? $event.id : null"
        />
        <ion-label class="ion-margin-start">
          <due-date :date="dueDate" icon iconSize="1.25" @date="setDueDate" />
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { IonContent, IonItem, IonLabel, IonList } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import UserAvatarInput from "@/components/business/UserAvatarInput.vue";
import DueDate from "@/components/business/tickets/DueDate.vue";
import TextInput from "@/components/ui/TextInput.vue";
import { Task, Team, User } from "@/types";
import { removeHtml } from "@/utils/comment-parser";

export default defineComponent({
  name: "EditTaskModal",
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    UserAvatarInput,
    DueDate,
    TextInput,
  },
  props: {
    task: {
      type: Object as PropType<Task>,
      default: () => ({}),
    },
    users: {
      type: Array as PropType<Array<User>>,
      default: () => [],
    },
    teams: {
      type: Array as PropType<Array<Team>>,
      default: () => [],
    },
  },
  emits: ["done", "cancel"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const user = ref(props.task ? props.task.user : null);
    const dueDate = ref(props.task ? props.task.due_date : null);
    const author = computed(() => {
      if (!props.users || !user.value) return null;
      return props.users.find((u: User) => u.id === user.value);
    });
    const textToDisplay = ref(props.task?.description || "");

    const authorFullName = computed(() => {
      return author.value?.profile?.full_name || t("common.assignee");
    });

    const updateTask = () => {
      const changes = {
        ...props.task,
        user: user.value,
        due_date: dueDate.value,
      };
      changes.description = removeHtml(textToDisplay.value || "");
      emit("done", changes);
    };

    const focusTaskDescription = () => {
      const content: HTMLElement | null = document.querySelector(
        ".task-title .text-editor-content"
      );
      if (!content) return;
      setTimeout(() => content.focus(), 600);
    };

    const setDueDate = (ev: any) => {
      dueDate.value = ev?.start || null;
    };

    return {
      author,
      authorFullName,
      dueDate,
      user,
      updateTask,
      setDueDate,
      t,
      focusTaskDescription,
      textToDisplay,
    };
  },
});
</script>

<style scoped>
.list-item {
  font-size: var(--font-size-m);
}

.task-title {
  --text-editor-font-size: var(--font-size-l);
}

.due-date {
  font-size: var(--font-size-m);
}

.send-task-btn {
  padding-right: var(--ion-padding);
  text-align: right;
  color: var(--ion-color-secondary);
  font-size: var(--font-size-m);
}
</style>
<style>
.task-title .text-editor .text-editor-placeholder {
  padding: 0;
}
</style>
