import { getDocument, getFile } from "@/services/knowledgeDocuments";
import { defineStore } from "pinia";

export const useKnowledgeDocumentsStore = defineStore(
  "knowledgeDocuments",
  () => {
    const fileMap = new Map<string, string>();
    const findDocument = async (id: string) => {
      return getDocument(id);
    };

    const loadFile = async (id: string) => {
      const fileUrl = fileMap.get(id);
      if (fileUrl) {
        return fileUrl;
      }

      const fetchedFileUrl = await getFile(id);
      if (fetchedFileUrl) {
        fileMap.set(id, fetchedFileUrl);
      }
      return fetchedFileUrl;
    };

    return {
      findDocument,
      loadFile,
    };
  }
);
