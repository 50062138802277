<script lang="ts" setup>
import { useConnectivityInfo } from "@/composables/useConnectivityInfo";
import { computed, onBeforeUnmount, ref, watch } from "vue";

const { poorConnexion, online, showMessage } = useConnectivityInfo();

const fade = ref(online.value);

const color = computed(() => {
  if (online.value && !poorConnexion.value) return "var(--ion-color-success)";
  if (online.value && poorConnexion.value) return "var(--ion-color-warning)";
  return "var(--ion-color-danger)";
});

let tm: NodeJS.Timeout;
watch(online, (v) => {
  if (!v) return (fade.value = false);
  if (tm) clearTimeout(tm);
  tm = setTimeout(() => (fade.value = true), 1000);
});

onBeforeUnmount(() => {
  if (tm) clearTimeout(tm);
});
</script>
<template>
  <div
    class="connectivity-icon"
    :class="{ fade: fade && !poorConnexion }"
    :style="{ color }"
  >
    <font-icon
      v-if="online && !poorConnexion"
      :color="color"
      size="1.5"
      name="wifi"
      material
      @click="showMessage"
    />
    <font-icon
      v-else-if="online && poorConnexion"
      :color="color"
      size="1.5"
      name="wifi"
      material
      @click="showMessage"
    />
    <font-icon
      v-else
      :color="color"
      size="1.5"
      name="wifi_off"
      material
      @click="showMessage"
    />
  </div>
</template>

<style scoped>
.connectivity-icon {
  display: inline-block;
  background: no-repeat center center transparent;
  background-size: contain;
  opacity: 1;
  transform: scale(1);
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  position: relative;
}
.connectivity-icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: currentColor;
  opacity: 0.1;
}

.fade {
  opacity: 0;
  transform: scale(0);
  transition: opacity 2000ms 2s, transform 2000ms 2s;
  pointer-events: none;
}
.fade::after {
  opacity: 0;
}
</style>
