import { FieldType, Ticket, TicketColumn, TicketTemplate } from "@/types";

export const extraFieldTypesWithPresetValues: FieldType[] = [
  FieldType.Equipment,
  FieldType.Zone,
  FieldType.Product,
  FieldType.Priority,
  FieldType.Custom1,
  FieldType.Custom2,
];

export const openFieldsWithPresetValues: TicketColumn[] = [
  TicketColumn.Title,
  TicketColumn.Description,
  TicketColumn.Cause,
];

function translate(i18n: Record<string, string>, userLocale: string) {
  const userLocaleTranslation = i18n[userLocale];
  if (userLocaleTranslation !== undefined) return userLocaleTranslation;
  const enTranslation = i18n["en"];
  if (enTranslation !== undefined) return enTranslation;
  const lowerInAlphabeticalOrderTranslation =
    i18n[Object.keys(i18n).sort((a, b) => (a > b ? 1 : -1))[0]];
  return lowerInAlphabeticalOrderTranslation;
}

export const addTicketTemplatePresetValues = (
  ticket: Ticket,
  template: TicketTemplate,
  userLocale: string
): Ticket => {
  const preselectedTeams = template.pre_selected_teams || [];
  const resultingTicket: Ticket = { ...ticket, teams: [...preselectedTeams] };
  if (template.fields === undefined || template.fields.length === 0)
    return resultingTicket;
  template.fields.forEach((field) => {
    const fieldValue = field.value;
    if (fieldValue === undefined || fieldValue.length === 0) return;
    const { labels, categories, status } = fieldValue;
    if (labels !== undefined) resultingTicket.labels = [...labels];
    if (categories !== undefined) resultingTicket.categories = [...categories];
    if (status !== undefined && fieldValue.status.length > 0)
      resultingTicket.status = status[0];
    extraFieldTypesWithPresetValues.forEach((extraFieldType) => {
      const extraField: number[] | undefined = fieldValue[extraFieldType];
      if (extraField !== undefined) {
        const extraFields = resultingTicket.extra_fields || {};
        resultingTicket.extra_fields = {
          ...extraFields,
          [extraFieldType]: [...extraField],
        };
      }
    });
    openFieldsWithPresetValues.forEach((openField) => {
      const value: string | { i18n: Record<string, string> } | undefined =
        fieldValue[openField];

      if (value !== undefined) {
        if (typeof value === "string") {
          // @ts-ignore
          resultingTicket[openField] = value;
        } else {
          // @ts-ignore
          const translatedField =
            value.i18n !== undefined ? value.i18n : fieldValue[openField];
          // @ts-ignore
          resultingTicket[openField] = translate(translatedField, userLocale);
        }
      }
    });
  });
  return resultingTicket;
};
