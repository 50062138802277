<template>
  <div>
    <html-content
      tag="div"
      v-if="instance && instance.scheduler && instance.scheduler.instructions"
      class="ion-margin-bottom ion-text-center hint instance-instructions"
      :content="instance.scheduler.instructions"
    />
    <instance-step
      v-if="step && answer && currentStep < nbSteps"
      :step="step"
      :answer="answer"
      :instance="instance"
      :readonly="readonly"
      :routine="routine"
      :template="template"
      :answers="answers"
      :uncompletedSteps="uncompletedSteps"
      :current-step="currentStep + 1"
      :execution-date="executionDate"
      @pin="pinAnswer(answer.uuid, $event)"
      @select="selectAnswer(answer.uuid, $event)"
      @value="valueAnswer(step.answer.uuid, $event)"
      @detailed_value="detailedValueAnswer(step.answer.uuid, $event)"
      @checklist="selectChecklist"
      @comment="$emit('comment', answer)"
      @file="$emit('file', answer)"
    />
    <div
      v-if="currentStep === nbSteps && missingSteps.length === 0"
      class="ion-text-center"
    >
      <ion-row class="thumb-finished ion-justify-content-center">🎉</ion-row>
      <ion-row
        class="ion-justify-content-center ion-margin-vertical"
        v-if="!readonly || canBeResent"
      >
        <div class="routine-end">{{ t("routines.finished") }}</div>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-margin-vertical" v-else>
        <div class="routine-end">{{ t("routines.complete") }}</div>
      </ion-row>
      <ion-row
        class="ion-justify-content-center ion-margin-top"
        v-if="!readonly || canBeResent"
      >
        <div class="routine-end">
          <ion-button
            expand="block"
            :disabled="loading"
            class="ion-margin-top routine-button"
            @click="submitRoutine"
          >
            <font-icon
              name="spinner"
              spin
              color="white"
              size="0.4"
              v-if="loading"
            />
            <span v-else>{{ t("routines.submit") }}</span>
          </ion-button>
        </div>
      </ion-row>
    </div>
    <div
      v-if="currentStep === nbSteps && missingSteps.length > 0"
      class="ion-text-center"
    >
      <ion-row class="thumb-finished ion-justify-content-center">⚠️</ion-row>
      <ion-row class="ion-justify-content-center ion-margin-vertical">
        <div class="routine-end">
          {{ missingSteps.length }}
          {{ t("routines.uncompleted", missingSteps.length) }}
        </div>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <div
          class="missing-step"
          v-for="step of missingSteps"
          :key="step.id"
          @click="$emit('current', step.index)"
        >
          {{ step.index }}
        </div>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-margin-vertical">
        <div class="routine-end">
          {{ t("routines.needToComplete", missingSteps.length) }}
        </div>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-margin-top">
        <div class="routine-end button">
          <ion-button
            expand="block"
            class="ion-margin-top routine-button"
            @click="$emit('current', missingSteps[0].index)"
          >
            {{ t("routines.missing") }}
          </ion-button>
        </div>
      </ion-row>
    </div>
    <div class="ion-padding-vertical">&nbsp;</div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IonRow, IonButton } from "@ionic/vue";
import {
  Instance,
  Execution,
  Template,
  ExecutionAnswer,
  Routine,
  TemplateStep,
} from "@/types";
import InstanceStep from "@/components/business/routines/InstanceStep.vue";

import { useInstanceExecution } from "@/composables/useInstanceExecution";
import HtmlContent from "@/components/tools/HtmlContent.vue";

export default defineComponent({
  name: "InstanceExecution",
  props: {
    template: {
      type: Object as PropType<Template>,
      required: true,
    },
    instance: {
      type: Object as PropType<Instance>,
      required: true,
    },
    routine: {
      type: Object as PropType<Routine>,
      required: true,
    },
    execution: {
      type: Object as PropType<Execution>,
      required: true,
    },
    answers: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      required: true,
    },
    steps: {
      type: Array as PropType<Array<TemplateStep>>,
      required: true,
    },
    jumpedSteps: {
      type: Array as PropType<Array<number>>,
      required: true,
    },
    optionalSteps: {
      type: Array as PropType<Array<number>>,
      required: true,
    },
    uncompletedSteps: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      default: () => [],
    },
    missingSteps: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      default: () => [],
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    canBeResent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["complete", "current", "next", "file", "comment"],
  components: {
    HtmlContent,
    IonRow,
    IonButton,
    InstanceStep,
  },
  setup(props, { emit }) {
    const instanceExecutionComposable = useInstanceExecution(props, emit);

    return {
      ...instanceExecutionComposable,
    };
  },
});
</script>

<style scoped></style>
