import { Animation, createAnimation } from "@ionic/vue";

export const ticketPageAnimation = (_: HTMLElement, opts: any): Animation => {
  // create root transition
  const rootTransition = createAnimation()
    .duration(opts.duration || 200)
    .delay(0)
    .easing("cubic-bezier(0,0,1,1)");

  const enterTransition = createAnimation().addElement(opts.enteringEl);
  const exitTransition = createAnimation().addElement(opts.leavingEl);

  enterTransition.fromTo("opacity", "0", "1");
  exitTransition.fromTo("opacity", "1", "0");

  if (opts.direction === "forward") {
    enterTransition.fromTo("transform", "translateY(100%)", "translateY(0%)");
  } else {
    exitTransition.fromTo("transform", "translateY(0%)", "translateY(100%)");
  }

  rootTransition.addAnimation([enterTransition, exitTransition]);
  return rootTransition;
};

export const ticketsPageAnimation = (_: HTMLElement, opts: any): Animation => {
  // create root transition
  const rootTransition = createAnimation()
    .duration(opts.duration || 200)
    .delay(0)
    .easing("cubic-bezier(0,0,1,1)");

  const enterTransition = createAnimation().addElement(opts.enteringEl);
  const exitTransition = createAnimation().addElement(opts.leavingEl);

  enterTransition.fromTo("opacity", "0", "1");
  exitTransition.fromTo("opacity", "1", "0");

  if (opts.direction === "forward") {
    enterTransition.fromTo("transform", "translateX(100%)", "translateX(0%)");
  } else {
    exitTransition.fromTo("transform", "translateX(0%)", "translateX(100%)");
  }

  rootTransition.addAnimation([enterTransition, exitTransition]);
  return rootTransition;
};
