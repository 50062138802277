import { Team, Ticket } from "@/types";

export const getUserRole = (
  team: Team,
  userId: number,
  hasNewTeamRoles?: boolean
) => {
  if (team.owners.includes(userId)) return hasNewTeamRoles ? "admin" : "owner";
  if (team.admins.includes(userId)) return "admin";
  if (team.coordinators.includes(userId)) return "coordinator";
  if (team.auditors.includes(userId)) return "auditor";
  if (team.users.includes(userId)) return "member";
  return false;
};

export const userIsAdmin = (
  ticket: Ticket,
  teams: Team[],
  userId: number,
  hasNewTeamRoles?: boolean
): boolean => {
  const ticketTeams = teams.filter((t) =>
    ticket.teams.some((id) => id === t.id)
  );
  const roles = ["admin", "owner", "coordinator"];
  return ticketTeams.some((t) => {
    const role = getUserRole(t, userId, hasNewTeamRoles);
    if (!role) return false;
    return roles.includes(role);
  });
};
