<template>
  <ion-list class="ticket-list">
    <ticket-item
      v-for="ticket of tickets"
      :key="ticket.id"
      :ticket="ticket"
      @select="$emit('select', $event)"
    />
  </ion-list>
</template>

<script lang="ts">
import { IonList } from "@ionic/vue";
import { defineComponent, PropType } from "vue";
import TicketItem from "@/components/business/tickets/TicketItem.vue";
import { Ticket } from "@/types";

export default defineComponent({
  name: "TicketList",
  components: {
    IonList,
    TicketItem,
  },
  emits: ["select"],
  props: {
    tickets: {
      type: Array as PropType<Array<Ticket>>,
      default: () => [],
    },
  },
});
</script>

<style scoped>
.ticket-list {
  /* padding: 0 calc(var(--ion-padding) / 2) var(--ion-padding); */
  padding: 0;
  --ion-item-background: transparent;
}
</style>
