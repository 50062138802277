const userInfoHostAndPort = [
  /((?<UserInfo>(%[0-9a-f][0-9a-f]|[a-z0-9\-._~]|[!$&'()*+,;=]|:)*)@)?/,
  /(?<Host>(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])|([a-z0-9\-._~]|%[0-9a-f][0-9a-f]|[!$&'()*+,;=])*)/,
  /(:(?<Port>[0-9]+))?/,
]
  .map(function (r) {
    return r.source;
  })
  .join("");

export const supportedSchemes = [
  "file",
  "ftp",
  "http",
  "https",
  "mailto",
  "tel",
  "callto",
  "cid",
];

const uriRegex = new RegExp(
  [
    /^/,
    /(?<Scheme>[a-z][a-z0-9+\-.]*):/,
    new RegExp(
      `(?<HierPart>\\/\\/(?<Authority>${userInfoHostAndPort})(?<Path>(\\/([a-z0-9\\-._~!$&'()*+,;=:@]|(%[a-f0-9]{2}))*)*))`
    ),
    /(?<Query>\?([a-z0-9\-._~!$&'()*+,;=:@/?]|(%[a-f0-9]{2}))*)?/,
    /(?<Fragment>#([a-z0-9\-._~!$&'()*+,;=:@/?]|(%[a-f0-9]{2}))*)?/,
    /$/,
  ]
    .map(function (r) {
      return r.source;
    })
    .join(""),
  "i"
);

export const isUri = (
  rawUri: string,
  options?: { schemes: string[]; microsoftCompatibility?: boolean }
) => {
  let uri = rawUri;
  if (options?.microsoftCompatibility) {
    uri = uri.replace(/\\/g, "/");
  }
  uri = encodeURI(uri);
  if (options === undefined) {
    return uriRegex.test(uri);
  }
  const match = uriRegex.exec(uri);
  if (match === null) {
    return false;
  }
  const { Scheme } = match.groups as { Scheme: string };
  return options.schemes.includes(Scheme);
};
