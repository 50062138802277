<script lang="ts" setup>
import { TemplateStep, ExecutionAnswer, FabriqFile } from "@/types";
import { useI18n } from "@/composables/useI18n";
import FileList from "@/components/business/FileList.vue";
const { t } = useI18n();

const emit = defineEmits<{
  (event: "file"): void;
}>();

interface Props {
  step: TemplateStep;
  answer: ExecutionAnswer;
  files: Array<FabriqFile>;
}

defineProps<Props>();
</script>

<template>
  <div class="step-attachment">
    <file-list
      v-if="files && files.length"
      class="answers-files"
      :files="files"
      :readonly="false"
      :viewer="true"
    />
    <div
      class="add-attachment-button"
      :class="{ 'no-margin-top': files && files.length }"
      @click="emit('file')"
    >
      <font-icon
        name="add"
        material
        size="1"
        color="var(--ion-color-primary-shade)"
      />
      <div>{{ t("routines.placeholders.attachment") }}</div>
    </div>
  </div>
</template>

<style scoped>
.step-attachment {
  margin-top: 0;
}
</style>
