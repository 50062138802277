export const calcModalPercents = (
  elementHeight: number,
  nbElement: number,
  subtract = 0
) => {
  const windowHeight = window.innerHeight;
  if (nbElement === 0) return [0, 0.3, 0.5];
  const substractPercent = (subtract * 100) / windowHeight;
  const elementPercent = ((elementHeight * 100) / windowHeight) * nbElement;
  if (substractPercent + elementPercent > 100) return [0, 1];
  const middle = (substractPercent + elementPercent) / 100 + 0.1;
  return [0, middle > 1 ? 1 : middle, 1];
};
