import { ApiClient } from "./clients";
import { Routine, ListService } from "@/types";

export const RoutineService: ListService<Routine> = {
  async all(options?: any): Promise<Array<any>> {
    const routines = await ApiClient.get(`/api/v1/routines/${options?.id}/`);
    if (!routines || !routines.length) return [];
    return routines.map((r: any) => ({ ...r, updated_at: r.created_at }));
  },
  async load(options?: any, otherParams?: any) {
    try {
      const routines = await ApiClient.get(
        `/api/v1/routine/instances/user/${options}/?existing_routine_ids=[${otherParams}]`
      );
      if (!routines || !routines.length) return null;
      const r = routines.map((r: any) => ({ ...r, updated_at: r.created_at }));
      return r[0];
    } catch (e) {
      console.error(e);
    }
    return null;
  },
};
