<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item class="line-bottom">
        <score-disk slot="start" :size="36" :score="score" :max="100" />
        <div class="template-infos">
          <div class="template-title">{{ template.label }}</div>
          <div class="template-description hint">
            {{ template.description }}
          </div>
        </div>
      </ion-item>
    </ion-list>
    <ion-list lines="none" class="template-score-fields">
      <ion-item
        class="template-score-field"
        v-for="field of fields"
        :key="field.id"
      >
        <font-icon
          slot="start"
          :name="field.nudge ? 'radio_button_unchecked' : 'check_circle'"
          material
          outline
          :color="
            field.nudge ? 'var(--ion-border-color)' : 'var(--ion-color-success)'
          "
        />
        <ion-label class="bold">
          {{ field.name }}
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { IonContent, IonList, IonItem, IonLabel } from "@ionic/vue";
import ScoreDisk from "@/components/ui/ScoreDisk.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "TemplateScoreModal",
  props: ["template", "scoreColor", "score", "fields", "nbTotal"],
  components: { ScoreDisk, IonContent, IonList, IonItem, IonLabel },
});
</script>

<style scoped>
.line-bottom {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--ion-border-color);
}
.score-disk {
  margin-right: 22px;
}
.template-infos {
  width: 100%;
}
.template-title {
  padding-right: var(--ion-padding);
  margin-bottom: calc(var(--ion-padding) / 2);
  max-width: calc(100% - var(--ion-padding));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--font-size-m);
}
.template-description {
  font-size: var(--font-size-s);
  display: -webkit-box;
  max-width: calc(100% - var(--ion-padding));
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.template-score-field {
  padding-left: calc(var(--ion-padding) / 2);
}
.template-score-field ion-label {
  font-size: var(--font-size-m);
}
.template-score-fields {
  padding-top: 24px;
  padding-bottom: 24px;
  max-height: calc(100vh - 114px);
  overflow-y: auto;
}
</style>
