<template>
  <ion-row class="ion-align-items-center ion-justify-content-center">
    <html-content v-if="answer.value" tag="div" :content="answer.value" />
  </ion-row>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TemplateStep, ExecutionAnswer } from "@/types";
import { IonRow } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import HtmlContent from "@/components/tools/HtmlContent.vue";

export default defineComponent({
  name: "StepDivider",
  components: { HtmlContent, IonRow },
  emits: ["value"],
  props: {
    step: {
      type: Object as PropType<TemplateStep>,
      default: () => ({}),
    },
    answer: {
      type: Object as PropType<ExecutionAnswer>,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ...useI18n(),
    };
  },
});
</script>

<style scoped></style>
