const FABRIQ_ENV = import.meta.env.VITE_FABRIQ_ENV || "production";
const AUTH_CLIENT_ID =
  import.meta.env.VITE_AUTH_CLIENT_ID || "93282a11-04c3-48c0-a483-ff9c58c4e58f";
const MIXPANEL_TOKEN =
  import.meta.env.VITE_MIXPANEL_TOKEN || "e77c357e812b69f98db2cc057973c676";
const SSO_REDIRECT_URI =
  import.meta.env.VITE_SSO_REDIRECT_URI ||
  "tech.fabriq.mobile://saml-auth-code";

const config = () => {
  console.log("RUNNING app in", FABRIQ_ENV, "mode!");
  const production = {
    env: "production",
    deviceDeepLink: "tech.fabriq.mobile://",
    ssoRedirectUri: SSO_REDIRECT_URI,
    webappUrl: "https://app.fabriq.tech",
    apiUrl: "https://api.fabriq.tech",
    searchUrl: "https://search.fabriq.tech",
    futureAuthPrefix: "/auth",
    futureLegacyApiPath: "/legacy/api",
    futureLegacySearchPath: "/legacy/search",
    futurePushNotificationsPath: "/push-notifications",
    futureMobilePerformancePath: "/mobile-performances",
    futureKpiPrefix: "/kpi",
    futureEventsPrefix: "/events",
    futureDependenciesPrefix: "/dependencies",
    knowledgePrefix: "/knowledge",

    futureAuthClientId: AUTH_CLIENT_ID || "",
    messagingBaseUrl: "/legacy/messaging",

    notificationsUrl: "https://push-notifications.fabriq.tech",
    performancesUrl: "https://mobile-performances.fabriq.tech",

    mixpanelPath: "/proxy/mxpnl/api-eu",
    mixpanelToken: MIXPANEL_TOKEN || "",
  };
  if (FABRIQ_ENV === "production") {
    return production;
  }
  if (FABRIQ_ENV === "local-on-production") {
    return { ...production, env: "local" };
  }
  if (FABRIQ_ENV === "staging") {
    return {
      ...production,
      env: "staging",
      deviceDeepLink: "tech.fabriq.mobile://",
      ssoRedirectUri: SSO_REDIRECT_URI,
      webappUrl: "https://app.fabriq.dev",
      apiUrl: "https://api.fabriq.dev",
      searchUrl: "https://search.fabriq.dev",
      futureAuthClientId: AUTH_CLIENT_ID || "",
      mixpanelToken: "",
    };
  }
  if (FABRIQ_ENV === "qa") {
    return {
      ...production,
      env: "qa",
      ssoRedirectUri: `https://${window.location.host}/saml-auth-code`,
      webappUrl: "https://qa.fabriq.dev",
      apiUrl: "https://qa.fabriq.dev",
      searchUrl: "https://qa.fabriq.dev",
      futureAuthClientId: AUTH_CLIENT_ID || "",
      mixpanelToken: "",
    };
  }
  return {
    ...production,
    env: "local",
    ssoRedirectUri: "http://localhost:8100/saml-auth-code",
    webappUrl: "https://la.fabriq.test",
    apiUrl: "http://localhost:8000",
    searchUrl: "https://search.fabriq.test",
    futureAuthClientId: "client1",
    notificationsUrl: "https://push-notifications.fabriq.tech",
    performancesUrl: "https://mobile-performances.fabriq.tech",
    mixpanelToken: "",
  };
};

export default config();
