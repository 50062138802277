<script lang="ts" setup>
import { ref, computed, onMounted, nextTick, onBeforeUnmount } from "vue";
import { IonItem, createGesture } from "@ionic/vue";
import UserAvatar from "@/components/business/UserAvatar.vue";
import { commentParser } from "@/utils/comment-parser";
import { User, Comment, FabriqFile } from "@/types";
import { format, isToday, parseISO } from "date-fns";
import { useI18n } from "@/composables/useI18n";
import { useFabriqStore } from "@/store/fabriq";
import CommentFile from "./CommentFile.vue";
import { useFilesStore } from "@/store/files";
import HtmlContent from "@/components/tools/HtmlContent.vue";
const { t } = useI18n();

interface CommentWithChildren extends Comment {
  children?: Comment[];
}

interface Props {
  comment: CommentWithChildren;
  users: Array<User>;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits<{
  (event: "react", payload: { comment: Comment }): void;
  (event: "unreact", payload: { comment: Comment; reaction: unknown }): void;
}>();

const fabriqStore = useFabriqStore();
const filesStore = useFilesStore();
let longGesture = false;
let opened = false;
const commentRef = ref();
const author = computed(() => {
  return props.users.find((u: User) => u.id === props.comment.author);
});

const fullName = computed(() => {
  return author.value?.profile?.full_name;
});

const dateFormat = t("formats.dayOfMonth");

const createdAt = computed(() => {
  if (!props.comment.created_at) return null;
  const date = parseISO(props.comment.created_at);
  const hours = format(date, "HH:mm");
  if (isToday(date)) return `${t("dates.today")} ${hours}`;
  return `${format(date, dateFormat)} ${hours}`;
});

const content = computed(() => commentParser(props.comment.content));

const hasBeenEdited = computed(() => {
  if (!props.comment.updated_at) return false;
  return (
    String(props.comment.created_at).substring(0, 19) <
    String(props.comment.updated_at).substring(0, 19)
  );
});

const files = computed(() => {
  const commentId = props.comment.id || props.comment.uuid;
  const files = filesStore.collection.filter((f: FabriqFile) => {
    return f.comment && f.comment === commentId;
  });
  files.sort((f: FabriqFile, t: FabriqFile) =>
    String(f.id).localeCompare(String(t.id))
  );
  return files;
});

onMounted(() => {
  const gesture = createGesture({
    gestureName: "LongPress",
    el: commentRef.value.$el,
    threshold: 0,
    onStart: () => {
      longGesture = true;

      setTimeout(() => {
        if (!longGesture) return;
        opened = true;
        emit("react", { comment: props.comment });
      }, 500);
    },
    onEnd: () => {
      longGesture = false;
      if (fabriqStore.platform === "web" && !opened) {
        return emit("react", { comment: props.comment });
      }
    },
    onMove: (detail) => {
      const deltaY = Math.abs(detail.deltaY);
      if (deltaY > 40) longGesture = false;
    },
  });
  nextTick(() => gesture.enable());
  onBeforeUnmount(() => {
    gesture.destroy();
  });
});

const stopClick = (ev: any) => {
  if (!opened) return (opened = false);
  ev.stopPropagation();
  ev.preventDefault();
};

const removeReaction = (ev: MouseEvent, reaction: any) => {
  if (!reaction.mine) return;
  ev.preventDefault();
  ev.stopPropagation();
  emit("unreact", { comment: props.comment, reaction });
};

const reactions = computed(() => {
  if (!props.comment.reactions?.length) return;
  const reactions: any = [];
  const userId = fabriqStore.user?.id;
  props.comment.reactions.forEach((r: any) => {
    if (!r || !r.reaction) return;
    const idx = reactions.findIndex((re: any) => re.reaction === r.reaction);
    if (idx < 0) {
      reactions.push({
        reaction: r.reaction,
        mine: r.user === userId,
        nb: 1,
      });
    } else {
      reactions[idx].nb++;
      reactions[idx].mine = reactions[idx].mine || r.user === userId;
    }
  });
  return reactions;
});
</script>

<template>
  <ion-item
    class="comment ion-align-items-start"
    :class="{ [`comment-${comment.id}`]: true }"
    ref="commentRef"
    @click="stopClick"
  >
    <div class="comment-container">
      <font-icon
        class="pinned-icon"
        name="push_pin"
        material
        v-if="comment.pinned"
        color="var(--ion-color-secondary)"
      />
      <div class="comment-comment">
        <div class="comment-avatar">
          <user-avatar :user="author" />
        </div>
        <div class="comment-content">
          <div class="comment-infos">
            <div class="comment-author">
              {{ fullName }}
            </div>
            <div class="comment-date">∙</div>
            <div class="comment-date">{{ createdAt }}</div>
            <div class="comment-edited" v-if="hasBeenEdited">
              ({{ t("ticketModal.edited") }})
            </div>
          </div>
          <html-content tag="div" class="comment-text" :content="content" />
          <div class="comment-files" v-if="files.length">
            <comment-file
              v-for="file of files"
              :key="`comment-${comment.uuid}-${file.id}`"
              :file="file"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="comment-reactions" v-if="reactions">
        <div
          class="comment-reaction"
          :class="{ mine: reaction.mine }"
          v-for="(reaction, index) of reactions"
          :key="index"
          @click="removeReaction($event, reaction)"
        >
          {{ reaction.reaction }}
          <small>{{ reaction.nb }}</small>
        </div>
      </div>
    </div>
  </ion-item>
  <template v-if="comment.children">
    <comment-item
      @react="emit('react', $event)"
      @unreact="emit('unreact', $event)"
      v-for="child of comment.children"
      :key="child.id || child.uuid"
      :comment="child"
      :users="users"
    />
  </template>
</template>

<style>
.comment-user-link,
.comment-ticket-link,
.comment-task-link,
.comment-step-link {
  border-radius: 2px;
  padding: 0.1em 0.3em;
  font-size: 1em;
  white-space: nowrap;
  text-decoration: none;
}

.comment-user-link {
  background-color: var(--comment-user-link-bg-color, rgba(29, 155, 209, 0.4));
  color: var(--comment-user-link-color, rgb(136, 200, 255));
}

.comment-ticket-link,
.comment-task-link,
.comment-step-link {
  background-color: var(--comment-task-link-bg-color, rgba(232, 145, 45, 0.4));
  color: var(--comment-task-link-color, rgb(242, 199, 68));
}

.comment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.pinned-icon {
  position: absolute !important;
  right: 10px;
  top: 18px;
}

.comment-comment {
  display: flex;
  flex-direction: row;
}

.comment-reactions {
  padding-left: calc((var(--ion-padding) * 3) - 3px);
  display: flex;
  gap: calc(var(--ion-padding) / 2);
  padding-bottom: calc(var(--ion-padding) / 2);
  flex-wrap: wrap;
}

.comment-reaction {
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--ion-border-color);
  border-radius: 50px;
  padding: 2px 8px;
  font-size: var(--font-size-s);
}

.comment-reaction.mine {
  background-color: var(--ion-color-secondary);
}

.comment-content {
  border: 1px solid var(--ion-border-color);
  border-radius: 5px;
  padding: var(--ion-padding);
  margin-top: calc(var(--ion-padding) / 2);
  margin-left: calc(var(--ion-padding) / 3);
  margin-bottom: calc(var(--ion-padding) / 2);
  width: 100%;
  max-width: 100%;
  position: relative;
}

.comment-infos {
  display: flex;
  flex-direction: row;
  gap: calc(var(--ion-padding) / 2);
  align-items: center;
  margin-bottom: calc(var(--ion-padding) / 2);
}

.comment-author {
  font-size: var(--font-size-s);
  font-weight: 500;
}

.comment-date,
.comment-edited {
  font-size: var(--font-size-s);
  color: var(--ion-color-primary-shade);
}

.comment-edited {
  font-style: italic;
}

.comment-text {
  font-size: var(--font-size-s);
  max-width: calc(100vw - 90px);
  line-height: 1.42;
}

.comment-text p {
  margin: 0;
}

.comment-avatar {
  /* margin-top: var(--ion-padding); */
  padding-top: calc(var(--ion-padding) + 3px);
}

.comment-files {
  display: flex;
  flex-direction: row;
  gap: var(--ion-padding);
  flex-wrap: wrap;
  margin-top: var(--ion-padding);
}

.comment-form .editor-container .text-editor-row .text-editor {
  align-items: flex-start;
  max-width: calc(100vw - 128px);
  max-height: 15vh;
  overflow-y: auto;
}
</style>
