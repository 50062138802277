import { useFabriqStore } from "@/store/fabriq";
import { useTicketsStore } from "@/store/tickets";
import { useFieldsStore } from "@/store/fields";
import { useTasksStore } from "@/store/tasks";
import { useTeamsStore } from "@/store/teams";
import { useCommentsStore } from "@/store/comments";
import { useRoutinesStore } from "@/store/routines";
import { useInstancesStore } from "@/store/instances";
import { useSchedulesStore } from "@/store/schedules";
import { useTemplatesStore } from "@/store/templates";
import { useExecutionsStore } from "@/store/executions";
import { useAnswersStore } from "@/store/answers";
import { useFilesStore } from "@/store/files";
import { useIndicatorsStore } from "@/store/indicators";
import { useDependenciesStore } from "@/store/dependencies";
import { useDatapointsStore } from "@/store/datapoints";
import { useNotificationsStore } from "@/store/notifications";
import { useTicketTemplatesStore } from "@/store/ticketTemplates";
import tracker from "./tracker";
import { ActionType } from "@/classes/PerformanceTracker";
import storage from "./storage";
import { useClientStore } from "@/store/client";
import { parseISO } from "date-fns";
import { useEventTypesStore } from "@/store/eventTypes";
import { useEventsStore } from "@/store/events";
import { useDiamondDependenciesStore } from "@/store/diamondDependencies";

export const initializeApp = async (router: any, firstLoad = false) => {
  const fabriqStore = useFabriqStore();
  const ticketsStore = useTicketsStore();
  const tasksStore = useTasksStore();
  const teamsStore = useTeamsStore();
  const commentsStore = useCommentsStore();
  const routinesStore = useRoutinesStore();
  const instancesStore = useInstancesStore();
  const schedulesStore = useSchedulesStore();
  const templatesStore = useTemplatesStore();
  const executionsStore = useExecutionsStore();
  const answersStore = useAnswersStore();
  const filesStore = useFilesStore();
  const indicatorsStore = useIndicatorsStore();
  const dependenciesStore = useDependenciesStore();
  const datapointsStore = useDatapointsStore();
  const notificationsStore = useNotificationsStore();
  const ticketTemplatesStore = useTicketTemplatesStore();
  const eventTypesStore = useEventTypesStore();
  const eventsStore = useEventsStore();
  const diamondDependenciesStore = useDiamondDependenciesStore();
  const fields = useFieldsStore();
  tracker.next("init", ActionType.Process, "initializeApp");
  tracker.next("connexion", ActionType.Process, "initializeApp");
  fabriqStore.setLoading(true);
  try {
    localStorage.setItem("last-tickets-open", "0");
    localStorage.setItem("last-notifications-open", "0");
    await Promise.all([
      ticketsStore.initialize(),
      fields.initialize(),
      tasksStore.initialize(),
      teamsStore.initialize(),
      commentsStore.initialize(),
      routinesStore.initialize(),
      instancesStore.initialize(),
      schedulesStore.initialize(),
      filesStore.initialize(),
      dependenciesStore.initialize(),
      templatesStore.initialize(),
      executionsStore.initialize(),
      answersStore.initialize(),
      indicatorsStore.initialize(),
      datapointsStore.initialize(),
      notificationsStore.initialize(),
      ticketTemplatesStore.initialize(),
      eventTypesStore.initialize(),
      eventsStore.initialize(),
      diamondDependenciesStore.initialize(),
    ]);
    tracker.next(
      "init",
      ActionType.Request,
      `${!firstLoad ? "async " : ""}load user data`
    );
    const promises = [
      fabriqStore.loadUserData(teamsStore),
      ticketTemplatesStore.all(),
      eventTypesStore.all(),
      notificationsStore.getCount(),
    ];
    notificationsStore.all({ flush: true, all: false });
    tracker.next("connexion", ActionType.Request, "load user data");
    if (firstLoad) await Promise.allSettled(promises);
    tracker.next("init", ActionType.Process, "go to tickets page");
    tracker.next("connexion", ActionType.Request, "go to tickets page");
    fabriqStore.setInitializing(false);
    await fabriqStore.connectSocket();
    fabriqStore.setLoading(false);
    router.replace("/main/tickets");
  } catch (e) {
    fabriqStore.setLoading(false);
    console.error(e);
  }
};

export const autoLogin = async (router: any) => {
  const fabriqStore = useFabriqStore();
  const clientStore = useClientStore();
  let email,
    password,
    type,
    emailChecked = false;
  let error: string | undefined;
  try {
    tracker.next("init", ActionType.Process, "autoLogin");
    try {
      email = await storage.get("email");
      password = await storage.get("password");
      type = await storage.get("type");
    } catch (e) {
      console.error("ARG", e);
    }

    const user = await storage.readFromStorage("user");
    const client = await storage.readFromStorage("client");

    const token = await storage.get("token");
    const connected = await storage.get("connected");
    if (token && user && connected) {
      tracker.setClientId(client.id);
      tracker.setUserId(user.id);
      emailChecked = true;
      fabriqStore.setInitializing(true);
      await fabriqStore.setCredentials(email, password, type);
      fabriqStore.setUser(user);
      clientStore.setClient(client);
      fabriqStore.setToken({
        ...token,
        expiresAt: parseISO(token.expiresAt),
      });
      return initializeApp(router, false);
    } else if (email && email.length) {
      const response = await fabriqStore.checkCurrent(email);
      if (response && response.token && response.user) {
        fabriqStore.setInitializing(true);
        await fabriqStore.setCredentials(email, password, type);
        fabriqStore.setToken(response.token);
        return initializeApp(router, true);
      }
    }
    fabriqStore.setLoading(false);
    fabriqStore.setInitializing(false);
    return { email, password, type, emailChecked };
  } catch (e: any) {
    fabriqStore.setInitializing(false);
    console.error(e);
    error = e.message;
  }
  fabriqStore.setLoading(false);
  fabriqStore.setInitializing(false);
  return { email, password, type, emailChecked, error };
};
