import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
export class FabriqVault {
  clear() {
    SecureStoragePlugin.clear();
  }

  reset(): Promise<any> {
    this.clear();
    return Promise.resolve();
  }

  set(key: string, value: any): Promise<boolean> {
    return SecureStoragePlugin.set({
      key,
      value: encodeURIComponent(JSON.stringify(value)),
    }).then(({ value }) => value);
  }

  async get(key: string): Promise<any> {
    try {
      const value = await SecureStoragePlugin.get({ key }).then(
        ({ value }) => value
      );
      return value ? JSON.parse(decodeURIComponent(value)) : null;
    } catch (e) {
      return null;
    }
  }
  remove(key: string) {
    return SecureStoragePlugin.remove({ key }).then(({ value }) => value);
  }
}
