<template>
  <f-button :selected="selected" @click="$emit('select')">
    {{ t(`common.filters.${filter}`) }}
    <div v-if="badge" class="filter-badge">{{ badge }}</div>
  </f-button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FButton from "@/components/ui/FButton.vue";
import { useI18n } from "@/composables/useI18n";

export default defineComponent({
  name: "FilterItem",
  props: {
    filter: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: Number,
      default: null,
    },
  },
  emits: ["select"],
  components: {
    FButton,
  },
  setup() {
    return {
      ...useI18n(),
    };
  },
});
</script>

<style scoped>
.filter-badge {
  font-size: var(--font-size-xs);
  background-color: var(--ion-border-color);
  color: var(--ion-color-primary-shade);
  border-radius: 50px;
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1rem;
  padding: 0;
  margin: 0;
  margin-left: calc(var(--ion-padding) / 2);
  text-align: center;
  white-space: nowrap;
}
.plt-ios .filter-badge {
  line-height: 1.125rem;
}
.f-button.selected .filter-badge {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-primary-contrast);
}
.dark .filter-badge,
.dark .f-button.selected .filter-badge {
  color: white;
}
</style>
