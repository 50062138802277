<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar ion-padding-top">
      <ion-title>
        {{ t("routines.completed") }}: {{ completed }}/{{ total }}
      </ion-title>
      <ion-buttons slot="end" class="ion-padding-end">
        <ion-button @click="$emit('cancel')">
          <font-icon
            name="close"
            material
            size="0.875"
            color="var(--f-color-discrete)"
          />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content :scroll-events="true" class="normal">
    <ion-list class="step-list scroll">
      <template v-for="(step, index) of allSteps" :key="step.id">
        <ion-item lines="none" @click="$emit('done', index)">
          <font-icon
            :color="
              index > current
                ? 'var(--ion-color-primary)'
                : 'var(--ion-color-warning)'
            "
            size="1"
            slot="start"
            material
            v-if="!step.answered"
            name="reorder"
          />
          <font-icon
            v-else
            color="var(--ion-color-success)"
            size="1"
            slot="start"
            material
            name="check"
          />
          <ion-label> {{ index + 1 }}. {{ step.question }} </ion-label>
        </ion-item>
      </template>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import {
  IonContent,
  IonButtons,
  IonButton,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { ExecutionAnswer, TemplateStep } from "@/types";

export default defineComponent({
  name: "RoutineInstanceSummaryModal",
  props: {
    steps: {
      type: Array as PropType<Array<TemplateStep>>,
      default: () => [],
    },
    answers: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      default: () => [],
    },
    uncompletedSteps: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      default: () => [],
    },
    current: {
      type: Number,
    },
  },
  components: {
    IonContent,
    IonButtons,
    IonButton,
    IonTitle,
    IonHeader,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
  },
  emits: ["done", "cancel"],
  setup(props) {
    const completed = computed(() => {
      return props.steps.length - props.uncompletedSteps.length;
    });
    const total = computed(() => {
      if (!props.steps) return 0;
      return props.steps.length;
    });
    const allSteps = computed(() => {
      return props.steps
        .filter((s: any) => !s.parent)
        .map((s: any) => {
          const answer = props.answers.find((a) => a.step.id === s.id);
          if (!answer) return { ...s };
          const idx = props.uncompletedSteps.findIndex(
            (a: any) => a.id === answer.id
          );
          return { ...s, answered: idx < 0 };
        });
    });

    return {
      total,
      completed,
      allSteps,
      ...useI18n(),
    };
  },
});
</script>

<style scoped>
.step-list .item {
  border: 1px solid var(--ion-border-color);
  margin: calc(var(--ion-padding) / 2);
  border-radius: 5px;
  font-size: var(--font-size-m);
}
</style>
