<template>
  <div
    v-if="canShowDocument"
    class="document-hint clickable"
    @click="$emit('select', document.id)"
  >
    <div class="document-hint-label">
      <font-icon
        v-if="document.type === 'loading'"
        name="spinner"
        spin
        size="0.4"
        class="document-hint-loading"
      />
      <font-icon
        v-else
        class="document-hint-icon"
        name="menu_book"
        material
        outlined
      />
      <html-content tag="div" class="title" :content="title" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { truncateMarks } from "@/utils/comment-parser";
import HtmlContent from "@/components/tools/HtmlContent.vue";
import { useFabriqStore } from "@/store/fabriq";

export default defineComponent({
  name: "DocumentHint",
  components: {
    HtmlContent,
  },
  emits: ["select"],
  props: {
    document: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const fabriqStore = useFabriqStore();
    const title = computed(() =>
      truncateMarks(
        props.document._highlightResult?.title?.value || props.document.title
      )
    );

    const canShowDocument = computed(() => {
      return (
        props.document.authorId === fabriqStore.user?.future_user_id ||
        props.document.status !== "draft"
      );
    });
    return {
      title,
      canShowDocument,
    };
  },
});
</script>

<style scoped>
.document-hint {
  background-color: var(--ion-background-color);
  padding: var(--f-padding-m);
  border-bottom: 1px solid var(--ion-border-color);
}
.document-hint:first-child {
  border-top: 1px solid var(--ion-border-color);
}
li .document-hint:first-child {
  border-top: 0;
}

.document-hint-label {
  display: flex;
  flex-direction: row;
  gap: calc(var(--f-padding-m) / 2);
  font-size: var(--font-size-m);
  align-items: center;
}

.document-hint-loading {
  margin: -4.5px -4px -4.5px -8px;
}

.document-hint-label.sub-item {
  padding-left: calc(var(--f-padding-m) / 2);
  padding-top: var(--f-padding-m);
}
.document-hint-label div.title {
  flex: 1;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.document-hint-icon {
  width: 16px;
  height: 16px;
  display: block;
}
</style>
<style>
.document-hint mark {
  color: var(--ion-color-secondary);
  background-color: transparent;
}
p {
  margin: 0 !important;
}

.document-hint-label div.title p,
.document-hint-label div.title div {
  display: inline;
}
</style>
