import { useTicketsStore } from "@/store/tickets";
import tracker from "@/utils/tracker";
import { computed, toRaw, watch, nextTick, ref } from "vue";
import { useFabriqStore } from "@/store/fabriq";
import { useAnswersStore } from "@/store/answers";
import { useExecutionsStore } from "@/store/executions";
import { useInstancesStore } from "@/store/instances";
import { useCommentsStore } from "@/store/comments";
import { useRouter } from "vue-router";
import {
  DetailedValue,
  ExecutionAnswer,
  PinnedAnswer,
  StepChoice,
  TemplateStep,
} from "@/types";
import { parseISO } from "date-fns";
import { useI18n } from "@/composables/useI18n";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { formatDateForRoutine, getNextFromLogic } from "@/utils/routines";
import { fabriqDeepClone } from "@/services/fabriqDeepClone";

export const useInstanceExecution = (props: any, emit: any) => {
  const answersStore = useAnswersStore();
  const executionsStore = useExecutionsStore();
  const fabriqStore = useFabriqStore();
  const instancesStore = useInstancesStore();
  const commentStore = useCommentsStore();
  const ticketsStore = useTicketsStore();
  const router = useRouter();

  const loading = ref(false);

  const tunnel = (action: string) => {
    tracker.tunnel(
      "routine",
      "routine page",
      RecordCategory.Routines,
      ActionType.Process,
      action
    );
  };

  const nbSteps = computed(() => {
    return props.steps?.length;
  });

  const checkLogic = (step: TemplateStep, value: any) => {
    emit("next", null);
    const jumpTo = getNextFromLogic(step, value, props.template);
    if (!jumpTo) return;
    if (jumpTo === "confirmationScreen") {
      tunnel("logic");
      emit("next", props.steps.length + 1);
    } else {
      const idx = props.steps.findIndex((s: any) => s.id === +jumpTo);
      if (idx >= 0) {
        tunnel("logic");
        emit("next", idx + 1);
      }
    }
  };

  const selectAnswer = async (uuid: string, id: number) => {
    const step = props.steps.find(
      (s: any) => s.answer && s.answer.uuid === uuid
    );
    if (!step || !step.answer) return;
    const choice = step.choices?.find((c: StepChoice) => c.id === id);
    if (!choice || !choice.id) return;
    const oldChoices = [...(step.answer.choices || [])];
    const idx = oldChoices.indexOf(choice.id);
    const choices: Array<number> =
      idx >= 0
        ? [...oldChoices.slice(0, idx), ...oldChoices.slice(idx + 1)]
        : step.multiple_choice
        ? [...oldChoices, choice.id]
        : [choice.id];
    const score = step.choices.reduce((acc: any, a: any) => {
      if (!choices.includes(a.id)) return acc;
      if (choice.invalidates) return acc;
      return acc + +a.score;
    }, 0);
    const execution_info = {
      executionDate: Date.now() / 1000,
      executorId: fabriqStore.user?.id,
    };
    let value = !step.multiple_choice ? choice.text : undefined;
    if (oldChoices.includes(id)) value = null;
    await answersStore.update(uuid, {
      choices,
      score,
      execution_info,
      value,
    });
    checkLogic(step, choices);
  };

  const selectChecklist = async ({
    choiceId,
    stepId,
  }: {
    choiceId: number;
    stepId: number;
  }) => {
    const checklistStep = props.template.steps.find(
      (s: any) => s.id === stepId
    );
    if (!checklistStep) return;
    const checklistAnswer = props.answers.find(
      (a: any) => a.step.id === stepId
    );
    if (!checklistAnswer) return;
    const choice = checklistStep.choices.find(
      (c: StepChoice) => c.id === choiceId
    );
    if (!choice || !choice.id) return;
    const oldChoices = [...(checklistAnswer.choices || [])];
    const idx = oldChoices.indexOf(choice.id);
    const choices: Array<number> =
      idx >= 0
        ? [...oldChoices.slice(0, idx), ...oldChoices.slice(idx + 1)]
        : checklistStep.multiple_choice
        ? [...oldChoices, choice.id]
        : [choice.id];
    const score = checklistAnswer.step.choices.reduce((acc: any, a: any) => {
      if (!choices.includes(a.id)) return acc;
      if (choice.invalidates) return acc;
      return acc + +a.score;
    }, 0);
    const execution_info = {
      executionDate: Date.now() / 1000,
      executorId: fabriqStore.user?.id,
    };
    await answersStore.update(checklistAnswer.uuid, {
      choices,
      score,
      execution_info,
    });
  };

  const valueAnswer = async (
    uuid: string,
    value: any,
    detailed_value?: any
  ) => {
    const idx = props.steps.findIndex(
      (s: any) => s.answer && s.answer.uuid === uuid
    );
    if (idx < 0) return;
    const step = props.steps[idx];
    if (!step || !step.answer) return;
    const execution_info = {
      executionDate: Date.now() / 1000,
      executorId: fabriqStore.user?.id,
    };
    await answersStore.update(uuid, { value, execution_info, detailed_value });
    checkLogic(step, value);
  };

  const detailedValueAnswer = async (
    uuid: string,
    detailed_value: DetailedValue
  ) => {
    valueAnswer(
      uuid,
      detailed_value.value !== null ? `${detailed_value.value}` : null,
      fabriqDeepClone(toRaw(detailed_value))
    );
  };

  const pinAnswer = async (uuid: string, value: any) => {
    const step = props.steps.find(
      (s: any) => s.answer && s.answer.uuid === uuid
    );
    if (!step || !step.answer) return;
    const pinned = value ? PinnedAnswer.Up : PinnedAnswer.Down;
    const execution_info = {
      executionDate: Date.now() / 1000,
      executorId: fabriqStore.user?.id,
    };
    await answersStore.update(uuid, {
      pinned: pinned === step.answer.pinned ? null : pinned,
      execution_info,
    });
    tunnel("pin");
  };

  const stepHasNonApplicableChoice = (answer: ExecutionAnswer) => {
    if (!answer?.choices?.length) return false;
    return (
      answer.choices.filter((id) => {
        const choice = answer.step.choices.find((c: StepChoice) => c.id === id);
        return choice?.invalidates;
      }).length > 0
    );
  };

  const isAnswerFilled = (answer: ExecutionAnswer) => {
    switch (answer.step.step_type) {
      case "text":
      case "long_text": {
        if (!answer.value) return false;
        return answer.value.trim().length > 0;
      }
      case "date":
      case "number": {
        return ![null, undefined, ""].includes(answer.value);
      }
      case "attachment": {
        if (!answer.files) return false;
        return answer.files.length > 0;
      }
      case "multiple_choice":
      case "picture_choice":
      case "audit": {
        if (!answer.choices) return false;
        return answer.choices?.length > 0;
      }
      case "checklist": {
        return answersStore.collection
          .filter((aa: any) => {
            if (aa.instance_execution !== answer.instance_execution)
              return false;
            if (aa.step.parent !== answer.step.id) return false;
            return true;
          })
          .every((aaa: ExecutionAnswer) => isAnswerFilled(aaa));
      }
      case "indicator":
        return (
          ![null, undefined, ""].includes(answer.value) &&
          answer.detailed_value &&
          Object.keys(answer.detailed_value).length > 0
        );
      default: {
        return false;
      }
    }
  };

  const getScore = (realized = false) => {
    if (realized) {
      return props.answers.reduce((acc: any, a: ExecutionAnswer) => {
        if (props.optionalSteps.includes(a.step.id) && !isAnswerFilled(a))
          return acc;
        if (stepHasNonApplicableChoice(a)) return acc;
        return acc + +(a.score || 0);
      }, 0);
    }
    return props.answers.reduce((acc: any, a: ExecutionAnswer) => {
      if (props.optionalSteps.includes(a.step.id) && !isAnswerFilled(a))
        return acc;
      if (stepHasNonApplicableChoice(a)) return acc;
      const stepId = a.step.parent || a.step.id;
      const idx = props.steps.findIndex((s: any) => s.id === stepId);
      const parent = props.steps.find((s: any) => s.id === a.step.parent);
      if (
        a.step.step_type === "checklist" ||
        idx < 0 ||
        props.jumpedSteps.includes(idx + 1)
      )
        return acc;

      if (parent?.step_type === "checklist") {
        if (props.optionalSteps.includes(parent.id) && !isAnswerFilled(a)) {
          return acc;
        }
      }
      return acc + +(a.step.potencial_score || 0);
    }, 0);
  };

  const submitRoutine = async () => {
    loading.value = true;
    nextTick(async () => {
      tracker.begin(
        "submitRoutine",
        "Submit routine",
        RecordCategory.Routines,
        ActionType.Process
      );
      tunnel("submit");
      const score = { potential: getScore(), realized: getScore(true) };

      const columns = {
        end_date: formatDateForRoutine(new Date()),
        current_step_index: props.answers.length,
        ongoing: false,
        is_done: true,
        score,
      };
      await executionsStore.update(props.execution.uuid, columns);
      await instancesStore.update(props.instance.uuid, {
        sending: true,
        scheduler: {
          ...fabriqDeepClone(toRaw(props.instance.scheduler)),
          is_done: true,
          has_started: true,
        },
        execution_end_date: columns.end_date,
        score,
      });
      tracker.next("submitRoutine", ActionType.Request, "asynchronous save");
      executionsStore
        .save()
        .then(() => tracker.end("submitRoutine"))
        .catch((e: any) => tracker.end("submitRoutine", e));
      ticketsStore.save();
      commentStore.clearDraft();
      router.back();
    });
  };

  const step = computed(() => props.steps[props.currentStep]);
  const answer = computed(() => {
    if (!step.value) return;
    return step.value.answer;
  });

  watch(
    step,
    (v, o) => {
      if (v?.id !== o?.id) {
        nextTick(() => {
          tracker.end("nextStep");
          tracker.end("previousStep");
        });
      }
      if (!v?.answer) return;
      const value =
        v.answer.choices && v.answer.choices.length
          ? v.answer.choices
          : v.answer.value || null;
      tunnel(`step ${v.step_type}`);
      checkLogic(v, value);
    },
    { immediate: true }
  );

  watch(
    () => props.instance?.id,
    () => {
      tracker.end("routine");
    },
    { immediate: true }
  );

  const executionDate = computed(() => {
    const date = props.execution?.created_at
      ? parseISO(props.execution.created_at)
      : new Date();
    return date;
  });

  return {
    ...useI18n(),
    nbSteps,
    loading,
    selectAnswer,
    submitRoutine,
    pinAnswer,
    valueAnswer,
    detailedValueAnswer,
    selectChecklist,
    step,
    answer,
    getScore,
    executionDate,
  };
};
