<script lang="ts" setup>
import { computed, watch, onBeforeUnmount } from "vue";
import { useRoutinesStore } from "@/store/routines";
import { useInstancesStore } from "@/store/instances";
import { useI18n } from "@/composables/useI18n";
import { IonLabel, IonRow } from "@ionic/vue";
import { useFabriqStore } from "@/store/fabriq";
import type { Routine, Instance } from "@/types";

const { t } = useI18n();

const props = defineProps({
  entityId: Number,
});

const fabriqStore = useFabriqStore();
const routinesStore = useRoutinesStore();
const instancesStore = useInstancesStore();
const routine = computed(() => {
  const instance = instancesStore.collection.find(
    (i: Instance) => i.id === props.entityId
  );
  if (!instance) return null;
  const routine = routinesStore.collection.find(
    (t: Routine) => t.id === instance.routine_id
  );
  return routine;
});

let tm: NodeJS.Timeout;
watch(
  () => routine,
  (value) => {
    if (tm) clearTimeout(tm);
    if (!value) return;
    tm = setTimeout(
      () => routinesStore.load(fabriqStore.user?.id, true, props.entityId),
      750
    );
  },
  { immediate: true }
);
onBeforeUnmount(() => {
  if (tm) clearTimeout(tm);
});
</script>

<template>
  <ion-row
    class="ion-justify-content-between ion-align-items-center routine-row"
  >
    <ion-row class="ion-align-items-center">
      <font-icon
        class="dependency-icon"
        color="var(--ion-color-secondary)"
        name="bar_chart"
        material
      />
      <ion-label v-if="routine" class="text-ellipsis routine-title">
        {{ routine.title || t("common.noTitle") }}
      </ion-label>
      <ion-label v-else>{{ t("common.loading") }}</ion-label>
    </ion-row>
  </ion-row>
</template>

<style scoped>
.routine-title {
  max-width: calc(100vw - 150px);
}

.routine-row {
  width: 100%;
}

.routine-row .font-icon {
  margin-right: var(--ion-padding);
  margin-left: calc(var(--ion-padding) - 2px);
}
</style>
