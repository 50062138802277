import aws_exports_local from "./aws-exports-local";
import aws_exports_staging from "./aws-exports-staging";
import aws_exports_production from "./aws-exports-production";

import type { Config } from "@/types/config";
import config from "@/config";

/**
 * Returns the appropriate AWS config for the current environment.
 */

export const getAwsConfig = (): Config => {
  if (config.env === "production") {
    return aws_exports_production;
  }
  if (config.env === "staging") {
    return aws_exports_staging;
  }
  return aws_exports_local;
};
