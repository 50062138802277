<script lang="ts" setup>
import { computed, Ref, ref } from "vue";
import {
  IonContent,
  IonRow,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { format, parseISO } from "date-fns";
import { useFabriqStore } from "@/store/fabriq";

interface Props {
  date: string;
  noFutureDate?: boolean;
  canClearDate: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  date: format(new Date(), "yyyy-MM-dd HH:mm"),
  noFutureDate: false,
  canClearDate: true,
});

const emit = defineEmits(["done", "cancel"]);
const { t } = useI18n();
const { theme, colorScheme } = useFabriqStore();
const today = new Date();
const model: Ref<Date | null> = ref(parseISO(props.date));

const modified = ref(false);
const maxDate = props.noFutureDate ? today : undefined;
const done = () => {
  emit("done", {
    date: format(model.value ?? today, "yyyy-MM-dd"),
    time: format(model.value ?? today, "HH:mm"),
  });
};

const isDark = computed(() => {
  if (!theme) return colorScheme === "dark";
  return theme === "dark";
});
</script>

<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar edit-modal-toolbar">
      <div slot="start">
        <ion-button
          v-if="canClearDate"
          fill="clear"
          @click="$emit('done', null)"
        >
          {{ t("common.clearDate") }}
        </ion-button>
      </div>
      <ion-title></ion-title>
      <ion-buttons slot="end" class="buttons-done-cancel">
        <ion-button
          v-if="modified"
          fill="clear"
          class="cancel-btn"
          @click="done"
        >
          {{ t("common.done") }}
        </ion-button>
        <ion-button
          v-else
          fill="clear"
          class="cancel-btn"
          @click="$emit('cancel')"
        >
          {{ t("common.cancel") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="normal">
    <ion-row class="ion-justify-content-center ion-align-items-start">
      <v-date-picker
        :is-dark="isDark"
        is-expanded
        v-model="model"
        mode="dateTime"
        is24hr
        @update:modelValue="modified = true"
        :maxDate="maxDate"
      />
    </ion-row>
  </ion-content>
</template>

<style scoped>
.date-buttons-container {
  width: calc(100% - var(--ion-padding) * 2.5);
  gap: var(--ion-padding);
}
.date-buttons {
  --border-color: var(--ion-border-color);
  --color: var(--ion-color-primary);
  flex: 1;
}
ion-toggle {
  --background-checked: var(--ion-color-secondary);
  --background: var(--ion-color-primary-shade);
  width: 40px;
  height: 20px;
  --handle-width: 16px;
  --handle-height: 16px;
}
.vc-container {
  border: 0;
}
.buttons-done-cancel {
  margin-right: var(--ion-padding);
}
</style>
<style>
.vc-day {
  margin-top: 8px;
}
.vc-time-picker {
  justify-content: center;
}
</style>
