<template>
  <div>
    <colored-chip
      v-if="!modelValue && addBtn && !edit"
      @click="edition"
      icon="add"
      material
      faded
      :label="addBtn"
    />
    <div class="text-input" v-else>
      <div v-if="addBtn || icon" class="text-input-label">
        <font-icon
          v-if="icon"
          class="text-input-icon"
          color="var(--ion-color-primary-shade)"
          size="0.875"
          :name="icon"
          material
        />
        <div v-if="addBtn">{{ addBtn }}</div>
      </div>
      <text-editor
        :readonly="readonly"
        @blur="sendCurrent"
        :class="{ big }"
        :placeholder="placeholder"
        :icon="icon"
        ref="editor"
        :modelValue="modelValue"
        @update:modelValue="updateModelValue"
        :onlyNumber="onlyNumber"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref, onMounted } from "vue";
import TextEditor from "@/components/tools/TextEditor.vue";
import ColoredChip from "@/components/ui/ColoredChip.vue";
import { onIonViewWillLeave } from "@ionic/vue";

export default defineComponent({
  name: "TextInput",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    addBtn: {
      type: String,
    },
    big: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onlyNumber: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  components: {
    TextEditor,
    ColoredChip,
  },
  setup(props, { emit }) {
    const edit = ref(false);
    const editor = ref();

    const current = ref();

    const edition = () => {
      edit.value = true;
      nextTick(() => editor.value.focus());
    };

    let updateTimer: any;
    const updateModelValue = (ev: any) => {
      current.value = ev;
      if (!props.modelValue || !props.modelValue.length) sendCurrent();
      if (updateTimer) clearTimeout(updateTimer);
      updateTimer = setTimeout(() => sendCurrent(), 200);
    };

    const sendCurrent = () => {
      emit("update:modelValue", current.value);
    };

    onMounted(() => {
      current.value = props.modelValue;
    });

    onIonViewWillLeave(sendCurrent);

    return {
      sendCurrent,
      edit,
      editor,
      edition,
      updateModelValue,
    };
  },
});
</script>

<style scoped>
.text-input {
  margin: 0;
  padding: 0;
}
.text-input-label {
  display: flex;
  align-items: center;
  gap: calc(var(--ion-padding) / 1.375);
  color: var(--ion-color-primary-shade);
  font-size: var(--font-size-m);
}
.text-input-label.button {
  padding: calc(var(--ion-padding) / 1.7125) var(--ion-padding);
  padding-left: calc(var(--ion-padding) / 1.5);
  border: 1px solid var(--ion-color-primary-shade);
  border-radius: 50px;
  width: max-content;
  margin-bottom: var(--ion-padding);
}
.text-input-label.button .label {
  margin-left: -5px;
}
.text-input-icon {
  margin-right: 3px;
}
</style>
