<script setup lang="ts">
import DOMPurify from "dompurify";
import { computed } from "vue";

const domPurifyParams = {
  USE_PROFILES: { html: true },
  ALLOWED_TAGS: [
    "b",
    "i",
    "u",
    "a",
    "ol",
    "ul",
    "li",
    "p",
    "strong",
    "em",
    "code",
    "pre",
    "blockquote",
    "q",
    "abbr",
    "cite",
    "sup",
    "sub",
    "strike",
    "br",
    "h1",
    "h2",
    "h3",
  ],
};

interface Props {
  tag: string;
  content: string;
}

const props = withDefaults(defineProps<Props>(), {
  tag: "span",
});

const transformedContent = computed(() =>
  DOMPurify.sanitize(props.content, domPurifyParams)
);

function forceExternalLinksToOpenInNewTab() {
  DOMPurify.addHook("afterSanitizeAttributes", function (element: Element) {
    if (!["a", "A"].includes(element.tagName)) {
      return;
    }
    const href = element.getAttribute("href");
    if (!href || href.startsWith("#")) {
      return;
    }
    element.setAttribute("target", "_blank");
  });
}

forceExternalLinksToOpenInNewTab();
</script>

<template>
  <Component :is="tag" v-html="transformedContent" />
</template>
