import { ActionType } from "@/classes/PerformanceTracker";
import { RecordCategory } from "@/classes/PerformanceTracker";
import tracker from "@/utils/tracker";
import { PushNotifications } from "@capacitor/push-notifications";
import { Device, DeviceInfo } from "@capacitor/device";
import storage from "./storage";
import { UserService } from "@/services/user";
import { useFabriqStore } from "@/store/fabriq";
import { useTeamsStore } from "@/store/teams";
import { useTicketsStore } from "@/store/tickets";
import { ticketPageAnimation } from "@/utils/animations";
import { useNotificationsStore } from "@/store/notifications";
import { Notification, NotificationType, AuthType } from "@/types";
import { App } from "@capacitor/app";
import { Badge } from "@capawesome/capacitor-badge";
import loader from "./loader";
import { useClientStore } from "@/store/client";
import { useConfigStore } from "@/store/config";

const getStore = (name?: string): any => {
  switch (name) {
    case "config":
      return useConfigStore();
    case "client":
      return useClientStore();
    case "teams":
      return useTeamsStore();
    case "tickets":
      return useTicketsStore();
    case "notifications":
      return useNotificationsStore();
    default:
      return useFabriqStore();
  }
};

export const isTicketNotification = (type: any) => {
  return String(type || "").startsWith("ticket_");
};

export const isTicketCommentNotification = (type: any) => {
  return (
    String(type || "").startsWith("ticket_comment_") ||
    String(type || "").startsWith("ticket_reaction_") ||
    String(type || "").startsWith("ticket_reply_") ||
    String(type || "").startsWith("ticket_mention_") ||
    String(type || "").startsWith("task_mention_")
  );
};

const gotoNotifications = async (router: any) => {
  router.navigate("/notifications", "forward", "push", ticketPageAnimation, {
    duration: 0,
  });
};

export const addListeners = async (router: any) => {
  App.addListener("appStateChange", async ({ isActive }) => {
    const fabriqStore = getStore();
    const configStore = getStore("config");
    const ticketsStore = getStore("tickets");
    if (!isActive) {
      ticketsStore.setOpenComments(false);
      ticketsStore.setFromPush(false);
      configStore.saveUserConfig();
      return;
    }
    const notificationsStore = getStore("notifications");
    if (!fabriqStore.connected) return;
    await notificationsStore.getCount();
    Badge.set({ count: notificationsStore.count });
  });
  await PushNotifications.addListener("registration", async (deviceId: any) => {
    const fabriqStore = getStore();
    const clientStore = getStore("client");
    const version = fabriqStore.version;
    const teamsStore = getStore("teams");
    const oldToken = await storage.get(`notificationsToken-${version}`);

    if (deviceId.value !== oldToken) {
      const device: DeviceInfo = await Device.getInfo();
      const user = fabriqStore.user?.id;
      const clientId = clientStore.client?.id;
      const teams = teamsStore.collection.map((t: any) => t.id);

      if (oldToken) {
        await UserService.removePushNotificationToken(oldToken);
      }

      await UserService.setPushNotificationToken(
        deviceId.value,
        device,
        user,
        teams,
        clientId,
        fabriqStore.token?.accessToken,
        fabriqStore.token?.type === AuthType.Future
      );
      await storage.set(`notificationsToken-${version}`, deviceId.value);
    }
  });

  await PushNotifications.addListener("registrationError", (err) => {
    console.error("Registration error: ", err.error);
  });

  await PushNotifications.addListener(
    "pushNotificationReceived",
    async (notification) => {
      if (
        notification?.data?.count !== undefined &&
        notification?.data?.count !== null &&
        !isNaN(notification?.data?.count)
      ) {
        Badge.set({ count: notification?.data?.count });
      }
      if (isTicketNotification(notification.data?.notification_type)) {
        const ticketsStore = getStore("tickets");
        const id = +notification?.data?.model_id;
        await ticketsStore.load(id);
      }
    }
  );

  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    async (action) => {
      const ticketsStore = getStore("tickets");
      if (isTicketNotification(action?.notification.data?.notification_type)) {
        await loader.show();
        const id = +action?.notification.data?.model_id;
        const ticket = await ticketsStore.load(id);
        if (ticket) {
          if (
            isTicketCommentNotification(
              action?.notification.data?.notification_type
            )
          ) {
            ticketsStore.setOpenComments(true);
          }
          ticketsStore.setFromPush(action.notification.data);
        }
      }
      tracker.tunnel(
        "notifications",
        "notifications page",
        RecordCategory.Notifications,
        ActionType.Process,
        "push"
      );
      tracker.begin(
        "push",
        "Push to notifications",
        RecordCategory.General,
        ActionType.Process
      );
      gotoNotifications(router);
    }
  );
};

export const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();
  let badgePermStatus = await Badge.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }
  if (badgePermStatus.display === "prompt") {
    badgePermStatus = await Badge.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  await PushNotifications.register();
};

export const getRelevantNotifications = (
  notifications: any,
  setBadge = false
) => {
  const teamsStore = getStore("teams");
  const clientStore = getStore("client");
  const notifs: Array<any> = [];
  notifications.forEach((n: Notification) => {
    if (!n.team_ids?.length) return;
    if (!NotificationType.includes(n.type)) return;
    const teamId = n.team_ids[0];
    const team = teamsStore.collection.find((t: any) => t.id === teamId);
    if (!team) return;
    const actorUser = clientStore.users.find((u: any) => u.id === n.actor);
    if (!actorUser) return;
    notifs.push({ ...n, team, actorUser });
  });
  if (setBadge) {
    const count = notifs.reduce((acc: number, n: Notification) => {
      if (!n.is_read) return acc + 1;
      return acc;
    }, 0);
    Badge.set({ count });
  }
  return notifs;
};

export const log = async (message: any) => {
  try {
    console.log("LOGGING response", message);
  } catch (e) {
    console.error("CANT LOG ERROR", e);
  }
};
