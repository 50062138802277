import { createListStore } from "./list";
import { TemplateService } from "@/services/templates";

export const useTemplatesStore = createListStore(
  "templates",
  TemplateService,
  null,
  null,
  null,
  true
);
