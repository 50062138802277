<template>
  <div class="horizontal-scroller hide-scrollbar"><slot></slot></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HorizontalScroller",
  props: {},
  components: {},
  setup() {
    return {};
  },
});
</script>

<style>
.horizontal-scroller {
  --spacer: 1rem;
  display: grid;
  gap: var(--spacer);
  grid-auto-flow: column;
  grid-auto-columns: auto;

  overflow-x: auto;
  overscroll-behavior-inline: contain;

  scroll-snap-type: inline mandatory;
  scroll-padding-inline: calc(var(--spacer) * 0.2);
}

@media (min-width: 500px) {
  .horizontal-scroller {
    grid-auto-columns: 45%;
  }
}

@media (min-width: 700px) {
  .horizontal-scroller {
    grid-auto-columns: 35%;
  }
}

@media (min-width: 800px) {
  .horizontal-scroller {
    grid-auto-columns: 30%;
  }
}

@media (min-width: 900px) {
  .horizontal-scroller {
    grid-auto-columns: 35%;
  }
}

@media (min-width: 1000px) {
  .horizontal-scroller {
    grid-auto-columns: 30%;
  }
}

@media (min-width: 1200px) {
  .horizontal-scroller {
    grid-auto-columns: 25%;
  }
}

@media (min-width: 1400px) {
  .horizontal-scroller {
    grid-auto-columns: 15%;
  }
}

.horizontal-scroller > * {
  scroll-snap-align: start;
}
</style>
