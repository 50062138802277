<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar edit-modal-toolbar">
      <div slot="start">
        <ion-button fill="clear" @click="$emit('done', null)">
          {{ t("common.clearDate") }}
        </ion-button>
      </div>
      <ion-title></ion-title>
      <ion-buttons slot="end" class="buttons-done-cancel">
        <ion-button
          v-if="modified"
          fill="clear"
          class="cancel-btn"
          @click="$emit('cancel')"
        >
          {{ t("common.done") }}
        </ion-button>
        <ion-button
          v-else
          fill="clear"
          class="cancel-btn"
          @click="$emit('cancel')"
        >
          {{ t("common.cancel") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="normal">
    <ion-row class="ion-justify-content-center ion-align-items-start">
      <v-date-picker
        :is-dark="isDark"
        is-range
        is-expanded
        v-if="isRange"
        ref="rangePicker"
        v-model="range"
        @update:modelValue="rangeIsDone"
      />
      <v-date-picker
        :is-dark="isDark"
        is-expanded
        v-else
        v-model="model"
        @update:modelValue="done"
      />
    </ion-row>
    <ion-row
      @click="changeIsRange(!isRange)"
      class="ion-margin ion-justify-content-between ion-align-items-center"
    >
      <div class="big-button">{{ t("dates.endDate") }}</div>
      <ion-toggle
        size="small"
        class="toggle-btn"
        :checked="isRange"
        color="secondary"
      />
    </ion-row>
    <ion-row
      class="ion-margin date-buttons-container ion-justify-content-between ion-align-items-center"
    >
      <ion-button class="date-buttons" fill="outline" @click="setDate(today)">
        {{ t("datetime.today") }}
      </ion-button>
      <ion-button
        class="date-buttons"
        fill="outline"
        @click="setDate(tomorrow)"
      >
        {{ t("datetime.tomorrow") }}
      </ion-button>
      <ion-button class="date-buttons" fill="outline" @click="setDate(monday)">
        {{ t("datetime.monday") }}
      </ion-button>
    </ion-row>
  </ion-content>
</template>

<script lang="ts">
import { computed, defineComponent, Ref, ref } from "vue";
import {
  IonContent,
  IonRow,
  IonHeader,
  IonToolbar,
  IonTitle,
  // IonDatetime,
  IonButton,
  IonButtons,
  IonToggle,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { format, parseISO, addDays, addBusinessDays } from "date-fns";
import { useFabriqStore } from "@/store/fabriq";
import { getNextMonday } from "@/utils/dates";

export default defineComponent({
  name: "DatePickerModal",
  props: {
    date: {
      type: String,
      default: format(new Date(), "yyyy-MM-dd"),
    },
    end: {
      type: String,
      default: null,
    },
  },
  components: {
    IonContent,
    IonRow,
    IonHeader,
    IonToolbar,
    IonTitle,
    // IonDatetime,
    IonButton,
    IonButtons,
    IonToggle,
  },
  emits: ["done", "cancel"],
  setup(props, { emit }) {
    const { locale, theme, colorScheme } = useFabriqStore();
    const today = new Date();
    const datetime = ref();
    const rangePicker = ref();
    const tomorrow = addDays(today, 1);
    const monday = parseISO(getNextMonday());
    const model: Ref<Date | null> = ref(parseISO(props.date));
    const range: Ref<{ start: Date | null; end: Date | null }> = ref({
      start: parseISO(props.date),
      end: props.end
        ? parseISO(props.end)
        : addBusinessDays(parseISO(props.date), 1),
    });
    const setStart = ref(true);
    const modified = ref(false);
    const forceRange = ref(props.end?.length > 0);

    const done = (ev: any) => {
      if (!isRange.value) {
        emit("done", {
          start: format(ev, "yyyy-MM-dd"),
          end: null,
        });
      } else {
        emit("done", {
          start: format(ev.start, "yyyy-MM-dd"),
          end: format(ev.end, "yyyy-MM-dd"),
        });
      }
    };

    const rangeIsDone = () => {
      if (range.value?.start && range.value?.end && setStart.value) {
        done(range.value);
      }
    };

    const setDate = (date: Date) => {
      if (!isRange.value) return done(date);
      if (setStart.value) {
        range.value = { start: date, end: date };
        setStart.value = false;
      } else {
        range.value.end = date;
        if (range.value.start && range.value.end < range.value.start) {
          range.value = { start: date, end: range.value.start };
        }
        done(range.value);
      }
      rangePicker.value.forceUpdateValue(range.value);
    };

    const isDark = computed(() => {
      if (!theme) return colorScheme === "dark";
      return theme === "dark";
    });

    const isRange = computed(() => forceRange.value);

    const changeIsRange = (wantRange: boolean) => {
      forceRange.value = wantRange;
      range.value = { start: null, end: null };
      model.value = null;
    };

    return {
      modified,
      model,
      locale,
      datetime,
      today,
      tomorrow,
      monday,
      done,
      rangeIsDone,
      setDate,
      rangePicker,
      isDark,
      isRange,
      range,
      changeIsRange,
      ...useI18n(),
    };
  },
});
</script>

<style scoped>
.date-buttons-container {
  width: calc(100% - var(--ion-padding) * 2.5);
  gap: var(--ion-padding);
}
.date-buttons {
  --border-color: var(--ion-border-color);
  --color: var(--ion-color-primary);
  flex: 1;
}
ion-toggle {
  --background-checked: var(--ion-color-secondary);
  --background: var(--ion-color-primary-shade);
  width: 40px;
  height: 20px;
  --handle-width: 16px;
  --handle-height: 16px;
}
.vc-container {
  border: 0;
}
.buttons-done-cancel {
  margin-right: var(--ion-padding);
}
</style>
<style>
.vc-day {
  margin-top: 8px;
}
</style>
