<script lang="ts" setup>
import { computed, Ref, ref } from "vue";
import {
  Datapoint,
  ExecutionAnswer,
  Indicator,
  IndicatorRule,
  IndicatorUnitType,
  TemplateStep,
} from "@/types";
import { parseValue, unParseValue } from "@/utils/indicators";
import { IonButton } from "@ionic/vue";

type TimeSeriesType = "value" | "target";

const props = defineProps<{
  step: TemplateStep;
  indicator:
    | Indicator
    | (Indicator & {
        rules: IndicatorRule;
      });
  datapoint: Datapoint;
  answer: ExecutionAnswer;
  rules?: IndicatorRule[];
  disabled: boolean;
}>();

const emit = defineEmits<{
  (event: TimeSeriesType, value: string | number | null): void;
  (event: "target_met", value: boolean | null): void;
}>();

const isTargetMet: Ref<null | boolean | undefined> = ref();

const isAutomatic = computed(() => {
  if (!props.indicator.config.rules_active) return false;
  if (!props.rules?.length) return false;
  const nb = props.rules.reduce(
    (acc: number, r: any) => acc + r.conditions.length || 0,
    0
  );
  return nb > 0;
});

const hasOnlyConstantRules = computed(() => {
  if (!props.rules?.length) return false;
  return props.rules.every((r: any) =>
    r.conditions.every((c: any) => c.type === "constant")
  );
});

const processInput = (e: Event, inputType: TimeSeriesType) => {
  const targetElement = e.target as HTMLInputElement;
  const val = targetElement.value;
  const decimals =
    props.indicator?.unit_type === "time" ? 3 : props.indicator?.decimals;
  const regExp = new RegExp(
    !decimals ? "^[0-9]*$" : `^[0-9]*([,|.][0-9]{0,${decimals}})?$`
  );
  if (regExp.test(val)) {
    if (val.endsWith(",") || val.endsWith(".")) return;
    emit(
      inputType,
      "" + unParseValue(val, props.indicator.unit_type, props.indicator.config)
    );
  } else {
    targetElement.value =
      inputType === "value"
        ? `${answerValue.value || ""}`
        : `${answerTarget.value || ""}`;
  }
};

const setTargetMet = (value: boolean | null) => {
  isTargetMet.value = value;
  emit("target_met", value);
};

const answerValue = computed(() =>
  parseValue(props.answer.value, props.indicator)
);

const answerTarget = computed(() =>
  parseValue(props.answer?.detailed_value?.target, props.indicator)
);

const formatStep = computed(() =>
  String(1 / Math.pow(10, props.indicator.decimals))
);

const suffix = computed(() => {
  if (props.indicator.unit_type !== IndicatorUnitType.Time) return null;
  switch (props.indicator.config?.default_time) {
    case "seconds": {
      return "s";
    }
    case "minutes": {
      return "min";
    }
    case "hours":
    default: {
      return "h";
    }
  }
});
</script>

<template>
  <div>
    <div class="time-series-form-group">
      <div class="time-series-form">
        <label class="time-series-label">{{ $t("indicatorPage.value") }}</label>
        <div class="time-series-input-container">
          <input
            :disabled="disabled"
            class="time-series-input"
            autofocus
            type="text"
            :value="answerValue"
            @input="processInput($event, 'value')"
            :placeholder="`${$t('indicatorPage.valuePlaceholder')} ${
              props.indicator.unit_type === 'percentage'
                ? `(${$t('indicatorPage.percentage').toLocaleLowerCase()})`
                : '' // Which kind of unit do we have? To set a new function with it.
            }`"
          />
          <span v-if="suffix">{{ suffix }}</span>
        </div>
      </div>
      <div
        class="time-series-form"
        v-if="!isAutomatic || (isAutomatic && !hasOnlyConstantRules)"
      >
        <label class="time-series-label">
          {{ $t("indicatorPage.target") }}
        </label>
        <input
          class="time-series-input"
          type="number"
          :value="answerTarget"
          @input="processInput($event, 'target')"
          :step="formatStep"
          :placeholder="$t('indicatorPage.targetPlaceholder')"
          :disabled="disabled"
        />
      </div>
      <div class="time-series-form" v-if="isAutomatic">
        <label class="time-series-label">
          {{ $t("indicatorPage.reached") }}
        </label>
        <colored-chip
          v-if="answer.value"
          material
          :icon="answer.detailed_value?.target_met ? 'done' : 'close'"
          :label="answer.detailed_value?.target_met ? 'OK' : 'NOK'"
          :class="
            answer.detailed_value?.target_met
              ? 'time-series-target-met'
              : 'time-series-target-not-met'
          "
        />
      </div>
    </div>
    <div class="time-series-reach" v-if="!isAutomatic">
      <p class="time-series-label">{{ $t("indicatorPage.reached") }}</p>
      <div class="time-series-btn-group">
        <ion-button
          class="time-series-btn"
          expand="full"
          fill="clear"
          :class="{
            'time-series-btn--reached': answer.detailed_value?.target_met,
          }"
          @click="setTargetMet(true)"
        >
          <font-icon
            name="check"
            material
            :color="answer.detailed_value?.target_met ? 'green' : ''"
          />
          <span>{{ $t("OK") }}</span>
        </ion-button>
        <ion-button
          class="time-series-btn"
          expand="full"
          fill="clear"
          :class="{
            'time-series-btn--notreached':
              answer.detailed_value?.target_met === false,
          }"
          @click="setTargetMet(false)"
        >
          <font-icon
            name="close"
            material
            :color="answer.detailed_value?.target_met === false ? 'red' : ''"
          />
          <span>{{ $t("NOK") }}</span>
        </ion-button>
        <ion-button
          class="time-series-btn"
          :disabled="isAutomatic"
          expand="full"
          fill="clear"
          :class="{
            'time-series-btn--equals':
              answer.detailed_value?.target_met === null,
          }"
          @click="setTargetMet(null)"
        >
          <font-icon
            name="remove"
            material
            :color="
              answer.detailed_value?.target_met === null ? 'warning' : 'grey'
            "
          />
        </ion-button>
      </div>
    </div>
  </div>
</template>

<style>
.time-series-form-group {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.time-series-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  min-height: 32px;
}

.time-series-input {
  border: 1px solid var(--ion-border-color);
  border-radius: var(--f-border-radius);
  background-color: var(--ion-background-color-shade);
  height: 40px;
  width: 232px;
  max-width: 232px;
  padding-inline: 16px;
}

.time-series-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.time-series-input--disabled {
  color: var(--f-color-disabled);
}

.time-series-input::placeholder {
  text-align: left;
  font-size: var(--font-size-m);
}

.time-series-reach {
  display: flex;
  flex-direction: column;
}

.time-series-label {
  color: var(--f-color-discrete);
  font-size: var(--font-size-m);
}

.time-series-btn-group {
  display: flex;
  justify-content: stretch;
}

.time-series-btn {
  width: max-content;
  position: relative;
  border: 1px solid var(--ion-border-color);
  text-align: center;
  padding: var(--ion-padding);
  border-radius: var(--f-border-radius);
  margin: var(--ion-padding) 0;
  font-size: var(--font-size-m);
  font-weight: 500;
  min-width: 100px;
  flex-grow: 1;
}

.time-series-btn:first-child {
  margin-right: var(--ion-padding);
}

.time-series-btn:last-child {
  margin-left: var(--ion-padding);
}

.time-series-btn > span {
  margin-left: 8px;
}

.time-series-btn--reached {
  border-color: var(--f-color-success);
  color: var(--ion-color-success);
}

.time-series-btn--notreached {
  border-color: var(--f-color-danger);
  color: var(--ion-color-danger);
}

.time-series-btn--equals {
  border-color: var(--f-color-warning);
  color: var(--ion-color-warning);
}

.time-series-target-met {
  background-color: var(--ion-color-success) !important;
  margin-top: 0;
  color: var(--f-color-primary);
}

.time-series-target-not-met {
  background-color: var(--ion-color-danger) !important;
  margin-top: 0;
  color: var(--f-color-primary);
}
</style>
