<script setup lang="ts">
import { IonModal } from "@ionic/vue";
import { computed, ref } from "vue";
import { Field, User } from "@/types";
import UserAvatar from "@/components/business/UserAvatar.vue";
import UserPickerModalWithUsersInProps from "@/components/modals/UserPickerModalWithUsersInProps.vue";
import FieldPickerModalWithFieldsInProps from "@/components/modals/FieldPickerModalWithFieldsInProps.vue";
import ColoredChip from "@/components/ui/ColoredChip.vue";

interface Props {
  label: string;
  icon: string;
  type: "auditee" | "equipment" | "zone" | "product";
  selectedValues: number[];
  allowedUsers?: User[];
  allowedFields?: Field[];
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: "updateSelectedValues", payload: number[]): void;
}>();

const editModalOpened = ref(false);

function openEditModal() {
  editModalOpened.value = true;
}

const auditee = computed(() => {
  if (props.selectedValues.length === 0) return null;
  return (
    props.allowedUsers?.find((user) => user.id === props.selectedValues[0]) ||
    null
  );
});

const auditeeName = computed(() => {
  if (!auditee.value) return null;
  return `${auditee.value.first_name} ${auditee.value.last_name}`;
});

function updateAuditee(user: User | null) {
  if (user === null) emit("updateSelectedValues", []);
  else emit("updateSelectedValues", [user.id]);
  editModalOpened.value = false;
}

function updateFields(values: number[] | null) {
  if (values === null) emit("updateSelectedValues", []);
  else emit("updateSelectedValues", values);
}
</script>

<template>
  <div class="clickable container" @click.stop="openEditModal">
    <div class="field">
      <div class="field-label">
        <font-icon
          :name="icon"
          size="0.875"
          color="var(--ion-color-primary-shade)"
          material
        />
        <div>{{ label }}</div>
      </div>
      <div class="field-values">
        <div v-if="selectedValues.length === 0" class="field-empty">
          {{ $t("empty") }}
        </div>
        <div v-else-if="type === 'auditee'" class="field">
          <user-avatar
            :user="auditee"
            :name="auditeeName"
            clickable
            @click="openEditModal"
          />
        </div>
        <div v-else>
          <colored-chip
            v-for="value in selectedValues"
            :label="
              allowedFields?.find((field) => parseInt(field.id) === value)
                ?.name || ''
            "
            :key="value"
          />
        </div>
      </div>
    </div>
  </div>

  <ion-modal
    :is-open="editModalOpened"
    mode="ios"
    :breakpoints="[0, 1]"
    :initialBreakpoint="1"
    @didDismiss="editModalOpened = false"
  >
    <UserPickerModalWithUsersInProps
      v-if="type === 'auditee'"
      :users="allowedUsers"
      :selectedUserId="auditee?.id || null"
      @cancel="editModalOpened = false"
      @done="updateAuditee"
    />
    <FieldPickerModalWithFieldsInProps
      v-else
      :allowedValues="allowedFields || []"
      :selectedValues="selectedValues"
      :label="$t(type)"
      :placeholder="$t(`tickets.placeholders.${type}`)"
      :chooseAFieldLabel="$t(`tickets.choices.${type}`)"
      @done="editModalOpened = false"
      @update="updateFields"
    />
  </ion-modal>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
}

.stack-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: var(--font-size-s);

  .no-gap {
    gap: 2px;
  }
}

.wrap {
  flex-wrap: wrap;
  max-width: 200px;
}
</style>
