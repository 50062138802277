<script lang="ts" setup>
import { ref, computed, Ref } from "vue";
import {
  commentParser,
  commentUnparser,
  cleanCommentHtml,
  normalizeString,
} from "@/utils/comment-parser";
import { IonItem, IonList, IonLabel } from "@ionic/vue";
import UserAvatar from "@/components/business/UserAvatar.vue";
import { useI18n } from "@/composables/useI18n";
import TextEditor from "@/components/tools/TextEditor.vue";
import CommentFile from "./CommentFile.vue";
import type {
  User,
  Ticket,
  Task,
  ExecutionAnswer,
  Team,
  FabriqFile,
} from "@/types";
import { useAddFileItem } from "@/composables/useAddFileItem";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { formatISO } from "date-fns";

const props = defineProps<{
  users: User[];
  tickets: Ticket[];
  tasks: Task[];
  teams: Team[];
  files: FabriqFile[];
  answers: ExecutionAnswer[];
  reply: boolean;
  edit?: boolean;
  to: string | null;
}>();

const emit = defineEmits<{
  (event: "save", payload: string): void;
  (event: "draft", payload: string): void;
  (event: "cancel"): void;
  (event: "add-file", payload: FabriqFile): void;
  (event: "remove-file", payload: FabriqFile): void;
}>();

const editor = ref();
const content = ref("");
const html = ref("");

const onChange = (value: string) => {
  html.value = value;
  content.value = commentUnparser(value);
  emit("draft", cleanCommentHtml(value));
};

const clear = (keepFocus = false) => {
  html.value = "";
  content.value = "";
  newFiles.value = [];
  editor.value.clear(keepFocus);
};

const initialize = (value: string | null) => {
  if (!value?.length) {
    clear(true);
  } else {
    content.value = `${value} `;
    editor.value.addComplexHtml(commentParser(content.value));
  }
};

defineExpose({
  initialize,
  clear,
});

const hasFocus = ref(false);

const mentionSearchReg = /^.*(@[a-zA-Z0-9À-ÖØ-öø-ÿ]*).*$/i;
const openUsersList = computed(() => {
  return mentionSearchReg.test(
    content.value.replaceAll(/<span\sdata-[^>]*>[^<]*<\/span>&nbsp;/gi, "")
  );
});
const searchedUsers = computed(() => {
  const search = normalizeString(
    content.value.replace(mentionSearchReg, "$1").replace("@", "")
  );
  const users: Array<User> = [];
  const teamsUsers = (props.teams || []).reduce((acc: any, a: any) => {
    acc.push(...a.users);
    return acc;
  }, []);
  (props.users || []).forEach((u: User) => {
    if (props.teams?.length && !teamsUsers.includes(u.id)) return;
    if (!normalizeString(u.profile?.full_name).includes(search)) return;
    users.push(u);
  });
  return users;
});
const searchedTickets = computed(() => {
  const search = normalizeString(
    content.value.replace(mentionSearchReg, "$1").replace("@", "")
  );
  const tickets: Array<Ticket> = [];
  (props.tickets || []).forEach((t: Ticket) => {
    if (!normalizeString(t.title).includes(search)) return;
    tickets.push(t);
  });
  return tickets;
});
const searchedTasks = computed(() => {
  const search = normalizeString(
    content.value.replace(mentionSearchReg, "$1").replace("@", "")
  );
  const tasks: Array<Task> = [];
  (props.tasks || []).forEach((t: Task) => {
    if (!normalizeString(t.description).includes(search)) return;
    tasks.push(t);
  });
  return tasks;
});

const searchedAnswers = computed(() => {
  const search = normalizeString(
    content.value.replace(mentionSearchReg, "$1").replace("@", "")
  );
  const answers: Array<ExecutionAnswer> = [];
  (props.answers || []).forEach((a: ExecutionAnswer) => {
    if (!normalizeString(a.step?.question).includes(search)) return;
    answers.push(a);
  });
  return answers;
});

const addMention = (type: string, id: number) => {
  const search = content.value.replace(mentionSearchReg, "$1");
  content.value = content.value.replace(search, `{${type}Id: ${id}} `);
  editor.value.addComplexHtml(commentParser(content.value) + "&nbsp;");
};

const focusEditor = () => {
  editor.value.focus();
};

const cancel = () => {
  emit("cancel");
};

const saveComment = () => {
  if (!String(content.value || "").trim().length) return;
  const value = cleanCommentHtml(content.value);
  newFiles.value.forEach((image: FabriqFile) => {
    emit("add-file", image);
  });
  emit("save", value);
  clear();
  editor.value.focus();
};

const tunnel = (action: string) => {
  tracker.tunnel(
    "ticket",
    "ticket page",
    RecordCategory.Tickets,
    ActionType.Process,
    action + " | comment"
  );
};

const newFiles: Ref<FabriqFile[]> = ref([]);

const addImage = (image: any) => {
  const now = formatISO(new Date());
  newFiles.value.push({
    ...image,
    created_at: now,
    updated_at: now,
  });
};

const { addFileItem } = useAddFileItem(
  tunnel,
  addImage,
  "add | file | comment"
);

const removeExistingFile = async (file: FabriqFile) => {
  emit("remove-file", file);
};

const removeNewFile = (index: number) => {
  newFiles.value.splice(index, 1);
};

const { t } = useI18n();
</script>
<template>
  <div class="comment-form ion-items-align-center">
    <ion-list
      v-if="openUsersList"
      class="search-users-list shadow-inverted ion-margin-horizontal"
    >
      <ion-item
        v-for="user of searchedUsers"
        :key="user.id"
        lines="none"
        class="searched-item"
        @click="addMention('user', user.id)"
      >
        <user-avatar slot="start" :user="user" />
        <ion-label class="ion-margin-start font-size-m">
          {{ user.profile.full_name }}
        </ion-label>
      </ion-item>
      <ion-item
        v-for="ticket of searchedTickets"
        :key="ticket.id || ticket.uuid"
        lines="none"
        class="searched-item"
        @click="addMention('ticket', ticket.id)"
      >
        <font-icon
          slot="start"
          name="post_add"
          color="var(--ion-color-secondary)"
          material
          size="1"
        />
        <ion-label class="font-size-m">
          {{ ticket.title }}
        </ion-label>
      </ion-item>
      <ion-item
        v-for="task of searchedTasks"
        :key="task.id || task.uuid"
        lines="none"
        class="searched-item"
        @click="addMention('task', task.id)"
      >
        <font-icon
          slot="start"
          name="check_circle"
          style="margin-left: 4px"
          color="var(--f-color-discrete)"
          material
          outlined
          size="1"
        />
        <ion-label class="font-size-m">
          {{ task.description }}
        </ion-label>
      </ion-item>
      <ion-item
        v-for="answer of searchedAnswers"
        :key="answer.id || answer.uuid"
        lines="none"
        class="searched-item"
        @click="addMention('answer', answer.id)"
      >
        <font-icon
          slot="start"
          name="post_add"
          color="var(--ion-color-secondary)"
          material
          size="1"
        />
        <ion-label class="font-size-m">
          {{ answer.step.question }}
        </ion-label>
      </ion-item>
      <ion-item
        class="searched-item"
        lines="none"
        v-if="
          !searchedUsers.length &&
          !searchedTickets.length &&
          !searchedTasks.length &&
          !searchedAnswers.length
        "
      >
        <ion-label>{{ t("ticketModal.noMatches") }}</ion-label>
      </ion-item>
    </ion-list>
    <div class="comment-reply" v-if="reply">
      <div class="comment-reply-header">
        <font-icon
          name="reply_all"
          material
          outlined
          size="0.875"
          color="var(--ion-color-primary)"
        />
        <div class="comment-reply-title">
          {{ t("comments.reply") }}
        </div>
        <font-icon
          @click="cancel"
          class="clickable"
          name="close"
          size="0.875"
          material
          outlined
        />
      </div>
      <div class="comment-reply-to hint">{{ to }}</div>
    </div>
    <div class="comment-reply" v-if="edit">
      <div class="comment-reply-header">
        <font-icon
          name="edit"
          material
          color="var(--ion-color-primary)"
          size="0.875"
        />
        <div class="comment-reply-title">
          {{ t("comments.edit") }}
        </div>
        <font-icon
          @click="cancel"
          class="clickable"
          name="close"
          size="0.875"
          material
          outlined
        />
      </div>
    </div>
    <div class="editor-container">
      <!-- <user-avatar-input :user="user" /> -->
      <div class="text-editor-container">
        <div class="text-editor-row">
          <div class="add-file-btn clickable" @click="addFileItem">
            <font-icon name="add" material color="var(--f-color-discrete)" />
          </div>
          <text-editor
            class="comment-text-editor"
            ref="editor"
            tabindex="0"
            @click="hasFocus = true"
            @blur="hasFocus = false"
            :modelValue="html"
            @update:modelValue="onChange"
            :placeholder="t('tickets.placeholders.comment')"
          />
        </div>
        <div class="comment-files" v-if="files.length || newFiles.length">
          <comment-file
            v-for="file of files"
            :key="`file-${file.id}`"
            :file="file"
            :readonly="false"
            @remove="removeExistingFile(file)"
          />
          <comment-file
            v-for="(file, index) of newFiles"
            :key="`new-file-${index}`"
            :file="file"
            :readonly="false"
            @remove="removeNewFile(index)"
          />
        </div>
      </div>
      <div class="comment-editor-buttons">
        <div class="comment-button-spacer" @click="focusEditor" />
        <div
          class="send-comment-btn"
          :class="{ 'look-disabled': !html.length }"
        >
          <font-icon
            @click="saveComment"
            class="clickable"
            name="send"
            size="0.75"
            material
            color="white"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.replying {
  left: 0;
  right: 6px;
  text-align: right;
  padding: 3px;
  position: absolute;
  top: 30px;
}

.replying-icon {
  left: 0;
  position: absolute;
  top: 0;
}

.comment-form {
  padding: var(--ion-padding);
  position: relative;
  z-index: 2;
  overflow: visible;
  display: flex;
  flex-direction: column;
  gap: var(--ion-padding);
  border-top: 1px solid var(--ion-border-color);
  padding-left: 18px;
}

.search-users-list {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transform: translateY(calc((100% + 1px) * -1));
  background-color: var(--ion-background-color);
  border-radius: var(--f-border-radius) var(--f-border-radius) 0 0;
  border: 1px solid var(--ion-border-color);
  border-bottom: 0;
  max-height: 200px;
  overflow: auto;
  overflow-y: scroll;
  z-index: 3;
}

.search-users-list ion-label {
  color: var(--f-color-primary);
  font-size: var(--font-size-m);
}

.text-editor-container {
  border: 1px solid var(--ion-border-color);
  border-radius: var(--f-border-radius);
  position: relative;
  padding: var(--ion-padding);
  flex: 1;
}

.text-editor-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: var(--ion-padding);
}

.editor-container {
  display: flex;
  align-items: flex-end;
}

.editor-container .user-avatar-input {
  padding-top: calc(var(--ion-padding));
}

.text-editor {
  margin: 0;
}

.comment-reply {
  font-size: var(--font-size-s);
}

.comment-reply-header {
  display: flex;
  align-items: center;
  gap: var(--ion-padding);
}

.comment-reply-title {
  flex: 1;
}

.comment-reply-to {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.comment-reply-to.hint {
  padding: calc(var(--ion-padding) / 2) 0 0;
}

.comment-editor-buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.font-size-m {
  font-size: var(--font-size-m);
}

.searched-item {
  --ion-item-background: var(--ion-background-color);
}

.undercover {
  opacity: 0;
}

.add-file-btn {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-file-btn::before {
  content: "";
  position: absolute;
  background-color: var(--f-color-discrete);
  opacity: 0.2;
  width: 100%;
  height: 100%;
  border-radius: 80px;
}
.add-file-btn > .font-icon {
  opacity: 1;
}

.comment-button-spacer {
  flex: 1;
}

.send-comment-btn {
  margin-left: var(--ion-padding);
  background-color: var(--ion-color-secondary);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
</style>
<style>
.comment-text-editor.text-editor .text-editor-placeholder {
  padding: 0;
}
</style>
