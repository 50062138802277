import { Category, Field } from "./client";

export const documentFields = [
  "effectiveFrom",
  "expiresAt",
  "equipment",
  "product",
  "category",
  "zone",
] as const;
export type DocumentField = typeof documentFields[number];
// TODO: type when things get clearer
type DocumentContent = any[];

type DocumentStatus = "draft" | "published" | "circulated";

interface DocumentView {
  id: string;
  documentTypeId: string;
  title: string;
  identifier: string;
  createdAt: string;
  lastVersionAt: string;
  authorId: string;
  versionNumber: string | null;
  status: DocumentStatus;
  publishedAt: string | null;
  fields: {
    effectiveFrom?: string;
    expiresAt?: string;
    equipment?: number[];
    product?: number[];
    category?: number[];
    zone?: number[];
  };
  content: DocumentContent;
}

export interface DocumentViewWithDocumentType extends DocumentView {
  documentTypeName: string;
}

export interface DocumentViewReadyToDisplay
  extends Omit<DocumentViewWithDocumentType, "fields"> {
  fields: {
    effectiveFrom?: string;
    expiresAt?: string;
    equipment?: Field[];
    product?: Field[];
    category?: Category[];
    zone?: Field[];
  };
}
