import { type Ref, computed, ref, watch } from "vue";
import { EventsService } from "@/services/events.js";
import { FabriqFile, MediaType } from "@/types/ticket.js";
import { FabriqEvent, FabriqEventPayload } from "@/types/events.js";
import { toastController } from "@ionic/vue";
import { useI18n } from "./useI18n";

export function useEventFiles(event: Ref<FabriqEventPayload | FabriqEvent>) {
  const eventFiles: Ref<FabriqFile[]> = computed(() => [...event.value.files]);
  const files: Ref<FabriqFile[]> = ref([]);

  const { t } = useI18n();

  const filesWithLoadingFlag = computed(() => {
    return eventFiles.value.map((file) => {
      const loaded = files.value.find((f) => f.key === file.key);
      return {
        ...(loaded ?? file),
        loading: !loaded?._file,
      };
    });
  });

  const loadedFiles: Map<string, FabriqFile> = new Map();

  async function loadMissingFiles(filesList: FabriqFile[]) {
    const loadingFiles: FabriqFile[] = [];
    for (const file of filesList) {
      if (file.media_type === MediaType.Url) {
        loadingFiles.push(file);
        continue;
      }
      if (!loadedFiles.has(file.key!)) {
        const fileResponse = await EventsService.fetchEventFile(file.key!);
        if (fileResponse.outcome === "fetched") {
          const loaded = {
            ...file,
            _file: {
              url: fileResponse.url,
            },
          };
          loadingFiles.push(loaded);
          loadedFiles.set(file.key!, loaded);
        }
      } else {
        loadingFiles.push(loadedFiles.get(file.key!)!);
      }
    }
    files.value = loadingFiles;
  }

  let timer: any; // This timer is used to prevent multiple file loads when multiple files are added at once
  watch(
    eventFiles,
    async (filesList) => {
      if (timer) {
        clearTimeout(timer);
      }
      setTimeout(() => loadMissingFiles(filesList), 500);
    },
    { immediate: true, deep: true }
  );

  async function uploadEventFile(url: string) {
    const response = await EventsService.postEventFile(url);
    if (response.outcome === "uploaded") {
      return response.fileId;
    } else if (response.reason === "maximumFileSizeExeeded") {
      const toast = await toastController.create({
        color: "warning",
        message: t("files.fileTooBig", { size: "26Mbs" }),
        duration: 1000,
      });
      toast.present();
    }
  }

  return { files: filesWithLoadingFlag, uploadEventFile };
}
