<script setup lang="ts">
import { Category, Field } from "@/types";
import {
  DocumentField,
  documentFields,
  DocumentViewReadyToDisplay,
  DocumentViewWithDocumentType,
} from "@/types/knowledge";
import { localize } from "@/utils/localize";
import { useFabriqStore } from "@/store/fabriq";
import { useClientStore } from "@/store/client";
import { computed } from "vue";

const props = defineProps<{ document: DocumentViewWithDocumentType }>();

const fabriqStore = useFabriqStore();
const clientStore = useClientStore();

const allCategoriesById = (function buildAllCategoriesById() {
  const res: Record<number, Category> = {};
  for (const category of clientStore.categories) {
    res[category.id] = category;
  }
  return res;
})();

const allFieldsById = (function buildAllFieldsById() {
  const allFields = [
    ...clientStore.groups.flatMap((group) => group.extra_fields),
    ...clientStore.extra_fields,
  ];
  const res: Record<number, Field> = {};
  for (const field of allFields) {
    res[field.id] = field;
  }
  return res;
})();

function isFieldTypeProperField(couple: {
  fieldName: DocumentField;
  fieldValue: string | number[];
}): couple is {
  fieldName: Exclude<DocumentField, "effectiveFrom" | "expiresAt">;
  fieldValue: number[];
} {
  return Array.isArray(couple.fieldValue);
}

function getDocumentFieldsValues(documentValue: DocumentViewWithDocumentType) {
  const properFields: DocumentViewReadyToDisplay["fields"] = {};

  for (const fieldName of documentFields) {
    const fieldValue = documentValue.fields[fieldName];
    if (!fieldValue) {
      continue;
    }
    const couple = {
      fieldName,
      fieldValue,
    };
    if (isFieldTypeProperField(couple)) {
      const { fieldName: name, fieldValue: value } = couple;
      if (name === "category") {
        properFields[name] = value
          .map((fieldValue) => {
            return allCategoriesById[fieldValue];
          })
          .filter((field) => field);
      } else {
        properFields[name] = value
          .map((fieldValue) => {
            return allFieldsById[fieldValue];
          })
          .filter((field) => field);
      }
    } else {
      properFields[fieldName as "effectiveFrom" | "expiresAt"] =
        fieldValue as string;
    }
  }
  return properFields;
}

const properDocument = computed(() => {
  return {
    ...props.document,
    fields: getDocumentFieldsValues(props.document),
  };
});

function getTranslation(field: Field | Category) {
  return localize(field, fabriqStore.locale, 0);
}
</script>

<template>
  <div class="document-header">
    <h1>
      {{ properDocument.title }}
    </h1>
    <div class="document-properties">
      <div
        v-for="field in properDocument.fields.category"
        class="document-property-chip"
        :key="field.id"
        variant="outlined"
      >
        <div class="chip-content">
          <font-icon
            name="category"
            size="0.875"
            color="var(--ion-color-primary-shade)"
            material
          />
          <span>{{ getTranslation(field) }}</span>
        </div>
      </div>
      <div
        v-for="field in properDocument.fields.equipment"
        class="document-property-chip"
        :key="field.id"
        variant="outlined"
      >
        <div class="chip-content">
          <font-icon
            name="inventory"
            size="0.875"
            color="var(--ion-color-primary-shade)"
            material
          />
          <span>{{ getTranslation(field) }}</span>
        </div>
      </div>
      <div
        v-for="field in properDocument.fields.product"
        class="document-property-chip"
        :key="field.id"
        variant="outlined"
      >
        <div class="chip-content">
          <font-icon
            name="precision_manufacturing"
            size="0.875"
            color="var(--ion-color-primary-shade)"
            material
          />
          <span>{{ getTranslation(field) }}</span>
        </div>
      </div>
      <div
        v-for="field in properDocument.fields.zone"
        class="document-property-chip"
        :key="field.id"
        variant="outlined"
      >
        <div class="chip-content">
          <font-icon
            name="location_on"
            size="0.875"
            color="var(--ion-color-primary-shade)"
            material
          />
          <span>{{ getTranslation(field) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.document-header {
  width: calc(100% - 32px);
  margin-inline: auto;

  display: flex;
  flex-direction: column;
  gap: 12px;

  h1 {
    font-size: 24px;
    font-weight: bold;
  }

  .document-properties {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    .document-property-chip {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;
      gap: 8px;

      .chip-content {
        display: flex;
        align-items: center;
        padding: 8px 10px;
        border: 1px solid #e4e7ec;
        border-radius: 20px;
        gap: 5px;
      }
    }
  }
}
</style>
