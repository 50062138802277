<template>
  <ion-list class="routine-list" v-if="favoritesOnly">
    <loading-block v-if="!ordered.length && loading && !hideLoader" />
    <template v-else>
      <routine-item
        v-for="routine of ordered"
        :favorites="favorites"
        :key="routine.id"
        :routine="routine"
        :users="users"
        @goto="$emit('goto', $event)"
        @favorite="$emit('favorite', $event)"
      />
    </template>
    <div v-if="!ordered.length && !loading" class="routines-empty">
      <div class="icon">⏰</div>
      <div>{{ t("routines.empty.favorites") }}</div>
      <div class="hint">{{ t("routines.empty.favoritesHint") }}</div>
    </div>
  </ion-list>
  <div v-else>
    <loading-block
      v-if="loading && !hideLoader"
      class="routines-loading-spinner"
    />
    <template v-else>
      <accordion-item v-for="team of ordered" :key="team.id" :label="team.name">
        <ion-list class="routine-list">
          <routine-item
            v-for="routine of team.routines"
            :favorites="favorites"
            :key="routine.id"
            :routine="routine"
            :users="users"
            @goto="$emit('goto', $event)"
            @favorite="$emit('favorite', $event)"
          />
        </ion-list>
      </accordion-item>
    </template>
    <div v-if="noRoutine && !loading" class="routines-empty">
      <div class="icon">⏰</div>
      <div>{{ t("routines.empty.routines") }}</div>
      <div class="hint">{{ t("routines.empty.routinesHint") }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { IonList } from "@ionic/vue";
import {
  defineComponent,
  computed,
  ref,
  PropType,
  watch,
  onMounted,
} from "vue";
import RoutineItem from "@/components/business/routines/RoutineItem.vue";
import LoadingBlock from "@/components/ui/LoadingBlock.vue";
import { Routine, User, Team } from "@/types";
import AccordionItem from "@/components/ui/AccordionItem.vue";
import { useI18n } from "@/composables/useI18n";
import { storeToRefs } from "pinia";
import { useRoutinesStore } from "@/store/routines";

export default defineComponent({
  name: "RoutineList",
  components: {
    IonList,
    RoutineItem,
    AccordionItem,
    LoadingBlock,
  },
  emits: ["favorite", "goto"],
  props: {
    routines: {
      type: Array as PropType<Array<Routine>>,
      default: () => [],
    },
    users: {
      type: Array as PropType<Array<User>>,
      default: () => [],
    },
    teams: {
      type: Array as PropType<Array<Team>>,
      default: () => [],
    },
    favorites: {
      type: Array as PropType<Array<number>>,
      default: () => [],
    },
    favoritesOnly: {
      type: Boolean,
      default: false,
    },
    routineFilter: {
      type: String,
      default: "",
    },
    hideLoader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const routinesStore = useRoutinesStore();

    const { loading } = storeToRefs(routinesStore);

    const noRoutine = ref(true);

    const ordered = computed(() => {
      const routines = props.routines || [];
      if (props.favoritesOnly)
        return routines.filter(
          (r: Routine) => r.id && (props.favorites || []).includes(+r.id)
        );
      const teams: Array<Team & { routines: Array<Routine> }> = [];
      const filter = String(props.routineFilter || "").toLocaleLowerCase();
      props.teams.forEach((t: Team) => {
        const teamRoutines = routines.filter((r: Routine) => {
          if (!(r.teams || []).includes(t.id)) return false;
          if (!r.title.toLocaleLowerCase().includes(filter)) return false;
          return true;
        });
        if (!teamRoutines.length) return;
        noRoutine.value = false;
        teams.push({ ...t, routines: teamRoutines });
      });
      return teams;
    });

    const hideSearch = async (smooth = false) => {
      const content: HTMLIonContentElement | null = document.querySelector(
        ".routines-page ion-content"
      );
      if (!content) return;
      const threshold = 70;
      const searchInput: HTMLIonInputElement | null = document.querySelector(
        ".routines-page .search-input input"
      );
      if (document.activeElement === searchInput) return;
      if (props.routineFilter?.length) return;
      if (props.favoritesOnly) return content.scrollToTop();
      if (content?.scrollEl?.scrollTop < threshold) {
        searchInput?.blur();
        content?.scrollToPoint(0, threshold, smooth ? 200 : 0);
      }
    };

    watch(ordered, () => hideSearch());
    onMounted(() => hideSearch());

    return {
      ...useI18n(),
      noRoutine,
      loading,
      ordered,
    };
  },
});
</script>

<style scoped>
.routine-list {
  padding: 0 calc(var(--ion-padding) / 2) var(--ion-padding);
  background: var(--ion-background-color);
}
.search-input {
  margin: var(--ion-padding);
}

.routines-loading-spinner {
  margin-top: calc(var(--ion-padding) * 2 + 70px);
}
</style>
