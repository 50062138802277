export const resizeListContainer = (parent = "") => {
  const parentElement = document.querySelector(parent);
  if (!parentElement) return false;
  const content = document.querySelector(`${parent} .route-page-content`);
  if (!content) return false;
  const container = document.querySelector(`${parent} .list-container`);
  if (!container) return false;
  const toolbar = document.querySelector(`${parent} .route-page-toolbar`);
  if (!toolbar) return false;
  content.scrollIntoView({ block: "start" });
  const contentRect = content.getBoundingClientRect();
  const toolbarRect = toolbar.getBoundingClientRect();
  let height = contentRect.height - toolbarRect.height;
  const others = document.querySelectorAll(`${parent} .add-to-scroll`);
  others.forEach((el: any) => {
    const otherRect = el.getBoundingClientRect();
    height -= otherRect.height;
  });
  if (!height) return false;
  container.setAttribute(
    "style",
    `height: ${height}px; max-height: ${height}px`
  );
  return true;
};
