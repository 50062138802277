<script lang="ts" setup>
import { computed, ComputedRef, Ref, ref } from "vue";
import { arrowBackOutline } from "ionicons/icons";
import {
  IonContent,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import ColoredChip from "@/components/ui/ColoredChip.vue";
import LoadingBlock from "@/components/ui/LoadingBlock.vue";

import { useModalScroller } from "@/composables/useModalScroller";
import { useTeamsStore } from "@/store/teams";
import { Indicator, IndicatorType, Team } from "@/types";
import { useIndicatorsStore } from "@/store/indicators";
import { useClientStore } from "@/store/client";
import { useConfigStore } from "@/store/config";
interface IndicatorCategories extends Indicator {
  cats: any[];
}

const { t } = useI18n();
const teamsStore = useTeamsStore();
const indicatorsStore = useIndicatorsStore();
const clientStore = useClientStore();
const configStore = useConfigStore();
const teamFilter = ref("");
const indicatorFilter = ref("");
const team: Ref<Team | null> = ref(null);
const loading = ref(false);

const emit = defineEmits(["done", "cancel"]);

const teams = computed(() => {
  const filter = teamFilter.value.toLocaleLowerCase();
  const teams: Team[] = [];
  teamsStore.collection.forEach((t: Team) => {
    if (!t.name.toLocaleLowerCase().includes(filter)) return;
    teams.push(t);
  });
  teams.sort((a: Team, b: Team) => {
    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
  });
  return teams;
});

const indicators: ComputedRef<IndicatorCategories[]> = computed(() => {
  const filter = indicatorFilter.value.toLocaleLowerCase();
  const indicators: IndicatorCategories[] = [];
  const mine = configStore.config?.favoriteKpis || [];
  indicatorsStore.collection.forEach((i: Indicator) => {
    if (mine.includes(Number(i.id))) return;
    if (
      i.indicator_type !== IndicatorType.TimeSeries &&
      i.indicator_type !== IndicatorType.Status
    )
      return;
    if (team.value?.id === undefined) return;
    if (
      !i.teams?.ids?.includes(team.value.id) &&
      !i.teams?.admin_ids?.includes(team.value.id)
    )
      return;
    if (!i.name.toLocaleLowerCase().includes(filter)) return;
    const categories = (clientStore.client?.categories || []).filter((c: any) =>
      i.categories?.includes(c.id)
    );
    indicators.push({
      ...i,
      cats: categories,
    });
  });
  indicators.sort((a: IndicatorCategories, b: IndicatorCategories) => {
    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
  });
  return indicators;
});

const selectTeam = async (team_: Team | null) => {
  if (team_?.id === team.value?.id) return;
  team.value = team_;
  if (!team_) return;
  loading.value = true;
  await indicatorsStore.all({ team_id: team_.id });
  loading.value = false;
};
useModalScroller();
</script>

<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar add-modal-toolbar ion-padding-top">
      <ion-buttons v-if="team" slot="start">
        <ion-button @click="team = null" class="back-to-team">
          <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
          {{ t("common.back") }}
        </ion-button>
      </ion-buttons>
      <div class="modal-title-bar" v-else>
        <font-icon
          name="search"
          material
          size="1"
          class="search-icon"
          color="var(--ion-color-primary-shade)"
        />
        <input
          v-if="!team"
          autofocus
          class="modal-search-input"
          :placeholder="t('indicators.filterTeam')"
          v-model="teamFilter"
        />
        <font-icon
          name="cancel"
          v-if="teamFilter.length"
          @click="teamFilter = ''"
          material
          outlined
          size="1"
          color="var(--ion-color-primary-shade)"
        />
        <ion-buttons slot="end">
          <ion-button fill="clear" class="cancel-btn" @click="emit('cancel')">
            {{ t("common.cancel") }}
          </ion-button>
        </ion-buttons>
      </div>
    </ion-toolbar>
    <div class="list-title">
      {{ team?.name || t("indicators.selectTeam") }}
    </div>
  </ion-header>
  <ion-content :fullscreen="true" :scroll-y="false">
    <ion-list
      ref="list"
      class="scroller ion-padding-horizontal"
      lines="none"
      v-if="!team"
    >
      <recycle-scroller class="scroller" :items="teams" :item-size="44">
        <template #default="{ item }">
          <ion-item @click="selectTeam(item)">
            <colored-chip
              class="clickable"
              :label="item.name"
              :emoji="item?.config?.emoji"
              :icon="item?.config?.icon"
              :color="item?.config?.color"
              :material="true"
            />
          </ion-item>
        </template>
      </recycle-scroller>
    </ion-list>
    <template v-else>
      <loading-block v-if="loading" />
      <ion-list v-else class="scroller">
        <recycle-scroller class="scroller" :items="indicators" :item-size="66">
          <template #before v-if="indicators.length === 0">
            <div class="routines-empty">
              <div class="icon">📈</div>
              <!-- because the text is very long, I have to break the line + add an overall padding. -->
              <div style="white-space: pre-line" class="ion-padding">
                {{ t("indicators.noTeamIndicatorsDetails") }}
              </div>
            </div>
          </template>
          <template #default="{ item }">
            <ion-item @click="$emit('done', item)" class="indicator-item">
              <ion-label>
                {{ item.name }}
              </ion-label>
              <ion-label slot="end">
                <category-chip
                  v-for="category of item.cats"
                  :key="category.id"
                  :readonly="true"
                  :category="category"
                />
              </ion-label>
            </ion-item>
          </template>
        </recycle-scroller>
        <ion-item v-if="!indicators.length" lines="none">
          <ion-label>
            <div class="routines-empty">
              <div class="icon">📈</div>
              <div>{{ t("noTeamIndicators") }}</div>
            </div>
          </ion-label>
        </ion-item>
      </ion-list>
    </template>
  </ion-content>
</template>

<style scoped>
.add-modal-toolbar {
  height: 61px;
}

.modal-title-bar {
  padding: 0;
}

.list-title {
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary-shade);
}

.user-item {
  margin-bottom: var(--ion-padding);
}

.selected-values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--ion-padding) / 2);
}

.field-header {
  width: 100%;
  padding: calc(var(--ion-padding) / 2);
  margin: calc(var(--ion-padding) / 2 * -1);
  font-size: var(--font-size-s);
  border-bottom: 1px solid var(--ion-border-color);
}

.search-icon {
  margin-left: calc(var(--ion-padding) / 2);
  margin-right: calc(var(--ion-padding) * -0.75);
}

ion-button.back-to-team {
  --icon-font-size: 14px;
  font-size: 14px;
  color: var(--color-default);
  margin-top: 6px;
}

ion-button.back-to-team ion-icon {
  font-size: 14px;
}

.indicator-item::part(native) {
  height: 66px;
  border-bottom: 1px solid var(--ion-border-color);
  --inner-border-width: 0;
}
</style>
