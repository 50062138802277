<template>
  <ion-reorder-group
    class="task-list"
    :disabled="!reorder || ordered.length <= 1"
    @ionItemReorder="doReorder($event)"
  >
    <task-item
      class="task-list-item"
      v-for="task of ordered"
      :key="task.uuid"
      :task="task"
      :users="users"
      :readonly="readonly || right === 'auditor'"
      :right="right"
      @update="$emit('update', $event)"
      @edit="$emit('edit', task)"
      @delete="$emit('delete', task)"
    />
  </ion-reorder-group>
</template>

<script lang="ts">
import { IonReorderGroup } from "@ionic/vue";
import { defineComponent, computed, ref, PropType } from "vue";
import TaskItem from "@/components/business/tickets/TaskItem.vue";
import { useTasksStore } from "@/store/tasks";
import { useI18n } from "@/composables/useI18n";
import { formatISO } from "date-fns";
import { User, Task } from "@/types";

export default defineComponent({
  name: "TaskList",
  components: {
    IonReorderGroup,
    TaskItem,
  },
  emits: ["edit", "update"],
  props: {
    ticketId: {
      type: [Number, String],
      default: null,
    },
    tasks: {
      type: Array as PropType<Array<Task>>,
      default: () => [],
    },
    users: {
      type: Array as PropType<Array<User>>,
      default: () => [],
    },
    reorder: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    right: {
      type: String,
      default: "none",
    },
  },
  setup(props) {
    const tasksStore = useTasksStore();
    const newTask = ref("");
    const ordered = computed(() => {
      const tasks = props.tasks || [];
      tasks.sort((a: Task, b: Task) => {
        if (a.order === b.order)
          return String(a.updated_at || "").localeCompare(
            String(b.updated_at || "")
          );
        return a.order - b.order;
      });
      return tasks;
    });
    const doReorder = (ev: CustomEvent) => {
      const from: number = ev.detail.from;
      const to: number = ev.detail.to;

      const fromTask: any = ordered.value[from];

      tasksStore.update(fromTask.uuid, { order: to });

      ev.detail.complete();
    };

    const addTask = async () => {
      if (!newTask.value || !newTask.value.length) return;
      await tasksStore.add({
        description: newTask.value,
        ticket: props.ticketId,
        order: props.tasks.length,
        updated_at: formatISO(new Date()),
        created_at: formatISO(new Date()),
      });
      tasksStore.save();
      newTask.value = "";
    };

    return {
      ...useI18n(),
      newTask,
      addTask,
      doReorder,
      ordered,
    };
  },
});
</script>

<style scoped>
.task-list-item {
  margin: 0;
  border: 1px solid var(--ion-border-color);
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  --ion-item-background: var(--ion-color-primary-contrast);
  --task-padding-y: calc(var(--ion-padding) / 2);
}

.task-list-item:first-child {
  border-top-width: 0;
}

.task-list-item:last-child {
  border-bottom-width: 1px;
}
</style>
