<template>
  <div class="accordion-item">
    <div
      class="accordion-header"
      :class="{ disabled, shade }"
      @click="opened = !disabled && !opened && !fixed"
    >
      <div>{{ label }}</div>
      <font-icon
        v-if="!fixed"
        :name="opened ? 'expand_less' : 'expand_more'"
        color="var(--ion-color-primary-shade)"
        material
        size="1"
      />
    </div>
    <div
      class="accordion-content"
      :class="{
        hide: !opened || disabled,
        show: opened,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "AccordionItem",
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    shade: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const opened = ref(false);

    watch(
      () => props.disabled,
      (v) => {
        if (v) {
          opened.value = false;
        } else {
          opened.value = true;
        }
      },
      { immediate: true }
    );

    return {
      opened,
    };
  },
});
</script>

<style scoped>
.accordion-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--ion-color-primary-shade);
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding);
  padding-left: calc(var(--ion-padding) * 2);
  letter-spacing: 1px;
}
.accordion-header.disabled {
  pointer-events: none;
  opacity: 0.5;
}
ion-modal .accordion-header {
  background-color: var(--ion-color-primary-contrast);
}
ion-modal .accordion-header.shade {
  background-color: var(--ion-background-color-shade);
}
.accordion-content {
  --ion-item-background: var(--ion-color-primary-contrast);
  --f-border-radius: 5px;
  display: block;
  /* transition: all 150ms ease-in-out, opacity 0ms; */
}
.accordion-content::part(native) {
  --f-border-radius: 5px;
}
.accordion-content.hide {
  /* max-height: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top; */
  display: none;
  /* margin-bottom: calc(var(--ion-padding) * -1); */
}
/* .accordion-content.show {
  max-height: max-content;
  opacity: 1;
  transform: scaleY(1);
  transform-origin: top;
} */
</style>
