import { EventType } from "@/types";
import { createListStore } from "./list";
import { EventTypesService } from "@/services/eventTypes";
import { useEventsWithPropertiesStore } from "./eventsWithProperties";
import { storeToRefs } from "pinia";

const hooks = {
  async all(options: any, entities: Array<EventType>) {
    const eventsWithPropertiesStore = useEventsWithPropertiesStore();
    const { eventTypePropertiesMap } = storeToRefs(eventsWithPropertiesStore);

    entities.forEach(({ properties }) => {
      properties.forEach((property) => {
        eventTypePropertiesMap.value[property.id] = property;
      });
    });

    return entities;
  },
};

export const useEventTypesStore = createListStore(
  "eventTypes",
  EventTypesService,
  hooks,
  null,
  null,
  true
);
