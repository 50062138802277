<script lang="ts" setup>
import {
  BarcodeScanner,
  BarcodeFormat,
} from "@capacitor-mlkit/barcode-scanning";
import { onBeforeUnmount, onDeactivated, ref } from "vue";
import { toastController, useIonRouter } from "@ionic/vue";
import RoutePage from "@/components/ui/RoutePage.vue";
import { useI18n } from "@/composables/useI18n";
import FButton from "@/components/ui/FButton.vue";

import { BarcodeScannerUI } from "@/utils/barcode-scanner-ui";
import { validate as isUuid } from "uuid";
const router = useIonRouter();
let scanning = false;
const documentToOpen = ref<string | undefined>();
const { t } = useI18n();
const barcodeUIBuilder = new BarcodeScannerUI();

const stopScan = async () => {
  if (!scanning) return;
  scanning = false;
  document.body.style.display = "block";
  await BarcodeScanner.removeAllListeners();
  await BarcodeScanner.stopScan();
  barcodeUIBuilder.remove();
};

onDeactivated(() => {
  stopScan();
});

onBeforeUnmount(() => {
  stopScan();
});

const extractDocumentIdFromUrl = (documentUrl: string): string | undefined => {
  const url = new URL(documentUrl);
  if (!url.pathname.includes("/documents")) {
    return;
  }
  const documentId = url.searchParams.get("document");
  if (!documentId || !isUuid(documentId)) {
    return;
  }
  return documentId;
};
const openBarcodeScanner = async () => {
  let scanned = false;
  documentToOpen.value = undefined;
  const status = await BarcodeScanner.checkPermissions();
  if (!status.camera) {
    const { camera } = await BarcodeScanner.requestPermissions();
    if (!camera) return false;
  }

  scanning = true;

  try {
    document.body.style.display = "none";
    barcodeUIBuilder.make(stopScan, t("common.qrcode"));
    const listener = await BarcodeScanner.addListener(
      "barcodeScanned",
      async (result) => {
        if (scanned) {
          return;
        }
        scanned = true;

        await listener.remove();
        await stopScan();
        if (result.barcode) {
          const documentUrl = result.barcode.displayValue;
          const documentId = extractDocumentIdFromUrl(documentUrl);
          documentToOpen.value = documentId;
          if (!documentId) {
            const toast = await toastController.create({
              message: t("knowledgePage.notFound"),
              duration: 2000,
            });
            toast.present();
            return;
          }
          if (documentToOpen.value && documentToOpen.value === documentId) {
            router.push(`/documents/${documentId}`);
          }
        }
      }
    );

    await BarcodeScanner.startScan({
      formats: [BarcodeFormat.QrCode],
    });
  } catch (e) {
    console.error(e);
    scanned = false;
  }
};
</script>

<template>
  <route-page
    :title="t('knowledgePage.title', 2)"
    class="knowledge-page"
    fabIcon="qrcode"
    @fab="openBarcodeScanner"
    @refresh="(ev) => ev?.target?.complete()"
  >
    <ion-label>
      <div class="routines-empty">
        <div class="icon">📖</div>
        <div>
          {{ t("knowledgePage.descriptionHint") }}
        </div>
        <div class="hint document-text">
          {{ t("knowledgePage.descriptionText") }}
        </div>
      </div>

      <div class="search-button">
        <f-button
          selected
          @click.stop.prevent="
            () => {
              router.push('/search');
            }
          "
        >
          {{ t("knowledgePage.search") }}
        </f-button>
      </div>
    </ion-label>
  </route-page>
</template>

<style scoped lang="postcss">
.search-button {
  display: flex;
  justify-content: center;
  margin-top: calc(var(--ion-padding) * 2);
}

.document-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-m);
  margin-inline: var(--ion-padding);
}
</style>
