import tracker from "@/utils/tracker";
import { useFabriqStore } from "@/store/fabriq";
import { useTicketTemplatesStore } from "@/store/ticketTemplates";
import { TicketTemplate, TicketTemplateFieldType } from "@/types/ticket";
import { computed, ComputedRef, Ref, ref } from "vue";
import { useI18n } from "./useI18n";
import { isEmpty } from "lodash";
import { ActionType } from "@/classes/PerformanceTracker";
import { useClientStore } from "@/store/client";

const i18nKeys: any = {
  title: "newTicket.title",
  description: "ticketContent.description",
  owner: "owner",
  due_date: "dates.dueDate",
  teams: "newTicket.teams",
  files: "newTicket.attachments",
  labels: "newTicket.labels",
  categories: "categories",
  status: "status",
  cause: "cause",
};

export const useTicketTemplates = (
  ticket: Ref<any> | ComputedRef<any>,
  ticketFields: Ref<any> | ComputedRef<any>,
  ticketFiles: Ref<any> | ComputedRef<any>
) => {
  const { t } = useI18n();
  const nudgeIsShown = ref(false);
  const ticketTemplatesStore = useTicketTemplatesStore();
  const fabriqStore = useFabriqStore();
  const clientStore = useClientStore();
  const ticketTemplate = computed(() => {
    const ticketTemplateId =
      ticket.value?.ticket_template || ticket.value?.ticket_template_id;
    if (!ticketTemplateId) return null;
    const template = ticketTemplatesStore.collection.find(
      (t: TicketTemplate) => t.id === ticketTemplateId
    );
    return template || {};
  });

  const hideTasks = computed(() => {
    return ticketTemplate.value?.hideTasks;
  });
  const hideDependencies = computed(() => {
    return ticketTemplate.value?.hideDependencies;
  });
  const hideProcesses = computed(() => {
    return ticketTemplate.value?.hideProcesses;
  });
  const hideFiles = computed(() => {
    return ticketTemplate.value?.hideFiles;
  });
  const hideComments = computed(() => {
    return ticketTemplate.value?.hideFiles;
  });

  const getFieldValue = (label: string, type = "column") => {
    if (!ticket.value) return;
    if (type === "column") {
      if (label === "files") return ticketFiles.value?.length;
      return ticket.value[label];
    } else {
      if (!ticketFields.value) return;
      const field = ticketFields.value.find((f: any) => f.key === label);
      if (!field) return;
      return field.multiple ? field.value?.length : field.value;
    }
  };

  const templateFields = computed(() => {
    const fields: any[] = [];
    if (!ticketTemplate.value) return fields;
    const locale = fabriqStore.locale;
    (ticketTemplate.value.fields || []).forEach((f: any) => {
      const label = f.type === "field" ? f.field_type : f.label;
      const name = getTranslation(f, label, locale);
      const placeholder: any =
        (f.placeholder || {})[locale] || (f.placeholder || {}).en;
      const help = (f.help || {})[locale] || (f.help || {}).en;
      const value = getFieldValue(label, f.type);
      const nudge = f.required && (isNaN(value) ? isEmpty(value) : value <= 0);

      fields.push({
        ...f,
        label,
        name,
        placeholder,
        help,
        nudge,
        nudgeIsShown: nudgeIsShown.value,
      });
    });
    return fields;
  });
  const requiredFields = computed(() => {
    return templateFields.value.filter((f) => f.required);
  });
  const nbRequiredFields = computed(() => {
    return requiredFields.value.length;
  });
  const nbRequiredFieldsFilled = computed(() => {
    return requiredFields.value.filter((f) => {
      return !f.nudge;
    }).length;
  });

  const showNudges = () => {
    nudgeIsShown.value = true;
  };
  const hideNudges = () => {
    nudgeIsShown.value = false;
  };
  const getTranslation = (field: any, label: string, locale: string) => {
    const translation: string =
      (field.name || {})[locale] || (field.name || {}).en;
    if (translation) return translation;
    if (field.type === "field") {
      const labelReg = /^([^|]*)\s?\|\s?([^|]*)$/i;
      if (label.startsWith("custom_")) {
        const locale = fabriqStore.locale;
        const custom: any = clientStore.custom_fields[label];
        if (!custom) return;
        const labels = custom.i18n[locale] || "";
        const customLabel = custom.multiple
          ? labels.replace(labelReg, "$2").trim()
          : labels.replace(labelReg, "$1").trim();
        return customLabel;
      }
      return t(label);
    }
    if (!label) return "";
    return t(i18nKeys[label]);
  };
  const loadTicketTemplate = async () => {
    const ticketTemplateId =
      ticket.value?.ticket_template || ticket.value?.ticket_template_id;
    if (!ticketTemplateId) return;
    tracker.next("ticket", ActionType.Request, "load async ticket template");
    await ticketTemplatesStore.load(ticketTemplateId);
  };

  const getTemplateField = (label: string, type = "column") => {
    if (!templateFields.value?.length) return {};
    return templateFields.value.find(
      (f) => f.label === label && f.type === type
    );
  };

  const forbiddenFields = computed(() => {
    const forbidden: string[] = [];
    if (!ticketTemplate.value?.fields?.length) return forbidden;
    ticketTemplate.value?.fields.forEach((f: any) => {
      if (!f.forbidden) return;
      forbidden.push(
        `${f.type}-${
          f.type === TicketTemplateFieldType.Column ? f.label : f.field_type
        }`
      );
    });
    return forbidden;
  });

  const readOnlyFields = computed(() => {
    const readOnly: string[] = [];
    if (!ticketTemplate.value?.fields?.length) return readOnly;
    ticketTemplate.value?.fields.forEach((f: any) => {
      if (!f.readonly) return;
      readOnly.push(
        `${f.type}-${
          f.type === TicketTemplateFieldType.Column ? f.label : f.field_type
        }`
      );
    });
    return readOnly;
  });

  const fieldIsForbidden = (label: string, type = "column") => {
    return forbiddenFields.value.includes(`${type}-${label}`);
  };

  const fieldIsReadOnly = (label: string, type = "column") => {
    return readOnlyFields.value.includes(`${type}-${label}`);
  };

  const getFieldLabel = (
    defaultValue: string,
    label: string,
    type = "column"
  ) => {
    const field = getTemplateField(label, type);
    if (!field?.name?.length) return defaultValue;
    return field.name;
  };

  const getFieldPlaceholder = (
    defaultValue: string,
    label: string,
    type = "column"
  ) => {
    const field = getTemplateField(label, type);
    if (!field?.placeholder?.length) return defaultValue;
    return field.placeholder;
  };

  const fieldIsNudge = (label: string, type = "column") => {
    if (!nudgeIsShown.value) return false;
    const field = templateFields.value.find(
      (f: any) => f.label === label && f.type === type
    );
    if (!field) return false;
    return field.nudge;
  };

  return {
    nudgeIsShown,
    ticketTemplate,
    hideTasks,
    hideComments,
    hideDependencies,
    hideFiles,
    hideProcesses,
    templateFields,
    requiredFields,
    nbRequiredFields,
    nbRequiredFieldsFilled,
    showNudges,
    hideNudges,
    loadTicketTemplate,
    getTemplateField,
    getFieldLabel,
    getFieldPlaceholder,
    fieldIsNudge,
    fieldIsForbidden,
    fieldIsReadOnly,
  };
};
