<script lang="ts" setup>
import { IonProgressBar, IonRow } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";

const { t } = useI18n();

interface Props {
  update?: boolean;
  progress?: number | null;
}

withDefaults(defineProps<Props>(), {
  update: false,
  progress: null,
});
</script>

<template>
  <div class="loading-block" slot="fixed">
    <div class="fabriq-logo ion-margin-bottom"></div>
    <ion-row
      v-if="!update"
      class="ion-justify-content-center ion-align-items-center ion-margin-bottom ion-padding-bottom"
    >
      <font-icon
        class="loading-block-spinner"
        spin
        name="spinner"
        color="var(--ion-color-primary-tint)"
        size="0.7"
      />
    </ion-row>
    <div v-if="update">
      <div class="ion-margin-bottom">
        <span class="update-text">
          {{ t("common.mobileAppUpdate") }}
        </span>
      </div>
      <ion-progress-bar :value="progress"></ion-progress-bar>
    </div>
  </div>
</template>

<style scoped>
.update-text {
  color: var(--ion-color-primary);
}

.login-block {
  position: relative;
  margin: auto;
  margin-top: 10rem;
  width: calc(100% - 84px);
  text-align: center;
}

.loading-block {
  position: relative;
  margin: auto;
  width: calc(100% - 84px);
  height: calc(100% - var(--offset-top) - var(--offset-bottom));
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-block-spinner {
  margin-left: 1rem;
}

.login-block .fabriq-logo {
  margin-bottom: calc(var(--ion-padding) * 4);
}
</style>
