import { ListEntity } from "./states";

export enum AssignmentsNotifications {
  TicketTaskAssigned = "ticket_task_assigned",
  TicketUserAssigned = "ticket_user_assigned",
  RoutineInstanceAssigned = "routine_instance_assigned",
}
export enum MentionsNotifications {
  IndicatorReactionCreated = "indicator_reaction_created",
  TicketReactionCreated = "ticket_reaction_created",
  InstanceReactionCreated = "instance_reaction_created",
  TaskMentionCreated = "task_mention_created",
  IndicatorMentionCreated = "indicator_mention_created",
  TicketMentionCreated = "ticket_mention_created",
  InstanceMentionCreated = "instance_mention_created",
  TicketReplyCreated = "ticket_reply_created",
  IndicatorReplyCreated = "indicator_reply_created",
  InstanceReplyCreated = "instance_reply_created",
}
export enum ActivityNotifications {
  InstanceExecutionCompleted = "instance_execution_completed",
  RoutineVersionCreated = "routine_version_created",
  RoutineTicketCreated = "routine_ticket_created",
  TicketTaskCompleted = "ticket_task_completed",
  TicketTaskCreated = "ticket_task_created",
  TicketTaskUpdated = "ticket_task_updated",
  TicketFileCreated = "ticket_file_created",
  TicketCommentCreated = "ticket_comment_created",
  TicketTeamArchived = "ticket_team_archived",
  TicketTeamCreated = "ticket_team_created",
  TicketStatusUpdated = "ticket_status_updated",
  IndicatorTicketTagged = "indicator_ticket_tagged",
  IndicatorDatapointCreated = "indicator_datapoint_created",
  IndicatorDatapointUpdated = "indicator_datapoint_updated",
  IndicatorFileCreated = "indicator_file_created",
  IndicatorCommentCreated = "indicator_comment_created",
}
export enum AdminNotifications {
  UserRolePromoted = "user_role_promoted",
  TeamUserAdded = "team_user_added",
  TeamUserRejected = "team_user_rejected",
  TeamUserRequested = "team_user_requested",
  TeamUserAccepted = "team_user_accepted",
  GroupUserAdmin = "group_user_admin",
  InvitationUserUsed = "invitation_user_used",
  GroupTeamCreated = "group_team_created",
}

export enum NotificationInstanceType {
  CommentsUnifiedComment = "comments.UnifiedComment",
  CommentsUnifiedCommentReaction = "comments.UnifiedCommentReaction",
  GroupsGroupUser = "groups.GroupUser",
  IndicatorsBaseDataPoint = "indicators.BaseDataPoint",
  IndicatorsIndicatorFile = "indicators.IndicatorFile",
  InvitationsInvitation = "invitations.Invitation",
  RoutinesInstanceExecution = "routines.InstanceExecution",
  RoutinesRoutineTemplate = "routines.RoutineTemplate",
  RoutinesUserInstance = "routines.UserInstance",
  TeamsGroupTeam = "teams.GroupTeam",
  TeamsTeamUser = "teams.TeamUser",
  TicketsTask = "tickets.Task",
  TicketsTicketFile = "tickets.TicketFile",
  TicketsTicketIndicator = "tickets.TicketIndicator",
  TicketsTicketTeam = "tickets.TicketTeam",
}

export const NotificationWithSubText = [
  ...Object.values(AssignmentsNotifications),
  ...Object.values(MentionsNotifications),
  ActivityNotifications.TicketTaskCompleted,
  ActivityNotifications.TicketTaskCreated,
  ActivityNotifications.TicketTaskUpdated,
  ActivityNotifications.TicketCommentCreated,
  ActivityNotifications.TicketTeamArchived,
  ActivityNotifications.TicketTeamCreated,
  ...Object.values(AdminNotifications),
];

export const NotificationType = [
  ...Object.values(AssignmentsNotifications),
  ...Object.values(MentionsNotifications),
  ...Object.values(ActivityNotifications),
  ...Object.values(AdminNotifications),
];

export const NotificationWithParentText = [
  MentionsNotifications.IndicatorReactionCreated,
  MentionsNotifications.TicketReactionCreated,
  MentionsNotifications.InstanceReactionCreated,
];

export enum NotificationTarget {
  Personal = "personal",
  Team = "team",
}

export interface Notification extends ListEntity {
  type:
    | AssignmentsNotifications
    | MentionsNotifications
    | ActivityNotifications
    | AdminNotifications;
  is_read: boolean;
  read_at: string;
  event_datetime: string;
  instance_repr: string;
  instance_id: number;
  instance_type: NotificationInstanceType;
  model_repr: string;
  model_id: number;
  model_type: NotificationInstanceType;
  parent_repr: string | null;
  parent_id: number | null;
  parent_type: NotificationInstanceType | null;
  team_ids: Array<number>;
  extra: any;
  target: NotificationTarget;
  object_id: number;
  notification_text: string;
  url: string;
  is_follower: boolean;
  email_only: boolean;
  user: number;
  actor: number;
  content_type: number;
}
