<template>
  <div>
    <div
      v-for="choice of choices"
      :key="choice.id"
      class="ion-margin step-choice"
      :class="{ selected: choice.selected }"
      @click="$emit('select', choice.id)"
    >
      {{ choice.text }}
      <div class="font-icon check-mark" v-if="choice.selected">
        <i class="material-icons">check</i>
      </div>
    </div>
    <ion-text v-if="hasNudgeCreateTicketActive" class="create-ticket-alert">
      <font-icon name="warning" material />
      <span> {{ t("indicatorPage.nudgeCreateTicketRoutine") }} </span>
    </ion-text>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import { IonText } from "@ionic/vue";
import { TemplateStep, ExecutionAnswer, StepChoice } from "@/types";
import { useI18n } from "@/composables/useI18n";
import { useRoutineNudgeCreateTicket } from "@/composables/useRoutineNudgeCreateTicket";

export default defineComponent({
  name: "StepMultiple",
  emits: ["select"],
  components: { IonText },
  props: {
    step: {
      type: Object as PropType<TemplateStep>,
      default: () => ({}),
    },
    answer: {
      type: Object as PropType<ExecutionAnswer>,
      default: () => ({}),
    },
  },
  setup(props) {
    const choices = computed(() => {
      return props.step.choices.map((c: StepChoice) => {
        if (!c.id) return c;
        return { ...c, selected: props.answer?.choices?.includes(c.id) };
      });
    });

    const { hasNudgeCreateTicketActive } = useRoutineNudgeCreateTicket(
      computed(() => props.step),
      computed(() => props.answer)
    );
    return {
      choices,
      ...useI18n(),
      hasNudgeCreateTicketActive,
    };
  },
});
</script>

<style scoped></style>
