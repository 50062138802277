<script lang="ts" setup>
import { computed, type ComputedRef } from "vue";
import { IonLabel, IonItem, IonRow, useIonRouter } from "@ionic/vue";
import { type FabriqEventWithReadableProperties } from "@/types";
import DueDate from "../tickets/DueDate.vue";
import { useGetEventPropertiesByType } from "@/composables/useGetEventPropertiesByType";
import { localize } from "@/utils/localize";
import { useFabriqStore } from "@/store/fabriq";

const router = useIonRouter();

const props = defineProps<{
  fEvent: FabriqEventWithReadableProperties;
}>();

const fabriqStore = useFabriqStore();
const { eventType, getEventPropertiesByType } = useGetEventPropertiesByType(
  computed(() => props.fEvent)
);

const eventTypeLabel = computed(() => {
  return localize(eventType.value?.label, fabriqStore.locale, 0);
});

const eventTypeEmoji = computed(() => {
  return eventType.value?.config?.emoji;
});

const eventDateValue: ComputedRef<string> = computed(() => {
  const dateValue = (
    (getEventPropertiesByType(props.fEvent, "datetime")?.value as string) ?? ""
  ).replace("Z", "");
  if (!dateValue) return "";
  return dateValue;
});

const goToEvent = () => {
  router.push(`/events/${props.fEvent.uuid}`);
};
</script>

<template>
  <ion-item class="dependency">
    <font-icon
      slot="start"
      name="swap_horiz"
      material
      size="1.5"
      color="var(--ion-color-primary-shade)"
    />
    <div class="dependency-type">
      <ion-row
        class="ion-justify-content-between ion-align-items-center event-row"
      >
        <ion-row class="ion-align-items-center">
          <font-icon
            class="dependency-icon"
            color="var(--ion-color-secondary)"
            name="bullseye"
            solid
          />
          <ion-row class="ion-align-items-center ion-margin-horizontal">
            {{ eventTypeEmoji }}
            <ion-label class="text-ellipsis event-title" @click="goToEvent">
              {{ eventTypeLabel }}
            </ion-label>
          </ion-row>
        </ion-row>
        <due-date class="dependency-due-date" :date="eventDateValue" readonly />
      </ion-row>
    </div>
  </ion-item>
</template>

<style scoped>
.event-title {
  max-width: calc(100vw - 180px);
  margin-left: var(--ion-padding);
}

.event-row {
  width: 100%;
}

.event-row .font-icon {
  margin-right: var(--ion-padding);
  margin-left: calc(var(--ion-padding) - 2px);
}

.dependency {
  --f-border-radius: 5px;
  --padding-start: var(--ion-padding);
  --padding-end: 0;
  --inner-padding-end: calc(var(--ion-padding) / 2);
  border-bottom: 1px solid var(--ion-border-color);
  padding-bottom: calc(var(--ion-padding) / 2);
  padding-top: calc(var(--ion-padding) / 2);
}

.dependency:first-child {
  padding-top: 0;
}

.dependency:last-child {
  padding-bottom: 0;
  border: 0;
}

.dependency .font-icon {
  margin-right: 0;
}

.dependency .dependency-type {
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - calc(var(--ion-padding) / 2));
  padding-right: calc(var(--ion-padding) / 2);
  padding-left: 0;
  border-radius: var(--f-border-radius, 4px);
  font-size: var(--font-size-m);
}
</style>
