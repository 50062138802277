import { ApiClient } from "./clients";
import { IndicatorRule, ListService } from "@/types";

export const RuleService: ListService<IndicatorRule> = {
  async load(indicatorId: number): Promise<IndicatorRule[]> {
    const rules: IndicatorRule[] = await ApiClient.get(
      `/api/v1/indicator/${indicatorId}/rules/`
    );
    return rules;
  },
};
