import { useConfigStore } from "./config";
import { createListStore } from "./list";
import { TicketService } from "@/services/tickets";
import { Ticket, Task, FabriqFile, Dependency, TicketField } from "@/types";

import { formatISO } from "date-fns";

import { useFilesStore } from "./files";
import { useCommentsStore } from "./comments";
import { useTasksStore } from "./tasks";
import { useFieldsStore } from "./fields";
import { useDependenciesStore } from "./dependencies";

const hooks = {
  async add(entity: Ticket) {
    const id = entity.id;
    if (!id) return;
    const filesStore = useFilesStore();
    const commentsStore = useCommentsStore();
    const tasksStore = useTasksStore();
    const fieldsStore = useFieldsStore();
    const configStore = useConfigStore();

    const today = formatISO(new Date());

    configStore.addOpenedTicket(+id, entity.title);

    const promises = [];
    const filePromises = filesStore.collection
      .filter((f: any) => f.ticket === entity.uuid)
      .map((f: any) => {
        filesStore.update(
          f.uuid,
          { ticket: entity.id, updated_at: today },
          true
        );
      });
    const taskPromises = tasksStore.collection
      .filter((t: any) => t.ticket === entity.uuid)
      .map((t: any) => tasksStore.update(t.uuid, { ticket: entity.id }, true));
    const fieldsPromises = fieldsStore.collection
      .filter((t: any) => t.ticketId === entity.uuid)
      .map((t: any) =>
        fieldsStore.update(
          t.uuid,
          { ticketId: entity.id, id: `${t.id}-${entity.id}` },
          true
        )
      );
    const commentPromises = commentsStore.collection
      .filter((c: any) => c.ticket === entity.uuid)
      .map((c: any) =>
        commentsStore.update(c.uuid, { ticket: entity.id }, true)
      );
    promises.push(Promise.all(fieldsPromises));
    promises.push(Promise.all(filePromises));
    promises.push(Promise.all(taskPromises));
    promises.push(Promise.all(commentPromises));
    setTimeout(() => {
      fieldsStore.save();
      filesStore.save();
      tasksStore.save();
      commentsStore.save();
      configStore.saveUserConfig();
    }, 10);
    await Promise.all(promises);
  },
  async load(entity: any) {
    const id = entity.id;
    if (!id) return;
    const tasksStore = useTasksStore();
    const filesStore = useFilesStore();
    const dependenciesStore = useDependenciesStore();
    const fieldsStore = useFieldsStore();
    const tasks: Array<Task> = [];
    const files: Array<FabriqFile> = [];
    const fields: Array<TicketField> = [];
    const dependencies: Array<Dependency> = [];

    const today = formatISO(new Date());

    if (entity.tasks && entity.tasks.length) {
      tasks.push(
        ...entity.tasks.map((ta: any) => ({
          ...ta,
          ticket: entity.id,
          user: ta.user?.id,
        }))
      );
    }
    if (entity.files && entity.files.length) {
      files.push(
        ...entity.files.map((fi: any) => ({
          ...fi,
          ticket: entity.id,
          updated_at: today,
        }))
      );
    }
    if (entity.extra_fields) {
      const ids: Array<string> = [];
      for (const field in entity.extra_fields) {
        if (!Array.isArray(entity.extra_fields[field])) continue;
        const tFields = entity.extra_fields[field].map((id: any) => {
          const idStr = `${entity.id}-${id}`;
          ids.push(idStr);
          return {
            id: idStr,
            fieldId: id,
            type: field,
            ticketId: entity.id,
            created_at: today,
            updated_at: today,
          };
        });
        fields.push(...tFields);
      }
      fieldsStore.collection.forEach((f: any) => {
        if (f.ticketId !== entity.id && f.ticketId !== entity.uuid) return;
        if (ids.includes(f.id)) return;
        fieldsStore.remove({ uuid: f.uuid }, true);
      });
    }
    if (entity.dependencies && entity.dependencies.length) {
      dependencies.push(
        ...entity.dependencies.map((de: any) => ({ ...de, updated_at: today }))
      );
    }
    tasksStore.merge(tasks);
    filesStore.merge(files);
    fieldsStore.merge(fields);
    dependenciesStore.merge(dependencies);
    return Promise.resolve({
      ...entity,
      status: entity.status?.id ?? entity.status,
      tasks: undefined,
      files: undefined,
      dependencies: undefined,
      extra_fields: undefined,
    });
  },
  async update(entity: Ticket) {
    const id = entity.id;
    if (!id) return;

    const today = formatISO(new Date());
    const filesStore = useFilesStore();
    const commentsStore = useCommentsStore();
    const tasksStore = useTasksStore();
    const fieldsStore = useFieldsStore();

    const promises = [];
    const filePromises = filesStore.collection
      .filter((f: any) => f.ticket === entity.uuid)
      .map((f: any) =>
        filesStore.update(
          f.uuid,
          { ticket: entity.id, updated_at: today },
          true
        )
      );
    const taskPromises = tasksStore.collection
      .filter((t: any) => t.ticket === entity.uuid)
      .map((t: any) => tasksStore.update(t.uuid, { ticket: entity.id }, true));
    const fieldsPromises = fieldsStore.collection
      .filter((t: any) => t.ticketId === entity.uuid)
      .map((t: any) =>
        fieldsStore.update(
          t.uuid,
          { ticketId: entity.id, id: `${t.id}-${entity.id}` },
          true
        )
      );
    const commentPromises = commentsStore.collection
      .filter((c: any) => c.ticket === entity.uuid)
      .map((c: any) =>
        commentsStore.update(c.uuid, { ticket: entity.id }, true)
      );
    promises.push(Promise.all(fieldsPromises));
    promises.push(Promise.all(filePromises));
    promises.push(Promise.all(taskPromises));
    promises.push(Promise.all(commentPromises));
    setTimeout(() => {
      fieldsStore.save();
      filesStore.save();
      tasksStore.save();
      commentsStore.save();
    }, 10);
    await Promise.all(promises);
  },
  async all(options: any, entities: Array<Ticket>) {
    const tasksStore = useTasksStore();
    const filesStore = useFilesStore();
    const dependenciesStore = useDependenciesStore();
    const fieldsStore = useFieldsStore();
    const tasks: Array<Task> = [];
    const files: Array<FabriqFile> = [];
    const tickets: Array<Ticket> = [];
    const fields: Array<TicketField> = [];
    const dependencies: Array<Dependency> = [];

    const today = formatISO(new Date());

    entities.forEach((t: any) => {
      tickets.push({
        ...t,
        status: t.status?.id ?? t.status,
        tasks: undefined,
        files: undefined,
        dependencies: undefined,
        extra_fields: undefined,
      });
      if (t.tasks && t.tasks.length) {
        tasks.push(
          ...t.tasks.map((ta: any) => ({
            ...ta,
            ticket: t.id,
          }))
        );
      }
      if (t.files && t.files.length) {
        files.push(
          ...t.files.map((fi: any) => ({
            ...fi,
            ticket: t.id,
            updated_at: today,
          }))
        );
      }
      if (t.extra_fields) {
        const ids: Array<string> = [];
        for (const field in t.extra_fields) {
          if (!Array.isArray(t.extra_fields[field])) continue;
          const tFields = t.extra_fields[field].map((id: any) => {
            const idStr = `${t.id}-${id}`;
            ids.push(idStr);
            return {
              id: idStr,
              fieldId: id,
              type: field,
              ticketId: t.id,
              created_at: today,
              updated_at: today,
            };
          });
          fields.push(...tFields);
        }
        fieldsStore.collection.forEach((f: any) => {
          if (f.ticketId !== t.id && f.ticketId !== t.uuid) return;
          if (ids.includes(f.id)) return;
          fieldsStore.remove({ uuid: f.uuid }, true);
        });
      }
      if (t.dependencies && t.dependencies.length) {
        dependencies.push(
          ...t.dependencies.map((de: any) => ({ ...de, updated_at: today }))
        );
      }
    });
    tasksStore.merge(tasks, { flush: true });
    filesStore.merge(files);
    fieldsStore.merge(fields, { flush: true });
    dependenciesStore.merge(dependencies);
    return Promise.resolve(tickets);
  },
};

export const useTicketsStore = createListStore(
  "tickets",
  TicketService,
  hooks,
  { openComments: false, fromPush: false },
  {
    setOpenComments(open: boolean) {
      this.openComments = open;
    },
    setFromPush(from: any) {
      this.fromPush = from;
    },
  }
);
