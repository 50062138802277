import { ref } from "vue";

export const useModalScroller = () => {
  const list = ref();
  const scroller = ref();
  const content = ref();

  let nb = 0;
  const interval = setInterval(() => {
    if (nb++ > 100) clearInterval(interval);
    if (!list.value?.$el || !scroller.value?.$el || !content.value?.$el) return;
    const rect = content.value.$el.getBoundingClientRect();
    if (rect.height > 0) clearInterval(interval);
    list.value.$el.style.maxHeight = `${rect.height - 24}px`;
    scroller.value.$el.style.maxHeight = `${rect.height - 24}px`;
  }, 100);

  return {
    list,
    scroller,
    content,
  };
};
