import { useI18n as useVueI18n } from "vue-i18n";
import type { TicketTemplate } from "@/types/ticket";

export const useI18n = () => {
  const { t, n, d } = useVueI18n();
  return { t, n, d };
};
export const translateTemplateName = (item: TicketTemplate, locale: string) => {
  return item.config?.i18n?.hasOwnProperty(locale)
    ? item.config?.i18n[locale]
    : item.label;
};
