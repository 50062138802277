<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item
        v-if="ticket.id"
        @click="$emit('done', 'copy')"
        class="clickable"
      >
        <font-icon slot="start" name="content_copy" material />
        <ion-label class="list-item">{{
          t("ticketContent.shareTicketLink")
        }}</ion-label>
      </ion-item>
      <ion-item
        @click="$emit('done', 'archive')"
        class="clickable"
        v-if="ticket.is_open"
        :disabled="!canEdit"
      >
        <font-icon slot="start" name="archive" material />
        <ion-label class="list-item">{{ t("tickets.archive") }}</ion-label>
      </ion-item>
      <ion-item @click="$emit('done', 'unarchive')" class="clickable" v-else>
        <font-icon slot="start" name="unarchive" material />
        <ion-label class="list-item">{{ t("tickets.unarchive") }}</ion-label>
      </ion-item>
      <ion-item
        :disabled="!canEdit"
        @click="$emit('done', 'delete')"
        class="clickable"
      >
        <font-icon
          slot="start"
          name="delete"
          color="var(--ion-color-danger)"
          material
        />
        <ion-label class="list-item" color="danger">
          {{ t("tickets.delete") }}
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonContent, IonList, IonItem, IonLabel } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";

export default defineComponent({
  name: "TicketOptionsModal",
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["done", "cancel"],
  setup() {
    return {
      ...useI18n(),
    };
  },
});
</script>

<style scoped>
.list-item {
  font-size: var(--font-size-m);
}
</style>
