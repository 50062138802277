import authServerFetch from "./fetchHelper";

function buildIdp(serverBaseUrl: string, subdomainUrl?: string) {
  // FIXME: Workaround as authServerFetch does not support local paths
  serverBaseUrl =
    (subdomainUrl ? subdomainUrl : window.location.origin) + serverBaseUrl;
  return {
    async authenticateUser(
      clientId: string,
      authenticationId: string,
      organizationId: string,
      username: string,
      password: string
    ) {
      const data = {
        clientId,
        authenticationId,
        emailAddress: username,
        password,
      };
      const response = await authServerFetch(
        `${serverBaseUrl}/user/login/send-credentials`,
        "POST",
        false,
        data
      );
      return response.body;
    },
  };
}

function buildIdpClient(serverBaseUri: string, subdomainUrl?: string) {
  // FIXME: Workaround as authServerFetch does not support local paths
  const idp = buildIdp(serverBaseUri, subdomainUrl);
  return {
    async authenticate(
      clientId: string,
      organizationId: string,
      authId: string,
      credentials: { username: string; password: string }
    ) {
      const result = await idp.authenticateUser(
        clientId,
        authId,
        organizationId,
        credentials.username,
        credentials.password
      );
      if (result.outcome === "notAuthenticated") {
        switch (result.reason) {
          case "passwordsDoNotMatch":
            return {
              outcome: "authenticationFailed",
              reason: "wrongPassword",
            };
          case "usernameOrPasswordError":
            return {
              outcome: "authenticationFailed",
              reason: "userWithNoPassword",
            };
          case "usernameDoesNotExist": {
            return {
              outcome: "authenticationFailed",
              reason: "userDoesNotExist",
            };
          }
          case "oldUsername": {
            return {
              outcome: "authenticationFailed",
              reason: "oldUsername",
              newUsername: result.newUsername,
            };
          }
          default:
            throw new Error(result.reason);
        }
      }
      return {
        outcome: "authenticationSucceeded",
        callbackUrl: result.authCodeRedirectUri,
      };
    },
  };
}

export default buildIdpClient;
