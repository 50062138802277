import { ListEntity } from "./states";

export enum Order {
  Asc = "asc",
  Desc = "desc",
}

export enum RequestMethod {
  Get,
  Post,
  Put,
  Patch,
  Delete,
}

export interface AllOptions {
  id?: number;
  ticket?: number;
  instance?: number;
  lastUpdatedAt?: string;
}
export interface ListService<Type extends ListEntity> {
  all?: (options?: any) => Promise<any>;
  load?: (options?: any, otherParams?: any) => Promise<any>;
  add?: (entity: Type, parentId?: number) => Promise<Type>;
  update?: (entity: Type, columns: any) => Promise<Type>;
  remove?: (entity: Type, parentId?: number) => Promise<void>;
}

export enum AuthType {
  None = "INEXISTENT",
  External = "EXTERNAL",
  Native = "NATIVE",
  Legacy = "LEGACY",
  Migrated = "MIGRATED",
  Future = "FUTURE",
}
export interface Token {
  type: string;
  accessToken: string;
  refreshToken: string;
  expiresAt: Date;
}
