<script lang="ts" setup>
import { useAddTicketFromEvent } from "@/composables/useAddTicketFromEvent";
import { useDiamondDependenciesStore } from "@/store/diamondDependencies";
import { useTicketsStore } from "@/store/tickets";
import {
  DiamondDependency,
  FabriqEventWithReadableProperties,
  Ticket,
} from "@/types";
import { IonRow } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import DueDate from "../tickets/DueDate.vue";

type Props = {
  fEvent: FabriqEventWithReadableProperties;
  disabled: boolean;
};

const props = defineProps<Props>();
const ticketsStore = useTicketsStore();
const dependenciesStore = useDiamondDependenciesStore();

const { collection: allTickets } = storeToRefs(ticketsStore);

const { openAddMenu, gotoTicket, eventDependencies, dependenciesLoading } =
  useAddTicketFromEvent(props.fEvent);

async function loadDependencies() {
  dependenciesLoading.value = true;

  if (eventDependencies.value.length) {
    dependenciesLoading.value = false;
    return;
  }
  await dependenciesStore.all({
    entityType: "event",
    entityId: props.fEvent.id,
  });
  dependenciesLoading.value = false;
}

const tickets = computed(() => {
  const tickets = eventDependencies.value.map(
    (dependency: DiamondDependency) => {
      const ticket =
        dependency.dependentType === "ticket"
          ? allTickets.value.find(
              (e: Ticket) => e.id === dependency.dependentId
            )
          : allTickets.value.find((e: Ticket) => e.id === dependency.relatedId);

      return {
        dependencyId: dependency.id,
        ticket,
      };
    }
  );

  return tickets.filter((t: any) => Boolean(t.ticket)) as {
    dependencyId: string;
    ticket: Ticket;
  }[];
});

const ticket = computed(() => {
  return tickets.value[0]?.ticket;
});

watch(
  eventDependencies,
  async (newVal) => {
    if (!newVal.length) return;
    await Promise.all(
      newVal.map((dependency: DiamondDependency) => {
        const ticketId =
          dependency.dependentType === "ticket"
            ? dependency.dependentId
            : dependency.relatedId;
        const ticket = allTickets.value.find((e: Ticket) => e.id === ticketId);
        if (!ticket) return ticketsStore.load(ticketId);
      })
    );
  },
  { immediate: true }
);

loadDependencies();
</script>

<template>
  <ion-row class="ticket-dependency">
    <ion-row
      class="ion-justify-content-center ticket-dependency-wrapper"
      v-if="dependenciesLoading"
    >
      <font-icon name="spinner" spin size="0.3" class="dependencies-loading" />
    </ion-row>
    <ion-row
      v-else-if="!ticket && eventDependencies.length"
      class="ion-align-items-center ticket-dependency-wrapper disabled"
    >
      <font-icon
        name="device_hub"
        material
        size="1"
        color="var(--f-color-disabled)"
      />
      {{ $t("locked") }}
    </ion-row>
    <ion-row
      v-else-if="ticket"
      class="ion-align-items-center ticket-dependency-wrapper"
      @click="gotoTicket(ticket.uuid!)"
    >
      <font-icon name="device_hub" material size="1" />
      <font-icon
        name="assignment"
        material
        size="1"
        color="var(--f-color-discrete)"
      />
      <div class="ticket-title-container">
        <div class="ticket-title">{{ ticket.title }}</div>
      </div>
      <DueDate :date="ticket.due_date" readonly class="due-date" />
    </ion-row>
    <ion-row
      v-else
      class="ion-align-items-center ticket-dependency-wrapper"
      :class="{ disabled }"
    >
      <font-icon
        name="device_hub"
        material
        size="1"
        :color="disabled ? 'var(--f-color-disabled)' : 'var(--f-color-primary)'"
      />
      <div class="ticket-title-container">
        <div class="ticket-title">
          {{ $t("events.eventModal.dependencies.ticket") }}
        </div>
      </div>
      <font-icon
        name="add"
        material
        size="1"
        :color="disabled ? 'var(--f-color-disabled)' : 'var(--f-color-primary)'"
        @click="openAddMenu"
      />
    </ion-row>
  </ion-row>
</template>

<style scoped>
.ticket-dependency {
  padding: 8px;
  flex: 1;
  max-width: 100%;
  border: 1px solid var(--ion-border-color);
  border-radius: 4px;
  font-size: var(--font-size-m);
}
.ticket-dependency-wrapper {
  flex: 1;
  gap: 8px;
}
.disabled {
  color: var(--f-color-disabled);
}
.dependencies-loading {
  margin-block: 1px;
}
.ticket-title-container {
  flex: 1;
  display: flex;
  min-width: 0;
}
.ticket-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.due-date {
  margin-right: 4px;
}
</style>
