<template>
  <user-avatar
    :user="user"
    :name="name"
    :textOnly="textOnly"
    :forceVisibility="forceVisibility"
    clickable
    @click="updateUser"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { modalController } from "@ionic/vue";
import { Team, User } from "@/types";
import UserPickerModal from "@/components/modals/UserPickerModal.vue";
import UserAvatar from "./UserAvatar.vue";

export default defineComponent({
  name: "UserAvatarInput",
  components: {
    UserAvatar,
  },
  emits: ["update", "modal"],
  props: {
    user: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    onlyMe: {
      type: Boolean,
      default: false,
    },
    textOnly: {
      type: Boolean,
      default: false,
    },
    forceVisibility: {
      type: Boolean,
      default: false,
    },
    teams: {
      type: Array as PropType<Array<Team>>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const updateUser = async () => {
      // emit("modal", true);
      const modal = await modalController.create({
        component: UserPickerModal,
        canDismiss: true,
        mode: "ios",
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        componentProps: {
          userId: props.user?.id,
          teams: props.teams,
          onlyMe: props.onlyMe,
          onDone: (user: User) => {
            setTimeout(() => modal.dismiss(), 200);
            emit("update", user);
            // emit("modal", false);
          },
          onCancel: () => {
            setTimeout(() => modal.dismiss(), 200);
            // emit("modal", false);
          },
        },
      });
      return modal.present();
    };

    return {
      updateUser,
    };
  },
});
</script>
