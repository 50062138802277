import { useFabriqStore } from "@/store/fabriq";
import { Ticket } from "@/types";
import { toastController } from "@ionic/vue";
import { computed } from "vue";
import { useI18n } from "./useI18n";
import { Ref, ComputedRef } from "vue";

export const useRights = (
  ticket: Ref<Ticket> | ComputedRef<Ticket>,
  teams: Ref<any[]> | ComputedRef<any[]>
) => {
  const { t } = useI18n();
  const fabriqStore = useFabriqStore();
  const alertCantEdit = async () => {
    const toast = await toastController.create({
      message: `🚨 ${t("permissions.limitedRights")}`,
      duration: 2000,
    });
    toast.present();
  };

  const right = computed(() => {
    const userId = fabriqStore.user?.id;
    if (!userId || !ticket.value) return "none";
    if (ticket.value.created_by === userId) return "all";
    if (ticket.value.owner === userId) return "all";
    for (const team of teams.value) {
      if (team.owners.includes(userId)) return "all";
      if (team.admins.includes(userId)) return "all";
      if (team.coordinators.includes(userId)) return "all";
      if (!team.auditors.includes(userId) && team.users.includes(userId))
        return "member";
    }
    return "auditor";
  });

  const canEdit = computed(() => {
    const userId = fabriqStore.user?.id;
    if (!userId || !ticket.value) return false;
    if (right.value === "none") return false;
    if (right.value === "auditor") return false;
    if (right.value === "member" && ticket.value.owner !== userId) return false;
    if (
      right.value === "member" &&
      ticket.value.owner === userId &&
      ticket.value.created_by !== userId
    )
      return false;
    return true;
  });

  return {
    canEdit,
    alertCantEdit,
    right,
  };
};
