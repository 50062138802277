<script lang="ts" setup>
import { IonList, IonButton } from "@ionic/vue";
import { ComputedRef, computed, watch } from "vue";
import DependencyItem from "@/components/business/dependencies/DependencyItem.vue";
import DependencyEvent from "@/components/business/dependencies/DependencyEvent.vue";
import type {
  Dependency,
  DiamondDependency,
  FabriqEventWithReadableProperties,
} from "@/types";
import { useEventsWithPropertiesStore } from "@/store/eventsWithProperties";
import { storeToRefs } from "pinia";
import { useEventsStore } from "@/store/events";
import { useClientStore } from "@/store/client";

interface Props {
  entityId?: number;
  dependencies: Dependency[];
  eventDependencies?: DiamondDependency[];
  readonly?: boolean;
}

const eventsWithPropertiesStore = useEventsWithPropertiesStore();
const eventsStore = useEventsStore();
const clientStore = useClientStore();

const { eventsWithProperties } = storeToRefs(eventsWithPropertiesStore);

const props = withDefaults(defineProps<Props>(), {
  dependencies: () => [],
  readonly: false,
});
const ordered = computed(() => {
  const dependencies = props.dependencies || [];
  dependencies.sort((a: Dependency, b: Dependency) => {
    return +(a.id || 0) - +(b.id || 0);
  });
  return dependencies; // (props.dependencies || []).filter((c) => !!c);
});
const addDependency = () => {
  console.log("addDependency");
};

const events: ComputedRef<
  {
    dependencyId: string;
    event: FabriqEventWithReadableProperties;
  }[]
> = computed(() => {
  if (!clientStore.config?.useEvents) return [];
  const events = (props.eventDependencies ?? []).map((dependency) => {
    const event =
      dependency.dependentType === "event"
        ? eventsWithProperties.value?.find(
            (e) => e.id === dependency.dependentId
          )
        : eventsWithProperties.value?.find(
            (e) => e.id === dependency.relatedId
          );

    return {
      dependencyId: dependency.id,
      event,
    };
  });

  return events.filter((t) => Boolean(t.event)) as {
    dependencyId: string;
    event: FabriqEventWithReadableProperties;
  }[];
});

let loaded = false;
watch(
  () => props.eventDependencies ?? [],
  async (v: DiamondDependency[]) => {
    if (!props.entityId) return;
    if (!clientStore.config?.useEvents) return;
    if (loaded) return;
    loaded = true;
    await Promise.all(
      (v ?? []).map((dependency) => {
        const eventId =
          dependency.dependentType === "event"
            ? dependency.dependentId
            : dependency.relatedId;

        const event = eventsWithProperties.value?.find((e) => e.id === eventId);

        if (!event) return eventsStore.load(eventId);
        return Promise.resolve(event);
      })
    );
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <ion-list class="dependency-list" lines="none">
      <dependency-item
        v-for="(dependency, index) of ordered"
        :key="dependency.uuid ?? index"
        :dependency="dependency"
        :entityId="entityId"
        :readonly="readonly"
      />
      <dependency-event
        v-for="(event, index) of events"
        :key="event.dependencyId ?? index"
        :fEvent="event.event"
      />
    </ion-list>
    <ion-button
      fill="clear"
      size="small"
      v-if="!readonly"
      :readonly="readonly"
      @click="addDependency"
    >
      <font-icon name="plus" />
    </ion-button>
  </div>
</template>

<style scoped>
.dependency-list {
  flex: 1;
  padding: calc(var(--ion-padding) / 2 - 4px) 0;
}
</style>
