<template>
  <ion-page>
    <page-toolbar
      :title="title"
      :collapse="true"
      :beta="beta"
      :is-account="isAccount"
    >
      <slot name="toolbar"></slot>
    </page-toolbar>
    <ion-content :fullscreen="true" class="hide-scrollbar">
      <ion-refresher
        slot="fixed"
        @ionRefresh="$emit('refresh', $event)"
        :disabled="!online"
      >
        <ion-refresher-content
          refreshing-spinner="crescent"
        ></ion-refresher-content>
      </ion-refresher>
      <div class="margin-for-floating" style="min-height: 100vh">
        <slot />
      </div>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <slot name="fab">
          <ion-fab-button
            v-if="fabIcon"
            @click="$emit('fab')"
            :disabled="fabLoading"
            class="fabriq-add-button"
          >
            <font-icon
              color="#ffffff"
              :name="fabLoading ? 'spinner' : fabIcon"
              :spin="fabLoading"
              size="1.375"
            />
          </ion-fab-button>
        </slot>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFabButton,
  IonFab,
} from "@ionic/vue";
import { useFabriqStore } from "@/store/fabriq";
import { storeToRefs } from "pinia";
import PageToolbar from "@/components/ui/PageToolbar.vue";

export default defineComponent({
  name: "RoutePage",
  props: {
    fabIcon: {
      type: String,
      default: null,
    },
    fabLoading: {
      type: Boolean,
      default: false,
    },
    isAccount: {
      type: Boolean,
      default: false,
    },
    scrollY: {
      type: Number,
      default: 20,
    },
    title: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
    },
    beta: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonFabButton,
    IonFab,
    PageToolbar,
  },
  setup() {
    const fabriqStore = useFabriqStore();
    const { online } = storeToRefs(fabriqStore);

    return {
      online,
    };
  },
});
</script>

<style>
ion-refresher.refresher-native ion-spinner {
  color: var(--ion-color-secondary) !important;
}
</style>
