<template>
  <div class="routines-empty">
    <font-icon
      class="icon loading-color"
      spin
      name="spinner"
      color="var(--ion-color-primary-tint)"
      size="1"
    />
  </div>
</template>
