import { DateOption } from "@/types";
import {
  format,
  subBusinessDays,
  subDays,
  lastDayOfMonth,
  lastDayOfYear,
  subMonths,
  lastDayOfISOWeek,
  subWeeks,
  endOfDay,
} from "date-fns";

export const useConvertDateOption = () => {
  const dateFormatter = (date: Date) => format(date, "yyyy-MM-dd");

  const convertDateOption = (date: DateOption, referenceDate: Date): string => {
    switch (date) {
      case "day_of_execution":
        return dateFormatter(referenceDate);
      case "day_before_execution":
        return dateFormatter(subDays(referenceDate, 1));
      case "week_of_execution":
        return dateFormatter(lastDayOfISOWeek(referenceDate));
      case "month_of_execution":
        return dateFormatter(endOfDay(lastDayOfMonth(referenceDate)));
      case "week_before_execution":
        return dateFormatter(subWeeks(lastDayOfISOWeek(referenceDate), 1));
      case "month_before_execution":
        return dateFormatter(
          subMonths(endOfDay(lastDayOfMonth(referenceDate)), 1)
        );
      case "year_before_execution":
        return dateFormatter(
          subMonths(endOfDay(lastDayOfYear(referenceDate)), 1)
        );
      case "business_day_before_execution":
        return dateFormatter(subBusinessDays(referenceDate, 1));
      default:
        return dateFormatter(referenceDate);
    }
  };

  return {
    convertDateOption,
  };
};
