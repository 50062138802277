<script lang="ts" setup>
import { computed, ref } from "vue";
import {
  IonContent,
  IonTitle,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useFabriqStore } from "@/store/fabriq";
import { useCommentsStore } from "@/store/comments";
import CommentList from "@/components/business/tickets/CommentList.vue";
import { formatISO } from "date-fns";
import {
  getMentionedUserIds,
  getTaggedChildrenIds,
} from "@/utils/comment-parser";
import { ExecutionAnswer, Instance, User, Comment } from "@/types";

interface Props {
  instance: Instance | null;
  users: User[];
  answers: ExecutionAnswer[];
  answer: ExecutionAnswer | null;
  current: number;
}

const props = withDefaults(defineProps<Props>(), {
  instance: null,
  answer: null,
  answers: () => [],
  users: () => [],
});

const { t } = useI18n();
const fabriqStore = useFabriqStore();
const commentStore = useCommentsStore();

const loaded = ref(false);
setTimeout(() => {
  loaded.value = true;
}, 1000);

const comments = computed(() => {
  if (!props.instance?.id) return [];
  return commentStore.collection.filter(
    (c: any) => c.instance === props.instance?.id
  );
});

commentStore.all({ instance: props.instance?.id });

const start =
  props.answer && props.answer.id
    ? `{routineExecutionAnswerId: ${props.answer.id}}`
    : null;

const addComment = async (payload: any) => {
  if (!payload.comment || !payload.comment.length) return;
  const date = formatISO(new Date());
  const author = fabriqStore.user?.id;
  const { uuid, comment, parentId } = payload;
  await commentStore.add({
    uuid,
    content: comment,
    mentioned_user_ids: getMentionedUserIds(comment),
    tagged_children: getTaggedChildrenIds(comment, true),
    instance: props.instance?.id,
    parent: parentId,
    author,
    created_at: date,
    updated_at: date,
  });
  commentStore.save();
};

const editComment = async (payload: any) => {
  if (!payload.comment || !payload.comment.length) return;
  const updated = commentStore.collection.find(
    (c: Comment) => c.id === payload.commentId
  );
  if (!updated) return;
  const { comment } = payload;
  await commentStore.update(updated.uuid, {
    content: comment,
    mentioned_user_ids: getMentionedUserIds(comment),
    tagged_children: getTaggedChildrenIds(comment, true),
  });
  commentStore.save();
};

const emit = defineEmits<{
  (event: "cancel"): void;
  (event: "done"): void;
}>();

const close = () => {
  commentStore.save();
  emit("cancel");
};
</script>

<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar ion-padding-top">
      <ion-title>{{ t("common.comments") }}</ion-title>
      <ion-buttons slot="end">
        <ion-button fill="clear" class="cancel-btn" @click="close">
          {{ t("close") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="normal">
    <comment-list
      :comments="comments"
      :users="users"
      :answers="answers"
      :start="start"
      @add="addComment"
      @edit="editComment"
    />
  </ion-content>
</template>

<style scoped>
.list-title {
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary-shade);
}
.user-item {
  margin-bottom: var(--ion-padding);
}
</style>
