<script lang="ts" setup>
import { IonContent, IonList, IonItem, IonLabel } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";

interface Props {
  canEdit?: boolean;
}
defineProps<Props>();
const { t } = useI18n();
const emit = defineEmits(["done", "cancel"]);
</script>

<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item @click="emit('done', 'copy')" class="clickable">
        <font-icon slot="start" name="content_copy" material />
        <ion-label class="list-item">{{
          t("eventContent.shareEventLink")
        }}</ion-label>
      </ion-item>
      <ion-item
        :disabled="!canEdit"
        @click="emit('done', 'delete')"
        class="clickable"
      >
        <font-icon
          slot="start"
          name="delete"
          color="var(--ion-color-danger)"
          material
        />
        <ion-label class="list-item" color="danger">
          {{ t("eventContent.delete") }}
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<style scoped>
.list-item {
  font-size: var(--font-size-m);
}
</style>
