import { ApiClient } from "./clients";
import { TicketTemplate, ListService } from "@/types";

export const TicketTemplatesService: ListService<TicketTemplate> = {
  async all(options?: any): Promise<TicketTemplate> {
    const params = options?.last
      ? {
          last: options.last,
        }
      : null;
    const templates = await ApiClient.get(`/api/v1/ticket_templates/`, params);
    return templates;
  },
  async load(id: number): Promise<TicketTemplate> {
    const template = await ApiClient.get(`/api/v1/ticket_template/${id}/`);
    return template;
  },
};
