import { defineStore, storeToRefs } from "pinia";
import { useEventTypesStore } from "./eventTypes";
import { ComputedRef, computed, reactive } from "vue";
import {
  EventDateTimeProperty,
  EventType,
  EventTypeCardinalProperty,
  EventTypeDateTimeProperty,
  EventTypeOrdinalProperty,
  EventTypeProperty,
  FabriqEvent,
  FabriqEventFromServer,
  FabriqEventWithReadableProperties,
} from "@/types";
import { useEventsStore } from "./events";
import { Localizable } from "@/utils/localize";

export const useEventsWithPropertiesStore = defineStore(
  "eventsWithProperties",
  () => {
    const eventsStore = useEventsStore();
    const eventTypesStore = useEventTypesStore();
    const { collection: eventTypes } = storeToRefs(eventTypesStore);
    const { collection: events } = storeToRefs(eventsStore);

    const eventTypePropertiesMap: Record<string, EventTypeProperty> = reactive(
      {}
    );

    const eventTypesMap: ComputedRef<Record<string, EventType>> = computed(
      () => {
        const map: Record<string, EventType> = {};
        eventTypes.value.forEach((eventType: EventType) => {
          map[eventType.id] = eventType;
        });
        return map;
      }
    );

    const allEventTypesOrdinalPropertiesValues: ComputedRef<
      Record<
        string,
        Record<
          string,
          {
            rank: number;
            label: Localizable;
            color?: string | undefined;
          }[]
        >
      >
    > = computed(() => {
      const ordinalPropertyValuesMap: Record<
        string,
        Record<
          string,
          {
            rank: number;
            label: Localizable;
            color?: string | undefined;
          }[]
        >
      > = {};
      const allEventTypes = eventTypes.value;
      allEventTypes.forEach((eventType: EventType) => {
        ordinalPropertyValuesMap[eventType.id] =
          getOrdinalValuesFromType(eventType);
      });
      return ordinalPropertyValuesMap;
    });

    const allEventTypesCardinalPropertiesValues: ComputedRef<
      Record<
        string,
        Record<
          string,
          {
            value: string;
            label: Localizable;
            color?: string | undefined;
          }[]
        >
      >
    > = computed(() => {
      const cardinalPropertyValuesMap: Record<
        string,
        Record<
          string,
          {
            value: string;
            label: Localizable;
            color?: string | undefined;
          }[]
        >
      > = {};
      const allEventTypes = eventTypes.value;
      allEventTypes.forEach((eventType: EventType) => {
        cardinalPropertyValuesMap[eventType.id] =
          getCardinalValuesFromType(eventType);
      });
      return cardinalPropertyValuesMap;
    });

    const allEventTypesDateTimeProperties: ComputedRef<
      Record<string, EventTypeDateTimeProperty>
    > = computed(() => {
      const properties: Record<string, EventTypeDateTimeProperty> = {};
      const allEventTypes = eventTypes.value;
      allEventTypes.forEach((eventType: EventType) => {
        const datetimeProperty = eventType.properties.find(
          (property): property is EventTypeDateTimeProperty =>
            property.type === "datetime"
        );
        if (datetimeProperty) {
          properties[eventType.id] = datetimeProperty;
        }
      });
      return properties;
    });

    const eventsWithProperties: ComputedRef<
      FabriqEventWithReadableProperties[]
    > = computed(() => {
      const allEvents = [...events.value];
      const fabriqEvents = allEvents.map((event: FabriqEventFromServer) => {
        const eventType = eventTypesMap.value[event.eventTypeId];
        return eventType
          ? mapEventToEventWithProperties(event, eventType)
          : event;
      });
      return sortEventsByDateProperty(
        fabriqEvents
      ) as FabriqEventWithReadableProperties[];
    });

    return {
      eventsWithProperties,
      eventTypePropertiesMap,
      eventTypesMap,
      eventTypes,
      allEventTypesOrdinalPropertiesValues,
      allEventTypesCardinalPropertiesValues,
      allEventTypesDateTimeProperties,
      retrieveEvents: eventsStore.retrieveEvents,
    };
  }
);

function getOrdinalValuesFromType(eventType: EventType) {
  const ordinalProperty =
    (eventType.properties.filter(
      (p) => p.type === "ordinal"
    ) as EventTypeOrdinalProperty[]) ?? [];
  if (!ordinalProperty) return {};
  return ordinalProperty.reduce(
    (acc, p) => {
      acc[p.id] = p.values;
      return acc;
    },
    {} as Record<
      string,
      {
        rank: number;
        label: Localizable;
        color?: string | undefined;
      }[]
    >
  );
}

function getCardinalValuesFromType(eventType: EventType) {
  const cardinalProperty = eventType.properties.filter(
    (p) => p.type === "cardinal"
  ) as EventTypeCardinalProperty[];
  if (!cardinalProperty) return {};
  return cardinalProperty.reduce(
    (acc, p) => {
      acc[p.id] = p.values;
      return acc;
    },
    {} as Record<
      string,
      {
        value: string;
        label: Localizable;
        color?: string | undefined;
      }[]
    >
  );
}

export function mapEventToEventWithProperties(
  eventsFromServer: FabriqEventFromServer,
  eventType: EventType
): FabriqEvent {
  const properties = eventType.properties.reduce<
    Record<string, EventTypeProperty>
  >((acc, property) => {
    acc[property.id] = property;
    return acc;
  }, {});

  const eventProperties = eventsFromServer.properties.map((property) => {
    const type = properties[property.eventPropertyTypeId]?.type;
    switch (type) {
      case "datetime":
        return {
          ...property,
          eventPropertyTypeId: property.eventPropertyTypeId,
          type,
          value: property.value,
        };
      case "ordinal":
      case "zone":
        return {
          ...property,
          eventPropertyTypeId: property.eventPropertyTypeId,
          type,
          value: property.value !== null ? Number(property.value) : null,
        };
      case "cardinal":
        return {
          ...property,
          eventPropertyTypeId: property.eventPropertyTypeId,
          type,
          value: property.value,
        };
      case "number":
        return {
          ...property,
          eventPropertyTypeId: property.eventPropertyTypeId,
          type,
          value: property.value !== null ? Number(property.value) : null,
        };
      case "text":
        return {
          ...property,
          eventPropertyTypeId: property.eventPropertyTypeId,
          type,
          value: property.value,
        };
      case "longText":
        return {
          ...property,
          eventPropertyTypeId: property.eventPropertyTypeId,
          type,
          value: property.value,
        };

      default:
        throw new Error("Unknown property type");
    }
  });
  return { ...eventsFromServer, properties: eventProperties };
}

function sortEventsByDateProperty(events: FabriqEvent[]) {
  return events.slice().sort((a, b) => {
    const dateA = a.properties.find(
      (p): p is EventDateTimeProperty => p.type === "datetime"
    )?.value;
    const dateB = b.properties.find(
      (p): p is EventDateTimeProperty => p.type === "datetime"
    )?.value;
    return dateA && dateB ? dateB.localeCompare(dateA) : 0;
  });
}
