<template>
  <div class="text-editor" ref="parentDiv">
    <div
      ref="placeholderDiv"
      class="text-editor-placeholder"
      v-if="!modelValue || !modelValue.length"
    >
      {{ placeholder }}
    </div>
    <div
      class="text-editor-content"
      ref="editor"
      @blur="$emit('blur')"
      @input="onInput"
      @keydown="onKeyDown"
      :contenteditable="!readonly"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick, onMounted, watch } from "vue";
import { useI18n } from "@/composables/useI18n";
import { cleanCommentHtml } from "@/utils/comment-parser";
import {
  setCaret,
  getCaretPosition,
  removeNode,
  isComplexHtml,
  scrollSelectionIntoView,
} from "@/utils/content-editable";

export default defineComponent({
  name: "TextEditor",
  emits: ["update:modelValue", "blur"],
  props: {
    readonly: Boolean,
    modelValue: String,
    placeholder: {
      type: String,
      default: "",
    },
    onlyNumber: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup(props, { emit }) {
    const editor = ref();
    const placeholderDiv = ref();
    const parentDiv = ref();
    let content: any;

    onMounted(() => {
      editor.value.addEventListener("paste", function (e: CustomEvent) {
        e.preventDefault();
        const text = (e.originalEvent || e).clipboardData.getData("text/plain");
        addComplexHtml(text);
      });

      content = cleanCommentHtml(props.modelValue);
      setInnerHTML(content);
      setTimeout(() => setEditorHeight(), 5);
    });

    let currentIndex: any;
    const onInput = () => {
      const { index } = getCaretPosition(editor.value.childNodes);
      currentIndex = index;
      const value = editor.value.innerHTML;
      content = `${value}`;
      emit("update:modelValue", value.replace("&nbsp;", " "));
      scrollSelectionIntoView();
    };

    const onKeyDown = (ev: KeyboardEvent) => {
      const selection = document.getSelection();
      const node = selection?.anchorNode;

      if (props.onlyNumber) {
        if (editor.value.innerHTML.indexOf(".") > -1 && ev.key == ".") {
          ev.preventDefault();
          return;
        }

        const reg = /[1-9.]/;
        if (!reg.test(ev.key) && ev.key != "Backspace") {
          ev.preventDefault();
          return;
        }
      }
      if (!isComplexHtml(node, editor.value)) return;
      if (ev.key === "Backspace") {
        removeNode(node?.parentNode);
        emit("update:modelValue", editor.value.innerHTML);
      }
    };

    const setInnerHTML = (value: string) => {
      editor.value.innerHTML = value.trim();
      scrollSelectionIntoView();
    };

    const clear = (keepFocus = false) => {
      editor.value.innerHTML = "";
      if (keepFocus) {
        nextTick(() => focusAndCaretToEnd());
      }
    };

    const focusAndCaretToEnd = () => {
      setCaret(editor.value.childNodes[editor.value.childNodes.length - 1]);
      setTimeout(() => editor.value.focus(), 0);
    };

    const addComplexHtml = (value: string) => {
      setInnerHTML(value);
      setTimeout(() => editor.value.focus(), 0);
      nextTick(() => {
        setCaret(
          currentIndex && editor.value.childNodes[currentIndex]
            ? editor.value.childNodes[currentIndex]
            : editor.value.childNodes[editor.value.childNodes.length - 1]
        );
        emit("update:modelValue", editor.value.innerHTML);
        scrollSelectionIntoView();
      });
    };

    const focus = () => {
      if (!editor.value) return;
      editor.value.focus();
      setCaret(
        currentIndex && editor.value.childNodes[currentIndex]
          ? editor.value.childNodes[currentIndex]
          : editor.value.childNodes[editor.value.childNodes.length - 1]
      );
    };

    const setEditorHeight = () => {
      if (props.modelValue?.length) {
        editor.value.style.minHeight = "auto";
      } else {
        if (!placeholderDiv.value) return;
        const parentStyle = getComputedStyle(parentDiv.value);
        placeholderDiv.value.style.paddingTop = parentStyle.paddingTop;
        placeholderDiv.value.style.paddingLeft = parentStyle.paddingLeft;
        placeholderDiv.value.style.paddingBottom = parentStyle.paddingBottom;
        placeholderDiv.value.style.paddingRight = parentStyle.paddingRight;
        const placeholderRect = placeholderDiv.value.getBoundingClientRect();
        const paddingVertical =
          parseInt(parentStyle.paddingTop) +
          parseInt(parentStyle.paddingBottom);
        editor.value.style.minHeight =
          placeholderRect.height - paddingVertical + "px";
      }
    };

    watch(
      () => props.modelValue,
      () => {
        nextTick(() => setEditorHeight());
      }
    );

    return {
      ...useI18n(),
      parentDiv,
      placeholderDiv,
      editor,
      onInput,
      onKeyDown,
      focus,
      addComplexHtml,
      clear,
      scrollSelectionIntoView,
    };
  },
});
</script>

<style scoped>
.text-editor {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  --font-size: var(--text-editor-font-size, var(--font-size-m));
  --line-height: calc(var(--font-size) * 1.5);
  margin: var(--text-editor-margin, var(--ion-padding)) 0;
}

.text-editor-content {
  background-color: inherit;
  color: inherit;
  display: inline-block;
  width: auto;
  min-height: var(--line-height);
  line-height: var(--line-height);

  font-size: var(--font-size);
  width: 100%;

  outline: 0;

  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.text-editor-placeholder {
  position: absolute;
  pointer-events: none;
  padding: 0;
  font-size: var(--font-size);
  min-height: var(--line-height);
  line-height: var(--line-height);
  top: 0;
  left: 0;
  right: 0;
  color: var(--f-color-disabled);
}
</style>
