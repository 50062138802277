export function localize(object: Localizable, locale: string, number: number) {
  let returnable: string | undefined;

  if (typeof object === "string") {
    returnable = object;
  } else if (isRecordOfTranslations(object, locale)) {
    returnable = object[locale];
  } else if (isRecordOfTranslations(object, "en")) {
    returnable = object.en;
  } else if (isObjectConfiguredWithLocalization(object, locale)) {
    returnable = object.config.i18n[locale];
  } else if (isLocalizedObject(object, locale)) {
    returnable = object.i18n[locale];
  } else if (isLocalizedObject(object, "en")) {
    returnable = object.i18n.en;
  } else if (isNamedObject(object)) {
    returnable = object.name;
  } else if (isCustomField(object)) {
    returnable = object.api_name;
  } else if (isTemplate(object)) {
    returnable = object.label;
  }

  if (returnable && returnable.indexOf("|") !== -1) {
    const splat = returnable.split("|");
    returnable = number > 1 ? splat[1].trim() : splat[0].trim();
  }
  return returnable;
}

// FIXME: We have too many ways of specifying the translations on an entity,
// which results in this confusing type and confusing function.
// Also, it is wasteful in terms of performance because, for a given entity
// type, the way of localizing it is always the same. It means that we do many
// checks that the caller actually knows the answers of.
// This must be redesigned.

export type Localizable =
  | string
  | RecordOfTranslations
  | LocalizedObject
  | ObjectConfiguredWithLocalization
  | NamedObject
  | CustomField
  | TemplateItem;

type RecordOfTranslations = Record<string, string>;

interface LocalizedObject {
  i18n: RecordOfTranslations;
}

interface ObjectConfiguredWithLocalization {
  config: LocalizedObject;
}

interface NamedObject {
  name: string;
}

interface CustomField {
  api_name: string;
}
interface TemplateItem {
  label: string;
}

function isRecordOfTranslations(
  object: unknown,
  locale: string
): object is RecordOfTranslations {
  return typeof (object as any)[locale] === "string";
}

function isObjectConfiguredWithLocalization(
  object: unknown,
  locale: string
): object is ObjectConfiguredWithLocalization {
  return typeof (object as any)?.config?.i18n?.[locale] === "string";
}

function isLocalizedObject(
  object: unknown,
  locale: string
): object is LocalizedObject {
  return typeof (object as any)?.i18n?.[locale] === "string";
}

function isNamedObject(object: unknown): object is NamedObject {
  return typeof (object as any).name === "string";
}

function isCustomField(object: unknown): object is CustomField {
  return typeof (object as any).api_name === "string";
}

function isTemplate(object: TemplateItem) {
  return typeof object.label === "string";
}
