import { useInstancesStore } from "@/store/instances";
import { createListStore } from "./list";
import { ExecutionService } from "@/services/executions";
import { useAnswersStore } from "@/store/answers";
import { v4 as uuidv4 } from "uuid";
import storage from "@/utils/storage";

import { useFabriqStore } from "./fabriq";
import { useFilesStore } from "./files";

import {
  Execution,
  ExecutionAnswer,
  TemplateStep,
  FabriqFile,
  Instance,
} from "@/types";
import { formatDateForRoutine } from "@/utils/routines";
import { fabriqDeepClone } from "@/services/fabriqDeepClone";
import { toRaw } from "vue";

const processExecution = (entity: Execution) => {
  if (!entity) return Promise.resolve(null);
  const filesStore = useFilesStore();
  const answersStore = useAnswersStore();
  const files: Array<FabriqFile> = [];

  const answers = (entity.answers || []).map((a: ExecutionAnswer) => {
    if (a.files && a.files.length) {
      files.push(...a.files.map((fi: any) => ({ ...fi, answer: a.id })));
    }
    return {
      ...a,
      files: undefined,
      instance_execution: entity.id,
      updated_at: a.created_at,
    };
  });
  answersStore.merge(answers);
  filesStore.merge(files);

  return Promise.resolve({ ...entity, answers: undefined });
};

function checkInstanceDone(entity: Execution) {
  const instancesStore = useInstancesStore();
  const instance = instancesStore.collection.find(
    (i: Instance) => i.id === entity.instance
  );
  if (instance) {
    instance.scheduler = {
      ...fabriqDeepClone(toRaw(instance.scheduler)),
      is_done: entity.is_done,
      has_started: true,
    };
    instance.execution_end_date = entity.end_date;
    instance.has_started = true;
    instance.sending = false;
    instancesStore.merge([instance]);
  }
}

const hooks = {
  load(entity: Execution) {
    checkInstanceDone(entity);
    return processExecution(entity);
  },
  update(entity: Execution) {
    checkInstanceDone(entity);
    return processExecution(entity);
  },
};

export const useExecutionsStore = createListStore(
  "executions",
  ExecutionService,
  hooks,
  null,
  {
    async add(
      execution: { instance: number | string; start_date: string },
      steps: Array<TemplateStep>
    ) {
      const fabriqStore = useFabriqStore();
      const isoDate = formatDateForRoutine(new Date());
      if (!fabriqStore.online) {
        const uuid = uuidv4();
        const answers: Array<ExecutionAnswer> = (steps || []).map(
          (step: TemplateStep) => {
            return <ExecutionAnswer>{
              uuid: uuidv4(),
              id: null,
              choices: [],
              step,
              instance_execution: uuid,
              created_at: isoDate,
              updated_at: isoDate,
              pinned: null,
            };
          }
        );
        const created = { uuid, id: null, ...execution, updated_at: isoDate };
        await storage.insertEntity("executions", created);
        this.collection = [...this.collection, created];
        const answersStore = useAnswersStore();
        answersStore.merge(answers);
        return Promise.resolve(created);
      } else if (
        ExecutionService.add &&
        typeof execution.instance === "number"
      ) {
        try {
          const entity = await this.load(execution.instance);
          return entity;
        } catch (e) {
          if (e !== null) return console.error(e);
          const created = await ExecutionService.add({
            ...execution,
            updated_at: isoDate,
          });
          const hookedCreated = await hooks.load({
            ...created,
            created_at: isoDate,
            updated_at: isoDate,
            uuid: uuidv4(),
          });
          this.merge([hookedCreated]);
          return hookedCreated;
        }
      }
    },
  }
);
