function buildUrlWithParams(url: string, params: any) {
  const resp = new URL(url);
  Object.keys(params).forEach((key) =>
    resp.searchParams.append(key, params[key])
  );
  return resp;
}

async function authServerFetch(
  url_: string,
  method = "GET",
  redirect = true,
  data?: Record<string, any>,
  params?: Record<string, any>
): Promise<{ body: any; status: number }> {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (!redirect) {
    headers["x-noredirect"] = "true";
  }
  const url = params ? buildUrlWithParams(url_, params) : url_;
  if (method === "GET") {
    const response = await fetch(url, {
      method: "GET",
      headers,
    });
    return { status: response.status, body: await response.json() };
  } else if (method === "POST") {
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });
    return { status: response.status, body: await response.json() };
  }
  throw new Error("Not implemented: Only GET/POST");
}

export default authServerFetch;
