<script lang="ts" setup>
import { computed } from "vue";
import { TemplateStep, ExecutionAnswer, StepChoice } from "@/types";
import CachedImage from "@/components/tools/CachedImage.vue";
import { useRoutineNudgeCreateTicket } from "@/composables/useRoutineNudgeCreateTicket";
import { useI18n } from "@/composables/useI18n";
import { IonText } from "@ionic/vue";

interface Props {
  step: TemplateStep;
  answer: ExecutionAnswer;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: "select", payload: unknown): void;
}>();

const { t } = useI18n();

const choices = computed(() => {
  return props.step.choices.map((c: StepChoice) => {
    if (!c.id) return c;
    return {
      ...c,
      selected: props.answer?.choices?.includes(c.id),
      file: c.files[0],
    };
  });
});

const { hasNudgeCreateTicketActive } = useRoutineNudgeCreateTicket(
  computed(() => props.step),
  computed(() => props.answer)
);
</script>
<template>
  <div class="step-images">
    <div
      v-for="choice of choices"
      :key="choice.id"
      class="step-choice image"
      :class="{ selected: choice.selected }"
      @click="emit('select', choice.id)"
    >
      <div class="step-image">
        <cached-image
          v-if="choice.file"
          :src="choice.file._file.url"
          :viewer="true"
          :fullScreenButton="true"
        />
        <font-icon v-else name="image" size="3" regular slash />
      </div>
      <div class="image-name" v-if="choice.file">
        {{ choice.text }}
      </div>
    </div>
    <ion-text v-if="hasNudgeCreateTicketActive" class="create-ticket-alert">
      <font-icon name="warning" material />
      <span> {{ t("indicatorPage.nudgeCreateTicketRoutine") }} </span>
    </ion-text>
  </div>
</template>
