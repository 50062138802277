import { ApiClient } from "./clients";
import { Template, ListService } from "@/types";

export const TemplateService: ListService<Template> = {
  async load(id: number): Promise<Template> {
    const template = await ApiClient.get(`/api/v1/template/${id}/`, {
      all_data: "1",
    });
    return { ...template, updated_at: template.created_at };
  },
};
