import { NotSavedYetError } from "@/classes/errors";
import { ApiClient } from "./clients";
import { FabriqFile, ListService } from "@/types";

export class FileSizeExeededError extends Error {}

export const getFormData = async (url: string, key = "_file") => {
  const result = await fetch(url);
  const blob = await result.blob();
  const _file = new File([blob], url);
  // File size must be under 26Mo
  if (_file.size > 26000000) {
    throw new FileSizeExeededError("maximumFileSizeExeeded");
  }
  const formData = new FormData();
  formData.append(key, _file);
  return formData;
};

export const FilesService: ListService<FabriqFile> = {
  async add(entity: any) {
    let url;
    if (entity.ticket) {
      if (typeof entity.ticket !== "number")
        return Promise.reject(new NotSavedYetError("Ticket not saved yet"));
      url = `/api/v1/ticket/${entity.ticket}/files/`;
    } else if (entity.answer) {
      if (typeof entity.answer !== "number")
        return Promise.reject(new NotSavedYetError("Instance not saved yet"));
      url = `/api/v1/files/upload/routines/instanceexecutionanswer/${entity.answer}/`;
      if (entity.url) {
        const formData = new FormData();
        formData.append("urls", entity.url);
        const files = await ApiClient.sendFile(url, formData);
        const file = files[0];
        if (!file) return Promise.reject("Url saving error");
        return {
          uuid: entity.uuid,
          ...file,
          id: file.id || null,
          answer: entity.answer,
        };
      }
    } else if (entity.comment) {
      if (typeof entity.comment !== "number")
        return Promise.reject(new NotSavedYetError("Instance not saved yet"));
      url = `/api/v1/comment/${entity.comment}/files/`;
    }
    if (!url) return;
    if (entity.url) {
      const file = await ApiClient.post(url, { url: entity.url });
      return {
        uuid: entity.uuid,
        ...file,
        id: file.id || null,
        ticket: entity.ticket,
      };
    }
    if (!entity._file || !entity._file.url || !url) return;
    const formData = await getFormData(
      entity._file.url,
      entity.answer ? "files" : "_file"
    );
    const result = await ApiClient.sendFile(url, formData);
    const file = Array.isArray(result) ? result[0] : result;
    return {
      uuid: entity.uuid,
      ...file,
      id: file.id || null,
      ticket: entity.ticket,
      answer: entity.answer,
      comment: entity.comment,
    };
  },
  async remove(entity: FabriqFile): Promise<void> {
    if (entity.ticket) {
      await ApiClient.delete(
        `/api/v1/ticket/${entity.ticket}/file/${entity.id}/`
      );
    } else if (entity.answer) {
      await ApiClient.delete(`/api/v1/files/${entity.id}/`);
    } else if (entity.comment) {
      await ApiClient.delete(
        `/api/v1/comment/${entity.comment}/files/${entity.id}/`
      );
    }
  },
};
