import { ApiClient } from "./clients";
import { ListService, Team } from "@/types";

export const TeamsService: ListService<Team> = {
  async all(): Promise<Array<Team>> {
    const teams = await ApiClient.get(`/api/v1/teams/`);
    return teams;
  },
  async load(id: number): Promise<Array<Team>> {
    return await ApiClient.get(`/api/v1/team/${id}/`);
  },
};
