import config from "@/config";
import * as Sentry from "@sentry/vue";
import { App } from "vue";
import { getCurrentBuild } from "./live-updates";

const sentryEnabled = ["production", "qa"].includes(config.env);

export function buildSentryManager(app: App, platform: string) {
  let needToSetupSentry = true;
  function reportError(error: Error, data?: Record<string, any>) {
    if (needToSetupSentry) {
      throw new Error("Sentry not setup; cannot report error");
    }
    console.error(error, data);
    if (sentryEnabled) {
      Sentry.captureException(error, { data });
    }
  }

  async function setupSentry(subdomain: string): Promise<void> {
    const release = platform === "web" ? "dev" : await getCurrentBuild();
    Sentry.init({
      app,
      environment: config.env,
      release,
      dsn: "https://121c2e37af5bca6b9f3baa03f82680f0@o549372.ingest.us.sentry.io/4507429597544448",
      integrations: [],
      tracesSampleRate: 0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1,
      enableTracing: false,
      logErrors: true,
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
      transportOptions: {
        // @ts-expect-error bad sentry types
        maxQueueSize: 100,
      },
      ignoreErrors: [/.+40(1|3|4).*/],
      beforeSend(event, hint) {
        if (
          hint.originalException &&
          hint.originalException instanceof Error &&
          hint.originalException.message
        ) {
          const message = hint.originalException.message;
          if (
            message.includes("Network Error") ||
            message.includes("Failed to fetch") ||
            message.includes("Failed to load resource") ||
            message.includes("timeout of") ||
            message.includes("UnhandledRejection")
          ) {
            return null;
          }
          event.tags = event.tags || {};
          event.tags["error.type"] = hint.originalException.name;
        }
        if (!sentryEnabled) {
          console.error(
            "Sentry not enabled",
            hint.originalException ??
              hint.syntheticException ??
              hint.data ??
              hint
          );
          return null;
        }

        return event;
      },
      tunnel: `${subdomain}/proxy/sentry/4507429597544448`,
    });
    needToSetupSentry = false;
  }

  function setSentryUser(organizationId: string, futureUserId: string) {
    if (needToSetupSentry) {
      throw new Error("Sentry not setup; cannot set user");
    }
    Sentry.setUser({ id: `${organizationId}__${futureUserId}` });
  }

  return {
    reportError,
    setupSentry,
    setSentryUser,
  };
}

export type SentryManager = ReturnType<typeof buildSentryManager>;
