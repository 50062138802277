<script lang="ts" setup>
import { computed } from "vue";
import type { Category } from "@/types";
import ColoredChip from "@/components/ui/ColoredChip.vue";
import { useI18n } from "@/composables/useI18n";

const { t } = useI18n();

interface Props {
  category: Category;
  readonly?: boolean;
  faded?: boolean;
  small?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  readonly: false,
  faded: false,
  small: false,
});

const emit = defineEmits<{
  (event: "update", payload: null): void;
}>();

const avatar = computed(() => {
  return props.category.avatar?.url;
});
const color = computed(() => {
  return props.category.config?.color || "#aeaeae";
});
const icon = computed(() => {
  return props.category.icon;
});
</script>

<template>
  <colored-chip
    :avatar="avatar"
    :label="category.name || t('tickets.noCategory')"
    :color="color"
    :icon="icon"
    :small="small"
    :material="true"
    :faded="!category.id"
    :clearable="!readonly && !!category.id"
    @remove="emit('update', null)"
  />
</template>
