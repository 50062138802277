<script lang="ts" setup>
import { computed, watch, onBeforeUnmount } from "vue";
import { useTicketsStore } from "@/store/tickets";
import { useI18n } from "@/composables/useI18n";
import { IonLabel, IonRow, useIonRouter } from "@ionic/vue";
import { TicketType, Ticket } from "@/types";
import DueDate from "../tickets/DueDate.vue";

const { t } = useI18n();

const props = defineProps({
  entityId: Number,
});

const router = useIonRouter();
const ticketsStore = useTicketsStore();
const ticket = computed(() =>
  ticketsStore.collection.find((t: Ticket) => t.id === props.entityId)
);
const icon = computed(() => {
  if (ticket.value?._type === TicketType.Communication) return "rss_feed";
  return "assignment";
});
const goToTicket = () => {
  if (!ticket.value) return;
  if (ticket.value._type === TicketType.Communication) return;
  router.push(`/tickets/${ticket.value.uuid}`);
};
let tm: NodeJS.Timeout;
watch(
  () => props.entityId,
  (id) => {
    if (tm) clearTimeout(tm);
    if (!id) return;
    tm = setTimeout(() => ticketsStore.load(id, true), 750);
  },
  { immediate: true }
);
onBeforeUnmount(() => {
  if (tm) clearTimeout(tm);
});
</script>

<template>
  <ion-row
    class="ion-justify-content-between ion-align-items-center ticket-row"
  >
    <ion-row class="ion-align-items-center">
      <font-icon
        class="dependency-icon"
        color="var(--ion-color-secondary)"
        :name="icon"
        material
      />
      <ion-label
        v-if="ticket"
        class="text-ellipsis ticket-title"
        @click="goToTicket"
      >
        {{ ticket.title || t("common.noTitle") }}
      </ion-label>
      <ion-label v-else>{{ t("common.loading") }}</ion-label>
    </ion-row>
    <due-date
      class="dependency-due-date"
      v-if="ticket"
      :date="ticket.due_date"
      readonly
    />
  </ion-row>
</template>

<style scoped>
.ticket-title {
  max-width: calc(100vw - 150px);
}

.ticket-row {
  width: 100%;
}

.ticket-row .font-icon {
  margin-right: var(--ion-padding);
  margin-left: calc(var(--ion-padding) - 2px);
}
</style>
