import { loadingController } from "@ionic/vue";

export class FabriqNativeLoader {
  #loader: HTMLIonLoadingElement | null;
  #message: string;

  constructor(loadingMessage: string) {
    this.#loader = null;
    this.#message = loadingMessage;
  }

  async show(message?: string) {
    if (!this.#loader) {
      this.#loader = await loadingController.create({
        message: message || this.#message,
        cssClass: "f-loader",
        spinner: "crescent",
        translucent: true,
        showBackdrop: true,
      });
    }
    await this.#loader.present();
  }

  async hide() {
    if (!this.#loader) return;
    await this.#loader.dismiss();
    this.#loader = null;
  }
}
