<script lang="ts" setup>
import { computed, PropType, toRefs } from "vue";
import { FilledInstance, RoutineInstance, RoutineScope, User } from "@/types";
import { IonItem, IonLabel } from "@ionic/vue";
import { parseISO, format } from "date-fns";
import { useI18n } from "@/composables/useI18n";
import { useCanStartRoutine } from "@/composables/useCanStartRoutine";
import { useDateString } from "@/composables/useDateString";
import AvatarList from "@/components/business/AvatarList.vue";
import ScoreDisk from "@/components/ui/ScoreDisk.vue";
import RoutineInstanceScope from "@/components/business/routines/RoutineInstanceScope.vue";

const emit = defineEmits(["goto", "reload"]);
const props = defineProps({
  instance: {
    type: Object as PropType<FilledInstance>,
    default: () => ({}),
  },
  users: {
    type: Array as PropType<Array<User>>,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const { loading, instance } = toRefs(props);
const { t } = useI18n();
const dayOfMonthFormat = t("formats.dayOfMonth");
const dateString = useDateString(props.instance, dayOfMonthFormat);
const { canStartRoutine, canBeResent, instanceIsDone, isSending } =
  useCanStartRoutine(instance, loading);
const instanceTimeString = computed<string>(() => {
  if (!props.instance.scheduler || props.instance.scheduler.all_day) return "";
  if (!props.instance?.start_date || !props.instance?.end_date) return "";
  const startDate = format(parseISO(props.instance.start_date), "HH:mm");
  const endDate = format(parseISO(props.instance.end_date), "HH:mm");
  return `${startDate} - ${endDate}`;
});

const gotoInstance = async () => {
  if (instanceIsDone.value) return;
  if (isSending.value) return;
  if (!canStartRoutine.value && !canBeResent.value) return;
  emit("goto", props.instance);
};

const score = computed(() => {
  return +(props.instance.score?.realized || 0);
});

const potential = computed(() => {
  return +(props.instance.score?.potential || 0);
});

function getInstanceScope(instance: RoutineInstance): RoutineScope {
  return {
    auditee: instance.scheduler?.auditee || null,
    zone: instance.scheduler?.properties?.zone || [],
    product: instance.scheduler?.properties?.product || [],
    equipment: instance.scheduler?.properties?.equipment || [],
  };
}
</script>

<template>
  <ion-item
    lines="none"
    class="f-list-item"
    :class="{ 'look-disabled': instance.disabled }"
    @click="gotoInstance"
  >
    <avatar-list
      class="ion-margin-end start"
      :ids="instance.users"
      :users="users"
    />
    <ion-label class="instance-title">
      {{ props.instance.routine?.title || t("common.noTitle") }}
      <div class="hint">
        {{ dateString }}
        {{ instanceTimeString ? `- ${instanceTimeString}` : "" }}
      </div>
      <RoutineInstanceScope
        class="instance-scope"
        :scope="getInstanceScope(instance as RoutineInstance)"
      />
    </ion-label>
    <template v-if="instanceIsDone">
      <score-disk
        v-if="potential > 0"
        style="margin-right: 6px"
        :size="32"
        :score="score"
        :max="potential"
      />
      <font-icon
        class="done-instance"
        name="check_circle"
        size="1.5"
        material
        color="var(--ion-color-success)"
      />
    </template>
    <div
      class="start-instance"
      :class="{ 'look-disabled': isSending }"
      v-else-if="isSending"
    >
      <font-icon
        slot="end"
        class="done-instance"
        name="refresh"
        size="0.875"
        material
        color="var(--ion-color-primary)"
      />
      {{ t("common.uploading") }}
    </div>
    <div class="start-instance" v-else-if="canBeResent">
      <font-icon
        slot="end"
        class="done-instance"
        name="error"
        size="0.875"
        material
        color="var(--ion-color-danger)"
      />
      {{ t("common.notSent") }}
    </div>
    <template v-else>
      <div
        class="start-instance"
        :class="{
          'look-disabled': !canStartRoutine,
          clickable: canStartRoutine,
        }"
        v-if="instance?.scheduler?.has_started"
      >
        <font-icon
          name="play_arrow"
          size="0.875"
          color="var(--ion-color-secondary)"
          material
        />
        {{ t("resume") }}
      </div>
      <div v-else-if="canStartRoutine" class="start-instance">
        <font-icon
          name="play_arrow"
          size="0.875"
          color="var(--ion-color-secondary)"
          material
        />
        {{ t("start") }}
      </div>
    </template>
  </ion-item>
</template>

<style scoped>
ion-item {
  --f-border-radius: 5px;
  --padding-start: var(--ion-padding);
  --padding-end: 0;
}

.instance-scope {
  font-size: var(--font-size-s);
  margin-top: calc(var(--ion-padding) / 2);
}

.instance-title {
  font-size: var(--font-size-m);
}

.instance-title .hint {
  margin-top: calc(var(--ion-padding) / 2);
}
</style>
