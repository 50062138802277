<script setup lang="ts">
import { IonInput } from "@ionic/vue";

interface Props {
  modelValue: string;
}

withDefaults(defineProps<Props>(), { modelValue: "" });

const emit = defineEmits<{
  (event: "update:modelValue", payload: string): void;
}>();

let searchTimer: any;
const updateModelValue = (query: any) => {
  if (searchTimer) clearTimeout(searchTimer);
  searchTimer = setTimeout(async () => {
    emit("update:modelValue", query);
  }, 750);
};
</script>

<template>
  <ion-input
    class="debounce-input"
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
  />
</template>

<style scoped>
.debounce-input {
  --border: none;
  --background: transparent;
  --placeholder-color: var(--f-color-discrete);
  --placeholder-opacity: 1;
  border: none;
}
</style>
