import { ApiClient } from "./clients";
import { TicketField, ListService } from "@/types";

export const FieldsService: ListService<TicketField> = {
  async add(entity: TicketField): Promise<TicketField> {
    if (isNaN(entity.ticketId)) return Promise.reject("ticketId not integer");
    await ApiClient.put(`/api/v1/ticket/${entity.ticketId}/fields/`, {
      type: entity.type,
      value: entity.fieldId,
    });
    return { id: `${entity.ticketId}-${entity.fieldId}`, ...entity };
  },
  async remove(entity: TicketField): Promise<void> {
    if (isNaN(entity.ticketId)) return Promise.reject("ticketId not integer");
    await ApiClient.delete(
      `/api/v1/ticket/${entity.ticketId}/fields/`,
      undefined,
      {
        type: entity.type,
        value: entity.fieldId,
      }
    );
    return;
  },
};
