/* eslint-disable no-undef */

interface WebSocketEventMap {
  close: CloseEvent;
  error: Event;
  message: MessageEvent;
  open: Event;
}

export class ReconnectingWebSocket extends EventTarget implements WebSocket {
  static readonly BACKOFF_MULTIPLIER = 2;
  static readonly MAX_BACKOFF_DELAY = 30000;
  static readonly INITIAL_DELAY = 1;
  static readonly KEEP_ALIVE_DELAY = 60000;

  CONNECTING = 0 as const;
  OPEN = 1 as const;
  CLOSING = 2 as const;
  CLOSED = 3 as const;

  #backoffDelay = 0;

  onclose: ((this: WebSocket, ev: CloseEvent) => any) | null = null;
  onerror: ((this: WebSocket, ev: Event) => any) | null = null;
  onmessage: ((this: WebSocket, ev: MessageEvent) => any) | null = null;
  onopen: ((this: WebSocket, ev: Event) => any) | null = null;

  addEventListener<K extends keyof WebSocketEventMap>(
    type: K,
    listener: (this: WebSocket, ev: WebSocketEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): void;
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void {
    super.addEventListener(type, listener, options);
  }
  removeEventListener<K extends keyof WebSocketEventMap>(
    type: K,
    listener: (this: WebSocket, ev: WebSocketEventMap[K]) => any,
    options?: boolean | EventListenerOptions
  ): void;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
  ): void {
    super.removeEventListener(type, listener, options);
  }

  #socket?: WebSocket;
  #webSocketFactory: (url: string) => WebSocket;

  url: string;

  constructor(
    url: string,
    webSocketFactory: (url: string) => WebSocket = (url) => new WebSocket(url)
  ) {
    super();
    this.url = url;
    this.#webSocketFactory = webSocketFactory;
    this.addEventListener("open", (event) => {
      this.#backoffDelay = ReconnectingWebSocket.INITIAL_DELAY;
      if (this.onopen) {
        this.onopen(event);
      }
    });
    this.addEventListener("close", (event) => {
      if (this.onclose) {
        this.onclose(event);
      }
      this.#updateBackoffDelay();
      setTimeout(() => this.open(), this.#backoffDelay);
    });
    this.addEventListener("message", (event) => {
      if (this.onmessage) {
        this.onmessage(event);
      }
    });
    this.addEventListener("error", (event) => {
      if (this.onerror) {
        this.onerror(event);
      }
    });
  }

  emit(type: string, payload: unknown) {
    if (this.#socket && this.#socket.readyState === this.OPEN) {
      return this.#socket.send(JSON.stringify({ t: type, p: payload }));
    }
    setTimeout(() => this.emit(type, payload), 100);
  }

  open() {
    this.#socket = this.#webSocketFactory(this.url);
    this.#socket.addEventListener("open", (event) => {
      this.dispatchEvent(cloneEvent(event));
    });
    this.#socket.addEventListener("close", (event) => {
      this.dispatchEvent(cloneEvent(event));
    });
    this.#socket.addEventListener("message", (event) => {
      this.dispatchEvent(cloneEvent(event));
    });
    this.#socket.addEventListener("error", (event) => {
      console.error("WebSocket error", event);
      this.dispatchEvent(cloneEvent(event));
    });
  }

  close() {
    if (this.#socket) {
      this.#socket.close();
    }
  }

  send(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
    if (this.#socket) {
      this.#socket.send(data);
    }
  }

  #updateBackoffDelay(): void {
    const backoffDelay =
      this.#backoffDelay * ReconnectingWebSocket.BACKOFF_MULTIPLIER;
    this.#backoffDelay = Math.min(
      backoffDelay,
      ReconnectingWebSocket.MAX_BACKOFF_DELAY
    );
  }

  get binaryType() {
    return this.#socket?.binaryType ?? "blob";
  }
  get bufferedAmount() {
    return this.#socket?.bufferedAmount ?? 0;
  }
  get extensions() {
    return this.#socket?.extensions ?? "";
  }
  get protocol() {
    return this.#socket?.protocol ?? "";
  }
  get readyState() {
    return this.#socket?.readyState ?? WebSocket.CLOSED;
  }
}

function cloneEvent(event: Event): Event {
  switch (event.type) {
    case "open":
    case "close":
    case "error":
      return new Event(event.type, event);
    case "message":
      const messageEvent = event as MessageEvent;
      return new MessageEvent("message", {
        data: messageEvent.data,
        origin: messageEvent.origin,
        lastEventId: messageEvent.lastEventId,
        source: messageEvent.source,
      });
    default:
      // Throw an error for unknown event types. You can add more cases to handle other event types if needed.
      throw new Error(`Unknown event type: ${event.type}`);
  }
}
