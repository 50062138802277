<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "@/composables/useI18n";
import { IonModal } from "@ionic/vue";
import FieldPickerModal from "@/components/modals/FieldPickerModal.vue";
import UserAvatarInput from "@/components/business/UserAvatarInput.vue";
import UserAvatar from "@/components/business/UserAvatar.vue";
import DueDate from "@/components/business/tickets/DueDate.vue";
import { Category, Field, FieldVue, Label, Status, Team } from "@/types";
import { useFabriqStore } from "@/store/fabriq";
import { getColorFromId, rgbToHex } from "@/utils/colors";
import { useFieldsStore } from "@/store/fields";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { useClientStore } from "@/store/client";
import { TeamSharingSettings } from "@/types/teamSharing";

const icons = [
  { key: "owner", icon: "person", outlined: true },
  { key: "due_date", icon: "today" },
  { key: "teams", icon: "group" },
  { key: "categories", icon: "category" },
  { key: "priority", icon: "flag" },
  { key: "status", icon: "add_box" },
  { key: "zone", icon: "location_on" },
  { key: "equipment", icon: "inventory" },
  { key: "product", icon: "precision_manufacturing" },
  { key: "labels", icon: "tag" },
  { key: "custom_1", icon: "redo" },
  { key: "custom_2", icon: "redo" },
];

interface Props {
  field: FieldVue;
  fields: any[];
  isAdmin: boolean;
  teams: Team[];
  allTeams: Team[];
  teamSharingSettings: TeamSharingSettings;
  teamsFields: any[];
  templateFields: any[];
  nudgeIsShown: boolean;
}

const emit = defineEmits(["update", "modal"]);
const props = defineProps<Props>();
const { t } = useI18n();
const editModal = ref(false);
const dueDateField = ref();
const ownerField = ref();
const labelReg = /^([^|]*)\s?\|\s?([^|]*)$/i;
const label = computed(() => {
  if (templateField.value?.name) return templateField.value?.name;
  if (props.field.key.startsWith("custom_")) {
    const locale = fabriqStore.locale;
    const custom = clientStore.custom_fields[props.field.key];
    if (!custom) return;
    const labels = custom.i18n[locale] || "";
    const label = custom.multiple
      ? labels.replace(labelReg, "$2").trim()
      : labels.replace(labelReg, "$1").trim();
    return label;
  }
  return t(`tickets.labels.${props.field.key}`);
});
const placeholder = computed(() => {
  if (templateField.value?.placeholder) return templateField.value?.placeholder;
  if (props.field.key.startsWith("custom_")) {
    return label.value;
  }
  return t(`tickets.placeholders.${props.field.key}`);
});
const choices = computed(() => {
  if (props.field.key.startsWith("custom_")) {
    return label.value;
  }
  return t(`tickets.choices.${props.field.key}`);
});
const icon = computed(() => {
  const icon = icons.find((i) => i.key === props.field.key);
  return icon ? icon.icon : null;
});
const outlined = computed(() => {
  const icon = icons.find((i) => i.key === props.field.key);
  return icon ? icon.outlined : false;
});

const getValues = (value: any) => {
  if (props.field.multiple && Array.isArray(value)) {
    const values: any = [];
    value.forEach((id: any) => {
      let val = possibleValues.value.find((va: any) => va.id === id);
      if (!val && props.field.key === "teams") {
        const siteTeam = props.teamSharingSettings.site?.find(
          (team) => team.id === id
        );
        if (siteTeam) val = siteTeam;
        else {
          const organisationTeam = props.teamSharingSettings.organization?.find(
            (team) => team.id === id
          );
          if (organisationTeam) val = organisationTeam;
        }
      }
      if (val) values.push(val);
    });
    return values;
  } else {
    const found = possibleValues.value.find(
      (v: Field | Label | Status | Category) => v.id === value
    );
    if (!found) return [];
    return [found];
  }
};

const possibleValues = computed(() => getFieldValues(props.field.key) || []);
const selectedValues = computed(() => {
  const value = props.field.value;
  if (props.field.key === "due_date") return [value];
  if (props.field.key === "owner") return [value];
  const values = getValues(value);
  return values.map((v: Field | Label | Status | Category) => {
    const icon =
      props.field.key === "priority" ? "flag" : v.icon || v.config?.icon;
    return {
      id: v.id,
      name: v.name,
      icon,
      coloredIconOnly: props.field.coloredIconOnly,
      config: v.config,
    };
  });
});

const regularFields = [
  "due_date",
  "teams",
  "categories",
  "owner",
  "labels",
  "status",
];

const isDueDate = computed(() => props.field.key === "due_date");
const isOwner = computed(() => props.field.key === "owner");

const updateField = (values: any) => {
  emit("update", values);
  if (!props.field.multiple) closeEditModal();
};

// The user has to select a team first
// The team selection modal can't be blocked though
const isTeamSelected = computed(
  () => props.teams.length > 0 || props.field.key === "teams"
);

const openEditModal = () => {
  if (isDueDate.value) {
    dueDateField.value.$el.click();
    return;
  }
  if (isOwner.value) {
    ownerField.value.$el.click();
    return;
  }
  if (props.field.readonly) return;
  if (!possibleValues.value || !possibleValues.value.length) return;
  editModal.value = true;
  if (regularFields.includes(props.field.key)) return;
  mixpanelTracker.track(`select | ${props.field.key} | ticket`);
};

const fieldsStore = useFieldsStore();

const closeEditModal = () => {
  setTimeout(() => (editModal.value = false), 200);
  fieldsStore.save();
};
const assignChipColor = (item: Category): `#${string}` | "transparent" => {
  if (item.config?.color) return item.config.color;
  if (props.field.key === "categories")
    return rgbToHex(getColorFromId(item.id));
  return "transparent";
};
const fabriqStore = useFabriqStore();
const clientStore = useClientStore();

const getFieldValues = (key: any): any => {
  if (!clientStore) return [];
  switch (key) {
    case "owner": {
      if (!props.teams?.length) return props.teams;
      return null;
    }
    case "teams":
      const userId = fabriqStore.user?.id || undefined;
      const userTeams = (props.allTeams || []).filter(
        (team: Team) =>
          userId &&
          team.users?.includes(userId) &&
          !team.auditors?.includes(userId)
      );
      return userTeams;
    case "categories": {
      return (
        clientStore.categories.map((cat) => {
          const color = assignChipColor(cat);
          if (!cat.config?.color) {
            cat.config = { ...cat.config, color };
          }
          return cat;
        }) || []
      );
    }
    case "status": {
      return clientStore.statuses || [];
    }
    case "labels":
      return (clientStore.labels || []).map((l: any) => ({
        ...l,
        config: { color: getColorFromId(l.id) },
      }));
    default: {
      if (!props.teamsFields) return [];
      const ids = props.teams?.length
        ? props.teams.reduce((acc: any, a: any) => {
            acc.push(...a.extra_fields);
            return acc;
          }, [])
        : null;
      return props.teamsFields.filter((k: Field) => {
        if (k.type !== key) return false;
        if (!ids) return true;
        return ids.includes(k.id);
      });
    }
  }
};

const templateField = computed(() =>
  props.templateFields.find((f: any) => f.label === props.field.key)
);

const nudge = computed(() => {
  return templateField.value?.nudge && templateField.value?.nudgeIsShown;
});
</script>

<template>
  <div class="field clickable" @click="openEditModal">
    <div class="field-label">
      <font-icon
        :name="icon"
        :outlined="outlined"
        size="0.875"
        color="var(--ion-color-primary-shade)"
        material
      />
      <div>{{ label }}</div>
    </div>
    <div class="field-values">
      <div class="field-date" v-if="isDueDate && field.value">
        <due-date
          ref="dueDateField"
          :date="field.value"
          :end="field.end"
          @date="updateField"
          :readonly="field.readonly"
        />
      </div>
      <div
        class="field-owner"
        v-else-if="isOwner && field.value && field.value.owner"
      >
        <user-avatar
          ref="ownerField"
          v-if="field.readonly"
          :user="field.value.owner"
          :name="field.value.ownerFullName"
        />
        <user-avatar-input
          ref="ownerField"
          v-else
          :user="field.value.owner"
          :name="field.value.ownerFullName"
          :only-me="!teams?.length"
          :teams="teams"
          @update="updateField($event ? $event.id : null)"
        />
      </div>
      <div
        v-else-if="
          !isDueDate && !isOwner && selectedValues && selectedValues.length
        "
      >
        <colored-chip
          v-for="(value, idx) in selectedValues"
          :key="idx"
          :label="value.name"
          :color="value.config?.color"
          :emoji="value.config?.emoji"
          :colored-icon-only="value.coloredIconOnly"
          :icon="value.icon"
          :material="true"
        />
      </div>
      <div
        v-else
        class="field-empty"
        :class="{ nudge }"
        :data-values="possibleValues"
      >
        {{
          (possibleValues && possibleValues.length) || isDueDate || isOwner
            ? t("empty")
            : t("noValue")
        }}
        <due-date
          class="fake-empty-field"
          v-if="isDueDate"
          ref="dueDateField"
          @date="updateField"
          :readonly="field.readonly"
        />
        <template v-if="isOwner">
          <user-avatar
            ref="ownerField"
            v-if="field.readonly"
            class="fake-empty-field"
          />
          <user-avatar-input
            v-else
            ref="ownerField"
            class="fake-empty-field"
            :only-me="!teams?.length"
            :teams="possibleValues"
            @update="updateField($event ? $event.id : null)"
          />
        </template>
      </div>
    </div>

    <ion-modal
      :is-open="editModal"
      mode="ios"
      :breakpoints="[0, 1]"
      :initialBreakpoint="1"
      @didDismiss="editModal = false"
    >
      <field-picker-modal
        @cancel="closeEditModal"
        @done="updateField"
        :isTeamSelected="isTeamSelected"
        :label="label"
        :placeholder="placeholder"
        :choices="choices"
        :selectedValues="selectedValues"
        :possibleValues="possibleValues"
        :is-admin="isAdmin"
        :teams="teams"
        :team-sharing-settings="teamSharingSettings"
        :all-teams="allTeams"
        :coloredIconOnly="field.coloredIconOnly"
        :field-key="field.key"
        :multiple="field.multiple"
      />
    </ion-modal>
  </div>
</template>

<style scoped>
.no-value {
  display: none;
}
</style>
