<template>
  <ion-page class="document-information-page">
    <ion-header mode="ios">
      <ion-toolbar
        class="ion-padding-start document-information-toolbar transparent-toolbar"
      >
        <back-button slot="start" @back="goBack" />
        <ion-buttons slot="end">
          <ion-button class="toolbar-end-button" @click="goBack">
            <font-icon
              name="info"
              size="1.25"
              material
              outlined
              color="#3D56FF"
            />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="document-information-content">
      <div v-if="document" class="document-meta-information">
        <div class="meta-information">
          <span> {{ t("knowledgePage.documentType.name") }} </span>
          <span>
            {{ document.documentTypeName }}
          </span>
        </div>
        <div class="meta-information">
          <span> {{ t("knowledgePage.table.headers.status") }} </span>
          <span>
            {{ t(`knowledgePage.status.${document.status}`) }}
          </span>
        </div>
        <div class="meta-information">
          <span> {{ t("knowledgePage.document.versionNumber") }} </span>
          <span>
            {{ document.versionNumber }}
          </span>
        </div>
        <div class="meta-information">
          <span> {{ t("identifier") }} </span>
          <span>
            {{ document.identifier }}
          </span>
        </div>
        <div v-if="document.fields.expiresAt" class="meta-information">
          <span> {{ t("expiresAt") }} </span>
          <span>
            {{ formatDate(document.fields.expiresAt) }}
          </span>
        </div>
        <div v-if="document.fields.effectiveFrom" class="meta-information">
          <span> {{ t("effectiveFrom") }} </span>
          <span>
            {{ formatDate(document.fields.effectiveFrom) }}
          </span>
        </div>
        <div class="meta-information">
          <span> {{ t("teamPage.lastUpdated") }} </span>
          <span>
            {{ formatDate(document.lastVersionAt) }}
          </span>
        </div>
        <div v-if="authorName" class="meta-information">
          <span> {{ t("author") }} </span>
          <span>
            {{ authorName }}
          </span>
        </div>
        <!-- Team sharing ?? -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import BackButton from "@/components/ui/BackButton.vue";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButton,
  IonButtons,
  useIonRouter,
  toastController,
} from "@ionic/vue";
import { useKnowledgeDocumentsStore } from "@/store/knowledgeDocuments.js";
import { DocumentViewWithDocumentType } from "@/types/knowledge";
import { computed, onMounted, ref } from "vue";
import { useClientStore } from "@/store/client";
import { format, parseISO } from "date-fns";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const knowledgeDocumentsStore = useKnowledgeDocumentsStore();
const clientStore = useClientStore();
const { t } = useI18n();

const router = useIonRouter();
const route = useRoute();
function goBack() {
  router.canGoBack() ? router.back() : router.push("/main/knowledge");
}
const dateFormat = t("formats.dateVerboseShy");

function formatDate(date: string) {
  const parsedDate = parseISO(date);
  return format(parsedDate, dateFormat);
}
const document = ref<DocumentViewWithDocumentType | undefined>(undefined);

function findUser(futureUserId: string) {
  return clientStore.findUserByFutureUserId(futureUserId);
}
const authorName = computed(() => {
  if (!document.value) {
    return undefined;
  }
  const foundUser = findUser(document.value.authorId);
  if (foundUser) {
    return `${foundUser.first_name} ${foundUser.last_name}`;
  }
  return undefined;
});

const documentId = Array.isArray(route.params.id)
  ? route.params.id[0]
  : route.params.id;

onMounted(async () => {
  document.value = await knowledgeDocumentsStore.findDocument(documentId);
  if (!document.value) {
    const toast = await toastController.create({
      message: t("knowledgePage.notFound"),
      duration: 2000,
    });
    await toast.present();
    goBack();
  }
});
</script>

<style lang="postcss" scoped>
.document-information-page .document-information-toolbar,
.document-information-page .document-information-content {
  --border-width: 0;
}
.document-meta-information {
  margin-top: 24px;
  width: calc(100% - 32px);
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .meta-information {
    font-size: 14px;
    display: flex;
    width: 100%;
    gap: 12px;

    :first-child {
      width: 30%;
      color: #98a2b3;
    }
    :last-child {
      text-align: right;
    }
  }
}
</style>
