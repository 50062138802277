<script lang="ts" setup>
import { computed } from "vue";
import CachedImage from "@/components/tools/CachedImage.vue";

const props = withDefaults(
  defineProps<{
    extension: string;
    fallbackColor?: string;
  }>(),
  {
    fallbackColor: "var(--ion-color-primary)",
  }
);

const icons = {
  pdf: { material: false, name: "file-pdf" },
  doc: { material: false, name: "file-word" },
  docx: { material: false, name: "file-word" },
  xls: { material: false, name: "file-excel" },
  xlsx: { material: false, name: "file-excel" },
  xlsm: { material: false, name: "file-excel" },
  csv: { material: false, name: "file-excel" },
  ppt: { material: false, name: "file-powerpoint" },
  pptx: { material: false, name: "file-powerpoint" },
  zip: { material: false, name: "file-archive" },
  rar: { material: false, name: "file-archive" },
  gitlab: "fab fa-gitlab",
  youtube: "fab fa-youtube",
  mov: { material: true, name: "play_circle_outline" },
  mp4: { material: true, name: "play_circle_outline" },
  mpg: { material: true, name: "play_circle_outline" },
  link: { material: true, name: "attach_file" },
};
const images = {
  notion:
    "https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png",
  slack: "https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg",
  airtable:
    "https://www.brandeps.com/logo-download/A/Airtable-logo-vector-01.svg",
  mixpanel:
    "https://www.insided.com/hubfs/inSided-2019/Logos/Mixpanel-logo-04.svg",
  postman:
    "https://seeklogo.com/images/P/postman-logo-F43375A2EB-seeklogo.com.png",
  pipedrive:
    "https://cdn.ireland.production.livestorm.io/uploads/organization/avatar/826fabc2-e987-4291-836b-0049c18d02d0/size_120x120_ed706449-4283-42ee-811a-d0d4104b538d.png?v=1525183977",
  loom: "https://ik.imagekit.io/himalayas/loom_logo_o7hg-nhEV.png",
};
const colors = {
  pdf: "#ff0000",
  doc: "#00a4ef",
  docx: "#00a4ef",
  xls: "#7fba00",
  xlsx: "#7fba00",
  xlsm: "#7fba00",
  csv: "#7fba00",
  ppt: "#f25022",
  pptx: "#f25022",
  gitlab: "#ff6131",
  youtube: "#ff0000",
};
const color = computed(() => colors[props.extension] || props.fallbackColor);
const icon = computed(() => icons[props.extension] || "attach_file");
const image = computed(() => images[props.extension] || null);
</script>

<template>
  <cached-image class="file-icon-image" v-if="image" :src="image" />
  <font-icon
    v-else
    :material="icon.material"
    :outlined="icon.outlined"
    :name="icon.name"
    size="2.95"
    :color="color"
  />
</template>
