import { format, formatRFC3339, parseISO, nextMonday } from "date-fns";

export const getNextMonday = (format_ = "yyyy-MM-dd", date = new Date()) => {
  return format(nextMonday(date), format_);
};

export const toZFormat = (date: string | Date | number | null) => {
  if (date === null) return null;
  try {
    const iso = typeof date === "string" ? parseISO(date) : date;
    return formatRFC3339(iso);
  } catch (e) {
    console.warn("Date in wrong format", e, date);
  }
  return date;
};

const toTwoDigitNumber = (nb: number): string => {
  if (nb < 10) return `0${nb}`;
  return `${nb}`;
};

export const secondsToDuration = (seconds_: number): string => {
  const hours = Math.floor(seconds_ / 3600);
  const rest = seconds_ % 3600;
  const minutes = Math.floor(rest / 60);
  const seconds = rest % 60;
  return `${hours ? `${toTwoDigitNumber(hours)}h` : ""}${
    hours || minutes ? `${toTwoDigitNumber(minutes || 0)}mn` : ""
  }${toTwoDigitNumber(seconds || 0)}s`;
};

export type DateTimePrecision =
  | "millisecond"
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

export const dateTimePrecisionOrder: string[] = [
  "year",
  "quarter",
  "month",
  "weekYear",
  "weekQuarter",
  "weekMonth",
  "week",
  "day",
  "hour",
  "minute",
  "second",
  "millisecond",
];

const stringRep: unique symbol = Symbol(
  "Key to access cached string representation of DateTime"
);

export interface DateTime extends Record<string, number | undefined> {
  year: number;
  quarter?: number;
  month?: number;
  week?: number;
  weekYear?: number;
  weekQuarter?: number;
  weekMonth?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
  millisecond?: number;
}

export const dateDependencies: Record<DateTimePrecision, DateTimePrecision[]> =
  {
    year: ["year"],
    quarter: ["year", "quarter"],
    month: ["year", "month"],
    day: ["year", "month", "day"],
    week: ["year", "week"],
    hour: ["year", "month", "day", "hour"],
    minute: ["year", "month", "day", "hour", "minute"],
    second: ["year", "month", "day", "hour", "minute", "second"],
    millisecond: [
      "year",
      "month",
      "day",
      "hour",
      "minute",
      "second",
      "millisecond",
    ],
  };

function getNumberOfWeek(date: Date): {
  year: number;
  month: number;
  quarter: number;
  week: number;
} {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  newDate.setDate(newDate.getDate() + 4 - (newDate.getDay() || 7));
  const yearStart = new Date(newDate.getFullYear(), 0, 1);
  const weekNo = Math.ceil(
    ((newDate.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7
  );

  const month = newDate.getMonth() + 1;
  return {
    year: newDate.getFullYear(),
    month,
    quarter: getQuarterFromMonth(month),
    week: weekNo,
  };
}

function getQuarterFromMonth(month: number) {
  return Math.floor((month - 1) / 3) + 1;
}

export function generateDateTimeFromDate(
  date: Date,
  precision: DateTimePrecision = "millisecond"
): DateTime {
  const weekData = getNumberOfWeek(date);
  const month = date.getMonth() + 1;
  const dateTime: DateTime = {
    year: date.getFullYear(),
    quarter: getQuarterFromMonth(month),
    month,
    weekYear: weekData.year,
    weekQuarter: weekData.quarter,
    weekMonth: weekData.month,
    week: weekData.week,
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
    millisecond: date.getMilliseconds(),
  };
  const returnedDateTime: DateTime = { year: dateTime.year };
  if (precision === "week") {
    return {
      year: dateTime.weekYear!,
      quarter: dateTime.weekQuarter,
      month: dateTime.weekMonth,
      week: dateTime.week,
    };
  }
  dateDependencies[precision].forEach((dateTimeParam) => {
    if (dateTimeParam === "month") {
      returnedDateTime.quarter = dateTime.quarter;
    }
    if (dateTimeParam === "day") {
      returnedDateTime.weekYear = dateTime.weekYear;
      returnedDateTime.weekMonth = dateTime.weekMonth;
      returnedDateTime.weekQuarter = dateTime.weekQuarter;
      returnedDateTime.week = dateTime.week;
    }
    returnedDateTime[dateTimeParam] = dateTime[dateTimeParam]!;
  });
  return returnedDateTime;
}

export const dateMap: Record<string, string> = {
  year: "",
  quarter: "Q",
  month: "M",
  weekYear: "WY",
  weekQuarter: "WQ",
  weekMonth: "WM",
  week: "W",
  day: "D",
  hour: "H",
  minute: "m",
  second: "s",
  millisecond: "ms",
};

export const dateMapPrecision: Record<string, number> = {
  year: 4,
  quarter: 1,
  month: 2,
  weekYear: 4,
  weekQuarter: 1,
  weekMonth: 2,
  week: 2,
  day: 2,
  hour: 2,
  minute: 2,
  second: 2,
  millisecond: 3,
};

function getTimezoneOffset(): {
  offsetSign: "+" | "-";
  offsetHours: number;
  offsetMinutes: number;
} {
  const offset = new Date().getTimezoneOffset();
  const offsetSign = offset < 0 ? "+" : "-";
  const offsetHours = Math.floor(Math.abs(offset) / 60);
  const offsetMinutes = Math.abs(offset) % 60;
  return { offsetSign, offsetHours, offsetMinutes };
}

function getPaddedTimezoneOffset(): {
  offsetSign: "+" | "-";
  offsetHours: string;
  offsetMinutes: string;
} {
  const clientTimeZone = getTimezoneOffset();

  return {
    offsetSign: clientTimeZone.offsetSign,
    offsetHours: clientTimeZone.offsetHours.toString().padStart(2, "0"),
    offsetMinutes: clientTimeZone.offsetMinutes.toString().padStart(2, "0"),
  };
}

/**
 * The result of that function is cached inside the object.
 * FIXME: This is a hack! We should have an opaque value object that
 * encapsulates its own string representation, but it's a significant effort.
 */
export function dateTimeToDateString(dateTime: DateTime): string {
  let dateStr =
    (dateTime[stringRep as any] as unknown as string | undefined) ?? "";
  if (dateStr) {
    return dateStr;
  }
  for (const k of dateTimePrecisionOrder) {
    if (dateMap[k] !== undefined && dateTime[k]) {
      let value = `${dateTime[k]}`;
      if (value.length < dateMapPrecision[k]) {
        value = "0".repeat(dateMapPrecision[k] - value.length) + value;
      }
      dateStr += dateMap[k] + value;
    }
  }
  Object.defineProperty(dateTime, stringRep, {
    value: dateStr,
    configurable: false,
    enumerable: false,
    writable: false,
  });
  return dateStr;
}

export function isoDateToInputDate(isoDate: string) {
  const date = new Date(Date.parse(isoDate));

  const { offsetSign, offsetHours, offsetMinutes } = getTimezoneOffset();

  if (offsetSign === "-") {
    date.setHours(date.getHours() - offsetHours);
    date.setMinutes(date.getMinutes() - offsetMinutes);
  } else {
    date.setHours(date.getHours() + offsetHours);
    date.setMinutes(date.getMinutes() + offsetMinutes);
  }

  const splitDate = format(date, "yyyy-MM-dd HH:mm:00").split(" ");

  const startDate = splitDate[0];
  const hours = splitDate[1].slice(0, 2);
  const minutes = splitDate[1].slice(3, 5);
  const startTime = `${hours}:${minutes}`;
  return {
    startDate,
    startTime,
  };
}

export function inputDateToIsoDate(startDate: string, startTime: string) {
  const { offsetSign, offsetHours, offsetMinutes } = getPaddedTimezoneOffset();
  const isoString = `${startDate}T${startTime}${offsetSign}${offsetHours}:${offsetMinutes}`;
  return isoString;
}
