import { toastController } from "@ionic/vue";
import { useI18n } from "./useI18n";

export const useNoOffline = () => {
  const { t } = useI18n();
  const noOffline = async () => {
    const toast = await toastController.create({
      message: t("routines.offline"),
      duration: 2000,
    });
    toast.present();
  };

  return {
    noOffline,
  };
};
