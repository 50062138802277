import { computed, ComputedRef, Ref } from "vue";
import { useI18n } from "@/composables/useI18n";
import { useUsers } from "@/composables/useUsers";
import { useTeamsStore } from "@/store/teams";
import { useTasksStore } from "@/store/tasks";
import { useFilesStore } from "@/store/files";
import { Category, FabriqFile, Task, Team, Ticket, User } from "@/types";
import { storeToRefs } from "pinia";
import { useTicketsStore } from "@/store/tickets";
import { formatISO } from "date-fns";
import { ticketUpdatedByMe } from "@/utils/socket";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { useClientStore } from "@/store/client";

export const useTicket = (ticket: Ref<Ticket> | ComputedRef<Ticket>) => {
  const clientStore = useClientStore();
  const teamsStore = useTeamsStore();
  const tasksStore = useTasksStore();
  const filesStore = useFilesStore();
  const ticketsStore = useTicketsStore();
  const { collection } = storeToRefs(teamsStore);

  const team: ComputedRef<Team> = computed(() => {
    if (!ticket?.value?.teams?.length) return {};
    const teamId = ticket.value.teams[0];
    return collection.value.find((t: Ticket) => t.id === teamId);
  });

  const { users } = useUsers();

  const tasksIndicator = computed(() => {
    const nbDone = ticketTasks.value.reduce((acc, t) => {
      if (t.done) return acc + 1;
      return acc;
    }, 0);
    return `${nbDone}/${ticketTasks.value.length}`;
  });

  const ticketTasks = computed((): Array<Task> => {
    if (!ticket.value) return [];
    if (ticket.value.tasks?.length) return ticket.value.tasks;
    return tasksStore.collection.filter(
      (t: Task) =>
        t.ticket === ticket.value.id || t.ticket === ticket.value.uuid
    );
  });

  const ticketFiles = computed((): Array<FabriqFile> => {
    if (!ticket.value) return [];
    return filesStore.collection.filter(
      (t: FabriqFile) =>
        t.ticket === ticket.value.id || t.ticket === ticket.value.uuid
    );
  });

  const owner = computed(() => {
    const ownerId = ticket?.value?.owner;
    return users.value.find((u: User) => u.id === ownerId);
  });

  const ownerFullName = computed(() => owner.value?.profile?.full_name);

  const category = computed(() => {
    if (!ticket.value) return [];
    return clientStore.categories?.find((c: Category) =>
      ticket.value.categories?.includes(c.id)
    );
  });

  const taskUpdate = async (
    ev: { uuid: string; field: string; value: string },
    now = false,
    screen = "ticket"
  ) => {
    if (ev.field === "done") {
      const mixevent = ev.value ? "mark done" : "mark not done";
      mixpanelTracker.track(`${mixevent} | task`, { screen });
    }
    await tasksStore.update(ev.uuid, { [ev.field]: ev.value });
    if (ticket.value?.id) ticketUpdatedByMe(+ticket.value.id);
    if (now) {
      tasksStore.save();
    }
  };

  const ticketUpdate = async (field: string, value: any, now = false) => {
    const updated = { ...ticket.value };
    await ticketsStore.update(updated.uuid, { [field]: value }); // 🔴 updated.uuid is undefined
    if (ticket.value?.id) ticketUpdatedByMe(+ticket.value.id);
    if (now) {
      ticketsStore.save();
    }
  };

  const addFile = async (file: FabriqFile) => {
    const now = formatISO(new Date());
    await filesStore.add({
      ...file,
      ticket: ticket.value.id || ticket.value.uuid,
      created_at: now,
      updated_at: now,
    });
    filesStore.save();
    if (ticket.value?.id) ticketUpdatedByMe(+ticket.value.id);
  };

  return {
    taskUpdate,
    ticketUpdate,
    addFile,
    ticketTasks,
    ticketFiles,
    tasksIndicator,
    category,
    team,
    users,
    owner,
    ownerFullName,
    ...useI18n(),
  };
};
