<template>
  <div class="step-checklist">
    <div class="step-header">
      <div class="previous" :class="{ visible: subStep > 0 }">
        <font-icon
          name="arrow_back"
          material
          size="1.25"
          @click="subStep--"
          color="var(--ion-color-secondary)"
        />
      </div>
      <div class="step-header-title">{{ header }}</div>
      <div class="next" :class="{ visible: subStep < dots.length - 1 }">
        <font-icon
          name="arrow_forward"
          material
          size="1.25"
          @click="subStep++"
          color="var(--ion-color-secondary)"
        />
      </div>
    </div>
    <div class="step-dots">
      <font-icon
        v-for="dot of dots"
        :key="dot.index"
        name="circle"
        material
        :color="dot.color"
        size="0.3"
      />
    </div>
    <div v-for="row of questions" :key="row.id" class="question">
      <div class="question-row">
        {{ row.question }}
        <div
          class="step-action"
          :class="{ highlight: row.nbComments }"
          @click="$emit('comment', row.answer)"
        >
          <font-icon
            slot="icon-only"
            name="chat_bubble_outline"
            material
            :color="
              row.nbComments
                ? 'var(--ion-color-primary)'
                : 'var(--ion-color-primary-shade)'
            "
            size="0.8"
          />
          {{ t("common.comment") }}
          <span v-if="row.nbComments"> ({{ row.nbComments }}) </span>
        </div>
      </div>
      <div class="step-choices">
        <div
          v-for="choice of row.choices"
          :key="choice.id"
          class="ion-margin step-choice"
          :class="{
            selected: choice.selected,
            [`${choice.config.type}`]: true,
          }"
          @click="$emit('checklist', { choiceId: choice.id, stepId: row.id })"
        >
          {{ t(`routines.auditStep.${choice.text}`) }}
          <div class="font-icon check-mark" v-if="choice.selected">
            <i class="material-icons">check</i>
          </div>
        </div>
      </div>
    </div>
    <ion-text v-if="hasNudgeCreateTicketActive" class="create-ticket-alert">
      <font-icon name="warning" material />
      <span> {{ t("indicatorPage.nudgeCreateTicketRoutine") }} </span>
    </ion-text>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "vue";
import { TemplateStep, ExecutionAnswer, StepChoice, Template } from "@/types";
import { useI18n } from "@/composables/useI18n";
import { IonText } from "@ionic/vue";
import { useRoutineNudgeCreateTicket } from "@/composables/useRoutineNudgeCreateTicket";
import { useRoutineStepCheckList } from "@/composables/useRoutineStepCheckList";

export default defineComponent({
  name: "StepChecklist",
  emits: ["checklist", "comment"],
  components: {
    IonText,
  },
  props: {
    step: {
      type: Object as PropType<TemplateStep>,
      default: () => ({}),
    },
    answer: {
      type: Object as PropType<ExecutionAnswer>,
      default: () => ({}),
    },
    answers: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      default: () => [],
    },
    comments: {
      type: Array as PropType<Array<Comment>>,
      default: () => [],
    },
    template: {
      type: Object as PropType<Template>,
      required: true,
    },
  },
  setup(props) {
    const subStep = ref(0);
    const { steps, questions } = useRoutineStepCheckList(
      computed(() => props.template),
      computed(() => props.step),
      computed(() => props.answers),
      computed(() => props.comments),
      subStep
    );
    const dots = computed(() => {
      let max = 0;
      steps.value.forEach((s: TemplateStep) => {
        if (s.config?.j && s.config.j > max) {
          max = s.config.j;
        }
      });
      const dots = [];
      for (let i = 0, color; i <= max; i++) {
        color = subStep.value === i ? "var(--ion-color-secondary)" : "#e1e1e0";
        dots.push({ index: i, color });
      }
      return dots;
    });

    const header = computed(() => {
      if (!questions.value.length) return "";
      return questions.value[0].config?.header;
    });

    const choices = computed(() => {
      return props.step.choices.map((c: StepChoice) => {
        if (!c.id) return c;
        return {
          ...c,
          selected: props.answer?.choices?.includes(c.id),
        };
      });
    });

    const { hasNudgeCreateTicketActive } = useRoutineNudgeCreateTicket(
      computed(() => props.step),
      computed(() => props.answer),
      undefined,
      undefined,
      questions
    );

    return {
      subStep,
      choices,
      header,
      questions,
      dots,
      ...useI18n(),
      hasNudgeCreateTicketActive,
    };
  },
});
</script>

<style scoped></style>
