<template>
  <div class="scroller-container filters-bar">
    <filter-item
      v-for="f of filledFilters"
      :key="f.filter"
      :filter="f.filter"
      :badge="f.badge"
      :selected="f.filter === modelValue"
      @select="$emit('update:modelValue', f.filter)"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import FilterItem from "@/components/ui/FilterItem.vue";

export default defineComponent({
  name: "FilterBar",
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    badges: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  components: {
    FilterItem,
  },
  setup(props) {
    const filledFilters = computed(() => {
      return props.filters.map((f: any) => {
        const badge: any = props.badges?.find((b: any) => b.filter === f);
        return {
          filter: f,
          badge: badge ? badge.nb : 0,
        };
      });
    });
    return {
      filledFilters,
    };
  },
});
</script>

<style scoped>
.scroller-container {
  display: flex;
  flex-direction: row;
  overflow: visible;
  margin: var(--ion-padding) 0;
  justify-content: space-between;
}
.scroller-container > * {
  flex: 33%;
}
</style>
