<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref, computed } from "vue";
import { downloadFile, transformUrl } from "@/utils/files";
import { v4 as uuidv4 } from "uuid";
import storage from "@/utils/storage";
import { formatISO } from "date-fns";
import { Capacitor } from "@capacitor/core";
import { useFabriqStore } from "@/store/fabriq";
import { storeToRefs } from "pinia";

interface Props {
  src: string;
  viewer?: boolean;
  fullScreenButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  viewer: false,
  fullScreenButton: false,
});

const defaultFile = "./img/fake.png";
const url = ref(defaultFile);
const target = ref();
const image = ref();
const isVisible = ref(false);

const fabriqStore = useFabriqStore();
const { online } = storeToRefs(fabriqStore);

const originalUrl = computed(() =>
  transformUrl(props.src, fabriqStore.token, fabriqStore.subdomain)
);

const platform = Capacitor.getPlatform();

const fileError = async (e: Event) => {
  console.error("Cached file error", e);
  url.value = originalUrl.value || defaultFile;
  if (originalUrl.value)
    await storage
      .getDb()
      .table("cachedFiles")
      .where("url")
      .equals(originalUrl.value)
      .delete();
  if (!originalUrl.value) return;
  saveOrGetImage(originalUrl.value);
};

const saveOrGetImage = async (value: string): Promise<string | undefined> => {
  try {
    try {
      const file = await storage
        .getDb()
        .table("cachedFiles")
        .get({ url: value });
      if (file) return Capacitor.convertFileSrc(file.path);
    } catch (e) {
      fileError(null, e);
    }
    const uuid = uuidv4();
    const createdAt = formatISO(new Date());
    const path = await downloadFile(value, uuid);
    if (!path) return originalUrl.value;
    await storage.getDb().table("cachedFiles").add({
      uuid,
      url: originalUrl.value,
      path,
      name: "...",
      createdAt,
    });
    return Capacitor.convertFileSrc(path);
  } catch (e) {
    console.error("saveOrGetImage", e);
    return originalUrl.value;
  }
};

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) isVisible.value = true;
    });
  },
  { root: document.querySelector(".ion-page") }
);

onMounted(async () => {
  observer.observe(target.value);
  url.value =
    online.value && originalUrl.value ? originalUrl.value : defaultFile;
  if (!originalUrl.value) return;
  if (platform === "web") {
    url.value = originalUrl.value;
    return;
  }
  const urlValue = await saveOrGetImage(originalUrl.value);
  url.value = urlValue || defaultFile;
});

onBeforeUnmount(() => {
  observer.disconnect();
});

const openFullscreen = () => {
  image.value.$viewer.show();
};
</script>

<template>
  <span ref="target">
    <span v-if="viewer && isVisible">
      <img
        ref="image"
        v-viewer.static="{
          navbar: 0,
          toolbar: 0,
          transition: false,
          movable: false,
        }"
        @error="fileError"
        load="lazy"
        :src="url"
        :class="{ 'image-fullscreen--disable': fullScreenButton && viewer }"
      />
      <font-icon
        v-if="fullScreenButton"
        name="zoom_out_map"
        material
        class="image-fullscreen"
        @click.stop.prevent="openFullscreen"
      />
    </span>
    <img
      ref="image"
      v-else-if="isVisible"
      @error="fileError"
      load="lazy"
      :src="url"
    />
  </span>
</template>

<style scoped>
.image-fullscreen--disable {
  pointer-events: none;
}
.image-fullscreen {
  text-shadow: #000 0px 0 3px;
}
</style>
