import { ApiClient } from "./clients";
import { Dependency, ListService } from "@/types";

export const DependenciesService: ListService<Dependency> = {
  async add(entity: Dependency): Promise<Dependency> {
    const dependency = await ApiClient.post("/api/v1/dependencies/", entity);
    return dependency;
  },
  async update(entity: Dependency, columns: any): Promise<Dependency> {
    const dependency = await ApiClient.put(
      `/api/v1/dependencies/${entity.id}/`,
      columns
    );
    return dependency;
  },
};
