import { ExecutionAnswer, StepChoice, Template, TemplateStep } from "@/types";
import { Ref, computed } from "vue";

export const useRoutineStepCheckList = (
  template: Ref<Template>,
  step: Ref<TemplateStep>,
  answers: Ref<ExecutionAnswer[]>,
  comments: Ref<any[]>,
  subStep?: Ref<number>
) => {
  const steps = computed(() => {
    const steps = template.value.steps.filter(
      (s: TemplateStep) => s.parent === step.value.id && s.config
    );
    steps.sort((a: TemplateStep, b: TemplateStep) => {
      if (a.config?.i === b.config?.i)
        return (a.config?.j || -1) - (b.config?.j || -1);
      return (a.config?.i || -1) - (b.config?.i || -1);
    });
    return steps;
  });
  const questions = computed(() => {
    const questions = subStep
      ? steps.value.filter((s) => {
          return s.config?.j === subStep.value;
        })
      : steps.value;
    return questions.map((q: any) => {
      const answer = answers.value.find((a) => a.step.id === q.id);
      if (!answer) return q;
      const nbComments = (comments.value || []).filter((c: any) => {
        return c.content.includes(`{routineExecutionAnswerId: ${answer.id}}`);
      }).length;
      return {
        ...q,
        nbComments,
        answer,
        choices: q.choices.map((c: StepChoice) => {
          if (!c.id) return c;
          return {
            ...c,
            selected: answer?.choices?.includes(c.id),
          };
        }),
      };
    });
  });

  return {
    questions,
    steps,
  };
};
