<template>
  <ion-content class="ion-padding-top normal">
    <div v-if="!allEmojis && !confirmDelete">
      <div class="default-emoji-list border-bottom">
        <div
          class="emoji-item"
          v-for="emoji of defaultEmojis"
          :key="emoji"
          @click="done('emoji', emoji)"
        >
          {{ emoji }}
        </div>
        <div class="emoji-item icon">
          <font-icon
            name="add_reaction"
            class="clickable"
            @click="allEmojis = true"
            size="1.40"
            material
            outlined
            color="var(--f-color-discrete)"
          />
        </div>
      </div>
      <ion-list
        lines="none"
        class="ion-margin-bottom ion-padding-bottom border-bottom"
      >
        <ion-item @click="done('reply')" class="clickable btn-reply">
          <font-icon
            slot="start"
            name="reply"
            material
            color="var(--ion-color-secondary)"
          />
          <ion-label class="list-item">
            {{ t("comments.reply") }}
          </ion-label>
        </ion-item>
        <ion-item @click="done('file')" class="clickable btn-file" v-if="mine">
          <font-icon
            slot="start"
            name="attach_file"
            material
            outlined
            color="var(--ion-color-secondary)"
          />
          <ion-label class="list-item">
            {{ t("common.upload") }}
          </ion-label>
        </ion-item>
        <ion-item @click="done('pin')" class="clickable btn-pin">
          <font-icon
            class="pin-icon"
            slot="start"
            name="push_pin"
            material
            :color="pinColor"
          />
          <ion-label class="list-item">
            {{ pinned ? t("comments.unpin") : t("comments.pin") }}
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list
        v-if="mine"
        lines="none"
        class="ion-margin-bottom ion-padding-bottom border-bottom mine-list"
      >
        <ion-item @click="done('edit')" class="clickable btn-edit">
          <font-icon
            slot="start"
            name="edit"
            material
            color="var(--ion-color-secondary)"
          />
          <ion-label class="list-item">
            {{ t("edit") }}
          </ion-label>
        </ion-item>
        <ion-item @click="confirmDelete = true" class="clickable btn-delete">
          <font-icon
            slot="start"
            name="delete"
            material
            color="var(--ion-color-danger)"
          />
          <ion-label class="list-item">
            {{ t("delete") }}
          </ion-label>
        </ion-item>
      </ion-list>
    </div>
    <div v-else-if="allEmojis">
      <div class="cancel-btn ion-margin-top clickable" @click="emit('cancel')">
        {{ t("cancel") }}
      </div>
      <emoji-reactions @select="done('emoji', $event)" />
    </div>
    <div v-else-if="confirmDelete">
      <div class="confirm-delete-title">{{ t("comments.confirm") }}</div>
      <div class="confirm-delete-buttons">
        <ion-button @click="confirmDelete = false">
          {{ t("cancel") }}
        </ion-button>
        <ion-button color="danger" @click="done('delete')">
          {{ t("delete") }}
        </ion-button>
      </div>
    </div>
  </ion-content>
</template>

<script lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "@/composables/useI18n";
</script>

<script lang="ts" setup>
import { IonContent, IonList, IonItem, IonLabel, IonButton } from "@ionic/vue";
import EmojiReactions from "@/components/ui/EmojiReactions.vue";
const defaultEmojis = ["👌", "👍", "✅", "🙂", "🙁"];

const { t } = useI18n();

const props = withDefaults(defineProps<{ mine: boolean; pinned: boolean }>(), {
  mine: false,
  pinned: false,
});
const emit = defineEmits(["done", "cancel", "breakpoint"]);

const pinColor = props.pinned
  ? "var(--ion-color-danger)"
  : "var(--ion-color-secondary)";

const allEmojis = ref(false);
const confirmDelete = ref(false);

const done = (
  action: "emoji" | "reply" | "pin" | "file" | "delete" | "edit",
  emoji?: string
) => {
  emit("done", { action, emoji });
};

watch(allEmojis, () => emit("breakpoint"));
</script>

<style scoped>
.hint {
  font-size: var(--font-size-m);
}
.template-list {
  margin-top: var(--ion-padding);
}
.list-item {
  font-size: var(--font-size-m);
}
.make-favorite {
  padding: 10px;
  margin-right: -6px;
}
.emoji {
  font-size: 0.8rem;
}
.ticket-templates-list {
  height: calc(100vh - 210px);
  overflow-y: auto;
}

.border-bottom {
  border-bottom: solid 1px var(--ion-border-color);
}

.default-emoji-list {
  padding: var(--ion-padding);
  padding-bottom: calc(var(--ion-padding) * 1.5);
  margin: var(--ion-padding) 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.emoji-item {
  width: 48px;
  height: 48px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: solid 1px var(--ion-border-color);
  background-color: var(--ion-background-color-shade);
  border-radius: 150px;
}

.confirm-delete-title {
  text-align: center;
  margin-top: calc(var(--ion-padding) * 2);
  margin-bottom: var(--ion-padding);
  padding: var(--ion-padding);
}
.confirm-delete-buttons {
  margin-top: var(--ion-padding);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cancel-btn {
  padding-right: var(--ion-padding);
  margin-right: var(--ion-padding);
  text-align: right;
  color: var(--f-color-discrete);
  font-size: var(--font-size-m);
}
</style>
