<script lang="ts" setup>
import { computed } from "vue";
import { IonItem } from "@ionic/vue";
import { DependencyType, Dependency, ObjectType } from "@/types";

import DependencyTicket from "@/components/business/dependencies/DependencyTicket.vue";
import DependencyRoutine from "@/components/business/dependencies/DependencyRoutine.vue";
import DependencyIndicator from "@/components/business/dependencies/DependencyIndicator.vue";

interface Props {
  entityId: number;
  dependency: Dependency;
  readonly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  readonly: false,
});

const isDependent = computed(
  () => props.dependency.dependent_id !== props.entityId
);
const objectType = computed(() =>
  isDependent.value
    ? props.dependency.dependent_model
    : props.dependency.related_model
);
const dependencyId = computed(() =>
  isDependent.value
    ? props.dependency.dependent_id
    : props.dependency.related_id
);
const properties = computed(() => {
  switch (props.dependency.dependency_type) {
    case DependencyType.IsImpacted:
    case DependencyType.Related:
      return {
        icon: "swap_horiz",
        inverse: false,
        color: "var(--ion-color-primary-shade)",
      };
    case DependencyType.Blocks:
      return {
        icon: "remove_circle",
        inverse: false,
        color: "var(--ion-color-danger)",
      };
    case DependencyType.IsBlocked:
      return {
        icon: "remove_circle",
        inverse: true,
        color: "var(--ion-color-danger)",
      };
    case DependencyType.IsResult:
      return {
        icon: "subdirectory_arrow_left",
        inverse: true,
        color: "var(--ion-color-secondary-shade)",
      };
    case DependencyType.Resulted:
      return {
        icon: "subdirectory_arrow_right",
        inverse: false,
        color: "var(--ion-color-warning)",
      };
    case DependencyType.Contains:
      return {
        icon: "picture_in_picture_alt",
        inverse: false,
        color: "var(--ion-color-success)",
      };
    case DependencyType.IsContained:
      return {
        icon: "picture_in_picture_alt",
        inverse: true,
        color: "var(--ion-color-success)",
      };
    default:
      return {
        icon: "bug_report",
        inverse: false,
        color: "var(--ion-color-warning)",
      };
  }
});

const is = computed(() => {
  switch (objectType.value) {
    case ObjectType.Routine:
      return DependencyRoutine; // icon: "bar_chart" };
    case ObjectType.Indicator:
      return DependencyIndicator; // icon: "bar_chart" };
    default:
      return DependencyTicket; // icon: "assignment" };
  }
});
</script>

<template>
  <ion-item class="dependency">
    <font-icon
      slot="start"
      :name="properties.icon"
      material
      size="1.5"
      :class="{ inverse: properties.inverse }"
      :color="properties.color"
    />
    <div class="dependency-type">
      <component :entityId="dependencyId" :is="is" />
    </div>
  </ion-item>
</template>

<style scoped>
.dependency {
  --f-border-radius: 5px;
  --padding-start: var(--ion-padding);
  --padding-end: 0;
  --inner-padding-end: calc(var(--ion-padding) / 2);
  border-bottom: 1px solid var(--ion-border-color);
  padding-bottom: calc(var(--ion-padding) / 2);
  padding-top: calc(var(--ion-padding) / 2);
}

.dependency:first-child {
  padding-top: 0;
}

.dependency:last-child {
  padding-bottom: 0;
  border: 0;
}

.dependency .font-icon {
  margin-right: 0;
}

.dependency .dependency-type {
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - calc(var(--ion-padding) / 2));
  padding: calc(var(--ion-padding) / 2);
  padding-left: 0;
  border-radius: var(--f-border-radius, 4px);
  font-size: var(--font-size-m);
}
</style>
