export class BarcodeScannerUI {
  #toolbar: any;
  #scanRect: any;

  remove() {
    if (this.#toolbar) {
      this.#toolbar.parentNode.classList.remove("dark");
      this.#toolbar.parentNode.removeChild(this.#toolbar);
      this.#toolbar = null;
    }
    if (this.#scanRect) {
      this.#scanRect.parentNode.removeChild(this.#scanRect);
      this.#scanRect = null;
    }
  }

  make(onClick: any, backTitle = "") {
    const dark = document.body.classList.contains("dark");
    this.#toolbar = document.createElement("div");
    this.#toolbar.id = "barcodeScannerToolbar";
    this.#toolbar.classList.add("barcode-toolbar");

    const arrowBack = document.createElement("span");
    arrowBack.id = "barcodeClose";
    arrowBack.innerHTML = "arrow_back";
    arrowBack.classList.add("barcode-close-icon");
    arrowBack.classList.add("material-icons");
    arrowBack.onclick = () => {
      if (onClick) onClick();
    };
    this.#toolbar.innerHTML = backTitle;
    this.#toolbar.appendChild(arrowBack);
    document.body.parentNode?.appendChild(this.#toolbar);
    this.#scanRect = document.createElement("div");
    this.#scanRect.id = "scanRect";
    this.#scanRect.classList.add("barcode-scan-rect");
    document.body.parentNode?.appendChild(this.#scanRect);
    if (dark) this.#toolbar.parentNode.classList.add("dark");
  }
}
