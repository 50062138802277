import { BOOLEAN_NUMBER_BINARY_OPERATORS } from "@babel/types";
<template>
  <div
    v-if="textOnly"
    class="full-name"
    :class="{ clickable }"
    @click.stop.prevent="emitClick"
  >
    {{ fullName }}
  </div>
  <div
    v-else
    class="user-avatar"
    ref="avatarDiv"
    :class="{ clickable }"
    @click.stop.prevent="emitClick"
  >
    <cached-avatar
      v-if="user && (avatarIsVisible || forceVisibility)"
      :key="user.id"
      :avatar="avatar"
      :fallback="initials"
    />
    <font-icon
      v-else
      class="avatar-icon"
      outlined
      name="person"
      :size="name ? '0.875' : '1.25'"
      color="var(--ion-color-primary-shade)"
      material
    />
    <div class="avatar-name" :class="{ shade: !user }" v-if="name">
      {{ name }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import CachedAvatar from "@/components/ui/CachedAvatar.vue";
import { useElementVisibility } from "@vueuse/core";
import { transformUrl } from "@/utils/files";
import { useFabriqStore } from "@/store/fabriq";

export default defineComponent({
  name: "UserAvatar",
  components: {
    CachedAvatar,
  },
  emits: ["click"],
  props: {
    user: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    textOnly: {
      type: Boolean,
      default: false,
    },
    forceVisibility: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const fabriqStore = useFabriqStore();
    const avatarDiv = ref();
    const avatarIsVisible = useElementVisibility(avatarDiv);
    const avatar = computed(() => {
      const url = props.user?.profile?.avatar?.url || props.user?.avatar?.url;
      if (!url) return;
      if (url.match(/\.webp/)) return url;

      const subdomainThumbnailUrl = transformUrl(
        url,
        fabriqStore.token,
        fabriqStore.subdomain
      );

      return subdomainThumbnailUrl;
    });
    const initials = computed(() => {
      const first_name = props.user?.first_name || "";
      const last_name = props.user?.last_name || "";
      const initials = `${first_name
        .substring(0, 1)
        .toLocaleUpperCase()}${last_name.substring(0, 1).toLocaleUpperCase()}`;
      return initials;
    });
    const fullName = computed(() => {
      if (!props.user) return props.name;
      const first_name = props.user?.first_name || "";
      const last_name = props.user?.last_name || "";
      return `${first_name} ${last_name}`;
    });

    function emitClick() {
      if (props.clickable) {
        emit("click");
      }
    }

    return {
      avatarIsVisible,
      avatarDiv,
      avatar,
      initials,
      fullName,
      emitClick,
    };
  },
});
</script>

<style scoped>
.user-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.avatar-icon {
  padding: 3px;
}
.user-avatar {
  margin: 0;
  padding: 0;
}
.full-name {
  --_color: var(--full-name-color, var(--ion-color-primary-shade, "black"));
  --_font-size: var(--full-name-size, var(--font-size-s, 12px));
  font-size: var(--_font-size);
  color: var(--_color);
}
.avatar-name {
  font-size: var(--font-size-m);
  margin-left: calc(var(--ion-padding) / 2);
}
.avatar-name.shade {
  color: var(--ion-color-primary-shade);
}
</style>
