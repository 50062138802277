export const useQueryParameters = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  setTimeout(() => {
    window.history.replaceState(
      null,
      "",
      window.location.pathname + window.location.hash
    );
  });

  return params;
};
