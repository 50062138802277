<script lang="ts" setup>
import { useRouter } from "vue-router";
import { IonHeader, IonToolbar } from "@ionic/vue";
import { useFabriqStore } from "@/store/fabriq";
import { storeToRefs } from "pinia";
import UserAvatar from "@/components/business/UserAvatar.vue";
import ConnectivityInfo from "@/components/ui/ConnectivityInfo.vue";

defineProps({
  title: {
    type: String,
    required: true,
  },
  collapse: {
    type: Boolean,
    default: false,
  },
  isAccount: {
    type: Boolean,
    default: false,
  },
  beta: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const fabriqStore = useFabriqStore();
const { user } = storeToRefs(fabriqStore);
fabriqStore.setLoading(false);

const gotoAccount = () => {
  router.push("/account");
};
</script>

<template>
  <ion-header
    mode="ios"
    class="ion-no-border"
    :collapse="collapse ? 'condense' : undefined"
    :translucent="!collapse"
  >
    <ion-toolbar v-if="collapse" mode="ios">
      <div class="page-toolbar">
        <div class="page-title">
          {{ title }}<span class="beta" v-if="beta">BETA</span>
        </div>
        <connectivity-info />
        <user-avatar
          slot="end"
          class="page-toolbar-avatar"
          :large="true"
          clickable
          v-if="collapse && !isAccount"
          :user="user"
          @click="gotoAccount"
        />
      </div>
      <slot v-if="collapse" />
    </ion-toolbar>
    <div v-else class="fake-toolbar">&nbsp;</div>
  </ion-header>
</template>

<style scoped>
.spinner {
  margin-right: 1rem;
}

.page-toolbar-avatar {
  --avatar-size: 36px;
}
.page-title {
  flex: 1;
  font-size: var(--font-size-xl);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.fake-toolbar {
  --_fake-toolbar-height: var(--fake-toolbar-height, 48px);
  min-height: var(--_fake-toolbar-height);
  max-height: var(--_fake-toolbar-height);
  height: var(--_fake-toolbar-height);
}

.beta {
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary);
  opacity: 0.5;
  padding: 4px;
  position: relative;
  margin-left: 8px;
}

.beta::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--ion-color-primary);
  opacity: 0.1;
  border-radius: 4px;
}
</style>

<style>
.page-toolbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-inline: 6px;
  margin-top: 12px;
}

.plt-ios .page-toolbar {
  margin-top: 48px;
}
</style>
