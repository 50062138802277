<template>
  <ion-item-group class="f-list-item f-list-item-ticket clickable">
    <ion-item
      lines="none"
      class="ticket-divider"
      :class="{ 'with-tasks': ticket.tasks.length }"
      @click="$emit('select', ticket.uuid)"
    >
      <div slot="start" class="start">
        <user-avatar
          :user="owner"
          @update="ticketUpdate('owner', $event ? $event.id : null, true)"
        />
      </div>
      <ion-label style="line-height: 1">
        {{ ticket.title || t("common.noTitle") }}
      </ion-label>
      <due-date
        slot="end"
        :date="ticket.due_date"
        :end="ticket.end_date"
        :readonly="true"
        @date="ticketUpdate('due_date', $event, true)"
      />
    </ion-item>
    <task-item
      class="ticket-task"
      v-for="task of ticket.tasks"
      :key="task.uuid"
      :task="task"
      readonly
      in-ticket-item
      @click="$emit('select', ticket.uuid)"
      @update="updateTaskWithDelay"
    />
  </ion-item-group>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import { IonItemGroup, IonItem, IonLabel } from "@ionic/vue";
import UserAvatar from "@/components/business/UserAvatar.vue";
import { useTicket } from "@/composables/useTicket";
import DueDate from "@/components/business/tickets/DueDate.vue";
import TaskItem from "@/components/business/tickets/TaskItem.vue";
import { Ticket } from "@/types";
import { useFabriqStore } from "@/store/fabriq";

export default defineComponent({
  name: "TicketItem",
  components: {
    IonItemGroup,
    IonItem,
    IonLabel,
    UserAvatar,
    DueDate,
    TaskItem,
  },
  emits: ["select"],
  props: {
    ticket: {
      type: Object as PropType<Ticket>,
      default: () => ({}),
    },
  },
  setup(props) {
    const ticket = computed(() => props.ticket);
    const ticketComposable = useTicket(ticket);
    const fabriqStore = useFabriqStore();

    const updateTaskWithDelay = async ({
      uuid,
      field,
      value,
    }: {
      uuid: string;
      field: string;
      value: any;
    }) => {
      if (field !== "done") return;
      if (value) {
        fabriqStore.closeTask(uuid);
        ticketComposable.taskUpdate(
          { uuid, field, value },
          true,
          "tickets screen"
        );
      } else {
        fabriqStore.closeTask(uuid);
        ticketComposable.taskUpdate(
          { uuid, field, value },
          true,
          "tickets screen"
        );
      }
    };
    return {
      updateTaskWithDelay,
      ...ticketComposable,
    };
  },
});
</script>

<style scoped>
.ticket-divider::part(native) {
  --background: transparent;
  padding: calc(var(--ion-padding) / 2) 0 calc(var(--ion-padding) / 2)
    var(--ion-padding);
  display: flex;
  align-items: center;
  border: 0;
}
.ticket-task {
  --ion-item-background: var(--ion-color-primary-contrast);
  border-radius: var(--f-border-radius);
  margin-left: 2px;
}
.ticket-group .ticket-task:first-child {
  margin-top: calc(var(--ion-padding) * -1);
}
.ticket-group .ticket-task:last-child {
  margin-bottom: var(--ion-padding);
}
</style>
