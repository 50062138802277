import { DatapointOperation } from "./datapoint";
import { ListEntity } from "./states";

export enum IndicatorUnitType {
  Number = "number",
  Currency = "currency",
  Percentage = "percentage",
  Time = "time",
}

export enum IndicatorValueType {
  Target = "target",
  Ytd = "ytd",
  Mtd = "mtd",
  ValueChange = "valueChange",
  PercentChange = "percentChange",
  ValueDelta = "valueDelta",
  PercentDelta = "percentDelta",
}

export enum IndicatorType {
  Status = "status",
  TimeSeries = "time-series",
  Counter = "counter",
  IFrame = "iframe",
}

export interface IndicatorConfigRange {
  name: string;
  value: number;
}

export interface IndicatorConfig {
  date_format?: string;
  default_range?: IndicatorConfigRange;
  preview?: string;
  default_currency?: string;
  default_time: "minutes" | "hours" | "seconds";
  aggregated_target_operation?: DatapointOperation;
  aggregated_target_active?: boolean;
  color?: `#${string}`;
  rules_active?: boolean;
  nudge_create_ticket?: boolean;
}

export interface IndicatorStatsHiLo {
  low: {
    id: number;
    date: string;
    value: number;
  };
  high: {
    id: number;
    date: string;
    value: number;
  };
}

export interface IndicatorStatsStreak {
  record: {
    streak: number;
    to_date: string;
    from_date: string;
  };
  current: {
    streak: number;
    from_date: string | null;
  };
  threshold: number;
}

export interface IndicatorChoices {
  options: Array<{ key: string; value: number }>;
}

export enum IndicatorFrequency {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Yearly = "yearly",
}

export interface Indicator extends ListEntity {
  files: [];
  datapoints_count: number;
  teams: {
    ids: Array<number>;
    admin_ids: Array<number>;
  };
  template: null;
  followers: Array<number>;
  is_dynamic: boolean;
  name: string;
  description: string;
  unit: string | null;
  unit_type: IndicatorUnitType | null;
  indicator_type: IndicatorType;
  decimals: number;
  value_type: IndicatorValueType | null;
  image: null;
  power: number;
  config: IndicatorConfig;
  created_at: string;
  stats_hi_lo: IndicatorStatsHiLo;
  stats_streak: IndicatorStatsStreak;
  choices: IndicatorChoices;
  default_target: null;
  is_multiple: boolean;
  frequency: IndicatorFrequency;
  iframe_urls: Array<string>;
  creation_complete: boolean;
  is_open: boolean;
  series: {
    "0": "default";
  };
  stats: {
    mtd: number | null;
    ytd: number | null;
    valueDelta: number | null;
    valueChange: number | null;
    percentDelta: number | null;
    percentChange: number | null;
  };
  client: number;
  last_modified_by: number;
  deleted_by: null;
  categories: Array<number>;
  avatar: {
    url: string;
  };
  icon: string;
  // </to-review>
  rules?: IndicatorRule[];
  rule?: { conditions: IndicatorRule };
  v3_indicator_id?: string;
}

export interface IndicatorRule extends ListEntity {
  related_object: any;
  conditions: RuleCondition[];
  content_type: "indicator";
  deleted_by_cascade: boolean;
  name: string;
  order: number;
  created_at: string;
  updated_at: string;
  object_id: number;
}

export type RuleCondition =
  | {
      id: number;
      deleted_by_cascade: boolean;
      type: "constant";
      field: "value";
      operator: ">" | "<" | "<=" | ">=" | "=" | "!=";
      value: string;
      conjunction: null | "and" | "or";
      order: number;
      created_at: string;
      updated_at: string;
      rule: number;
    }
  | {
      id: number;
      deleted_by_cascade: boolean;
      type: "target";
      field: "value";
      operator: ">" | "<" | "<=" | ">=" | "=" | "!=";
      coefficient: string;
      conjunction: null | "and" | "or";
      order: number;
      created_at: string;
      updated_at: string;
      rule: number;
    };
