import { buildClient } from "@fabriq/auth-client";
import buildServer from "./server.js";
import buildIdpClient from "./identityProvider.js";
import config from "@/config/index";
import { buildAuthTokenRepo, buildPkceRepo } from "./storageRepositories.js";

const pkceCodeRepository = buildPkceRepo();
const authenticationTokensRepository = buildAuthTokenRepo();

export const createFutureAuthClient = (subdomainUrl: string) => {
  const server = buildServer(config.futureAuthPrefix, subdomainUrl);
  const fabriqIdentityProvider = buildIdpClient(
    config.futureAuthPrefix,
    subdomainUrl
  );

  const uriParams = {
    paths: { login: "login" },
    baseUri: "",
    schema: config.ssoRedirectUri,
  };

  const dependencies = {
    server,
    authenticationTokensRepository,
    fabriqIdentityProvider,
    pkceCodeRepository,
    uriParams,
    subdomainUrl,
  };
  // FIXME TODO: separate auth client interface for mobile
  // @ts-ignore
  const authClient = buildClient(config.futureAuthClientId, dependencies);
  return authClient;
};
