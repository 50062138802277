<template>
  <ion-page class="document-page">
    <ion-header mode="ios">
      <ion-toolbar
        class="ion-padding-start document-toolbar transparent-toolbar"
      >
        <back-button slot="start" :text="t('leave')" @back="goBack" />
        <ion-buttons slot="end">
          <ion-button class="toolbar-end-button" @click="goToInformation">
            <font-icon
              name="info"
              size="1.25"
              material
              outlined
              color="#475467"
            />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="document-content">
      <DocumentHeader v-if="document" :document="document" />
      <div ref="blockNote" style="height: 100%; margin-top: 24px" />
    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import BackButton from "@/components/ui/BackButton.vue";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButton,
  IonButtons,
  useIonRouter,
  toastController,
} from "@ionic/vue";
import { computed, onMounted, ref } from "vue";
import {
  BlockNoteEditor,
  createReactComponentForBlockNote,
  buildBlockNoteScheme,
  locales,
} from "@fabriq-public/block-note-library";
import "@fabriq-public/block-note-library/dist/style.css";
import { useKnowledgeDocumentsStore } from "@/store/knowledgeDocuments";
import { useFabriqStore } from "@/store/fabriq";
import DocumentHeader from "@/components/business/documents/DocumentHeader.vue";
import { DocumentViewWithDocumentType } from "@/types/knowledge";
import { useRoute } from "vue-router";
import { useI18n } from "@/composables/useI18n";
import { isUri } from "@/utils/is_uri";
import { useConfigStore } from "@/store/config";
const knowledgeDocumentsStore = useKnowledgeDocumentsStore();
const configStore = useConfigStore();
const { theme, colorScheme, locale, user } = useFabriqStore();

const router = useIonRouter();
const route = useRoute();
const { t } = useI18n();
function goBack() {
  router.canGoBack() ? router.back() : router.push("/main/knowledge");
}

const isDark = computed(() => {
  if (!theme) return colorScheme === "dark";
  return theme === "dark";
});

function goToInformation() {
  router.push(`/documents/${documentId}/info`);
}

const documentId = Array.isArray(route.params.id)
  ? route.params.id[0]
  : route.params.id;

const blockNote = ref();
const document = ref<DocumentViewWithDocumentType | undefined>(undefined);

async function dummyUploadFile() {
  return "";
}

function dummyOpenModal() {
  return;
}

onMounted(async () => {
  document.value = await knowledgeDocumentsStore.findDocument(documentId);

  const canShowDocument =
    document.value?.authorId === user?.future_user_id ||
    document.value?.status !== "draft";

  if (!document.value || !canShowDocument) {
    const toast = await toastController.create({
      message: t("knowledgePage.notFound"),
      duration: 2000,
    });
    await toast.present();
    goBack();
    return;
  }

  configStore.addOpenedDocument(document.value.id, document.value.title);
  const schema = buildBlockNoteScheme(t);

  const editor = BlockNoteEditor.create({
    initialContent: document.value.content,
    schema,
    async resolveFileUrl(url: string) {
      if (
        isUri(url, {
          schemes: ["http", "https", "file", "ftp"],
        })
      )
        return url;
      return await knowledgeDocumentsStore.loadFile(url);
    },
    // @ts-ignore
    dictionary: locales[locale] ?? locales.en,
    trailingBlock: false,
  }) as unknown as BlockNoteEditor;

  createReactComponentForBlockNote(
    editor,
    blockNote.value,
    dummyUploadFile,
    knowledgeDocumentsStore.loadFile,
    t,
    false,
    isDark.value,
    dummyOpenModal,
    undefined,
    undefined
  );
});
</script>

<style scoped lang="postcss">
.document-page {
  .document-toolbar,
  .document-content {
    --border-width: 0;
  }
  .document-content {
    --background: white;
  }
}
body.dark .document-page .document-content {
  --background: var(--ion-background-color);
}
</style>
<style lang="postcss">
.bn-visual-media-wrapper {
  max-width: 100%;
}
.bn-visual-media-wrapper iframe {
  max-width: 100%;
}
.bn-pdf-block-content-wrapper {
  max-width: 100%;
}
.ProseMirror-selectednode .bn-file-default-preview {
  background-color: transparent !important;
}
</style>
