import { KpiV3Client } from "./clients";
import { Indicator, ListService } from "@/types";

export const IndicatorService: ListService<Indicator> = {
  async all(options: any): Promise<Indicator> {
    const params: { team_id?: number; indicator_ids?: string } = {};
    if (options.team_id) params.team_id = options.team_id;
    if (options.indicator_ids)
      params.indicator_ids = options.indicator_ids.join(",");
    const indicators = await KpiV3Client.get(`/v2/indicators`, params);
    return indicators;
  },
  async load(indicatorId: number): Promise<Indicator> {
    const indicator: Indicator = await KpiV3Client.get(
      `/v2/indicator/${indicatorId}`
    );
    return indicator;
  },
};
