<template>
  <div class="score-disk" :style="`width: ${size}px; height: ${size}px`">
    <svg class="progress-ring" :width="size" :height="size">
      <circle
        class="progress-ring__circle"
        stroke="#cecece"
        stroke-width="2"
        fill="transparent"
        :r="rayon"
        :cx="size / 2"
        :cy="size / 2"
      />
      <circle
        class="progress-ring__circle"
        :style="`stroke-dasharray: ${strokeDasharray}; stroke-dashoffset: ${strokeDashoffset};`"
        :stroke="color"
        stroke-width="2"
        :store-dashoffset="strokeDashoffset"
        :store-dasharray="strokeDasharray"
        fill="transparent"
        :r="rayon"
        :cx="size / 2"
        :cy="size / 2"
      />
    </svg>
    <div class="score-value" :style="`font-size: ${size / 4}px`">
      {{ percent }}%
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "ScoreDisk",
  props: {
    size: {
      type: Number,
      default: 48,
    },
    score: {
      type: Number,
      default: 0,
      require: true,
    },
    max: {
      type: Number,
      required: true,
    },
    danger: {
      type: Number,
      default: 25,
    },
    warning: {
      type: Number,
      default: 50,
    },
  },
  setup(props) {
    const percent = computed(() => {
      if (props.max <= 0) return 0;
      return Math.round((props.score * 100) / props.max);
    });

    const rayon = computed(() => props.size / 2 - props.size * 0.1);

    const circumference = computed(() => rayon.value * 2 * Math.PI);

    const strokeDasharray = computed(
      () => `${circumference.value} ${circumference.value}`
    );
    const strokeDashoffset = computed(
      () => circumference.value - (percent.value / 100) * circumference.value
    );

    const color = computed(() => {
      if (percent.value <= props.danger) return "var(--ion-color-danger)";
      if (percent.value <= props.warning) return "var(--ion-color-warning)";
      return "var(--ion-color-success)";
    });

    return {
      rayon,
      strokeDasharray,
      strokeDashoffset,
      percent,
      color,
    };
  },
});
</script>

<style scoped>
.score-disk {
  position: relative;
}
.score-value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50%;
}
</style>
