<script lang="ts" setup>
import { IonContent, IonList, IonItem, IonLabel } from "@ionic/vue";
import ScoreDisk from "@/components/ui/ScoreDisk.vue";
import { localize } from "@/utils/localize";
import { useFabriqStore } from "@/store/fabriq";
import { EventType, EventTypeProperty } from "@/types";

interface Props {
  eventType: EventType;
  scoreColor: string;
  score: number;
  fields: EventTypeProperty[];
  nbTotal: number;
  missingFields: EventTypeProperty[];
}
const props = defineProps<Props>();
const { locale } = useFabriqStore();
const eventTypeLabel = localize(props.eventType.label, locale, 0);
const missingFieldIds = new Set(
  props.missingFields.map((field: any) => field.id)
);
</script>

<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item class="line-bottom">
        <score-disk
          slot="start"
          :size="36"
          :score="score"
          :max="100"
          :warning="99"
          :danger="0"
        />
        <div class="event-missing-fields-title">{{ eventTypeLabel }}</div>
      </ion-item>
    </ion-list>
    <ion-list lines="none" class="event-missing-fields-score-fields">
      <ion-item
        class="event-missing-fields-score-field"
        v-for="field of fields"
        :key="field.id"
      >
        <font-icon
          slot="start"
          :name="
            missingFieldIds.has(field.id)
              ? 'radio_button_unchecked'
              : 'check_circle'
          "
          material
          outline
          :color="
            missingFieldIds.has(field.id)
              ? 'var(--ion-border-color)'
              : 'var(--ion-color-success)'
          "
        />
        <ion-label class="bold">
          {{ localize(field.label, locale, 0) }}
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<style scoped>
.line-bottom {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--ion-border-color);
}
.score-disk {
  margin-right: 22px;
}
.event-missing-fields-title {
  width: 100%;

  padding-right: var(--ion-padding);
  max-width: calc(100% - var(--ion-padding));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--font-size-m);
}
.event-missing-fields-score-field {
  padding-left: calc(var(--ion-padding) / 2);
}
.event-missing-fields-score-field ion-label {
  font-size: var(--font-size-m);
}
.event-missing-fields-score-fields {
  padding-top: 24px;
  padding-bottom: 24px;
  max-height: calc(100vh - 114px);
  overflow-y: auto;
}
</style>
