import storage from "@/utils/storage";
import { KnowledgeClient } from "./clients";
import { DocumentViewWithDocumentType } from "@/types/knowledge";

export async function getDocument(
  documentId: string
): Promise<DocumentViewWithDocumentType | undefined> {
  const {
    documentView: document,
  }: {
    documentView: DocumentViewWithDocumentType;
    documentCirculation: any;
  } = await KnowledgeClient.get(`/documents/${documentId}`);

  if (!document) {
    return undefined;
  }
  const documentTypeName = await getDocumentType(document.documentTypeId);
  return {
    ...document,
    documentTypeName,
  };
}

export async function getFile(id: string) {
  const subdomainUrl = await storage.get("future-subdomain");
  const result = await KnowledgeClient.get(`/file?key=${id}`);

  if (!result.path) {
    throw new Error("Failed to load file");
  }

  return `${subdomainUrl}${result.path}`;
}

async function getDocumentType(documentTypeId: string) {
  const result = await KnowledgeClient.get(`/document-types/${documentTypeId}`);
  return result?.name;
}
