import { getCurrentBuild } from "@/utils/live-updates";
import { ref } from "vue";

export const useBuildVersion = () => {
  const buildVersion = ref<string>();
  getCurrentBuild().then(
    (currentBuildVersion) => (buildVersion.value = currentBuildVersion)
  );

  return buildVersion;
};
