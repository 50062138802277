<script lang="ts" setup>
import { computed } from "vue";
import UserAvatar from "@/components/business/UserAvatar.vue";
import {
  Notification,
  NotificationWithSubText,
  NotificationWithParentText,
  User,
} from "@/types";
import { differenceInDays, parseISO, format } from "date-fns";
import { useI18n } from "@/composables/useI18n";
import { useFabriqStore } from "@/store/fabriq";
import { getDateFnsLocales } from "@/i18n";
import { commentParser, removeHtml, removeLinks } from "@/utils/comment-parser";
import HtmlContent from "@/components/tools/HtmlContent.vue";

const props = defineProps<{
  notification: Notification;
  users: User[];
}>();

const user = computed(() =>
  props.users.find((u: User) => u.id === props.notification.actor)
);

const emit = defineEmits<{
  (event: "select", notification: Notification): void;
  (event: "toggle", notification: Notification): void;
}>();

const { t } = useI18n();
const fabriqStore = useFabriqStore();
const title = computed(() => props.notification.notification_text);
const createdSince = computed(() => {
  const now = parseISO(`${format(new Date(), "yyyy-MM-dd")}T00:00:00.00Z`);
  const eventDate = parseISO(props.notification.event_datetime);
  const compareDate = parseISO(
    `${props.notification.event_datetime.substring(0, 10)}T00:00:00.00Z`
  );
  const diff = differenceInDays(compareDate, now);
  if (diff === 0)
    return format(eventDate, t("formats.todayNotification"), {
      locale: getDateFnsLocales(fabriqStore.locale),
    });
  if (diff === -1)
    return format(eventDate, t("formats.yesterdayNotification"), {
      locale: getDateFnsLocales(fabriqStore.locale),
    });
  return format(eventDate, t("formats.olderNotification"), {
    locale: getDateFnsLocales(fabriqStore.locale),
  });
});

const subText = computed(() => {
  if (!NotificationWithSubText.includes(props.notification.type)) return null;
  const sub = props.notification.instance_repr;
  if (!sub) return;
  if (props.notification.notification_text.includes(sub)) return null;
  const title = removeLinks(removeHtml(commentParser(sub)));
  return title;
});

const parentText = computed(() => {
  if (!NotificationWithParentText.includes(props.notification.type))
    return null;
  const sub = props.notification.parent_repr;
  if (!sub) return;
  if (props.notification.notification_text.includes(sub)) return null;
  const title = removeLinks(removeHtml(commentParser(sub)));
  if (title === "[[link]]") return null;
  return title;
});

const icon = computed(() => {
  if (props.notification.is_read) return "mark_email_unread";
  return "done";
});
const color = computed(() => {
  if (props.notification.is_read) return "var(--ion-color-primary-shade)";
  return "var(--ion-color-secondary)";
});
</script>

<template>
  <div
    class="notification-hint clickable"
    :class="{ unread: !notification.is_read }"
    @click="emit('select', notification)"
  >
    <div class="notification-hint-label">
      <user-avatar :user="user" readonly force-visibility />
      <div class="notification-informations">
        <html-content tag="div" class="title" :content="title" />
        <div class="hint">{{ createdSince }}</div>
        <div class="hint comment" v-if="subText">
          {{ subText }}
        </div>
        <div class="hint comment" v-if="parentText">
          {{ parentText }}
        </div>
      </div>
      <div
        class="notification-icon"
        @click.stop.prevent="emit('toggle', notification)"
      >
        <font-icon
          class="clickable"
          :name="icon"
          :color="color"
          size="1"
          material
          outlined
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.notification-hint {
  background-color: var(--ion-background-color);
  padding: var(--f-padding-m);
  border-bottom: 1px solid var(--ion-border-color);
}

.notification-hint-label {
  display: flex;
  flex-direction: row;
  gap: var(--f-padding-m);
  font-size: var(--font-size-m);
  align-items: flex-start;
}

.notification-hint-label.sub-item {
  padding-left: calc(var(--f-padding-m) * 2);
  padding-top: var(--f-padding-m);
}

.notification-hint-label div.title {
  flex: 1;
  margin-bottom: calc(var(--f-padding-m) / 2);
  max-width: 100%;
}

.notification-hint-label .colored-chip {
  transform: scale(0.75);
  transform-origin: 100%;
}

.hint.comment {
  margin-top: var(--ion-padding);
  font-style: italic;
  max-width: 100%;
}

.notification-hint.unread {
  background-color: var(--f-item-selected);
}

.notification-informations {
  max-width: calc(100% - 73px);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title-wrapper {
  display: inline-flex;
  flex: 1 1 auto;
}

.reading {
  animation: fadeOut linear 1s, marginReturn linear 400ms;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes marginReturn {
  0% {
    margin-left: -200px;
    margin-right: 200px;
  }

  100% {
    margin-left: 0;
    margin-right: 0;
  }
}

/* .title,
.hint.comment {
  width: calc(100% - 24px - var(--f-padding-m));
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */
.notification-icon {
  padding: var(--ion-padding);
  margin: calc(var(--ion-padding) * -1);
}
</style>
<style>
.notification-hint mark {
  color: var(--ion-color-primary);
  font-weight: 500;
  background-color: transparent;
}
</style>
