import { modalController } from "@ionic/vue";
import AddFileModal from "@/components/modals/AddFileModal.vue";
import { useCloseModal } from "./useCloseModal";

export const useAddFileItem = (
  tunnel: (str: string) => void,
  addFile: (image: any) => void,
  mixpanelStr: string
) => {
  const { closeModal } = useCloseModal();
  const addFileItem = async () => {
    const modal = await modalController.create({
      component: AddFileModal,
      canDismiss: true,
      mode: "ios",
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        mixpanelStr,
        onDone: (image: any) => {
          closeModal(modal);
          if (!image) return;
          tunnel("add file");
          addFile(image);
        },
        onCancel: () => closeModal(modal),
      },
    });
    await modal.present();
  };

  return {
    addFileItem,
  };
};
