export const setCaret = (node: any) => {
  if (!node) return;
  const range = new Range();
  const sel = window.getSelection();
  if (!sel) return;
  const sibling = getComplexSibling(node);
  range.setStartAfter(sibling || node);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
};

const getComplexSibling = (node: Node): Node | null => {
  if (!node.nextSibling) return null;
  if ((<Element>node.nextSibling).classList?.contains("complex-html"))
    return node.nextSibling;
  return getComplexSibling(node.nextSibling);
};

export const getCaretPosition = (nodes: any, node?: any) => {
  if (window.getSelection) {
    const sel: Selection | null = window.getSelection();
    if (sel?.rangeCount) {
      const range: Range = sel.getRangeAt(0);
      return {
        position: range.startOffset * 1,
        node: range.startContainer,
        index: getNodeIndex(nodes, node || range.startContainer) * 1,
      };
    }
  }
  return { position: 0, index: 0 };
};

export const getNodeIndex = (nodes: NodeList, node: any): number => {
  let idx = 0;
  nodes.forEach((n: any, index: number) => {
    if (n === node) return (idx = index);
    if (n?.childNodes && n.childNodes[0] === node) return (idx = index);
  });
  return idx;
};

export const getNextIndex = (nodes: any, index: number): number => {
  if (index === 0 && nodes.length === 1) return 0;
  if (index + 2 > nodes.length - 1) return nodes.length - 1;
  return index + 2;
};

export const removeNode = (node: any) => {
  const range = new Range();
  range.setStartBefore(node);
  range.setEndAfter(node);
  range.deleteContents();
  const text = document.createElement("text");
  text.innerHTML = "&nbsp;";
  range.insertNode(text);
};

export const isComplexHtml = (
  node: Node | null | undefined,
  compare?: Node
) => {
  if (!node?.parentNode) return false;
  if (compare && node === compare) return false;
  if (compare && node.parentNode === compare) return false;
  return node.parentElement?.classList.contains("complex-html");
};

export const scrollSelectionIntoView = () => {
  const selection = window.getSelection();
  if (!selection?.rangeCount) return;
  const firstRange = selection.getRangeAt(0);
  if (firstRange.commonAncestorContainer === document) return;
  const tempAnchorEl = document.createElement("br");
  firstRange.insertNode(tempAnchorEl);
  tempAnchorEl.scrollIntoView({
    block: "end",
  });
  tempAnchorEl.remove();
};
