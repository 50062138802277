<template>
  <div class="avatar-list">
    <template v-for="avatar of avatars" :key="avatar.id">
      <user-avatar class="stacked" :user="avatar" />
    </template>
    <ion-avatar v-if="others">{{ others }}</ion-avatar>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import UserAvatar from "@/components/business/UserAvatar.vue";
import { IonAvatar } from "@ionic/vue";
import { User } from "@/types";

export default defineComponent({
  name: "AvatarList",
  components: {
    IonAvatar,
    UserAvatar,
  },
  props: {
    ids: {
      type: Array as PropType<Array<number>>,
      default: () => [],
    },
    users: {
      type: Array as PropType<Array<User>>,
      default: () => [],
    },
  },
  setup(props) {
    const avatars = computed(() => {
      if (!props.users || !props.users.length) return [];
      if (!props.ids || !props.ids.length) return [];
      const users = props.users;
      const avatars = props.ids.map(
        (id) => users.find((u: User) => u.id === id) || {}
      );
      return avatars.slice(0, 3);
    });
    const others = computed(() => {
      const nb = avatars.value.length - 3;
      return nb >= 0 ? nb : 0;
    });
    return {
      others,
      avatars,
    };
  },
});
</script>

<style scoped>
.avatar-list {
  display: flex;
}
.avatar-list .stacked:not(:first-child) {
  margin-left: -0.9rem;
}
.avatar-list .avatar {
  --_size: 1.5rem;
}
</style>
